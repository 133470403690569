import { isBlank } from "./utils";

export const validateInputControlThreeWordQuestion = (firstA, secondA, thirdA) => {
    const trimFirstA = firstA.trim();
    const trimSecondA = secondA.trim();
    const trimThirdA = thirdA.trim();

    function enteredTextIsValid(userInput) {
        const words = userInput.split(' ');

        // Function to check if a word contains three consecutive same letters
        function threeSameLetters(w) {
            for (let i = 0; i < w.length - 2; i++) {
                if (w[i] === w[i + 1] && w[i] === w[i + 2]) {
                    return true;
                }
            }
            return false;
        }

        // Function to check if a word contains repeated special characters
        function hasRepeatedSpecialCharacters(w) {
        const specialCharsPattern = /([!?:,.&/§+-])\1+/g;
        return specialCharsPattern.test(w);
        }

        // Function to check if a word contains only 2 different characters and has more than 6 characters
        function hasTwoDifferentCharacters(w) {
        const stringWithoutSpaces = w.replace(/\s/g, '');
        const uniqueChars = [...new Set(stringWithoutSpaces)]; // Convert the word to an array of unique characters
        return uniqueChars.length < 3  && stringWithoutSpaces.length > 6;
        }

        // Check conditions for entered text validity
        return (
        words.length < 5 // Less than 5 words
        && userInput.length < 35 // Less than 35 characters
        && !words.some(threeSameLetters) // No three consecutive same letters
        && userInput.length > 2 // At least 3 characters per input
        && !hasRepeatedSpecialCharacters(userInput) // No repeated special characters
        && !hasTwoDifferentCharacters(userInput) // No input with only 2 different characters and more than 6 characters
        )
    }

    if (
        trimFirstA === trimSecondA ||
        trimFirstA === trimThirdA ||
        trimSecondA === trimThirdA ||
        !enteredTextIsValid(trimFirstA) ||
        !enteredTextIsValid(trimSecondA) ||
        !enteredTextIsValid(trimThirdA)
    ) {
        return false;
    }

    return true;
}
  
export const extractMinMaxOfInterval = (valueParam) => {
    if(isBlank(valueParam)) return null;
  
    const regex = /\d+/g; // Find all occurrences of numbers in string
    const matches = valueParam.match(regex); // Find the numbers in the string
  
    if (matches && matches.length === 2) {
        return [parseInt(matches[0]), parseInt(matches[1])];
    } else {
        return null; // If less or more than 2 numbers found, return null
    }
}

  // this will extract number between parentheses or brackets: e.g const texte = "Normandie (27, 51, 76)" or "Ile de France [75, 78, 92]";
  export const extractListOfNumerics = (valueParam) => {
    if(isBlank(valueParam)) return null;

    // try to find between parentheses
    var matches = valueParam.match(/\((.*?)\)/);
    // try to find between brackets
    if(!matches) matches = valueParam.match(/\[(.*?)\]/);

    if (matches) {
      const results = isBlank(matches[1]) ? [] : matches[1].split(',').map(v => v.trim());
  
      // if some of the values AREN'T numeric return null
      if(results.length === 0 || results.filter(s => isNaN(s)).length > 0) return null;
  
      // convert to list of numbers and return
      return results.map(n => parseInt(n));
    }
  
    return null;
  }

  export const calculateWidthCol = (nbColumnsCata) => {
    return {
      width: nbColumnsCata === 1 && window.screen.width > 799 ? '50%' : nbColumnsCata === 2 ? '60%' : nbColumnsCata === 3 ? '70%' : '100%', marginLeft: nbColumnsCata === 1 && window.screen.width > 799 ? '25%' : nbColumnsCata === 2 ? '20%' : nbColumnsCata === 3 ? '15%' : '0px'
    };
  }

  export const isBlankHtml = (string) => {
    return isBlank(string) || isBlank(string.toString().replace(/<[^>]*>/g, ''));
  }

  export const modulesReactQuill = {
    toolbar: [
      [{size: []}],
      ['bold', 'italic', 'underline', 'strike'],
      [
          {
          color: ["#000000de", "#FF0000", "#00FF00", "#0000FF", "#00FFFF", "#FF00FF", "#808080", "#C0C0C0", "#87CEEB", "#32CD32", "#FFA500", "#FFC0CB", "#800080", "#A52A2A", "#40E0D0", "#FFDB58", "#000080"],
          },
      ],
      ['link'],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
      [{ 'indent': '-1'}, { 'indent': '+1' }],
      ],
      clipboard: {
      matchVisual: false
    }
  };

  /**
   * Profile question:
   * [Q1] => returns all answers separated by a comma ("Answer 1, Answer 2, Answer 3")
   * [Q1.A2] => returns only the second answer (e.g "Answer 2"), in single mode it's possible to write [Q1.A1]
   * [Q1.TEXT] => returns the text of the question
   * 
   * Battery of items:
   * [Q2.I1] => returns all answers separated by a comma for an item ("Answer 1, Answer 2, Answer 3")
   * [Q2.I1.A2] => returns only the second answer (e.g "Answer 2") to an item, in single mode it's possible to write [Q2.I1.A1]
   * [Q2.TEXT] => returns the text of the main question of the battery
   * [Q2.I1.TEXT] => returns the text of an item
   * 
   * Hotspot:
   * [Q3.Z1] => returns the answer for a zone (hotspot can only have 1 answer)
   * [Q3.Z1.A1] => it's possible to write this way to have the answer
   * [Q3.TEXT] => returns the text of the main question of the hotspot
   * [Q3.Z1.TEXT] => returns the text (name) of a zone
   * 
   */
  export const replaceDynamicElements = (sentence, allUserAnswers, globalVariables, blocksToDisplay) => {
    return sentence.replace(/\[(Q[0-9]{1,3}(\.[IZ][0-9]{1,3})?(\.[A][0-9]{1,3})?(\.TEXT)?|VAR_[0-9]{1,3})\]/gi, (match /* = [Q1.Z2] */, element /* = Q1.Z2 */) => {
        // "Q1.I2.A3" => ["Q1", "I2", "A3"] or "VAR_1" => ["VAR_1"]
        const parts = element.toUpperCase().split('.');

        // check if it's a variable
        if(parts[0].startsWith('VAR_')) {
          if(Array.isArray(globalVariables) && globalVariables.length > 0) {
            var correspondingGlobalVariable = globalVariables.find(v => v.code === parts[0]);
            if (correspondingGlobalVariable) {
              return (correspondingGlobalVariable.value || '');
            }
          }

          return match;
        }

        // we are looking for the TEXT of a Question, an Item or a Zone
        if(parts.filter(p => p === "TEXT").length > 0) {
          var text = undefined;

          var questionRef = parts.find(p => p.startsWith('Q'));
          var block = blocksToDisplay.find(b => b.ref === questionRef);

          if(block) {
            if(block.type === 'battery') {
              text = block.question;
              var itemRef = parts.find(p => p.startsWith('I'));
              if(itemRef) {
                var correspondingItem = block.battery.items.find(it => it.ref === itemRef);
                if(correspondingItem) text = correspondingItem.text;
              }
            } else if(block.type === 'hotspot') {
              text = block.question;
              var zoneRef = parts.find(p => p.startsWith('Z'));
              if(zoneRef) {
                var correspondingZone = block.hotspot.zones.find(it => it.ref === zoneRef);
                if(correspondingZone) text = correspondingZone.name;
              }
            } else if(block.type === 'question') {
              text = block.profile.question;
            }
          }

          return text 
            ? replaceDynamicElements((text || ''), allUserAnswers, globalVariables, blocksToDisplay)
            : match;
        }

        // search for the question only (eg. Q1.Z1) - rebuild questionRef with eligible parts
        var questionRef = parts.filter(p => p.startsWith('Q') || p.startsWith('I') || p.startsWith('Z')).join('.');
        var correspondingAnswer = allUserAnswers.find(a => a.questionRef === questionRef);

        if(correspondingAnswer) {
          if(!isBlank(correspondingAnswer.enteredValue)) {
            return correspondingAnswer.enteredValue;
          }

          if(Array.isArray(correspondingAnswer.answers) && correspondingAnswer.answers.length > 0) {
            // if we are looking for a specific answer index: eg. "Q1.I2.A3" (eg. case of multiple)
            var answerIndexField = parts.find(p => p.startsWith('A'));
            if(answerIndexField /* "A3" */) {
              var result = match;

              // "A3" => 3 - 1 => 2
              var realAnswerIndex = parseInt(answerIndexField.substring(1), 10 /* base 10 */) - 1;
              if(realAnswerIndex >= 0 && realAnswerIndex < correspondingAnswer.answers.length) {
                // R3MSCORE-845: as an answer can refer to a [VAR_XXX] we have to do a recursive call by security
                result = replaceDynamicElements((correspondingAnswer.answers[realAnswerIndex] || ''), allUserAnswers, globalVariables, blocksToDisplay);
              }

              return result;
            }

            // by default, returns values comma separated
            return correspondingAnswer.answers
              // R3MSCORE-845: as an answer can refer to a [VAR_XXX] we have to do a recursive call by security
              .map(oneAnswer => replaceDynamicElements((oneAnswer || ''), allUserAnswers, globalVariables, blocksToDisplay))
              .join(', ');
          }
        }

        // by default, return the match (eg. [Q1.Z2])
        return match;
      });
  }

  export const formatsReactQuill = ['bold', 'italic', 'underline', 'color', 'size', 'strike', 'list', 'indent', 'link'];

  export const IMAGE_WIDTH_AUTO = -1;
  export const IMAGE_WIDTH_100_PERCENT = -2;
