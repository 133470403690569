import { useState, useEffect } from 'react';
import '../../index.css';
import clsx from 'clsx';
import { makeStyles } from 'tss-react/mui';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Container from '@mui/material/Container';
import MenuIcon from '@mui/icons-material/Menu';
import Avatar from '@mui/material/Avatar';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import DashboardMenu from './DashboardMenu';
import Copyright from '../../components/shared/Copyright';
import { AuthService } from '../../services/AuthService';
import Tooltip from '@mui/material/Tooltip';
import NotificationService from '../../services/NotificationService';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import PreferenceService from '../../services/PreferenceService';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {NewVersionWatcher} from '../../components/NewVersionWatcher'
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import ISO6391 from 'iso-639-1';
import TranslationService from '../../services/TranslationService';
import Flag from '../../components/shared/Flag/Flag';
import { TbWorldCog } from "react-icons/tb";
import UserService from '../../services/UserService';
import CheckIcon from '@mui/icons-material/Check';

const preferenceService = new PreferenceService();
const notificationService = new NotificationService();
const translationService = new TranslationService();
const userService = new UserService();

const drawerWidth = 240;

const useStyles = makeStyles()((theme) => {
  return {
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  underSupervisionToolbar: {
    paddingRight: 24, // keep right padding when drawer closed
    backgroundColor: '#ba000d',
  },
  toolbarMain: {
    display: 'flex',
    maxHeight: '64px',
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  toolbarlogo: {
    width: '72%',
    marginLeft: '5px'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#003945',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  normalAvatar: {
    margin: 10,
    color: '#fff',
    backgroundColor: '#00e2dc',
  },
  notificationNumber: {
    backgroundColor: '#00e2dc',
  },
  whiteNotification: {
    color: '#fff',
  },
  noPadding: {
    padding: '0px',
  },
  supervisedUserCircleIcon: {
    verticalAlign: 'bottom',
    margin: '0px 7px 0 7px',
  },
}
});

export default function Dashboard(props) {

  const { t, maxWidth, children } = props;

  const {classes} = useStyles();

  const [open, setOpen] = useState(true);
  const [languages, setLanguages] = useState([]);

  const handleDrawerState = (isOpen) => {
    setOpen(isOpen);
    preferenceService.setMenubarState(isOpen ? 'open' : 'close');
  };

  useEffect(() => {
    var promises = [
      preferenceService.getMenubarState(),
      translationService.getSupportedLanguages()
    ]

    Promise.all(promises)
      .then(function (results) {
        setOpen(results[0].data === 'open');
        setLanguages(results[1].data);
      })
      .catch(err => {
        openSnackbar('error', t("react.codification.entries.error.while.deleting"));
      });
  }, []);

  const [delay, setDelay] = useState(1000);
  const [notifications, setNotifications] = useState({ 'total': 0 });

  useEffect(() => {
    // security if disabled or to avoid to many calls
    if (delay < 1000) return;

    const interval = setInterval(() => {
      // request just 1 notification because we just need the size
      notificationService.getMyNotifications(0, 1)
        .then(result => {
          setNotifications(result.data);
          // on success keep (or reset after error) delay to 60000
          setDelay(60000);
        })
        .catch(err => {
          // double delay on error and disable if >= 30 min
          let newDelay = delay < 60000 /* after first run */ ? 60000 /* delay to 1 min */ : delay * 2 /* else double */;
          setDelay(newDelay < 1800000 /* 30 min */ ? newDelay : 0);
          console.error(err);
        });
    }, delay);
    return () => clearInterval(interval);
  }, [delay]);

  const changeLanguage = (language) => {
    userService.changeLanguage(AuthService.getUser().id, language)
      .then((result) => {
        AuthService.updateCurrentUserPreferredLanguage(language);
      })
      .catch(err => {
      });

    handleCloseLanguages();
  }

  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenLanguages = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseLanguages = () => {
    setAnchorEl(null);
  };

  const openLanguages = Boolean(anchorEl);
  const id = openLanguages ? 'simple-popover' : undefined;

  return (
    <div className={classes.root}>
      <NewVersionWatcher {...props} />
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={AuthService.getUser().isUnderSupervision ? classes.underSupervisionToolbar : classes.toolbar} >
          <Tooltip title={t('react.dashboard.header.showmainmenu.tooltip')}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => handleDrawerState(true)}
              className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
              size="large">
              <MenuIcon />
            </IconButton>
          </Tooltip>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            {AuthService.getUser().isUnderSupervision &&
              <Tooltip title={t('react.dashboard.header.title.superviseduser.tooltip')}>
                <SupervisedUserCircleIcon fontSize="large" className={classes.supervisedUserCircleIcon} />
              </Tooltip>
            }
            {t('react.dashboard.header.title')}
          </Typography>
          <Tooltip title={notifications.total === 0 ? t('react.dashboard.header.notifications.tooltip.none') : t('react.dashboard.header.notifications.tooltip')}>
            <IconButton
              className={classes.whiteNotification}
              onClick={() => props.navigate('/dashboard')}
              size="large">
              <Badge badgeContent={notifications.total} max={999} classes={{ badge: classes.notificationNumber }} overlap="rectangular">
                <NotificationsIcon />
              </Badge>
            </IconButton>
          </Tooltip>
          <Tooltip title={t('react.dashboard.header.change.language')}>
            <IconButton color="inherit" onClick={handleOpenLanguages} size="large">
              <TbWorldCog />
            </IconButton>
          </Tooltip>
          <Popover
            id={id}
            open={openLanguages}
            anchorEl={anchorEl}
            onClose={handleCloseLanguages}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <ClickAwayListener onClickAway={handleCloseLanguages}>
              <MenuList id="split-button-menu" autoFocusItem>
                {languages.map((lang, index) => {
                  return (
                    <MenuItem key={index} onClick={e => changeLanguage(lang)}>
                      <Flag language={lang}/>&nbsp;{ISO6391.getNativeName(lang)}
                      {AuthService.getUser().preferredLanguage === lang &&
                        <>&nbsp;<CheckIcon color="success" /></>
                      }
                    </MenuItem>
                  )
                })}
              </MenuList>
            </ClickAwayListener>
          </Popover>
          <Tooltip title={t('react.dashboard.header.documentation')}>
            <IconButton color="inherit" onClick={() => props.navigate('/doc')} size="large">
              <HelpOutlineIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={t('react.dashboard.header.logout.tooltip')}>
            <IconButton color="inherit" href="/logout" size="large">
              <ExitToAppIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={t('react.dashboard.header.account.tooltip', { username: AuthService.getUserFullname() })}>
            <IconButton
              color="inherit"
              className={classes.noPadding}
              onClick={() => props.navigate(`/users/${AuthService.getUser().id}`)}
              size="large">
              <Avatar className={classes.normalAvatar} >{AuthService.getUserInitials()}</Avatar>
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarMain}>
          <div className={classes.toolbarlogo}>
            <img src="/img/logo_100x120.png" alt='R3m Score' height='60px' />
          </div>
          <div className={classes.toolbarIcon}>
            <Tooltip title={t('react.dashboard.header.hidemainmenu.tooltip')}>
              <IconButton onClick={() => handleDrawerState(false)} size="large">
                <ChevronLeftIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <Divider />
        <DashboardMenu {...props} open={open}/>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth={maxWidth} className={classes.container}>
          <div className="main">{children}</div>
        </Container>
        <Copyright />
      </main>
    </div>
  );
}
