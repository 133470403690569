import React, { useState } from 'react';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import FolderIcon from '@mui/icons-material/Folder';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import Toolbar from '@mui/material/Toolbar';
import Link from '@mui/material/Link';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import SecurityIcon from '@mui/icons-material/Security';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import Switch from '@mui/material/Switch';
import FlashAutoIcon from '@mui/icons-material/FlashAuto';
import PopupTwoButtons from '../shared/PopupTwoButtons/PopupTwoButtons';
import Filter1Icon from '@mui/icons-material/Filter1';
import Filter2Icon from '@mui/icons-material/Filter2';
import Filter3Icon from '@mui/icons-material/Filter3';
import Filter4Icon from '@mui/icons-material/Filter4';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { AuthService } from '../../services/AuthService';
import Input from '@mui/material/Input';
import ClearIcon from '@mui/icons-material/Clear';
import InputAdornment from '@mui/material/InputAdornment';
import ClearAllIcon from '@mui/icons-material/ClearAll';

import { toolbarStyles, ExportIcon } from '../../common.js';

export default function GroupingToolbar(props) {

    const { classes } = toolbarStyles();

    const {
        t, handleNewTag, handleNewGroup, handleClickDownloadGroupingFile, handleClickCopyGrouping, keywords, setKeywords,
        enableTranslations, displayTranslations, handleDisplayTranslations, handleAutoGenGroupingCallback, handleExpandBucket, 
        removeAllEmptyGroups } = props;

    const [openConfirmAutoGen, setOpenConfirmAutoGen] = useState(false);
    const [anchorEl, setAnchorEl] = useState({ first: null, second: null, third: null, fourth: null });

    const confirmAutoGen = () => {
        setOpenConfirmAutoGen(false);
        handleAutoGenGroupingCallback();
    };

    const handleOpenColumnMenu = (columnName) => (event) => {
        setAnchorEl({ ...anchorEl, [columnName]: event.currentTarget });
    }

    const handleApplyAndClose = (columnName, bucketIndex = undefined, expanded = undefined) => {
        setAnchorEl({ ...anchorEl, [columnName]: null });
        if(bucketIndex !== undefined && expanded !== undefined) handleExpandBucket(bucketIndex, expanded);
    }

    return (
        <Toolbar className={classes.root}>
            <PopupTwoButtons
                variant='warning'
                openState={openConfirmAutoGen}
                callbackOnclose={() => setOpenConfirmAutoGen(false)}
                callbackOnclickLeftButton={() => setOpenConfirmAutoGen(false)}
                callbackOnclickRightButton={confirmAutoGen}
                title={t('react.grouping.toolbar.button.auto-gen.title')}
                content={t('react.grouping.toolbar.button.auto-gen.body')}
                leftButton={t('react.button.cancel')}
                rightButton={t('react.button.confirm')}
            />
            <BubbleChartIcon className={classes.mainIcon} />
            <Link href='#' onClick={() => props.navigate('/projects/' + props.projectId)} style={{ width: '100%', color: "black" }}>
                <Typography className={classes.title} variant="h6" id="tableTitle">
                    {props.title}
                </Typography>
            </Link>
            <Input
              id="search-in-all-groups"
              value={keywords}
              className={classes.searchfield}
              placeholder={t('react.grouping.filter.label')}
              onChange={e => setKeywords(e.target.value)}
              autoComplete="off"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={() => setKeywords('')} edge="end" size="large">
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
            <Tooltip title={t('react.button.grouping.fold.column', {'columnIdx': 1})}>
                <IconButton onClick={handleOpenColumnMenu('first')} size="large">
                    <Filter1Icon color="primary" />
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl.first}
                keepMounted
                open={Boolean(anchorEl.first)}
                onClose={() => handleApplyAndClose('first')}
            >
                <MenuItem onClick={() => handleApplyAndClose('first', 1, true)}>{t('react.button.grouping.column.open')}</MenuItem>
                <MenuItem onClick={() => handleApplyAndClose('first', 1, false)}>{t('react.button.grouping.column.close')}</MenuItem>
            </Menu>
            <Tooltip title={t('react.button.grouping.fold.column', {'columnIdx': 2})}>
            <IconButton onClick={handleOpenColumnMenu('second')} size="large">
                    <Filter2Icon color="primary" />
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl.second}
                keepMounted
                open={Boolean(anchorEl.second)}
                onClose={() => handleApplyAndClose('second')}
            >
                <MenuItem onClick={() => handleApplyAndClose('second', 2, true)}>{t('react.button.grouping.column.open')}</MenuItem>
                <MenuItem onClick={() => handleApplyAndClose('second', 2, false)}>{t('react.button.grouping.column.close')}</MenuItem>
            </Menu>
            <Tooltip title={t('react.button.grouping.fold.column', {'columnIdx': 3})}>
                <IconButton onClick={handleOpenColumnMenu('third')} size="large">
                    <Filter3Icon color="primary" />
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl.third}
                keepMounted
                open={Boolean(anchorEl.third)}
                onClose={() => handleApplyAndClose('third')}
            >
                <MenuItem onClick={() => handleApplyAndClose('third', 3, true)}>{t('react.button.grouping.column.open')}</MenuItem>
                <MenuItem onClick={() => handleApplyAndClose('third', 3, false)}>{t('react.button.grouping.column.close')}</MenuItem>
            </Menu>
            <Tooltip title={t('react.button.grouping.fold.column', {'columnIdx': 4})}>
                <IconButton onClick={handleOpenColumnMenu('fourth')} size="large">
                    <Filter4Icon color="primary" />
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl.fourth}
                keepMounted
                open={Boolean(anchorEl.fourth)}
                onClose={() => handleApplyAndClose('fourth')}
            >
                <MenuItem onClick={() => handleApplyAndClose('fourth', 4, true)}>{t('react.button.grouping.column.open')}</MenuItem>
                <MenuItem onClick={() => handleApplyAndClose('fourth', 4, false)}>{t('react.button.grouping.column.close')}</MenuItem>
            </Menu>
            <Tooltip title={t("react.project.edit.codiftab.translate.entries")}>
                <span>
                    <Switch
                        disabled={!enableTranslations}
                        checked={displayTranslations}
                        onChange={handleDisplayTranslations}
                        color="primary"
                        name="translate-switch"
                    />
                </span>
            </Tooltip>
            <Tooltip title={t('react.button.creategroup')}>
                <IconButton onClick={handleNewGroup} size="large">
                    <FolderIcon color="primary" />
                </IconButton>
            </Tooltip>
            <Tooltip title={t('react.button.createtag')}>
                <IconButton onClick={handleNewTag} size="large">
                    <LocalOfferIcon color="primary" />
                </IconButton>
            </Tooltip>
            <Tooltip title={t('react.grouping.toolbar.button.remove.empty')} onClick={removeAllEmptyGroups}>
                <IconButton size="large">
                    <ClearAllIcon color="primary" />
                </IconButton>
            </Tooltip>
            <Tooltip title={t('react.grouping.toolbar.button.auto-gen')}>
                <IconButton
                    onClick={() => setOpenConfirmAutoGen(true)}
                    size="large">
                    <FlashAutoIcon color="primary" />
                </IconButton>
            </Tooltip>
            <Tooltip title={t('react.grouping.toolbar.button.copy-from')}>
                <IconButton onClick={handleClickCopyGrouping} size="large">
                    <FileCopyIcon color="primary" />
                </IconButton>
            </Tooltip>
            <Tooltip title={t('react.grouping.toolbar.button.export')}>
                <IconButton
                    onClick={handleClickDownloadGroupingFile}
                    size="large">
                    <ExportIcon color="primary" />
                </IconButton>
            </Tooltip>
            <Tooltip title={t('react.project.edit.view.results')}>
                <IconButton
                    onClick={() => props.navigate(`/projects/${props.projectId}/dashboard`)}
                    size="large">
                    <InsertChartIcon color="primary" />
                </IconButton>
            </Tooltip>
            {AuthService.isAdmin() &&
                <Tooltip title={t('react.dashboard.toolbar.button.project.admin')}>
                    <IconButton
                        onClick={() => props.navigate(`/projects/${props.projectId}/admin`)}
                        size="large">
                        <SecurityIcon color="primary" />
                    </IconButton>
                </Tooltip>
            }
        </Toolbar>
    );
};
