import React, { useState, useContext, useEffect, useReducer } from 'react';
import { makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PopupConditions from './PopupConditions';
import { SurveyContext } from './context';
import { indexOf, isBlank, cloneDeep, stripHtmlTags } from '../../utils/utils';
import { Typography } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const useStyles = makeStyles()(theme => ({
    paperAction: {
        textAlign: 'center',
        padding: '10px',
        marginBottom: '15px'
    },
    paper: {
        padding: '10px',
        marginBottom: '15px'
    },
    actions: {
        display: 'flex', flexFlow: 'row-reverse', marginTop: '-10px', marginBottom: '-10px'
    },
    typographyOfBlock: {
        marginBottom: '10px'
    },
    logicZone: {
        fontSize: '16px'
    },
    tooltip: {
        width: '40px'
    },
    divLogicBlocksConditions: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: 0
    },
    marginRight5: {
        marginRight: 5
    },
    marginRight2: {
        marginRight: 2
    },
    flexWrap: {
        display: 'flex',
        flexFlow: 'wrap',
        height: 30
    },
    marginNegativeLeft: {
        marginLeft: -2
    },
    widthFunction: {
        width: 22,
    },
    marginTop5: {
        marginTop: 5
    },
    flexMarginTopBottom: {
        display: 'flex',
        marginTop: 2,
        marginBottom: 2
    },
    leftLine: {
        height: 1,
        width: '40%',
        backgroundColor: 'rgba(0,0,0,0.2)',
        marginTop: 13,
        marginRight: '5%'
    },
    rightLine: {
        height: 1,
        width: '40%',
        backgroundColor: 'rgba(0,0,0,0.2)',
        marginTop: 13,
        marginLeft: '5%'
    },
    or: {
        width: 40,
        fontSize: 18,
        display: 'flex',
        justifyContent: 'center',
        color: 'rgba(0,0,0,0.7)'
    }
}));

const compartmentalizedConditionsTemplate = {
    'ofBlock': [], 
    'ofItems': {}
};

const editConditionTemplate = {
    'uuid': undefined, 
    'conditionedUuid': undefined
};

export default function MenuConditions(props) {

    const { classes } = useStyles();

    const { t, currentBlock, conditionners, setConditionners, 
        selectedIndex, getQuestionIndexByBlockUuid, conditionService } = props;

    const { getAllBlocks, setNeedToSave } = useContext(SurveyContext);

    const [compartmentalizedConditions, setCompartmentalizedConditions] = useState(
        cloneDeep(compartmentalizedConditionsTemplate));

    const [displayedIndex, setDisplayedIndex] = useState(-1);

    const [openPopupConditions, setOpenPopupConditions] = useState(false);
    const [editCondition, setEditCondition] = useState(cloneDeep(editConditionTemplate));
    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

    useEffect(() => {
        refreshAllConditions(conditionners);
        setDisplayedIndex(selectedIndex);
    }, [selectedIndex, currentBlock, conditionners])

    const refreshAllConditions = (incomingConditionners) => {
        if (currentBlock === undefined || incomingConditionners === undefined) {
            setCompartmentalizedConditions(
                cloneDeep(compartmentalizedConditionsTemplate));
            return;
        }

        // get conditions applied to the block itself
        let ofBlock = incomingConditionners.filter(conditionner => conditionner.conditionedUuids.includes(currentBlock.uuid));

        // map each answer of currentBlock with there conditions
        let ofItems = {};

        if (currentBlock.type === 'question') {
            currentBlock.configuration.question.answers.forEach(answer => {
                let oa = incomingConditionners.filter(conditionner => conditionner.conditionedUuids.includes(answer.uuid));
                if(oa.length > 0) {
                    ofItems[answer.uuid] = oa;
                }
            });
        } else if (currentBlock.type === 'hotspot') {
            currentBlock.configuration.hotspot.zones.forEach(zone => {
                let oa = incomingConditionners.filter(conditionner => conditionner.conditionedUuids.includes(zone.uuid));
                if(oa.length > 0) {
                    ofItems[zone.uuid] = oa;
                }
            });
        } else if (currentBlock.type === 'battery') {
            currentBlock.configuration.battery.items.forEach(item => {
                let oa = incomingConditionners.filter(conditionner => conditionner.conditionedUuids.includes(item.uuid));
                if(oa.length > 0) {
                    ofItems[item.uuid] = oa;
                }
            });
        }

        setCompartmentalizedConditions({
            'ofBlock': ofBlock, 
            'ofItems': ofItems
        });
    };

    const addCondition = () => {
        setEditCondition(cloneDeep(editConditionTemplate));
        setOpenPopupConditions(true);
    }

    const editConditionForConditionedUuid = (conditionUuid, conditionedUuid) => {
        setEditCondition({'uuid': conditionUuid, 'conditionedUuid': conditionedUuid});
        setOpenPopupConditions(true);
    }

    const removeUuidFromConditionedUuids = (conditionUuid, conditionedUuid) => {
        // remove the currentBlock from the condition that match the conditionUuid
        let newconditionners = [...conditionners];
        let c = newconditionners.find(conditionner => conditionner.uuid === conditionUuid);
        c.conditionedUuids = c.conditionedUuids.filter(it => it !== conditionedUuid);

        // refresh this component
        refreshAllConditions(newconditionners);

        // refresh all
        setConditionners(newconditionners);
        setNeedToSave(true);
        forceUpdate();
    }

    const callBackConfirmConditions = (modifiedConditionner, conditionName, conditionedUuid) => {
        // ----------------------------------------------------
        // update the name
        modifiedConditionner.name = conditionName;

        // associeted block to condition
        let idx1 = modifiedConditionner.conditionedUuids.indexOf(conditionedUuid);
        if(idx1 < 0) modifiedConditionner.conditionedUuids.push(conditionedUuid);

        // ----------------------------------------------------

        let newconditionners = [...conditionners];
        let idxOfCondition = indexOf(newconditionners, 'uuid', modifiedConditionner.uuid);
        if(idxOfCondition < 0) {
            newconditionners.push(modifiedConditionner);
        } else {
            newconditionners.splice(idxOfCondition, 1, modifiedConditionner);
        }

        // refresh this component
        refreshAllConditions(newconditionners);

        // refresh all
        setConditionners(newconditionners);

        setNeedToSave(true);
        forceUpdate();
        setOpenPopupConditions(false);
        setEditCondition(cloneDeep(editConditionTemplate));
    }

    const callBackCancelConditions = () => {
        setOpenPopupConditions(false);
        setEditCondition(cloneDeep(editConditionTemplate));
    }

    const hasQuestionsBeforeCurrentBlock = () => {
        return getAllBlocks()
            .findIndex((element, index) => index < selectedIndex && (element.type === 'question' || element.type === 'hotspot' || element.type === 'battery')) >= 0;
    }

    const renderConditionners = (conditionners, itemUuid = undefined /* can be undefined */) => {
        if(conditionners == undefined || conditionners.length <= 0) return null;

        let conditionedUuid = itemUuid === undefined ? currentBlock.uuid : itemUuid;

        let typographyText = t('react.survey.conditions.ofBlock');
        if(itemUuid !== undefined) {
            if(currentBlock.type === 'question') {
                let answer = currentBlock.configuration.question.answers.find(a => a.uuid === itemUuid);
                typographyText = t('react.survey.conditions.ofAnswer', {'answerValue': stripHtmlTags(answer.answer)});
            } else if(currentBlock.type === 'hotspot') {
                let zone = currentBlock.configuration.hotspot.zones.find(z => z.uuid === itemUuid);
                typographyText = t('react.survey.conditions.ofAnswer', {'answerValue': zone.name});
            } else if(currentBlock.type === 'battery') {
                let item = currentBlock.configuration.battery.items.find(i => i.uuid === itemUuid);
                typographyText = t('react.survey.conditions.ofAnswer', {'answerValue': stripHtmlTags(item.text)});
            }
        }

        return (
            <Paper className={classes.paper}>
                <Typography className={classes.typographyOfBlock}>
                    {typographyText}
                </Typography>
                {conditionners.map((conditionner, index) => (
                    <div key={index}>
                        <div className={classes.logicZone}>
                            <div className={classes.marginTop5}>
                                {!isBlank(conditionner.name) ? conditionner.name : renderConditionner(conditionner)}
                            </div>
                            <div className={classes.actions}>
                                <Tooltip className={classes.tooltip} title={t('react.survey.conditions.delete')}><span>
                                    <IconButton
                                        onClick={() => removeUuidFromConditionedUuids(conditionner.uuid, conditionedUuid)}
                                        size="large">
                                        <DeleteIcon />
                                    </IconButton></span>
                                </Tooltip>
                                <Tooltip className={classes.tooltip} title={t('react.survey.conditions.edit')}><span>
                                    <IconButton
                                        onClick={() => editConditionForConditionedUuid(conditionner.uuid, conditionedUuid)}
                                        size="large">
                                        <EditIcon />
                                    </IconButton></span>
                                </Tooltip>
                            </div>
                        </div>
                        {conditionners[index + 1] !== undefined &&
                            <div className={classes.flexMarginTopBottom}>
                                <div className={classes.leftLine} />
                                <div className={classes.or}>{t('react.survey.conditions.or')}</div>
                                <div className={classes.rightLine} />
                            </div>
                        }
                    </div>
                ))}
            </Paper>
        );
    }

    const renderConditionner = (conditionner) => {
        const getAnswerIndex = (block, parentUuid, answerUuid) => {
            if(block.type === 'question') {
                let idx = block.configuration.question.answers.findIndex(a => a.uuid === answerUuid);
                return idx >= 0 ? `A${idx + 1}` : 'xx';
            } else if(block.type === 'hotspot') {
                let zIdx = block.configuration.hotspot.zones.findIndex(z => z.uuid === parentUuid);
                let aIdx = block.configuration.hotspot.answers.findIndex(a => a.uuid === answerUuid);
                return zIdx >= 0 && aIdx >= 0 ? `Z${zIdx + 1}.A${aIdx + 1}` : 'xx';
            } else if(block.type === 'battery') {
                let iIdx = block.configuration.battery.items.findIndex(i => i.uuid === parentUuid);
                let aIdx = block.configuration.battery.answers.findIndex(a => a.uuid === answerUuid);
                return iIdx >= 0 && aIdx >= 0 ? `I${iIdx + 1}.A${aIdx + 1}` : 'xx';
            }
            return '';
        };
        if(conditionner === undefined) return;

        var lines = [];

        for(let i = 0; i < conditionner.conditions.length; i++) {
            const dataBag = conditionner.conditions[i];

            let concernedBlock = getAllBlocks().find(block => conditionService.isConditionableElementOfBlock(block, dataBag.parentUuid));
            if(concernedBlock === undefined) continue;

            let questionNumber = getQuestionIndexByBlockUuid(concernedBlock.uuid);

            const hasDataBagType0 = dataBag.groups.filter(g => g.type === 0).length > 0;
            const hasDataBagType1 = dataBag.groups.filter(g => g.type === 1).length > 0;
            const hasDataBagType2 = dataBag.groups.filter(g => g.type === 2).length > 0;
            if(hasDataBagType0) {
                const dataBagType0 = dataBag.groups.filter(g => g.type === 0)[0];

                if(dataBagType0.elements.length > 0) {
                    let subStr = '';

                    for(let element of dataBagType0.elements) {
                        if(subStr.length > 0) subStr+= ` ${t('react.survey.conditions.logic.and')} `;
                        subStr += ` ${t('react.survey.conditions.logic.not.answered')} Q${questionNumber}.${getAnswerIndex(concernedBlock, dataBag.parentUuid, element.itemUuid)} `;
                    }
    
                    lines.push(subStr)
                }
            } else if (hasDataBagType1) {
                const dataBagType1 = dataBag.groups.filter(g => g.type === 1)[0];

                if(dataBagType1.elements.length > 0) {
                    let subStr = '';

                    for(let element of dataBagType1.elements) {
                        if(subStr.length > 0) subStr+= ` ${t('react.survey.conditions.logic.or')} `;
                        subStr += ` Q${questionNumber}.${getAnswerIndex(concernedBlock, dataBag.parentUuid, element.itemUuid)} `;
                    }

                    subStr = ` ${t('react.survey.conditions.logic.answered')} (${subStr.trim()}) `
                    lines.push(subStr)
                }
            }

            if(hasDataBagType2) {
                const dataBagType2 = dataBag.groups.filter(g => g.type === 2)[0];

                if(dataBagType2.elements.length > 0) {
                    let subStr = '';

                    for(let element of dataBagType2.elements) {
                        if(subStr.length > 0) subStr+= ` ${t('react.survey.conditions.logic.and')} `;
                        subStr += ` Q${questionNumber}.${getAnswerIndex(concernedBlock, dataBag.parentUuid, element.itemUuid)} `;
                    }

                    subStr = ` ${t('react.survey.conditions.logic.answered')} (${subStr.trim()}) `
                    lines.push(subStr)
                }
            }
        }

        return (
            <div className={classes.divLogicBlocksConditions} key={conditionner.uuid}>
                {lines.map((line, index) => {
                    return (
                        <span key={index}>
                            {index > 0 && <span key={`and-${index}`} className={classes.marginRight2}>{t('react.survey.conditions.logic.and')}</span>}
                            <span className={classes.marginRight5}>{line}</span>
                        </span>);
                })}
            </div>
        );
    }

    if (currentBlock === undefined || compartmentalizedConditions === undefined || selectedIndex !== displayedIndex) return null;

    return (
        <>
            <PopupConditions
                {...props}
                openPopupConditions={openPopupConditions}
                editCondition={editCondition}
                currentBlock={currentBlock}
                renderConditionner={renderConditionner}
                callBackConfirmConditions={callBackConfirmConditions}
                callBackCancelConditions={callBackCancelConditions}
            />
            <Paper className={classes.paperAction}>
                <Typography variant="h6" component="h6" >
                    <Button className={classes.paramButton}
                        disabled={!hasQuestionsBeforeCurrentBlock()}
                        variant="text" color="primary"
                        startIcon={<AddCircleOutlineIcon />}
                        onClick={() => addCondition()}>{t('react.survey.conditions.add')}</Button>
                </Typography>
            </Paper>
            {renderConditionners(compartmentalizedConditions.ofBlock)}
            {Object.keys(compartmentalizedConditions.ofItems).map((key) => (
                renderConditionners(compartmentalizedConditions.ofItems[key], key)))}
        </>
    )
}
