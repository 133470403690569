import React, {useState, useEffect} from 'react';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import {capitalizeFirstLetter, appendSuffixIfGreaterThan} from '../../utils/utils';
import {DashboardConstants, getViewThemes, HIGHT_SCORE_LIMIT, LOW_SCORE_LIMIT} from '../../Constants';

const viewThemes = getViewThemes();

const width = 100;

export default function GroupChart(props) {

  const {t, color, configuration, specifications, data, hideArrows, comparationMap, dashboardService} = props;

  const [iconTheme, setIconTheme] = useState(viewThemes[viewThemes.default]);

  const compareWithMap = comparationMap ? comparationMap : {};

  var maxGroupSizes = 0;
  if(configuration.alignView) {
    maxGroupSizes = specifications.groups.maxGroupSizes[color];
  } else {
    maxGroupSizes = 0;
    data
      // print groups out of the RED ZONE (not inside limits) or diamond or relevant (@see DashboardService)
      .filter(d => !dashboardService.isInRedZone(d.score, configuration) || d.diamond || d.relevant)
      .forEach((item, i) => {
        maxGroupSizes++;
      });
  }

  useEffect(() => {
    if (configuration.iconTheme && viewThemes[configuration.iconTheme]) {
      setIconTheme(viewThemes[configuration.iconTheme]);
    }
  }, [configuration.iconTheme]);

  if(maxGroupSizes === 0) {
    // skip this bloc if there is nothing to print
    return null;
  }

  return (
    <div style={{width: `100%`, marginTop:'3px'}}>
      <div style={{height: `${maxGroupSizes*25}px`}} id={color}>
      {
        data
          // print groups out of the RED ZONE (not inside limits) or diamond or relevant (@see DashboardService)
          // .filter(d => d.score < configuration.low || d.score > configuration.high || d.diamond || d.relevant /* || grp.missing => missing have score eq to HIGHT_SCORE_LIMIT or LOW_SCORE_LIMIT */)
          .filter(d => !dashboardService.isInRedZone(d.score, configuration) || d.diamond || d.relevant /* || grp.missing => missing have score eq to HIGHT_SCORE_LIMIT or LOW_SCORE_LIMIT */)
          .map(d=>{
            // compute title and tooltip
            let titleCapitalized = capitalizeFirstLetter(d.title);
            let title = appendSuffixIfGreaterThan(titleCapitalized, '...', 30);
            let tooltip = t("react.dashboard.groupchart.tooltip", {title: titleCapitalized, score: Math.round(d.score), nbOccurrences: d.nbOccurrences});

            // compute size of the bar
            let size = 0;
            let ratio = dashboardService.computeBarScoreRatio(d.score, specifications.groups.absMaxScore);

            if(d.score !== LOW_SCORE_LIMIT && d.score !== HIGHT_SCORE_LIMIT) {
              size = Math.round(Math.abs(width * ratio) * 0.5);
              if(size < 4) {
                size = 4;
              }
            }

            // compute compare informations
            let groupIcon = undefined;

            if(d.diamond) {
              groupIcon = iconTheme['diamondIcon'];
            } else if (d.missing) {
              groupIcon = iconTheme['missingIcon'];
              tooltip = t("react.dashboard.groupchart.tooltip", {title: title, score: 'n.a.', nbOccurrences: 0});

              if(configuration.missingGroupsUseTheme && compareWithMap[d.id] !== undefined && compareWithMap[d.id] !== HIGHT_SCORE_LIMIT & compareWithMap[d.id] !== LOW_SCORE_LIMIT) {
                let scoreDiff = compareWithMap[d.id] - 0 /* USE 0 because current group is marked as MISSING */;

                if(Math.abs(scoreDiff) > configuration.comparisonTriggeringValue) {
                  groupIcon = scoreDiff < 0 ? iconTheme['upMissingIcon'] : iconTheme['downMissingIcon'];
                }
              }
            } else if (d.relevant) {
              groupIcon = iconTheme['missingIcon'];
              tooltip = t("react.dashboard.groupchart.tooltip", {title: title, score: Math.round(d.score), nbOccurrences: d.nbOccurrences});

              if(configuration.missingGroupsUseTheme && compareWithMap[d.id] !== undefined && compareWithMap[d.id] !== HIGHT_SCORE_LIMIT & compareWithMap[d.id] !== LOW_SCORE_LIMIT) {
                let scoreDiff = compareWithMap[d.id] - d.score;

                if(Math.abs(scoreDiff) > configuration.comparisonTriggeringValue) {
                  groupIcon = scoreDiff < 0 ? iconTheme['upMissingIcon'] : iconTheme['downMissingIcon'];
                }
              }
            } else if(!hideArrows) {
              let scoreDiff = 0;

              if(compareWithMap[d.id] !== undefined && compareWithMap[d.id] !== HIGHT_SCORE_LIMIT & compareWithMap[d.id] !== LOW_SCORE_LIMIT) {
                scoreDiff = compareWithMap[d.id] - d.score;
              } else {
                scoreDiff = 0 /* USE 0 because opposite group is MISSING */ - d.score;
              }

              groupIcon = scoreDiff < 0 ? iconTheme['upIcon'] : iconTheme['downIcon'];

              // do not diplay the icon if under the activation limit
              if(Math.abs(scoreDiff) <= configuration.comparisonTriggeringValue) {
                groupIcon = undefined;
              }
            }

          return (
            <Tooltip title={tooltip} key={d.id} style={{height: '25px'}}>
                <div>
                  <div style={{display:'inline-flex', marginLeft:'10px'}}>
                    <div id="bar-horzontal" style={{
                      width: `${width}px`,
                      height: `20px`,
                      marginRight:'10px',
                      marginBottom:'3px',
                      marginTop:'2px',
                      borderWidth:'1px'}}>
                          <div  style={{
                                width: `${size}px`,
                                height: `19px`,
                                left:`${(ratio > 0) ? 50 : (50 - size)}px`, position:'relative',
                                backgroundColor: d.missing || d.diamond || d.relevant ? null : `${ratio > 0 ? DashboardConstants.positiveColor : DashboardConstants.negativeColor}`,
                                borderStyle: d.missing || d.diamond || d.relevant ? null : "solid",
                                borderRadius: '5px',
                                borderWidth:'1px'
                              }}
                          >
                            &nbsp;
                          </div>
                       </div>
                       <div style={{
                              width: '30px',
                              color: compareWithMap[d.id] < d.score ? DashboardConstants.positiveColor : DashboardConstants.negativeColor
                          }}
                          >
                          { groupIcon ? groupIcon : <div></div> }
                       </div>
                       <div style={{whiteSpace: 'nowrap', fontStyle: d.missing || d.diamond || d.relevant ? 'italic' : null}}>{title}</div>
                  </div>
                </div>
            </Tooltip>
          );
        })
      }
      </div>
      {((configuration.alignView && maxGroupSizes > 0) || data.length > 0) && <Divider style={{marginTop: '5px', marginBottom: '5px'}}/>}
    </div>
  );
}
