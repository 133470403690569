import React, { useState, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { AuthService } from '../../services/AuthService';
import DraftsIcon from '@mui/icons-material/Drafts';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import CardHeader from '@mui/material/CardHeader';
import SecurityIcon from '@mui/icons-material/Security';
import LinearProgress from '@mui/material/LinearProgress';
import NotificationService from '../../services/NotificationService';
import ProjectService from '../../services/ProjectService';
import SurveysService from '../../services/SurveysService';
import SubscriptionService from '../../services/SubscriptionService';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import queryString from 'query-string';
import {LoadData} from '../../Constants.js'
import {NumericFormat} from 'react-number-format';
import Divider from '@mui/material/Divider';
import dateFormat from 'dateformat';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {Helmet} from "react-helmet";
import NotificationItem from './NotificationItem.js';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import BadgeIcon from '@mui/icons-material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const useStyles = makeStyles()(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: '100%',
  },
  paperWelcome: {
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: '100%',
    padding: theme.spacing(2),
  },
  welcomeCard: {
    height: '100%',
  },
  purpleAvatar: {
    color: '#fff',
    backgroundColor: '#00e2dc',
  },
  media: {
    height: 140,
  },
  welcomeMuiCardHeaderTitle: {
      color: '#003945',
      fontSize: '1.25rem',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 500,
      lineHeight: 1.6,
      letterSpacing: '0.0075em',
  },
  messageIcon : {
    verticalAlign: 'middle',
    paddingRight: theme.spacing(1),
  },
  markAsReadIcon : {
    verticalAlign: 'sub',
  },
  notificationsPagination : {
    marginLeft: 'auto',
  },
  listItemBlockTop : {
    paddingBottom: '0px',
    marginBottom: '0px',
  },
  listItemBlockMiddle : {
    paddingTop: '0px',
    paddingBottom: '0px',
    marginTop: '0px',
    marginBottom: '0px',
  },
  listItemBlockBottom : {
    paddingTop: '0px',
    marginTop: '0px',
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: 'calc(100% - 32px)',
  },
  myAccount: {
    color: '#003945',
    textAlign: 'left',
  },
  myOrg: {
    color: '#003945',
    textAlign: 'left',
    marginLeft: theme.spacing(2),
  },
  viewmyprofile: {
    marginTop: '10px',
  },
  ListItemTextProject: {
    cursor: 'pointer',
    color: 'rgba(0, 0, 0, 0.54);',
    '&:hover': {
      backgroundColor: '#eee'
    },
  },
  arrowForwardIosIcon: {
    color: 'rgba(0, 0, 0, 0.54);',
    fontSize: 'small',
    marginRight: '8px',
  },
  itemCenter: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '15px',
    marginTop: '30px',
  },
  accountTitle: {
    display: 'flex',
    alignItems: 'center',
    height: '32px !important',
  },
  noPaddingMargin: {
    margin: '0px',
    padding: '0px',
  },
  accountCardHeader: {
    padding: '16px',
  },
  alignStart: {
    display: 'flex',
    alignItems: 'start'
  },
  accountIcon: {
    marginRight: '16px',
  },
  justifySpaceAround: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  notificationCard: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  notificationCardContentEmpty: {
    flexGrow: 1,
  },
  notificationCardContentFull: {
    marginTop: '0',
    justifyContent: 'flex-start',
    height: '100%'
  },
}));

const projectService = new ProjectService();
const notificationService = new NotificationService();
const subscriptionService = new SubscriptionService();
const surveysService = new SurveysService();

export default function Welcome(props) {

  const { t, openSnackbar } = props;

  const { classes } = useStyles();

  const [user, setUser] = useState(undefined);
  const [expirationDate, setExpirationDate] = useState(undefined);
  const [customerSubscription, setCustomerSubscription] = useState(undefined);
  const [subscriptionExpirationDate, setSubscriptionExpirationDate] = useState(undefined);

  const [projects, setProjects] = useState({'total': 0, 'hits': []});
  const [userSurveys, setUserSurveys] = useState([]);

  const [notifications, setNotifications] = useState({'total': 0, 'hits': []});
  const [notifFrom, setNotifFrom] = useState(0);
  const [notifSize] = useState(3);

  const [loadData, setLoadData] = useState(LoadData.Load);
  useEffect(() => {
    if(loadData !== LoadData.Load) return;

    setLoadData(LoadData.Loading);

    let usr = AuthService.getUser();
    setUser(usr);

    if(usr.expirationDate !== undefined && usr.expirationDate > 0) {
      let expDate = new Date();
      expDate.setTime(usr.expirationDate);
      setExpirationDate(expDate);
    }

    const promises = [];

    promises.push(projectService.fetchWelcomeProjects());
    promises.push(surveysService.fetchWelcomeCollectors());

    Promise.all(promises)
      .then(results => {
        setProjects(results[0].data);
        setUserSurveys(results[1].data);
      })
      .catch(err => {
        openSnackbar('error', t('react.generic.error.while.loading'));
      })
      .finally(function() {
        setLoadData(LoadData.Loaded);
        const searchQuery = queryString.parse(window.location.search);
        if(searchQuery.error && searchQuery.error === '403'){
          openSnackbar('error', t('react.snackbar.welcome.accessdenied'));
        }
      });
  }, [loadData]);

  useEffect(() => {
    if(user === undefined || !AuthService.isManager()) return;

    subscriptionService.getCurrentCustomerSubscription(user.customerId)
      .then(result => {
        setCustomerSubscription(result.data);
        if(result.data.endDate !== undefined && result.data.endDate > 0) {
          let expDate = new Date();
          expDate.setTime(result.data.endDate);
          setSubscriptionExpirationDate(expDate);
        }
      })
      .catch(err => {
        setCustomerSubscription(undefined);
      });
  }, [user]);

  const [loadNotificationData, setLoadNotificationData] = useState(LoadData.Load);
  useEffect(() => {
    if(loadNotificationData !== LoadData.Load) return;

    setLoadNotificationData(LoadData.Loading);

    notificationService.getMyNotifications(notifFrom, notifSize)
      .then(result => {
        setNotifications(result.data);
      })
      .catch(err => {
        openSnackbar('error', t('react.generic.error.while.loading'));
      });
  }, [loadNotificationData, notifFrom, notifSize]);

  const handleClickMarkNotificationRead = (event, notificationId) => {
    event.preventDefault();
    notificationService.markAsRead(notificationId)
      .then(result => {
        setLoadNotificationData(LoadData.Load);
      })
      .catch(err => {
      });
  };

  const handleClickMarkAllNotificationsRead = (event) => {
    event.preventDefault();
    notificationService.markAllAsRead()
      .then(result => {
        setLoadNotificationData(LoadData.Load);
      })
      .catch(err => {
      });
  };

  const handleClickPreviousNotifications = (event) => {
    event.preventDefault();
    setNotifFrom(notifFrom - notifSize);
    setLoadNotificationData(LoadData.Load);
  }

  const handleClickNextNotifications = (event) => {
    event.preventDefault();
    setNotifFrom(notifFrom + notifSize);
    setLoadNotificationData(LoadData.Load);
  }

  const formatRemainings = (label, remaining, total) => {
    return <>
      {t(label)}&nbsp;
      <NumericFormat value={remaining} defaultValue={0} displayType={'text'} thousandSeparator={' '} />&nbsp;/&nbsp;
      <NumericFormat value={total} defaultValue={0} displayType={'text'} thousandSeparator={' '} />
      </>;
  };

  return (
    <div className={classes.root}>
    { loadData !== LoadData.Loaded ? ( <LinearProgress /> )
    : (
     <Grid container spacing={3}>
      <Helmet title={t('react.helmet.welcome')} />
       <Grid item xs={12}>
         <Paper className={classes.paperWelcome}>
           <Typography component="h2" variant="h6" color="inherit" noWrap >
             {t('react.dashboard.main.welcome1', {username: user.firstname})}
           </Typography>
           <Typography variant="body2" color="textSecondary" component="p">
             {t('react.dashboard.main.welcome2')}
           </Typography>
         </Paper>
       </Grid>
       <Grid item xs={6}>
         {/* LEFT COLUMN */}
         <Paper className={classes.paper}>
           <List className={classes.noPaddingMargin}>
             <ListItem className={classes.accountCardHeader}>
               <Grid container className={classes.alignStart}>
                   <Grid item xs={11} className={classes.accountTitle}>
                    <BadgeIcon color='primary' className={classes.accountIcon}/>
                    <Typography className={classes.myAccount} component="h2" variant="h6">
                      {t('react.dashboard.main.myaccount.title')}
                    </Typography>
                   </Grid>
                   <Grid item xs={1}>
                    <Avatar className={classes.purpleAvatar} >{AuthService.getUserInitials()}</Avatar>
                   </Grid>
               </Grid>
             </ListItem>
             <ListItem className={classes.listItemBlockMiddle}>
               <ListItemIcon>
                 <AccountCircleIcon />
               </ListItemIcon>
               <ListItemText primary={user.firstname + ' ' + user.lastname} />
             </ListItem>
             <ListItem className={classes.listItemBlockMiddle}>
               <ListItemIcon>
                 <DraftsIcon />
               </ListItemIcon>
               <ListItemText primary={user.email} />
             </ListItem>
             {expirationDate &&
             <ListItem className={classes.listItemBlockMiddle}>
               <ListItemText primary= {t('react.dashboard.main.myaccount.expire', {date: dateFormat(expirationDate, "UTC:dd/mm/yyyy")})} />
             </ListItem>
             }
             <ListItem className={[classes.listItemBlockBottom, classes.viewmyprofile].join(' ')}>
               <Button size="small" color="primary" onClick={() => props.navigate(`/users/${user.id}`)} >
               {t('react.dashboard.main.myaccount.viewmyprofile')}
               </Button>
             </ListItem>
             {AuthService.isManager() && customerSubscription !== undefined &&
             <>
               <Grid className={classes.justifySpaceAround}>
                <Divider className={classes.divider}/>
               </Grid>
               <ListItem className={classes.accountCardHeader}>
               <Grid container className={classes.alignStart}>
                   <Grid item xs={12} className={classes.accountTitle}>
                    <AccountBalanceWalletIcon color='primary' className={classes.accountIcon}/>
                    <Typography className={classes.myAccount} component="h2" variant="h6">
                      {t('react.dashboard.main.myorganization.title')}
                    </Typography>
                   </Grid>
               </Grid>
               </ListItem>
               <ListItem className={classes.listItemBlockTop}>
                 <ListItemText primary={formatRemainings('react.dashboard.main.remainingProjects', customerSubscription.remainingProjects, customerSubscription.maxProjects)} />
               </ListItem>
               <ListItem className={classes.listItemBlockMiddle}>
                 <ListItemText primary={formatRemainings('react.dashboard.main.remainingObservations', customerSubscription.remainingObservations, customerSubscription.maxObservations)} />
               </ListItem>
               <ListItem className={classes.listItemBlockBottom}>
               {subscriptionExpirationDate &&
                 <ListItemText primary= {t('react.dashboard.main.subscription.expire', {date: dateFormat(subscriptionExpirationDate, "UTC:dd/mm/yyyy")})} />
               }
               </ListItem>
             </>
             }
           </List>
         </Paper>
       </Grid>
       <Grid item xs={6}>
         <Card className={classes.notificationCard}>
           <CardHeader 
             classes={{title: classes.welcomeMuiCardHeaderTitle}}
             title={`${t('react.dashboard.main.notifications.title')} ${notifications.total > 0 ? `(${notifications.total})` : ''}`}
             avatar={<NotificationsIcon color='primary'/>}
             action={notifications.total > 0 ?
              <Tooltip title={t('react.dashboard.main.notifications.mark.all.as.read')} className={classes.markAsReadIcon}>
                <IconButton
                  onClick={e => handleClickMarkAllNotificationsRead(e)}
                  size="large">
                  <DeleteOutlineIcon />
              </IconButton>
           </Tooltip> : ''}/>
           {notifications.total === 0 &&
           <CardContent className={classes.notificationCardContentEmpty}>
             <Typography gutterBottom variant="h5" component="h2">
               {t('react.dashboard.main.notifications.no.notification.title')}
             </Typography>
             <Typography variant="body2" color="textSecondary" component="p">
               {t('react.dashboard.main.notifications.no.notification.content')}
             </Typography>
           </CardContent>
          }
           {notifications.total > 0 &&
           <CardContent className={classes.notificationCardContentFull}>
             <Typography gutterBottom variant="h5" component="h2">
               {t('react.dashboard.main.notifications.unread.notifications.title')}
             </Typography>
             <Grid className={classes.itemCenter}>
                {notifications.hits.map((notification, index) => (
                  <React.Fragment key={notification.id}>
                    <NotificationItem notification={notification} handleClickMarkNotificationRead={handleClickMarkNotificationRead} {...props}/>
                    {index !== notifications.hits.length - 1 && <Divider/>}
                  </React.Fragment>
                ))}
              </Grid>
              </CardContent>
             }
             {(notifications.total > notifSize || notifFrom >= notifSize) &&
             <CardActions disableSpacing>
               <Typography >
                 {t('react.dashboard.main.notifications.from.to.of', {'from':(notifFrom+1), 'to':(notifFrom + notifications.hits.length), 'total':notifications.total})}
               </Typography>
               <Tooltip title={t('react.dashboard.main.notifications.previous')} className={classes.notificationsPagination}>
                 <span>
                 <IconButton
                   disabled={notifFrom === 0}
                   onClick={e => handleClickPreviousNotifications(e)}
                   size="medium">
                   <KeyboardArrowLeftIcon />
                 </IconButton>
                 </span>
               </Tooltip>
               <Tooltip title={t('react.dashboard.main.notifications.next')}>
                 <span>
                 <IconButton
                   disabled={(notifFrom + notifSize) >= notifications.total}
                   onClick={e => handleClickNextNotifications(e)}
                   size="medium">
                   <KeyboardArrowRightIcon />
                 </IconButton>
                 </span>
               </Tooltip>
             </CardActions>
             }
         </Card>
       </Grid>
       <Grid item xs={6}>
         <Card className={classes.welcomeCard}>
           <CardHeader 
             classes={{title: classes.welcomeMuiCardHeaderTitle}}
             title={t('react.dashboard.main.projects.title')}
             avatar={<AllInboxIcon color='primary'/>} />
           <CardMedia
             className={classes.media}
             image="/img/background-project-20240531.png"
             title={t('react.dashboard.main.projects.title')}
           />
           <CardContent>
             <Typography gutterBottom variant="h5" component="h2">
               {t(projects.total === 0 ? 'react.dashboard.main.projects.content.title' : 'react.dashboard.main.projects.content.title.hasproject')}
             </Typography>
             {projects.total === 0 &&
             <Typography variant="body2" color="textSecondary" component="p">
               {t('react.dashboard.main.projects.content.text')}
             </Typography>
             }
             {projects.total > 0 &&
             <List>
               {projects.hits.map((project, index) => {
                 return (
                 <ListItem key={`ListItem-project-${index}`} className={classes.listItemBlockMiddle}>
                   <ArrowForwardIosIcon className={classes.arrowForwardIosIcon} /><ListItemText primary={project.name} onClick={() => props.navigate(`/projects/${project.id}`)} className={classes.ListItemTextProject}/>
                 </ListItem>
               )})}
             </List>
             }
           </CardContent>
           <CardActions>
             <Button size="small" color="primary" onClick={() => props.navigate('/projects/add')}>
               {t('react.dashboard.main.projects.button.add.title')}
             </Button>
             {projects.total > 0 ? (
               <Button size="small" color="primary" onClick={() => props.navigate('/projects')}>
                 {t('react.dashboard.main.projects.button.list.title')}
               </Button>
             ) : (
               <Button size="small" color="primary" onClick={() => props.navigate('/doc')}>
                 {t('react.dashboard.main.projects.button.howitworks.title')}
               </Button>
             )
             }
           </CardActions>
         </Card>
       </Grid>
       {userSurveys && 
        <Grid item xs={6}>
          <Card className={classes.welcomeCard}>
            <CardHeader 
               classes={{title: classes.welcomeMuiCardHeaderTitle}}
               title={t('react.dashboard.main.surveys.title')}
               avatar={<FactCheckIcon color='primary'/>} />
            <CardMedia
             className={classes.media}
             image="/img/background-surveys-20240531.png"
             title={t('react.dashboard.main.surveys.title')}
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                {t(userSurveys.total === 0 ? 'react.dashboard.main.surveys.content.title' : 'react.dashboard.main.surveys.content.title.hassurveys')}
              </Typography>
              {userSurveys.total === 0 &&
              <Typography variant="body2" color="textSecondary" component="p">
                {t('react.dashboard.main.surveys.content.text')}
              </Typography>
              }
              {userSurveys.total > 0 &&
                <List>
                  {userSurveys.hits.map((survey, index) => {
                    return (
                    <ListItem key={`ListItem-survey-${index}`} className={classes.listItemBlockMiddle}>
                      <ArrowForwardIosIcon className={classes.arrowForwardIosIcon} /><ListItemText primary={survey.name} onClick={() => props.navigate(`/surveys/${survey.id}`)} className={classes.ListItemTextProject}/>
                    </ListItem>
                  )})}
                </List>
              }
            </CardContent>
            <CardActions>
              <Button size="small" color="primary" onClick={() => props.navigate('/surveys/add')}>
                {t('react.dashboard.main.surveys.button.add.title')}
              </Button>
              {userSurveys.total > 0 ? (
                <Button size="small" color="primary" onClick={() => props.navigate('/surveys')}>
                  {t('react.dashboard.main.surveys.button.list.title')}
                </Button>
              ) : (
                <Button size="small" color="primary" onClick={() => props.navigate('/doc')}>
                  {t('react.dashboard.main.surveys.button.howitworks.title')}
                </Button>
              )
              }
            </CardActions>
          </Card>
        </Grid>
       }
       {AuthService.isAdmin() &&
       <Grid item xs={6}>
         <Card className={classes.welcomeCard}>
           <CardHeader
             classes={{title: classes.welcomeMuiCardHeaderTitle}}
             title={t('react.dashboard.main.users.title')}
             avatar={<SecurityIcon color="error"/>}
           />
           <CardContent>
           <Typography gutterBottom variant="h5" component="h2">
             {t('react.dashboard.main.users.content.title')}
           </Typography>
           <Typography variant="body2" color="textSecondary" component="p">
             {t('react.dashboard.main.users.content.text')}
           </Typography>
           </CardContent>
           <CardActions>
             <Button size="small" color="primary" onClick={() => props.navigate('/users')}>
               {t('react.dashboard.main.users.button.manage.title')}
             </Button>
           </CardActions>
         </Card>
       </Grid>
       }
     </Grid>
   ) }
   </div>
  );
}
