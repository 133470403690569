import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import FormControl from '@mui/material/FormControl';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import { AuthService } from '../../services/AuthService';
import CustomerService from '../../services/CustomerService';
import CircularProgress from '@mui/material/CircularProgress';
import dateFormat from 'dateformat';
import MenuItem from '@mui/material/MenuItem';
import { isBlank } from '../../utils/utils';
import DialogTitleWithCloseIcon from '../shared/DialogTitleWithCloseIcon/DialogTitleWithCloseIcon';

const customerService = new CustomerService();

const useStyles = makeStyles()(theme => ({
  formControl: {
    marginTop: '10px',
    minWidth: 120,
    width: '100%',
  },
  moreMargin: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  minTabHeight: {
    minWidth: 600,
    minHeight: 480,
  },
  cleanFieldMarginTop: {
    marginTop: '10px',
  }
}));

export default function EditCustomersDialog(props) {
  // expected parameters
  const {
    t,
    openState,
    customerId,
    callbackAfterConfirm,
    callbackAfterCancel,
    openSnackbar,
    closeSnackbar
  } = props;

  const { classes } = useStyles();

  // field values
  const [id, setId] = useState(0);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [resellerId, setResellerId] = useState(0);
  const [creationDate, setCreationDate] = useState(new Date());
  const [updateDate, setUpdateDate] = useState(new Date());
  const [resellers, setResellers] = useState({ 'total': 0, 'hits': [] });
  const [displayErrors, setDisplayErrors] = useState({ name: false });

  const getDefaultExpirationDate = () => {
    let exp = new Date();
    exp.setFullYear(exp.getFullYear() + 1);
    return exp;
  }

  const handleClickEditDialogCancel = (event) => {
    event.preventDefault();
    closeSnackbar();
    callbackAfterCancel();
  };

  // save
  const handleClickEditDialogConfirm = (data) => {
    let errorsInFields = { ...displayErrors };
    errorsInFields.name = isBlank(name);
    setDisplayErrors(errorsInFields);

    let nbErrors = Object.entries(errorsInFields).filter(value => value[1] === true).length;
    if (nbErrors > 0) {
      return;
    }

    closeSnackbar();

    let customer = {
      'id': id,
      'name': name,
      'description': description,
      'resellerId': resellerId,
      'creationDate': creationDate,
      'updateDate': updateDate
    };

    customerService.saveCustomer(customer)
      .then(result => {
        callbackAfterConfirm();
      })
      .catch(err => {
        openSnackbar('error', t('react.snackbar.editcustommer.save.error', { error: err.message }));
        console.error(err);
      });
  };

  const [loadData, setLoadData] = React.useState(false);
  useEffect(() => {

    if (!openState) return;

    setLoadData(openState);
    setDisplayErrors({ name: false });

    var isAdmin = AuthService.isAdmin();

    var promises = [];

    var idx = 0;
    var idxCustomer = -1;
    var idxResellers = -1;

    if (customerId > 0) {
      idxCustomer = idx++;
      promises.push(customerService.fetchCustomer(customerId));
    }

    if (isAdmin) {
      idxResellers = idx++;
      promises.push(customerService.fetchAllResellers());
    }

    Promise.all(promises)
      .then(results => {
        if (customerId === 0) {
          // if it's a new customer
          setId(0);
          setName('');
          setDescription('');
          setResellerId(0);
          setCreationDate('');
          setUpdateDate('');
        } else {
          var result = results[idxCustomer].data;
          setId(result.id);
          setName(result.name);
          setDescription(result.description);
          setResellerId(result.resellerId);
          setCreationDate(result.creationDate);
          setUpdateDate(result.updateDate);
        }

        if (isAdmin) {
          result = results[idxResellers].data;
          setResellers(result);
          if (result.total > 200) {
            openSnackbar('warning', t('react.snackbar.editcustommer.fetch.more200resellers.warning'));
          }
        }

        setLoadData(false);
      })
      .catch(err => {
        setLoadData(false);
        openSnackbar('error', t('react.snackbar.editcustommer.fetch.error', { error: err.message }));
      })
      .finally(function () {
      });
  }, [customerId, openState]);

  if (loadData) {
    return (
      <Dialog open={openState} onClose={handleClickEditDialogCancel}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    );
  }
  return (
    <Dialog open={openState} onClose={handleClickEditDialogCancel}>
      {id > 0 ?
        <DialogTitleWithCloseIcon
          title={t('react.customeredit.title.modify', { "name": name, "id": id })}
          callbackOnclose={handleClickEditDialogCancel}
        />
        : <DialogTitleWithCloseIcon
          title={t('react.customeredit.title.new')}
          callbackOnclose={handleClickEditDialogCancel}
        />
      }
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              name="name"
              label={t('react.customeredit.name')}
              type="text"
              value={name}
              autoComplete="off"
              onChange={e => setName(e.target.value)}
              fullWidth
              error={displayErrors.name}
              helperText={displayErrors.name && (t("react.validation.name.empty"))}
            />
            <TextField
              margin="dense"
              id="description"
              label={t('react.customeredit.description')}
              type="text"
              value={description}
              onChange={e => setDescription(e.target.value)}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
            {AuthService.isAdmin() &&
              <FormControl className={classes.formControl}>
                <TextField
                  select
                  label={t('react.customeredit.reseller')}
                  value={resellerId}
                  onChange={e => setResellerId(e.target.value)}
                  id='resellerId-native-simple'
                  name="resellerId"
                >
                  <MenuItem key="0" value="0" disabled>{t('react.customeredit.select.reseller')}</MenuItem>
                  {resellers.hits.map(reseller => (
                    <MenuItem key={reseller.id} value={reseller.id}>{reseller.name}</MenuItem>
                  ))}
                </TextField>
              </FormControl>
            }
            <TextField
              fullWidth
              margin="dense"
              id="creationDate"
              label={t('react.customeredit.creationdate')}
              type="datetime-local"
              value={dateFormat(creationDate, "UTC:yyyy-mm-dd'T'HH:MM")}
              disabled={true}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              fullWidth
              margin="dense"
              id="updateDate"
              label={t('react.customeredit.updatedate')}
              value={dateFormat(updateDate, "UTC:yyyy-mm-dd'T'HH:MM")}
              type="datetime-local"
              disabled={true}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              size="large"
              startIcon={<CloseIcon />}
              onClick={handleClickEditDialogCancel} color="primary">
              {t('react.button.cancel')}
            </Button>
            {AuthService.isAdmin() &&
              <Button
                variant="outlined"
                size="large"
                startIcon={<SaveIcon />}
                disabled={isBlank(name) || resellerId === 0}
                color="primary"
                onClick={handleClickEditDialogConfirm}>
                {t('react.button.save')}
              </Button>
            }
          </DialogActions>
        </Grid>
      </Grid>
    </Dialog>
  );
}
