import React, { useState, useEffect, Fragment } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from 'tss-react/mui';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import Grid from '@mui/material/Grid';
import SubscriptionService from '../../services/SubscriptionService';
import CustomerService from '../../services/CustomerService';
import UserService from '../../services/UserService';
import dateFormat from 'dateformat';
import {NumericFormat} from 'react-number-format';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Tooltip } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { indexOf, cloneDeep, isBlank } from '../../utils/utils.js';
import { LoadData } from '../../Constants.js';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import DialogTitleWithCloseIcon from '../shared/DialogTitleWithCloseIcon/DialogTitleWithCloseIcon';

const subscriptionService = new SubscriptionService();
const customerService = new CustomerService();
const userService = new UserService();

const useStyles = makeStyles()(theme => ({
    minTabHeight: {
        minWidth: 600,
        minHeight: 480,
    },
    formControl: {
        marginBottom: theme.spacing(1),
        minWidth: 200,
    },
    formControlSelectUsers: {
        marginTop: 10,
        marginBottom: theme.spacing(1),
        minWidth: 200,
    },
    formControl2: {
        marginBottom: theme.spacing(1),
        minWidth: 300,
    },
    moreMargin: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    marginRightButton: {
        marginRight: 8
    },
    flexBetween: {
        display: 'flex',
        justifyContent: 'space-between'
    },
}));

const emptySubscription = {
    id: 0,
    customerId: 0,
    description: '',
    startDate: new Date(),
    endDate: new Date(),
    maxObservations: 0,
    maxProjects: 0,
    contactUserId: 0
};

export default function EditSubscriptionDialog(props) {

    const {
        t,
        openSnackbar,
        showSpinner,
        callbackAfterConfirm,
        callbackAfterCancel,
        editDialogOpen,
        subscriptionId,
    } = props;

    const { classes } = useStyles();

    const [subscription, setSubscription] = useState(cloneDeep(emptySubscription));

    const [customerName, setCustomerName] = useState('');
    const [subscriptionTypes, setSubscriptionTypes] = useState([]);
    const [customerList, setCustomerList] = useState([]);
    const [lockFields, setLockFields] = useState(true);
    const [usersList, setUsersList] = useState([]);

    const [loadData, setLoadData] = useState(LoadData.Load);
    useEffect(() => {
        if (!editDialogOpen) {
            setLoadData(LoadData.Load);
            return;
        }

        if (loadData !== LoadData.Load) return;

        setLoadData(LoadData.Loading);

        showSpinner(true);

        if (subscriptionId > 0) {
            setLockFields(true);
            subscriptionService.getSubscriptionById(subscriptionId)
                .then(result => {
                    setSubscription(result.data);
                    var promises = []
                    promises.push(customerService.fetchCustomer(result.data.customerId));
                    promises.push(userService.fetchAllUsersWithCustomerId(result.data.customerId));
                    Promise.all(promises)
                        .then(result2 => {
                            setCustomerName(result2[0].data.name);
                            setUsersList(result2[1].data.hits);
                        }).catch(err => {
                            openSnackbar('error', t("react.error.while.loading"));
                        }).finally(() => {
                            showSpinner(false);
                            setLoadData(LoadData.Loaded);
                        });
                }).catch(err => {
                    openSnackbar('error', t("react.error.while.loading"));
                    showSpinner(false);
                    setLoadData(LoadData.Loaded);
                })
        } else {
            setUsersList([]);
            setLockFields(false);
            let today = new Date();
            let startDate = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()));
            let endDate = new Date(Date.UTC(today.getFullYear(), 11, 31));
            setSubscription(cloneDeep(emptySubscription, { startDate: startDate.getTime(), endDate: endDate.getTime(), subscriptionType: 'none' }));

            var promises = [];

            promises.push(subscriptionService.getSubscriptionTypes());
            promises.push(customerService.get500CustomersOrderByNameAsc());

            Promise.all(promises)
                .then(function (values) {
                    setSubscriptionTypes(values[0].data);
                    setCustomerList(values[1].data.hits);
                }).catch(err => {
                    openSnackbar('error', t("react.error.while.loading"));
                }).finally(() => {
                    showSpinner(false);
                    setLoadData(LoadData.Loaded);
                });
        }
    }, [loadData, editDialogOpen, subscriptionId])

    const handleClicksubmitSubscriptionPack = () => {
        if (subscriptionId === 0) {
            subscriptionService.create(subscription)
                .then(result => {
                    callbackAfterConfirm();
                    openSnackbar('success', t('react.snackbar.editcustommer.subscription.create.success'));
                })
                .catch(err => {
                    openSnackbar('error', t('react.snackbar.editcustommer.subscription.create.fail'));
                })

        } else {
            subscriptionService.updateSubscription(subscription.id, subscription)
                .then(result => {
                    callbackAfterConfirm();
                    openSnackbar('success', t('react.snackbar.editcustommer.subscription.update.success'));
                })
                .catch(err => {
                    openSnackbar('error', t('react.snackbar.editcustommer.subscription.update.fail'));
                });
        }
    };

    const changeDateStart = (e) => {
        setSubscription({ ...subscription, startDate: e.target.value });
    }

    const changeDateEnd = (e) => {
        setSubscription({ ...subscription, endDate: e.target.value });
    }

    const handleClickEditDialogCancel = (event) => {
        callbackAfterCancel();
    };

    const changeFormule = (event) => {
        if (event.target.value === 'none' || event.target.value === 'custom') {
            setSubscription({
                ...subscription,
                description: '',
                maxProjects: 0,
                maxObservations: 0,
                subscriptionType: event.target.value
            });
        } else {
            let selectionIdx = indexOf(subscriptionTypes, 'key', event.target.value)
            setSubscription({
                ...subscription,
                description: subscriptionTypes[selectionIdx].name,
                maxProjects: subscriptionTypes[selectionIdx].maxProjects,
                maxObservations: subscriptionTypes[selectionIdx].maxObservations,
                subscriptionType: event.target.value
            })
        }
    };

    const changeContact = (event) => {
        setSubscription({ ...subscription, contactUserId: event.target.value });
    }

    const changeCustomer = (event) => {
        setSubscription({ ...subscription, customerId: event.target.value, contactUserId: 0 });
        userService.fetchAllUsersWithCustomerId(event.target.value)
            .then(result => {
                setUsersList(result.data.hits);
            })
            .catch(err => {
                openSnackbar('error', t("react.error.while.loading"));
                setUsersList([]);
            })
    }

    if (loadData !== LoadData.Loaded) {
        return null;
    }

    return (
        <Dialog open={editDialogOpen}>
            {subscriptionId > 0 ?
                <DialogTitleWithCloseIcon
                    title={t('react.subscriptionedit.title.modify', { "name": customerName, "id": subscription.customerId })}
                    callbackOnclose={handleClickEditDialogCancel}
                />
                : <DialogTitleWithCloseIcon
                    title={t('react.subscriptionedit.title.new')}
                    callbackOnclose={handleClickEditDialogCancel}
                />
            }
            <Grid item xs={12} className={classes.minTabHeight}>
                <DialogContent>
                    {subscriptionId === 0 &&
                        <Fragment>
                            <FormControl className={classes.formControl2} >
                                <TextField
                                    select
                                    label={t('react.subscription.customer.list')}
                                    value={subscription.customerId}
                                    onChange={changeCustomer}
                                >
                                    <MenuItem value={0} disabled>{t('react.subscription.menuitem.select.value')}</MenuItem>
                                    {customerList.map((customer, index) => (
                                        <MenuItem key={index} value={customer.id}>{customer.name}</MenuItem>
                                    ))}
                                </TextField>
                            </FormControl>
                            <br />
                        </Fragment>
                    }
                    {subscriptionTypes !== undefined && subscriptionTypes.length > 0 && subscriptionId === 0 &&
                        <FormControl className={classes.formControl} >
                            <TextField
                                select
                                label={t('react.subscription.pack')}
                                value={subscription.subscriptionType}
                                onChange={changeFormule}
                            >
                                <MenuItem value={'none'} disabled>{t('react.subscription.menuitem.select.value')}</MenuItem>
                                {subscriptionTypes.map((subscriptionType, index) => (
                                    <MenuItem key={index} value={subscriptionType.key}>{subscriptionType.name}</MenuItem>
                                ))}
                                <MenuItem value={'custom'}>{t('react.subscription.menuitem.custom')}</MenuItem>
                            </TextField>
                        </FormControl>
                    }
                    <TextField
                        disabled={lockFields}
                        margin="dense"
                        id="description"
                        label={t('react.subscription.description')}
                        type="text"
                        value={subscription.description}
                        onChange={(e) => setSubscription({ ...subscription, description: e.target.value })}
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        disabled={lockFields}
                        margin="dense"
                        id="maxProjects"
                        label={t('react.subscription.nbmaxprojects')}
                        type="numeric"
                        value={subscription.maxProjects}
                        onChange={(e) => setSubscription({ ...subscription, maxProjects: e.target.value })}
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        disabled={lockFields}
                        margin="dense"
                        id="maxObservations"
                        label={t('react.subscription.nbmaxobservations')}
                        type="numeric"
                        value={subscription.maxObservations}
                        onChange={e => setSubscription({ ...subscription, maxObservations: parseInt(e.target.value) })}
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        disabled={lockFields}
                        margin="dense"
                        id="startDate"
                        label={t('react.subscription.startdate')}
                        type="date"
                        value={dateFormat(subscription.startDate, "UTC:yyyy-mm-dd")}
                        onChange={e => changeDateStart(e)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <br />
                    <TextField
                        disabled={lockFields}
                        margin="dense"
                        id="endDate"
                        label={t('react.subscription.enddate')}
                        type="date"
                        value={dateFormat(subscription.endDate, "UTC:yyyy-mm-dd")}
                        onChange={e => changeDateEnd(e)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <br />
                    <FormControl className={classes.formControlSelectUsers} >
                        <TextField
                            select
                            label={t('react.subscription.contact_user')}
                            disabled={lockFields}
                            value={subscription.contactUserId}
                            onChange={(e) => changeContact(e)}
                        >
                            <MenuItem value={0} disabled>{t('react.subscription.menuitem.select.value')}</MenuItem>
                            {usersList.map((user, index) => (
                                <MenuItem key={index} value={user.id}>{user.firstname} {user.lastname}</MenuItem>
                            ))}
                        </TextField>
                    </FormControl>
                    {subscription.id > 0 &&
                        <Box className={classes.moreMargin}>
                            <Typography>
                                <NumericFormat value={subscription.remainingProjects} defaultValue={0}
                                    displayType={'text'}
                                    thousandSeparator={' '}
                                    renderText={(value, props) => t('react.subscription.remaining.projects', { 'nbRemainingProjects': value })}
                                />
                            </Typography>
                            <Typography>
                                <NumericFormat value={subscription.remainingObservations} defaultValue={0}
                                    displayType={'text'}
                                    thousandSeparator={' '}
                                    renderText={(value, props) => t('react.subscription.remaining.observations', { 'nbRemainingObservations': value })}
                                />
                            </Typography>
                        </Box>
                    }
                </DialogContent>
                <DialogActions className={classes.flexBetween}>
                    {subscriptionId !== 0 ?
                        <Tooltip title={t('react.subscription.tooltip.lock')} placement="top">
                            <Button
                                variant="outlined"
                                size="large"
                                startIcon={lockFields ? <LockOpenIcon /> : <LockIcon />}
                                onClick={() => setLockFields(!lockFields)} color="primary"
                                style={{ marginLeft: 0 }}
                            >
                                {lockFields ? t('react.subscription.unlock') : t('react.subscription.lock')}
                            </Button>
                        </Tooltip> :
                        <div></div>
                    }
                    <Box>
                        <Button
                            variant="outlined"
                            size="large"
                            startIcon={<CloseIcon />}
                            onClick={() => handleClickEditDialogCancel()} color="primary"
                            className={classes.marginRightButton}>
                            {t('react.button.cancel')}
                        </Button>
                        <Button
                            variant="outlined"
                            size="large"
                            startIcon={<AccountBalanceWalletIcon />}
                            disabled={subscription.customerId === 0 || isBlank(subscription.description) || subscription.maxProjects <= 0 || subscription.maxObservations <= 0 || subscription.endDate <= subscription.startDate}
                            onClick={handleClicksubmitSubscriptionPack} color="primary">
                            {t('react.button.save')}
                        </Button>
                    </Box>
                </DialogActions>
            </Grid>
        </Dialog>
    );
};
