import React from 'react';
import clsx from 'clsx';
import {toolbarStyles} from '../../common.js';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import CachedIcon from '@mui/icons-material/Cached';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import Tooltip from '@mui/material/Tooltip';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import Input from '@mui/material/Input';
import ClearIcon from '@mui/icons-material/Clear';
import InputAdornment from '@mui/material/InputAdornment';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import LayersIcon from '@mui/icons-material/Layers';
import Switch from '@mui/material/Switch';
import ISO6391 from 'iso-639-1';

export default function DictionaryListToolbar(props) {

  const { classes } = toolbarStyles();

  const { t, numSelected, countForPagination, keywords, handleKeywordsChange, language, translateTo, handleLanguageChange, handleTranslateToChange, supportedLanguages,
    handleDeleteDictionayWords, handleEditDictionayWord, handleClickRefresh, setImportDialogOpen, handleMergeBiblioWords, withoutCodification, handleWithoutCodification } = props;
    
  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <MenuBookIcon className={classes.mainIcon}/>
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1">
        {t("react.dictionary.number.of.selected", {'numSelected': numSelected})}
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle">
          {t('react.dictionary.title', {'size': countForPagination})}
        </Typography>
      )}
      <Input
        id="search-filter-basic"
        value={keywords}
        className={classes.searchfield}
        placeholder={t('react.dictionary.search.title')}
        onChange={e => handleKeywordsChange(e.target.value)}
        endAdornment={
          <InputAdornment position="end">
            <IconButton onClick={e => handleKeywordsChange('')} edge="end" size="large">
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        }
      />

      <FormControl className={classes.formControl}>
        <Select labelId="select-langFilter" id="select-langFilter"
          displayEmpty
          value={language}
          onChange={e => handleLanguageChange(e.target.value)}
        >
        <MenuItem value=""><em>{t('react.dictionary.field.language')}</em></MenuItem>
        {supportedLanguages.map(lng => {
          return (
            <MenuItem key={`MenuItem-${lng}`} value={lng}>
              {`${ISO6391.getNativeName(lng)} (${lng})`}
            </MenuItem>
          )
        })}
        </Select>
      </FormControl>

      <FormControl className={classes.formControl}>
        <Select labelId="select-translateToFilter" id="select-translateToFilter"
          displayEmpty
          value={translateTo}
          onChange={e => handleTranslateToChange(e.target.value)}
        >
        <MenuItem value=""><em>{t('react.dictionary.field.language')}</em></MenuItem>
        {supportedLanguages.map(lng => {
          return (
            <MenuItem key={`translateTo-${lng}`} value={lng} disabled={lng === language}>
              {`${ISO6391.getNativeName(lng)} (${lng})`}
            </MenuItem>
          )
        })}
        </Select>
      </FormControl>

      <Tooltip title={t("react.dictionary.button.withoutCodification")}>
        <span>
          <Switch
            checked={withoutCodification}
            onChange={handleWithoutCodification}
            color="primary"
            name="translate-switch"
          />
        </span>
      </Tooltip>

      <Tooltip title={t("react.dictionary.button.merge.hint")}>
        <span>
        <IconButton
          onClick={handleMergeBiblioWords}
          disabled={numSelected < 2}
          size="large">
          <LayersIcon />
        </IconButton>
        </span>
      </Tooltip>

      {numSelected > 0 &&
          <Tooltip title={t('react.button.delete')}>
            <IconButton onClick={handleDeleteDictionayWords} size="large">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
      }
      <Tooltip title={t('react.button.add')}>
        <IconButton
          onClick={(e) => handleEditDictionayWord(e, 0)}
          size="large">
          <ControlPointIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title={t('react.dictionary.import.translations')}>
        <IconButton
          onClick={(e) => setImportDialogOpen(true)}
          size="large">
          <CloudUploadIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title={t("react.dashboard.menu.codification")}>
        <IconButton
          onClick={() => props.navigate('/codification')}
          size="large">
          <AccountTreeIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title={t('react.button.refresh')}>
        <IconButton onClick={handleClickRefresh} size="large">
          <CachedIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
};
