import React, { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const useStyles = makeStyles()({
    container: {
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 3,
        paddingBottom: 3,
        overflowY: 'auto'
    },
    titleCard: {
        fontSize: '1.2em', 
        fontWeight: 'bold',
        color: 'rgb(51,51,51)',
        marginBottom: 5
    },
});

export default function CellsChart(props) {

    const { classes } = useStyles();

    const {
        t,
        cells
    } = props;

    const getTotalAnswersGroup = (cells) => {
        return cells.reduce((total, cell) => total + cell.base, 0);
    }

    return (
        <div className={classes.container}>
            <div className={classes.titleCard}>
                {t("react.surveyReporting.cells.title")}
            </div>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t("react.surveyReporting.cells.base")}</TableCell>
                            {cells.map((cell, indexCell) => (
                                <TableCell key={indexCell}>{cell.name}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>N={getTotalAnswersGroup(cells)}</TableCell>
                            {cells.map((cell, indexCell) => (
                                    <TableCell key={indexCell}>{cell.base}</TableCell>
                            ))}
                        </TableRow>
                        <TableRow>
                            <TableCell>N=100%</TableCell>
                            {cells.map((cell, indexCell) => (
                                <TableCell key={indexCell}>
                                    {getTotalAnswersGroup(cells) !== 0 ? `${Math.round(cell.base/getTotalAnswersGroup(cells)*100)}%`: '0'}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};