import React, { useState, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitleWithCloseIcon from '../shared/DialogTitleWithCloseIcon/DialogTitleWithCloseIcon';
import CodificationService from '../../services/CodificationService'
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import ISO6391 from 'iso-639-1';
import {isBlank} from '../../utils/utils.js'

const codificationService = new CodificationService();

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  languageSelector: {
    marginBottom: theme.spacing(1),
    width: 130,
    marginTop: 8
  },
}));

const EditLibraryDialog = (props) => {
  // expected parameters
  const {
    t,
    openState,
    editLibraryId,
    appendToLibraryId,
    callbackAfterConfirm,
    callbackAfterCancel,
    openSnackbar,
    closeSnackbar,
    supportedLanguages,
    libraries,
  } = props;

  const { classes } = useStyles();

  const [id, setId] = useState(0);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [parentLibraryId, setParentLibraryId] = useState(0);
  const [creationDate, setCreationDate] = useState(new Date());
  const [optionalLanguage, setOptionalLanguage] = useState('none');
  const [parentLibrary, setParentLibrary] = useState(undefined);

  const handleClickConfirm = (event) => {
    event.preventDefault();

    let optionalLanguageToSave = optionalLanguage !== 'none' ? optionalLanguage : undefined;
    let parentOptionalLanguage = codificationService.getParentLanguage(libraries, parentLibrary);

    if (optionalLanguageToSave !== undefined && parentOptionalLanguage !== undefined && parentOptionalLanguage !== optionalLanguageToSave) {
      openSnackbar('error', t("react.codification.error.language"));
      return
    }

    let libraryAsJson = {
      'id': id,
      'name': name,
      'description': description || name,
      'parentLibraryId': parentLibraryId,
      'creationDate': creationDate,
      'optionalLanguage' : optionalLanguageToSave
    };

    if (id === 0) {
      codificationService.addLibrary(libraryAsJson)
        .then(result => {
          callbackAfterConfirm();
        })
        .catch(err => {
          openSnackbar('error', t("react.codification.error.while.saving"));
        });
    } else {
      codificationService.updateLibrary(id, libraryAsJson)
        .then(result => {
          callbackAfterConfirm();
        })
        .catch(err => {
          openSnackbar('error', t("react.codification.error.while.saving"));
        });
    }
  };

  useEffect(() => {
    if (!openState) return;

    if (editLibraryId > 0) {
      codificationService.fetchLibrary(editLibraryId)
        .then(result => {
          setId(result.data.id);
          setName(result.data.name);
          setDescription(result.data.description);
          setParentLibraryId(result.data.parentLibraryId);
          setCreationDate(result.data.creationDate);
          setOptionalLanguage(result.data.optionalLanguage ? result.data.optionalLanguage : 'none');
          setParentLibrary(libraries.find(l => l.id === result.data.parentLibraryId));
        })
        .catch(err => {
        }).finally(() => {
        });
    } else {
      setId(0);
      setName('');
      setDescription('');
      setParentLibraryId(appendToLibraryId);
      setCreationDate(new Date());
      setOptionalLanguage('none');
      setParentLibrary(libraries.find(l => l.id === appendToLibraryId));
    }
  }, [editLibraryId, appendToLibraryId, openState]);

  const handleClickCancel = (event) => {
    event.preventDefault();
    closeSnackbar();
    callbackAfterCancel();
  };

  return (
    <div>
      <Dialog open={openState} onClose={handleClickCancel}>
        <DialogTitleWithCloseIcon
          title={t(editLibraryId === 0 ? "react.codification.adddialog.title" : "react.codification.adddialog.title.modify") + (editLibraryId > 0 ? ` (id = ${editLibraryId})` : '')}
          callbackOnclose={handleClickCancel}
        />
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            value={name}
            autoComplete="off"
            onChange={e => setName(e.target.value)}
            label={t("react.codification.adddialog.field.name")}
            type="text"
            fullWidth
          />
          <TextField
            margin="dense"
            id="description"
            value={description}
            onChange={e => setDescription(e.target.value)}
            label={t("react.codification.adddialog.field.description")}
            type="text"
            fullWidth
          />
          <FormControl className={classes.languageSelector}>
              <TextField
                  select
                  label={t('react.codification.adddialog.field.lang')}
                  value={optionalLanguage}
                  onChange={e => setOptionalLanguage( e.target.value)}
              >
                <MenuItem key="none" value="none">{t("react.codification.adddialog.field.lang.none")}</MenuItem>
                  {supportedLanguages && supportedLanguages.map(language => (
                    <MenuItem key={language} value={language}>
                      {`${ISO6391.getNativeName(language)} (${language})`}
                    </MenuItem>                    
                  ))}
              </TextField>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickCancel} color="primary">
            {t("react.button.cancel")}
          </Button>
          <Button onClick={handleClickConfirm} color="primary" disabled={isBlank(name)}>
            {t(editLibraryId === 0 ? "react.button.add" : "react.button.save")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default EditLibraryDialog;
