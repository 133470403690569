import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { makeStyles } from 'tss-react/mui';
import Container from '@mui/material/Container';
import Copyright from '../../components/shared/Copyright';
import { AuthService } from '../../services/AuthService';
import { useParams, Navigate } from "react-router-dom";
import LoginPanel from './LoginPanel';
import RememberPasswordPanel from './RememberPasswordPanel'
import ResetPasswordPanel from './ResetPasswordPanel'
import CustomizedSnackbars from '../../components/CustomizedSnackbars/CustomizedSnackbars'

const useStyles = makeStyles()((theme) => {
  return {
  '@global': {
    body: {
      backgroundImage: 'none',
      backgroundColor: theme.palette.common.white,
      [theme.breakpoints.up(1280)] : {
        backgroundImage: `url('/img/logo.png')`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '500px',
      }
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
}
});

export default function LoginLayout(props) {
  // URL parameter
  const { actionParameter } = useParams();
  const [snackbarVariant, setSnackbarVariant] = React.useState('error');
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const openSnackbar = (variant, message) => {
    setSnackbarVariant(variant);
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  }
  const setSnackbarOnCloseHandler = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const { classes } = useStyles();
  const [showRememberPasword, setShowRememberPasword] = useState(false);
  const [redirectToHome, setRedirectToHome] = useState(AuthService.getUser() != null);

  const ComponentDisplay = () => {
    if(actionParameter === 'reset') {
      return (<ResetPasswordPanel {...props} openSnackbar={openSnackbar} setRedirectToHome={setRedirectToHome} />);
    } else if(showRememberPasword) {
      return (<RememberPasswordPanel openSnackbar={openSnackbar} setShowRememberPasword={setShowRememberPasword} />);
    } else {
      return (<LoginPanel openSnackbar={openSnackbar} setRedirectToHome={setRedirectToHome} setShowRememberPasword={setShowRememberPasword} />);
    }
  }

  if(redirectToHome) {
    return(
      <Navigate to="/" replace/>
    );
  } else {
    return (
      <Container component="main" maxWidth="xs">
        <CustomizedSnackbars
          variant={snackbarVariant}
          open={snackbarOpen}
          onClose={setSnackbarOnCloseHandler}
          message={snackbarMessage}
          vertical='top'
          horizontal='center'
        />
        <CssBaseline />
        <Box className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <ComponentDisplay />
        </Box>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    );
  }
}
