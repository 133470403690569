import React, { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { stripHtmlTags } from '../../utils/utils';

const useStyles = makeStyles()(theme => ({
    container: {
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 3,
        paddingBottom: 3,
        height: 400,
        overflowY: 'auto'
    },
    titleCard: {
        fontSize: '1.2em', 
        fontWeight: 'bold',
        color: 'rgb(51,51,51)',
        marginBottom: 5
    },
    flexColumn: {
        display: 'flex', 
        flexDirection: 'column'
    },
    noBorderBottom: {
        borderBottom: '1px solid white'
    }
}));

export default function ScreenOutChart(props) {
    const { classes } = useStyles();

    const {
        screenOuts,
        dashboardDataSurvey,
        t
    } = props;

    const [stimulus, setStimulus] = useState([]);

    useEffect(() => {
        let array = [];

        if (screenOuts.blocks) {
            screenOuts.blocks.forEach(s => {
                // this will be a problem when we have different type of block in this list (question, hotspot, etc..)
                // they MAY have the same id - we should use the blockId that is unique
                const question = dashboardDataSurvey.find(q => q.id === s.questionId);

                if (question) {
                    let answers = [];
                    let totalQuestion = 0;

                    Object.entries(s.countByAnswerIds).forEach(([key, value]) => {
                        const answerTitle = key === "-1" ? "outOfField" : (question.answers.find(a => a.id == key)?.answer || null);
                        answers.push({ title: answerTitle, number: value });
                        totalQuestion += value;
                    });

                    array.push({ stimulus: question.stimulusName, title: question.question, screenOuts: answers, total: totalQuestion });
                }
            });
        }    
        setStimulus(array);
    }, [screenOuts, dashboardDataSurvey]);

    const getFirstSixthWords = (str) => {
        const words = str.split(/\s+/);
        const firstEightWords = words.slice(0, 6);
        let result = firstEightWords.join(' ');
        if (words.length > 6) {
            result += '...';
        }
        return result;
    }

    return (
        <div className={classes.container}>
            <div className={classes.titleCard}>
                {t("react.surveyReporting.screenOutChart.title", {count: screenOuts.total})}
            </div>
            <Table size="small">
                <TableBody>
                    {stimulus.map((s, index) => (
                        <React.Fragment key={index}>
                            <TableRow key={`stimulus-${index}`}>
                                <TableCell colSpan={2} className={classes.flexColumn}>{s.stimulus} - {getFirstSixthWords(stripHtmlTags(s.title))}</TableCell>
                            </TableRow>
                            {s.screenOuts.map((answer, subindex) => (
                                <TableRow key={`answer-${index}-${subindex}`}>
                                    <TableCell key={`answer-${index}-${subindex}`} style={{paddingLeft: 30}}>
                                        <div>{answer.title === "outOfField" ? t("react.surveyReporting.outOfField.content") : answer.title}</div>
                                    </TableCell>
                                    <TableCell align="right">
                                        <b>{answer.number}</b>
                                    </TableCell>
                                </TableRow>
                            ))}
                            <TableRow key={`total-${index}`}>
                                <TableCell colSpan={2} className={classes.noBorderBottom} align="right"><b>{t("react.surveyReporting.screenOutChart.total", {count: s.total})}</b></TableCell>
                            </TableRow>
                        </React.Fragment>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
}