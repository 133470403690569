import React, { useState, useEffect, useContext } from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { makeStyles } from 'tss-react/mui';
import Image from './Image';
import CustomNextButton from './CustomNextButton';
import { CollectorContext } from './context';
import BarTimeout from './BarTimeout';

const useStyles = makeStyles()(theme => ({
    textAndButton: {
        position: 'relative',
    },
    positionRelative: {
        position: 'relative'
    },
    textIntro: {
        width:0, 
        height:0, 
        marginBottom: 10
    },
}));

export default function Introduction(props) {

    const { classes } = useStyles();

    const {
        t, showSpinner, openSnackbar, block
    } = props;

    const {
        collectParameter, collectorsService, handleNextBlock,
        handleScreenOut, imagesOfForm, embeddedReplaceDynamicElements
    } = useContext(CollectorContext);

    const [introductionImage, setIntroductionImage] = useState(undefined);
    const [disabledButton, setDisabledButton] = useState(true);
    const [showBarTimeout, setShowBarTimeout] = useState(true);

    const [dataReady, setDataReady] = useState(false);
    const [imageReady, setImageReady] = useState(false);

    useEffect(() => {
        setDataReady(false);
        if (!block) return;
        setShowBarTimeout(block.timeout > 0);
        setDisabledButton(block.timeout > 0);
        setDataReady(true);
    }, [block]);

    /*
     * This useEffect() is only used to control the display of the image
     */
    useEffect(() => {
        setImageReady(false);
        if (!block || block.imageId === 0) {
            setIntroductionImage(undefined);
            setImageReady(true);
            return;
        }

        let img = imagesOfForm.get(block.imageId);
        if (img !== undefined) {
            setIntroductionImage(img);
            setImageReady(true);
            return;
        }

        showSpinner(true);

        collectorsService.getPublicFormFileUrl(collectParameter, block.imageId)
            .then(result => {
                imagesOfForm.set(block.imageId, result.url);
                setIntroductionImage(result.url);
                setImageReady(true);
                showSpinner(false);
            }).catch(e => {
                setIntroductionImage(undefined);
                setImageReady(false);
                showSpinner(false);
                openSnackbar('error', t('react.error.fetch.message'));
                handleScreenOut();
            });
    }, [block]);

    const handleBarTimeoutExpired = () => {
        if (block.transitionAuto === true) {
            handleNextBlock();
        } else {
            setDisabledButton(false);
        }
        setShowBarTimeout(false);
    };

    if(!dataReady || !imageReady) return null;

    return (
        <Container className={classes.positionRelative}>
            <Grid container spacing={4}>
                {introductionImage !== undefined &&
                    <Grid item xs={12}>
                        <Image
                            imageUrl={introductionImage}
                            position={block.imagePosition}
                            width={block.imageWidth}
                            openFullscreen={block.openImageFullscreen}
                        />
                    </Grid>
                }
                <Grid item xs={12} sm={(block.image !== undefined && !block.openImageFullscreen) ? 8 : 12} className={classes.textAndButton}>
                    {showBarTimeout &&
                        <BarTimeout
                            {...props}
                            id={`BarTimeout-${block.id}`}
                            handleBarTimeoutExpired={handleBarTimeoutExpired}
                        />
                    }
                    {!showBarTimeout && <div className={classes.textIntro}></div>}
                    {block.text && <div className="ql-view" dangerouslySetInnerHTML={{ __html: embeddedReplaceDynamicElements(block.text) }} />}
                </Grid>
            </Grid>
            {(block.transitionAuto === false || block.timeout <= 0) &&
                <CustomNextButton
                    {...props}
                    disabled={disabledButton}
                    handleNextBlock={() => handleNextBlock()}
                />
            }
        </Container>
    )
};
