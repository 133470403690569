import React, { useState, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import Paper from '@mui/material/Paper';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official';
import IconButton from '@mui/material/IconButton';
import { Tooltip } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import { getTopicsThemes } from '../../Constants.js';
import { ImgIcon } from '../../common.js';
import { appendSuffixIfGreaterThan, formatPValue } from '../../utils/utils.js';

require("highcharts/modules/accessibility")(Highcharts);

const topicsThemes = getTopicsThemes();

const useStyles = makeStyles()(theme => ({
  paper: {
    width: '100%',
    margin: '10px 10px 10px 0'
  },
  toolsbar: {
    float: 'right',
  },
}));

export default function TopicReportingChart(props) {
  const { project, stimuli, configuration, topic, t, openSnackbar, dashboardService, sequences, stimulusCardToImage } = props;

  const { classes } = useStyles();

  const [chartData, setChartData] = useState({});
  const [currentTopicCardId] = useState([`card_topic_${topic.id}`.replace('-', '_')]);

  useEffect(() => {
    if (configuration) {
      var stimulusNames = [];
      var topicValues = [];

      if (topic.functionType === 'MIN' || topic.functionType === 'MAX' ||
        topic.functionType === 'SUM' || topic.functionType === 'AVERAGE') {
        // single value mode
        topicValues.push({
          name: topic.functionType,
          data: [],
        });
      } else {
        // loop over all values
        topic.values.filter(val => !val.compound /* only ignore compound */).forEach(val => {
          let series = {
            id: val.id,
            name: val.value,
            index: val.order,
            legendIndex: val.order,
            data: [],
          };
          topicValues.push(series);
        });
      }

      stimuli.filter(stimulus => (configuration.showHiddenCards || (configuration.hidden || [])
        .indexOf(stimulus.id) === -1) && stimulus.id >= (configuration.showTotalCard ? -100 : 0))
        .map((stimulus, idx) => {
          // compute stimulus name
          var title = dashboardService.getStimulusName(stimulus, configuration, stimuli, sequences, t)
          stimulusNames.push(title + '<br>' + t('react.dashboard.card.base.count', { nbMatchedResponses: stimulus.nbMatchedResponses }))

          // load significativities if there is a compareWith
          let significativities = {};
          let compareWithStimulus = dashboardService.getCompareWith(stimulus, stimuli, configuration);
          if (compareWithStimulus && stimulus.significativity && stimulus.significativity[compareWithStimulus.id] !== undefined) {
            significativities = stimulus.significativity[compareWithStimulus.id].topicValues;
          }

          // append topic values of this stimulus to the data array
          for (var i = 0; i < topicValues.length; i++) {
            var tv = topicValues[i];
            var stimulusTopicValues = stimulus.topics[topic.id];

            if (!stimulusTopicValues || stimulusTopicValues === undefined) {
              // if no data for this stimulus
              tv.data.push(null);
            } else {
              // in this following case it should be a value
              if (topic.functionType === 'MIN' || topic.functionType === 'MAX' ||
                topic.functionType === 'SUM' || topic.functionType === 'AVERAGE') {
                tv.data.push(stimulusTopicValues);
                //FIXME it's possible that significativity must be done here too
              } else {
                // if the expected topic value doesn't exit
                if (stimulusTopicValues[tv.id] === undefined) {
                  tv.data.push(null);
                } else {
                  let significativitySign = undefined;
                  let significativity = significativities && significativities[tv.id] ? significativities[tv.id] : undefined;
                  if (significativity) {
                    if (significativity < configuration.comparisonSignificativityValue / 100) {
                      let compareWithStimulusTopicValues = compareWithStimulus.topics[topic.id];
                      significativitySign = stimulusTopicValues[tv.id] > compareWithStimulusTopicValues[tv.id] ? '+' : '-';
                    } else {
                      significativity = undefined;
                    }
                  }

                  tv.data.push({
                    y: stimulusTopicValues[tv.id],
                    significativity: significativity,
                    significativitySign: significativitySign,
                  });
                }
              }
            }
          }
        });

      let topicValueLength = topic.values.filter(val => !val.compound /* only ignore compound */).length;
      var colorsTheme = configuration.topicsTheme && topicsThemes[configuration.topicsTheme] !== undefined ? topicsThemes[configuration.topicsTheme] : topicsThemes[topicsThemes.default];
      var colors = colorsTheme['full'];
      if (topicValueLength == 2) {
        colors = colorsTheme['two'];
      } else if (topicValueLength == 3) {
        colors = colorsTheme['three'];
      } else if (topicValueLength == 4 || topicValueLength == 5) {
        colors = colorsTheme['five'];
      }

      var precision = 0;
      if (topic.functionType === 'PERCENTIL') {
        // leave 0 a precision for the moment
        precision = 0;
      } else if (topic.functionType === 'AVERAGE') {
        precision = 2;
      } else if (topic.dataType === 'DECIMAL' && (topic.functionType === 'MIN' || topic.functionType === 'MAX' || topic.functionType === 'SUM')) {
        precision = 2;
      }

      var postfix = topic.functionType === 'PERCENTIL' ? '%' : '';
      var yAxisMax = topic.functionType === 'PERCENTIL' ? 100 : null;

      var chartOptions = {
        accessibility: {
          enabled: false,
        },
        title: { text: topic.name, style: { fontSize: 20, fontWeight: 500 }},
        xAxis: {
          categories: stimulusNames,
          title: { text: '' },
          labels: {
            style: {
              fontSize: `${configuration.dashboardFontSize-1}px`,
              fontWeight: 300
            }
          }
        },
        yAxis: {
          title: { text: '' },
          max: yAxisMax
        },
        colors: colors,
        series: topicValues,
        legend: {
          labelFormatter: function () {
            return appendSuffixIfGreaterThan(this.name, '...', 30);
          },
          itemStyle: {
            fontSize: `${configuration.dashboardFontSize}px`,
            fontWeight: 600
          }
        },
        plotOptions: {
          series: {
            /* https://api.highcharts.com/highcharts/plotOptions.series.dataLabels */
            dataLabels: {
              style: {
                fontSize: `${configuration.dashboardFontSize}px`,
              },
              enabled: true,
              useHTML: true,
              formatter: function () {
                if (this.y === null || this.y === 0) return '';

                let label = Highcharts.numberFormat(this.y, precision) + postfix;
                if (this.point.significativitySign !== undefined) {
                  label = `${label}<br/>(${this.point.significativitySign})`;
                }

                return `<div style=text-align:center>${label}</div>`;
              },
            },
          }
        },
        tooltip: {
          outside: true,
          /* https://api.highcharts.com/highcharts/tooltip.formatter */
          formatter: function () {
            if (this.y === null || this.y === 0) return '';

            let tooltip = `<b>${this.x}</b><br/><span style="color:${this.series.color};">\u25CF</span> ${this.series.name}: <b>${Highcharts.numberFormat(this.y, 2)}${postfix}</b>`;
            if (this.point.significativity !== undefined) {
              tooltip = `${tooltip}<br/>${t('react.dashboard.card.comparewith.significativity', { pvalue: formatPValue(this.point.significativity, configuration.pValueType) })}`;
            }

            return tooltip
          },
        },
        credits: {
          enabled: false
        }
      };

      switch (topic.displayType) {
        case 'LINE':
          chartOptions.chart = { type: 'line' };
          break;
        case 'AREA':
          chartOptions.chart = { type: 'area' };
          break;
        case 'AREA_STACK':
          chartOptions.chart = { type: 'area' };
          chartOptions.plotOptions['area'] = { stacking: 'normal' };
          break;
        case 'BAR_STACK':
          chartOptions.chart = { type: 'column' };
          chartOptions.plotOptions['column'] = { stacking: 'normal', groupPadding: 0.1, maxPointWidth: 60 };
          break;
        case 'PIE':
          chartOptions.chart = { type: 'pie' };
          break;
        case 'BAR':
        default:
          chartOptions.chart = { type: 'column' };
          chartOptions.plotOptions['column'] = { groupPadding: 0.1, maxPointWidth: 60 };
          break;
      }

      setChartData(chartOptions);
    }
  }, [stimuli, configuration, topic]);

  const handleImageToClipboardClick = (blockId, name) => {
    stimulusCardToImage(
      blockId,
      null,
      null,
      true,
      true,
      name,
      'clipboard');

    new Promise(resolve => setTimeout(resolve, 2000)).then(() => {
      openSnackbar('success', t('react.dashboard.card.button.copied'));
    });
  };

  return (<Paper className={classes.paper} id={currentTopicCardId}>
    <HighchartsReact highcharts={Highcharts} options={chartData} />
    <div className={classes.toolsbar} data-html2canvas-ignore='true'>
      <Tooltip title={t('react.dashboard.card.button.copy')}>
        <IconButton size='small' onClick={e => handleImageToClipboardClick(currentTopicCardId, `${project.name}-topic-${topic.name}`)}>
          <ImgIcon fontSize='small' />
        </IconButton>
      </Tooltip>
      <Tooltip title={t('react.dashboard.card.button.download')}>
        <IconButton size='small' onClick={e => stimulusCardToImage(
          currentTopicCardId,
          null,
          null,
          true,
          true,
          `${project.name}-topic-${topic.name}`,
          'download')}>
          <GetAppIcon size='small' />
        </IconButton>
      </Tooltip>
    </div>
  </Paper>);
}
