import React, { useReducer, useContext, useRef } from 'react';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { SurveyContext } from './context';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
import { Trans } from 'react-i18next';
import { isBlank } from '../../utils/utils.js';
import { isBlankHtml } from '../../utils/surveysUtils.js';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import IconButton from '@mui/material/IconButton';
import QuillEditor from './QuillEditor.js';

const useStyles = makeStyles()(theme => ({
  previewRoot: {
    marginBottom: '25px',
    margin: '0',
    padding: '5px',
    width: `250px`,
    height: `150px`,
    display: 'inline-block',
    borderStyle: 'dotted',
    borderRadius: '7px',
  },
  previewImg: {
    textAlign: 'center',
    verticalAlign: 'middle',
    maxWidth: '100%',
    maxHeight: '140px',
    position: 'relative',
    top: '-50px'
  },
  previewBtns: {
    textAlign: 'center',
    verticalAlign: 'middle',
    maxWidth: '100%',
    height: '50px',
    position: 'relative', top: '25%', zIndex: '100'
  },
  alignCenter: {
    textAlign: 'center',
    verticalAlign: 'middle',
    maxWidth: '100%',
    maxHeight: '200px'
  },
  paddingPaper: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 1,
    paddingBottom: 30
  },
  fullWidth: {
    margin: '0',
    padding: '0',
    width: '100%',
    height: '100px',
    display: 'table',
  },
  formControlLabelInstructions: {
    marginLeft: 0,
    marginTop: 0,
  },
  gridImage: {
    marginTop: 20,
    marginBottom: 20,
  },
  textFieldContent: {
    marginTop: 40
  },
  infoIconHelp: {
    top: '8px',
    left: '5px',
    opacity: '0.8',
    transform: 'scale(0.8)',
  },
  powerSettingsNewIcon: {
    float: 'right',
  },
}));

export default function QuestionR3m(props) {

  const { classes } = useStyles();

  const { t, uuid } = props

  const { setNeedToSave, getComponentConfiguration, isBlockCurrentlyDisplayed } = useContext(SurveyContext);
  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

  const quillRef = useRef();

  const changeField = (fieldName, value) => {
    switch (fieldName) {
      case "title":
        getComponentConfiguration(uuid).experience.stimulusName = value;
        break;
      case "question":
        getComponentConfiguration(uuid).experience.threeWordQuestion = value;
        break;
      case "enabledBlock":
        getComponentConfiguration(uuid).active = !getComponentConfiguration(uuid).active;
        break;
      case "enabledInstructions":
        getComponentConfiguration(uuid).experience.displayInstructions = !getComponentConfiguration(uuid).experience.displayInstructions;
        break;
      case "enableInputControls":
        getComponentConfiguration(uuid).experience.enableInputControls = !getComponentConfiguration(uuid).experience.enableInputControls;
        break;
    }
    setNeedToSave(true);
    forceUpdate();
  };

  if(!isBlockCurrentlyDisplayed(uuid)) return null;

  return (
    <Paper className={classes.paddingPaper}>
      <Typography variant="h6" component="h6">
        {t("react.project.collectforms.block.experience")}
        <Tooltip title={t(getComponentConfiguration(uuid).active ? "react.project.collectforms.disable" : "react.project.collectforms.enable")} className={classes.powerSettingsNewIcon}>
          <IconButton 
            color={getComponentConfiguration(uuid).active ? 'success': 'error'} 
            onClick={e => changeField('enabledBlock', e.target.value)}>
            <PowerSettingsNewIcon />
          </IconButton>
        </Tooltip>
      </Typography>
      <Grid item xs={6}>
        <FormControlLabel
          className={classes.formControlLabelInstructions}
          control={<Switch checked={getComponentConfiguration(uuid).experience.displayInstructions} color="secondary" onChange={e => changeField('enabledInstructions', e.target.value)} />}
          label={
            <div style={{height: 36}}>                
                <span>{t('react.project.collectforms.enable.instructions')}</span>
                <Tooltip title={<Trans i18nKey={'react.project.collectforms.enable.instructions.help'}/>} placement="left-start">
                    <InfoIcon className={classes.infoIconHelp} />
                </Tooltip>
            </div>
          }
          labelPlacement="start"
        />
        <FormControlLabel 
          className={classes.formControlLabelInstructions}
          control={<Switch checked={getComponentConfiguration(uuid).experience.enableInputControls} color="secondary" onChange={e => changeField('enableInputControls', e.target.value)} />}
          label={
            <div style={{height: 36}}>                
                <span>{t('react.project.collectforms.enableInputControls')}</span>
                <Tooltip title={<Trans i18nKey={'react.project.collectforms.enableInputControls.help'}/>} placement="left-start">
                    <InfoIcon className={classes.infoIconHelp} />
                </Tooltip>
            </div>
          }
          labelPlacement="start"
        />
      </Grid>
      <TextField
        error={isBlank(getComponentConfiguration(uuid).experience.stimulusName)}
        label={`${t("react.project.collectforms.block.stimulusname")} (${(getComponentConfiguration(uuid).experience.stimulusName || '').length} / 255)`}
        fullWidth
        value={getComponentConfiguration(uuid).experience.stimulusName}
        onChange={e => changeField('title', e.target.value)}
        inputProps={{ maxLength: 255 }}
        style={{marginTop: 15}}
      ></TextField>
      <QuillEditor
        ref={quillRef}
        value={getComponentConfiguration(uuid).experience.threeWordQuestion}
        onChange={(value) => changeField('question', value)}
        isError={isBlankHtml(getComponentConfiguration(uuid).experience.threeWordQuestion)}
        style={{height: '400px', marginTop: '30px'}}
        placeholder= {t("react.project.collectforms.block.threeWordQuestion")}
        {...props}
      />
    </Paper>
  )
}
