import React, { useState, useEffect } from 'react';
import {toolbarStyles} from '../../common.js';
import clsx from 'clsx';
import { makeStyles } from 'tss-react/mui';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import CachedIcon from '@mui/icons-material/Cached';
import ImportExport from '@mui/icons-material/ImportExport';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import LinearProgress from '@mui/material/LinearProgress';
import LanguageIcon from '@mui/icons-material/Language';
import PopupTwoButtons from '../shared/PopupTwoButtons/PopupTwoButtons';
import TranslationEdit from './TranslationEdit'
import ImportExportTranslationsDialog from './ImportExportTranslationsDialog'
import TranslationService from '../../services/TranslationService';
import Input from '@mui/material/Input';
import ClearIcon from '@mui/icons-material/Clear';
import InputAdornment from '@mui/material/InputAdornment';
import {LoadData} from '../../Constants.js'
import TableSortLabel from '@mui/material/TableSortLabel';
import {getItemAriaLabel} from '../../utils/pagination.js'
import {Helmet} from "react-helmet";

const translationService = new TranslationService();

function createData(id, locale, key, content) {
  return { id, locale, key, content };
}

const headCells = [
  { id: 'id', label: 'react.translationtool.table.header.id', minWidth: 20, sortable: true },
  { id: 'locale', label: 'react.translationtool.table.header.locale', minWidth: 20, sortable: true },
  { id: 'key', label: 'react.translationtool.table.header.key', minWidth: 100, sortable: true },
  { id: 'content', label: 'react.translationtool.table.header.content', minWidth: 400, format: row => row['content'].substring(0, 180), sortable: false }
];

function TranslationsListHead(props) {

  const { t, classes, onSelectAllClick, numSelected, rowCount, sortBy, sortDirection, handleChangeSort } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            id='checkbox-all'
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={sortBy === headCell.id ? sortDirection : false}
          >
            {headCell.sortable === false ? (
            t(headCell.label)
            ) : (
            <TableSortLabel
              active={sortBy === headCell.id}
              direction={sortDirection}
              onClick={e => handleChangeSort(e, headCell.id)}
              hideSortIcon={!headCell.sortable}
              >
              {t(headCell.label)}
              {sortBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {sortDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
            </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function TranslationsListToolbar(props) {

  const { t, selected, countForPagination, handleEditTranslation, handleDeleteTranslations, setLoadData, handleFilterChange, handleClickImportExport } = props;

  const { classes } = toolbarStyles();

  const numSelected = selected.length;
  const handleClickRefresh = (event) => {
    event.preventDefault();
    setLoadData(LoadData.Load);
  };

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <LanguageIcon className={classes.mainIcon}/>
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1">
          {t('react.translationtool.selected.title', {'selectedsize':numSelected})}
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle">
          {t('react.translationtool.listsize.title', {'listsize':countForPagination})}
        </Typography>
      )}

      <Input
        id="search-filter-basic"
        className={classes.searchfield}
        placeholder={t('react.translationtool.search.title')}
        onChange={e => handleFilterChange(e.target.value)}
        endAdornment={
          <InputAdornment position="end">
            <IconButton onClick={e => handleFilterChange('')} edge="end" size="large">
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        }
      />

      {numSelected > 0 &&
          <Tooltip title={t("react.translationtool.button.delete.hint")}>
            <IconButton onClick={handleDeleteTranslations} size="large">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
      }
      <Tooltip title={t("react.translationtool.button.add.hint")}>
        <IconButton
          onClick={(event) => handleEditTranslation(event, 0)}
          size="large">
          <ControlPointIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={t("react.translationtool.button.refresh.hint")}>
        <IconButton onClick={handleClickRefresh} size="large">
          <CachedIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={t("react.translationtool.button.importexport.hint")}>
        <IconButton onClick={handleClickImportExport} size="large">
          <ImportExport />
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
}

const useStyles = makeStyles()(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  textField: {
    marginLeft: theme.spacing(2),
  }
}));

export default function TranslationList(props) {

  const { t, openSnackbar } = props;

  const { classes } = useStyles();

  const [countForPagination, setCountForPagination] = useState(0);
  const [rows, setRows] = useState([]);
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortBy, setSortBy] = useState('key');
  const [sortDirection, setSortDirection] = useState('asc');
  const [key, setKey] = useState(''); //used to filter list by contains 'key'

  const [supportedLanguages, setSupportedLanguages] = useState([]);

  const [editTranslationId, setEditTranslationId] = useState(0);
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    event.preventDefault();
    setPage(newPage);
    setLoadData(LoadData.Load);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setLoadData(LoadData.Load);
  };

  const handleChangeSort = (event, headCellId) => {
    event.preventDefault();
    if(sortBy !== headCellId) {
      setSortBy(headCellId);
      setSortDirection('asc')
    } else {
      setSortDirection(sortDirection === 'desc' ? 'asc' : 'desc');
    }
    setLoadData(LoadData.Load);
  };

  const handleFilterChange = (value) => {
    setFilterApplied(value);
    setPage(0);
    setKey(value);
    setLoadData(LoadData.Load);
  };

  const handleClickImportExport = (event) => {
    event.preventDefault();
    setImportExportDialogOpen(true);
  };

  const [loadData, setLoadData] = useState(LoadData.Load);
  const [filterApplied, setFilterApplied] = useState(false);
  useEffect(() => {
    if(loadData !== LoadData.Load) return;

    setLoadData(LoadData.Loading);

    var promises = [];
    promises.push(translationService.getSupportedLanguages());
    promises.push(translationService.fetch(key, page, rowsPerPage, sortBy, sortDirection));

    Promise.all(promises)
    .then(results => {
      setSupportedLanguages(results[0].data);
      setCountForPagination(results[1].data.total);
      let result = results[1];
      var newRows = [];
      for(var i = 0; i < result.data.hits.length; i++) {
        let hit = result.data.hits[i];
        newRows.push(createData(hit.id, hit.locale, hit.key, hit.content));
      }
      setRows(newRows);
    })
    .catch(err => {
      setRows([]);
      setCountForPagination(0);
      openSnackbar('error', t("react.generic.error.while.loading"));
    }).finally(() => {
      setLoadData(LoadData.Loaded);
    });
  }, [loadData, page, rowsPerPage, key]);

  const isSelected = id => selected.indexOf(id) !== -1;

  const handleEditTranslation = (event, translationId) => {
    event.preventDefault();
    setEditTranslationId(translationId);
    setEditDialogOpen(true);
  };
  const editDialogCallbackAfterConfirm = () => {
    setEditDialogOpen(false);
    setEditTranslationId(0);
    setLoadData(LoadData.Load);
  }
  const editDialogCallbackAfterCancel = () => {
    setEditDialogOpen(false);
    setEditTranslationId(0);
  }

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [importExportDialogOpen, setImportExportDialogOpen] = useState(false);

  const handleDeleteTranslations = (event, row) => {
    event.preventDefault();
    setDeleteDialogOpen(true)
  };
  const handleDeleteDialogConfirm = () => {
    var promises = [];
    for(var i = 0; i < selected.length; i++) {
      promises.push(translationService.delete(selected[i]));
    }

    Promise.all(promises)
    .then(function(values) {
      setDeleteDialogOpen(false);
      setLoadData(LoadData.Load);
      setSelected([]);
    })
    .catch(err => {
      console.error(err);
    });
  };
  const handleDeleteDialogCancel = () => {
      setDeleteDialogOpen(false);
  };

  const handleImportExportDialogClose = () => {
    setImportExportDialogOpen(false);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length);

  return (
    <div className={classes.root}>
      <Helmet title={t('react.helmet.translationslist')} />
      { loadData !== LoadData.Loaded && !filterApplied ? ( <LinearProgress /> )
      : (
      <Paper className={classes.paper}>
        <TranslationEdit
          {...props}
          id={editTranslationId}
          openState={editDialogOpen}
          supportedLanguages={supportedLanguages}
          callbackAfterConfirm={editDialogCallbackAfterConfirm}
          callbackAfterCancel={editDialogCallbackAfterCancel}
        />
        <PopupTwoButtons
            variant='warning'
            openState={deleteDialogOpen}
            callbackOnclose={handleDeleteDialogCancel}
            callbackOnclickLeftButton={handleDeleteDialogCancel}
            callbackOnclickRightButton={handleDeleteDialogConfirm}
            title={t('react.translationtool.deletedialog.title')}
            content={t('react.translationtool.deletedialog.body')}
            leftButton={t('react.button.cancel')}
            rightButton={t('react.button.delete')}
        />
        <ImportExportTranslationsDialog
          {...props}
          openState={importExportDialogOpen}
          onClose={handleImportExportDialogClose}
          openSnackbar={openSnackbar}
          supportedLanguages={supportedLanguages}
        />
        <TranslationsListToolbar
          {...props}
          selected={selected}
          countForPagination={countForPagination}
          handleEditTranslation={handleEditTranslation}
          handleDeleteTranslations={handleDeleteTranslations}
          handleFilterChange={handleFilterChange}
          handleClickImportExport={handleClickImportExport}
          setLoadData={setLoadData} />
        <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            size="medium"
          >
            <TranslationsListHead
              {...props}
              classes={classes}
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={rows.length}
              sortBy={sortBy}
              sortDirection={sortDirection}
              handleChangeSort={handleChangeSort}
            />
            <TableBody>
              {rows.map((row, index) => {
                  const isItemSelected = isSelected(row.id);

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          id={`checkbox-${row.id}`}
                          onClick={event => handleClick(event, row.id)}
                          checked={isItemSelected}
                        />
                      </TableCell>
                      {headCells.map(column => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}
                            onClick={event => handleEditTranslation(event, row.id)} >
                            {column.format ? column.format(row) : value}
                          </TableCell>
                        );
                      })}
                        </TableRow>
                      );
                    })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={countForPagination}
          rowsPerPage={rowsPerPage}
          page={page}
          labelRowsPerPage={t('react.list.number.lignes.per.page')}
          labelDisplayedRows={({ from, to, count }) => t('react.list.number.from.to.count', {'from':from, 'to':to, 'count':count})}
          getItemAriaLabel={(type) => getItemAriaLabel(t, type)}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      ) }
    </div>
  );
}
