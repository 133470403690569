import React, { useState, useEffect } from 'react';
import { LoadData } from '../../Constants.js'
import CodificationService from '../../services/CodificationService';
import LinearProgress from '@mui/material/LinearProgress';
import DictionaryListHead from './DictionaryListHead';
import DictionaryListToolbar from './DictionaryListToolbar';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import PopupTwoButtons from '../shared/PopupTwoButtons/PopupTwoButtons';
import { makeStyles } from 'tss-react/mui';
import EditDictionaryWord from './EditDictionaryWord';
import EditWordTranslations from './EditWordTranslations';
import ImportWordTranslations from './ImportWordTranslations';
import MergeWordsDialog from './MergeWordsDialog';
import { isBlank, indexOf } from '../../utils/utils.js';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {getItemAriaLabel} from '../../utils/pagination.js'
import {Helmet} from "react-helmet";

const codificationService = new CodificationService();

const useStyles = makeStyles()(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableRowCursorPointer: {
    cursor: 'pointer',
  },
  translationColumn: {
    borderLeft: '1px solid #eee'
  },
  divPaginationTop: {
    display: 'flex',
    flexDirection: 'row-reverse'
  },
  formControl: {
    marginTop: 13
  },
  fastPagination: {
    marginTop: 17,
    marginRight: 11
  },
  containerSticky: {
    position: 'sticky',
    top: '64px',
    background: 'white',
    zIndex: '99',
    borderBottom: '1px solid rgba(0,0,0,0.2)',
  }
}));

const headCells = [
  { id: 'word', label: 'react.dictionary.field.word', minWidth: 250 },
  { id: 'language', label: 'react.dictionary.field.language', minWidth: 70 },
  {
    id: 'variants',
    label: 'react.dictionary.field.variants',
    minWidth: '100%',
    format: value => value && value.length > 0 ? value.map(bv => bv.variant).join(', ') : '',
  },
  {
    id: 'translation',
    label: 'react.dictionary.field.translations',
    minWidth: '120px',
    format: value => value && value.length > 0 ? value.join(', ') : '',
  },
];

export default function DictionaryList(props) {

  const {
    t,
    preferredLanguage,
    openSnackbar,
    showSpinner,
    closeSnackbar
  } = props;

  const { classes } = useStyles();

  const [rows, setRows] = useState([]);
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(250);
  const [sortBy, setSortBy] = useState('word');
  const [sortDirection, setSortDirection] = useState('asc');
  const [countForPagination, setCountForPagination] = useState(0);
  const [keywords, setKeywords] = useState('');
  const [language, setLanguage] = useState(preferredLanguage);
  const [translateTo, setTranslateTo] = useState('');
  const [pageSelectionArray, setpageSelectionArray] = useState([]);
  const [withoutCodification, setWithoutCodification] = useState(false);

  const [supportedLanguages, setSupportedLanguages] = useState([]);
  const [translations, setTranslations] = useState([]);

  const [editDictionaryWordId, setEditDictionaryWordId] = useState(0);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editTranslationsDialogOpen, setEditTranslationsDialogOpen] = useState(false);

  const [importDialogOpen, setImportDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const isSelected = id => selected.indexOf(id) !== -1;

  const [loadData, setLoadData] = useState(LoadData.Load);
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length);

  useEffect(() => {
    if (loadData !== LoadData.Load) return;

    setLoadData(LoadData.Loading);

    var promises = [];
    promises.push(codificationService.getSupportedLanguages());
    promises.push(codificationService.searchWords((page * rowsPerPage), rowsPerPage, sortBy, sortDirection, keywords, language, withoutCodification));

    Promise.all(promises)
      .then(results => {
        setSupportedLanguages(results[0].data);
        setRows(results[1].data.hits);
        setCountForPagination(results[1].data.total);

        if (!isBlank(translateTo) && results[1].data.hits.length > 0) {
          // let distinctWords = results[1].data.hits.map(element -> element.word).distinct();
          let distinctWords = [...new Set(results[1].data.hits.map(element => element.word))];

          codificationService.translateEntries(distinctWords /* array of words */, language, translateTo)
            .then(result => {
              setTranslations(result.data)
            })
            .catch(err => {
              openSnackbar('error', t("react.error.while.loading"));
            });
        }
      })
      .catch(err => {
        openSnackbar('error', t("react.error.while.loading"));
      }).finally(() => {
        setLoadData(LoadData.Loaded);
      });
  }, [loadData])

  useEffect(() => {
    let emptyArray = [];
    for (var i = 0; i < Math.ceil(countForPagination / rowsPerPage); i++) {
      emptyArray.push(`${(i * rowsPerPage) + 1}-${(i + 1) * rowsPerPage}`);
    }
    setpageSelectionArray(emptyArray);
  }, [countForPagination, rowsPerPage])

  const handleClickRefresh = (event) => {
    event.preventDefault();
    setLoadData(LoadData.Load);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    event.preventDefault();
    setPage(newPage);
    setLoadData(LoadData.Load);
  };

  const handleChangeSelectPage = (event) => {
    setPage(event.target.value);
    setLoadData(LoadData.Load);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setLoadData(LoadData.Load);
  };

  const handleChangeSort = (event, headCellId) => {
    event.preventDefault();
    if (sortBy !== headCellId) {
      setSortBy(headCellId);
      setSortDirection('asc')
    } else {
      setSortDirection(sortDirection === 'desc' ? 'asc' : 'desc');
    }
    setLoadData(LoadData.Load);
  };

  const handleLanguageChange = (l) => {
    if (l !== language) {
      setPage(0);
    }
    setLanguage(l);
    setLoadData(LoadData.Load);
  };

  const handleTranslateToChange = (l) => {
    if (l !== translateTo) {
      setPage(0);
    }
    setTranslateTo(l);
    setLoadData(LoadData.Load);
  };

  const handleKeywordsChange = (k) => {
    if (k !== keywords) {
      setPage(0);
    }
    setKeywords(k);
    setLoadData(LoadData.Load);
  };

  const handleWithoutCodification = () => {
    setWithoutCodification(!withoutCodification);
    setLoadData(LoadData.Load);
  }

  const handleEditDictionayWord = (event, wordId) => {
    event.preventDefault();
    setEditDictionaryWordId(wordId);
    setEditDialogOpen(true);
  };
  const editDialogCallbackAfterConfirm = (dictionaryWordSaved) => {
    let reload = true;

    // if we are just modifying a word (don't change the text of the word)
    if (editDictionaryWordId === dictionaryWordSaved.id) {
      let wordPosition = indexOf(rows, 'id', editDictionaryWordId);
      if (wordPosition >= 0 && rows[wordPosition].word === dictionaryWordSaved.word /* word itself stay unchanged */) {
        // just update the word in the list
        rows.splice(wordPosition, 1, dictionaryWordSaved);
        reload = false;
      }
    }

    setEditDialogOpen(false);
    setEditDictionaryWordId(0);
    if (reload) setLoadData(LoadData.Load);
  };
  const editDialogCallbackAfterCancel = () => {
    setEditDialogOpen(false);
    setEditDictionaryWordId(0);
  };

  const handleDeleteDictionayWords = (event) => {
    event.preventDefault();
    setDeleteDialogOpen(true);
  };

  const handleDeleteDialogConfirm = () => {
    var promises = selected.map((wordId) => codificationService.deleteWord(wordId));

    Promise.all(promises)
      .then(function (values) {
        // remove elements from list (do not reload all)
        selected.forEach((wordId) => {
          let wordPosition = indexOf(rows, 'id', wordId);
          if(wordPosition >= 0) rows.splice(wordPosition, 1);
        });

        setSelected([]);
        setDeleteDialogOpen(false);
      })
      .catch(err => {
        openSnackbar('error', t("react.dictionary.error.while.deleting"));
      });
  };

  const handleDeleteDialogCancel = () => {
    setDeleteDialogOpen(false);
  };

  const [openMergeBiblioWordsDialog, setOpenMergeBiblioWordsDialog] = useState(false);
  const handleMergeBiblioWords = (event) => {
    event.preventDefault();
    setOpenMergeBiblioWordsDialog(true);
  };
  const callbackAfterConfirmMergeBiblioWords = (entriesId, destinationId) => {
    let payload = {
      'biblioWords': entriesId,
      'destination': destinationId
    };
    codificationService.mergeBiblioWords(payload)
      .then(result => {
        // update the destination in the list
        let wordPosition = indexOf(rows, 'id', destinationId);
        if(wordPosition >= 0) rows.splice(wordPosition, 1, result.data);

        // remove merged elements from list (do not reload all)
        entriesId.filter(wordId => wordId !== destinationId).forEach((wordId) => {
          let wordPosition = indexOf(rows, 'id', wordId);
          if(wordPosition >= 0) rows.splice(wordPosition, 1);
        });

        closeSnackbar();
        setSelected([]);
        setOpenMergeBiblioWordsDialog(false);
      })
      .catch(err => {
        if (err.response.status === 409 /* 409 = Conflict */ && err.response.data.message !== undefined) {
          openSnackbar('error', t('react.generic.error.while.saving.with.message', { message: err.response.data.message }));
        } else {
          openSnackbar('error', t('react.generic.error.while.saving'));
        }

        setOpenMergeBiblioWordsDialog(false);
      });
  };
  const callbackAfterCancelMergeBiblioWords = () => {
    setOpenMergeBiblioWordsDialog(false);
  }

  const handleEditDictionayWordTranslations = (event, wordId) => {
    event.preventDefault();
    setEditDictionaryWordId(wordId);
    setEditTranslationsDialogOpen(true);
  };
  const editTranslationsDialogCallbackAfterConfirm = (dictionaryWord, translateTo, translationsOfWord) => {
    // directly update translations in the array (XF20220615: do not reload the page)
    translations[dictionaryWord.word] = translationsOfWord;

    setEditTranslationsDialogOpen(false);
    setEditDictionaryWordId(0);
  };
  const editTranslationsDialogCallbackAfterCancel = () => {
    setEditTranslationsDialogOpen(false);
    setEditDictionaryWordId(0);
  };

  return (
    <Paper className={classes.paper}>
      <Helmet title={t('react.helmet.dictionary')} />
      <EditDictionaryWord
        {...props}
        id={editDictionaryWordId}
        openState={editDialogOpen}
        language={language}
        supportedLanguages={supportedLanguages}
        callbackAfterConfirm={editDialogCallbackAfterConfirm}
        callbackAfterCancel={editDialogCallbackAfterCancel}
      />
      <EditWordTranslations
        {...props}
        id={editDictionaryWordId}
        openState={editTranslationsDialogOpen}
        translateTo={translateTo}
        callbackAfterConfirm={editTranslationsDialogCallbackAfterConfirm}
        callbackAfterCancel={editTranslationsDialogCallbackAfterCancel}
      />
      <ImportWordTranslations
        {...props}
        openState={importDialogOpen}
        setImportDialogOpen={setImportDialogOpen}
      />
      <PopupTwoButtons
        variant='warning'
        openState={deleteDialogOpen}
        callbackOnclose={handleDeleteDialogCancel}
        callbackOnclickLeftButton={handleDeleteDialogCancel}
        callbackOnclickRightButton={handleDeleteDialogConfirm}
        title={t('react.dictionary.confirm.delete.title')}
        content={t('react.dictionary.confirm.delete.description')}
        leftButton={t('react.button.cancel')}
        rightButton={t('react.button.delete')}
      />
      <MergeWordsDialog
        {...props}
        openState={openMergeBiblioWordsDialog}
        callbackAfterConfirm={callbackAfterConfirmMergeBiblioWords}
        callbackAfterCancel={callbackAfterCancelMergeBiblioWords}
        entriesId={selected}
      />
      <div className={classes.containerSticky}>
        <DictionaryListToolbar
          {...props}
          numSelected={selected.length}
          countForPagination={countForPagination}
          keywords={keywords}
          handleKeywordsChange={handleKeywordsChange}
          handleDeleteDictionayWords={handleDeleteDictionayWords}
          handleEditDictionayWord={handleEditDictionayWord}
          handleClickRefresh={handleClickRefresh}
          setImportDialogOpen={setImportDialogOpen}
          language={language}
          translateTo={translateTo}
          handleLanguageChange={handleLanguageChange}
          handleTranslateToChange={handleTranslateToChange}
          supportedLanguages={supportedLanguages}
          handleMergeBiblioWords={handleMergeBiblioWords}
          withoutCodification={withoutCodification}
          handleWithoutCodification={handleWithoutCodification}
        />
        <div className={classes.divPaginationTop}>
          <TablePagination
            rowsPerPageOptions={[50, 100, 250]}
            component="div"
            count={countForPagination}
            rowsPerPage={rowsPerPage}
            page={page}
            labelRowsPerPage={t('react.list.number.lignes.per.page')}
            labelDisplayedRows={({ from, to, count }) => t('react.list.number.from.to.count', { 'from': from, 'to': to, 'count': count })}
            getItemAriaLabel={(type) => getItemAriaLabel(t, type)}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <FormControl size="small" className={classes.formControl} >
            <Select
              id="formControlTop"
              value={page}
              onChange={(e) => handleChangeSelectPage(e)}
              disableUnderline
              style={{ transform: 'scale(0.85)' }}
            >
              {pageSelectionArray.map((value, index) => (
                <MenuItem key={index} value={index}>{value}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <div className={classes.fastPagination}>
            {t('react.dictionary.fast.pagination')}
          </div>
        </div>
      </div>
      {
        loadData !== LoadData.Loaded
          ? <LinearProgress />
          : <div className={classes.tableWrapper}>
            <Table
              className={classes.table}
              size="small"
            >
              <DictionaryListHead
                {...props}
                headCells={headCells}
                classes={classes}
                numSelected={selected.length}
                onSelectAllClick={handleSelectAllClick}
                rowCount={rows.length}
                sortBy={sortBy}
                sortDirection={sortDirection}
                handleChangeSort={handleChangeSort}
                translateTo={translateTo}
              />
              <TableBody>
                {rows.map((row, index) => {
                  let isItemSelected = isSelected(row.id);

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                      className={classes.tableRowCursorPointer}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          id={`checkbox-${row.id}`}
                          onClick={event => handleClick(event, row.id)}
                          checked={isItemSelected}
                        />
                      </TableCell>
                      {headCells.map(column => {
                        var value = row[column.id];

                        if (column.id === 'translation') {
                          if (isBlank(translateTo)) return;
                          else {
                            value = translations[row.word];
                            return (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                title={t('react.dictionary.edit.word.translations')}
                                className={classes.translationColumn}
                                onClick={event => handleEditDictionayWordTranslations(event, row.id)}>
                                {column.format ? column.format(value) : value}
                              </TableCell>
                            );
                          }
                        }

                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            title={t('react.dictionary.edit.word')}
                            onClick={event => handleEditDictionayWord(event, row.id)} >
                            {column.format ? column.format(value) : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: (53) * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
      }
    </Paper >);
};
