import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import {LoadData} from '../../Constants.js'
import CollectorsService from '../../services/CollectorsService';
import Collectors  from './Collectors.js';
import Box from '@mui/material/Box';

const collectorsService = new CollectorsService();

export default function ObfuscatedCollectors(props) {

    const { 
        collectUuidParameter
    } = useParams();

    const {
        t,
        openSnackbar,
        showSpinner
    } = props;

    const [surveyId, setSurveyId] = useState(0);
    const [loadData, setLoadData] = useState(LoadData.Load);

    useEffect(() => {
        if(loadData !== LoadData.Load) return;

        showSpinner(true);

        async function fetchData() {
            const form = await collectorsService.getClientFormByCollectUuid(collectUuidParameter)
                .then(result => {
                    setSurveyId(result.data.id);
                    showSpinner(false);
                    setLoadData(LoadData.Loaded);
                })
                .catch(e => {
                    showSpinner(false);
                    setLoadData(LoadData.Loaded);

                    if (e.response && e.response.status && e.response.status === 403) {
                        openSnackbar('error', t('react.error.collectform.forbidden'));
                    } else {
                        openSnackbar('error', t('react.error.fetch.message'));
                    }
                });
        }
        fetchData();
    }, [collectUuidParameter]);

    if (loadData !== LoadData.Loaded) return null;

    if(surveyId === 0) {
        return (<Box>{t('react.error.collectform.forbidden.message')}</Box>);
    }

    return <Collectors {...props} providedSurveyId={surveyId}/>
};