import React from 'react';
import PropTypes from 'prop-types';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import WarningIcon from '@mui/icons-material/Warning';
import { makeStyles } from 'tss-react/mui';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const useStyles = makeStyles()(theme => ({
  success: {
    backgroundColor: '#4caf50',
  },
  error: {
    backgroundColor: '#f44336',
  },
  info: {
    backgroundColor: '#2196f3',
  },
  warning: {
    backgroundColor: '#ff9800',
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export default function CustomizedSnackbars(props) {
  const { classes, cx } = useStyles();


  const { className, message, open, handleClose, variant, vertical, horizontal } = props;

  const Icon = variantIcon[variant];

  const handleRequestClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    handleClose();
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      ContentProps={{
        classes: {
          root: classes[variant]
        }
      }}
      className={cx(classes[variant], className)}
      open={open}
      onClose={handleRequestClose}
      message={
        <span id="client-snackbar" className={classes.message}>
        <Icon className={cx(classes.icon, classes.iconVariant)} />
        {message}
        </span>
      }
      autoHideDuration={20000}
      disableWindowBlurListener={true}
      variant="rounded"
      action={[
        <IconButton
          key="close"
          color="inherit"
          onClick={handleClose}
          size="large">
        <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
    />
  );
}

CustomizedSnackbars.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  variant: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
  vertical: PropTypes.string,
  horizontal: PropTypes.string
};
