import React, { Fragment } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Copyright from '../../components/shared/Copyright';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => {
  return {
  '@global': {
    body: {
      backgroundImage: 'none',
      backgroundColor: theme.palette.common.white,
    },
  },
  root: {
    display: 'flex',
    maxWidth: '100%',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}
});

export default function EmptyLayout(props) {

    const { children, maxWidth, hideCopyright } = props;

    const { classes } = useStyles();

    return (<Box className={classes.root}>
      <Container className={classes.container} maxWidth={maxWidth}>
        <Fragment>{children}</Fragment>
        {(hideCopyright !== true) &&
        <Box mt={8}>
          <Copyright />
        </Box>
        }
    </Container>
  </Box>);
};
