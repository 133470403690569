import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import TranslationService from '../../services/TranslationService.js'
import { isBlank } from '../../utils/utils.js';
import FormControl from '@mui/material/FormControl';
import CircularProgress from '@mui/material/CircularProgress';
import { LoadData } from '../../Constants.js'
import MenuItem from '@mui/material/MenuItem';
import {Helmet} from "react-helmet";
import PromptsService from '../../services/PromptsService.js';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import DialogTitleWithCloseIcon from '../shared/DialogTitleWithCloseIcon/DialogTitleWithCloseIcon.js';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';

const translationService = new TranslationService();
const promptsService = new PromptsService();

const useStyles = makeStyles()(theme => ({
  paper: {
    flexGrow: 1,
    width: '100%',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
  formControl: {
    minWidth: 120,
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  textField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  flexBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  }
}));

export default function PromptEditDialog(props) {

  const { showSpinner, openSnackbar, t, setOpenEditPromptDialog, openEditPromptDialog, editDialogcallback } = props;

  const { classes } = useStyles();

  const [id, setId] = useState(0);
  const [type, setType] = useState("");
  const [content, setContent] = useState("");
  const [lang, setLang] = useState('none');
  const [result, setResult] = useState("");
  const [supportedLanguages, setSupportedLanguages] = useState([]);
  const [saving, setSaving] = useState(false);
  const [lockFields, setLockFields] = useState(true);
  const [displayErrors, setDisplayErrors] = useState({ lang: false, type: false, content: false});

  const handleClickEditDialogCancel = (event) => {
    event.preventDefault();
    setOpenEditPromptDialog({show:false, id:0});
  };

  const handleClickEditDialogConfirm = (data) => {
    let { ...errorsInFields } = displayErrors;
    errorsInFields.lang = isBlank(lang) || lang === 'none';
    errorsInFields.type = isBlank(type);
    errorsInFields.content = isBlank(content);
    setDisplayErrors(errorsInFields);

    let nbErrors = Object.entries(errorsInFields).filter(value => value[1] === true).length;
    if (nbErrors > 0) { return; }

    let promptBody = {
      'id': id,
      'lang': lang,
      'type': type,
      'content': content,
    };

    setSaving(true);

    // save the form
    promptsService.savePrompt(openEditPromptDialog.id, promptBody)
      .then(result => {
        if (openEditPromptDialog.id === 0) {
          setOpenEditPromptDialog({show:false, id:0})
        }
        showSpinner(false);
        setSaving(false);
        setLoadData(LoadData.Load);
        editDialogcallback();
      })
      .catch(err => {
        showSpinner(false);
        setSaving(false);
        openSnackbar('error', t("react.error.save.message"));
      });

    setOpenEditPromptDialog({show:false, id:0});
  };

  const [loadData, setLoadData] = useState(LoadData.Load);
  useEffect(() => {
    if (!openEditPromptDialog.show) {
      setLoadData(LoadData.Load);
      return;
    }

  if (loadData !== LoadData.Load) return;
    // Reset data
    setId(0);
    setType("");
    setContent("");
    setLang('none');
    setResult("");
    setSupportedLanguages([]);
    setSaving(false);
    setLockFields(true);
    setDisplayErrors({ lang: false, type: false, content: false});

    setLoadData(LoadData.Loading);

    let promises = [
      translationService.getSupportedLanguages(),
    ];
    if (openEditPromptDialog.id > 0) {
      promises.push(promptsService.fetchPrompt(openEditPromptDialog.id));
    }
    else{
      setLockFields(false);
    }

    Promise.all(promises)
    .then(results => {
      setSupportedLanguages(results[0].data);

      let prompt = openEditPromptDialog.id > 0 ? results[1] : null;
      if (prompt) {
        setId(prompt.data.id);
        setType(prompt.data.type);
        setContent(prompt.data.content);
        setLang(prompt.data.lang.toUpperCase());
      } else {
        setId(0);
        setType('');
        setContent('');
        setLang('none');
      }
    })
    .catch(err => {
      openSnackbar('error', err.message);
    }).finally(() => {
      setLoadData(LoadData.Loaded);
    });
  }, [loadData,openEditPromptDialog]);

  if (loadData !== LoadData.Loaded) {
    return null;
  }

  const handleChangeType = (event) => {
    event.preventDefault();
    setType(event.target.value);
  };

  const handleChangeContent = (event) => {
    event.preventDefault();
    setContent(event.target.value);
  };

  const handleChangeResult = (event) => {
    event.preventDefault();
    setResult(event.target.value);
  }

  const helmetTitle = id > 0 ? 
    `${type}` : 
    t('react.promptedit.title.add');

  const pageTitle = id > 0 ? 
    t('react.promptedit.title', { "type": type}) : 
    t('react.promptedit.title.add');
  
  if (openEditPromptDialog.show === false) {return null}
  
  return (
     <Dialog open={openEditPromptDialog.show} maxWidth={"lg"} fullWidth={true}>
        <Grid className={classes.paper}>
           <Helmet title={helmetTitle} />
           <DialogTitleWithCloseIcon title={pageTitle} callbackOnclose={handleClickEditDialogCancel} />
           <DialogContent spacing={3}>
              <Grid item xs={12}>
                 <TextField
                    autoFocus
                    margin="dense"
                    id="type"
                    label="Type"
                    disabled={lockFields}
                    type="text"
                    value={type}
                    onChange={handleChangeType}
                    fullWidth
                    className={classes.textField}
                    name="type"
                    error={displayErrors.type}
                    helperText={
                       displayErrors.type && t("react.promptedit.helper.type")
                    }
                 />
                 <FormControl className={classes.formControl}>
                    <TextField
                       select
                       value={lang}
                       label={t("react.promptedit.language")}
                       onChange={(e) => setLang(e.target.value.toUpperCase())}
                       name="lang"
                       id="lang-native-simple"
                       disabled={lockFields}
                       error={displayErrors.lang}
                       helperText={
                          displayErrors.lang &&
                          t("react.validation.preferred.language.empty")
                       }
                       fullWidth
                    >
                       <MenuItem key="none" value="none" disabled> {t("react.promptedit.select.language")} </MenuItem>
                       {supportedLanguages.map((language) => (
                          <MenuItem key={language.toUpperCase()} value={language.toUpperCase()} >
                             {language.toUpperCase()}
                          </MenuItem>
                       ))}
                    </TextField>
                 </FormControl>
                 <TextField
                    margin="dense"
                    id="description"
                    label={t("react.promptedit.description")}
                    variant="outlined"
                    multiline={true}
                    maxRows={15}
                    minRows={15}
                    type="text"
                    value={content}
                    autoComplete="off"
                    onChange={handleChangeContent}
                    disabled={lockFields}
                    fullWidth
                    className={classes.textField}
                    error={displayErrors.content}
                    helperText={
                       displayErrors.content &&
                       t("react.promptedit.helper.textfield")
                    }
                 />
                 {!isBlank(result) && (
                    <TextField
                       margin="dense"
                       id="result"
                       label={t("react.promptedit.result")}
                       variant="outlined"
                       multiline={true}
                       maxRows={15}
                       minRows={15}
                       type="text"
                       value={result}
                       autoComplete="off"
                       onChange={handleChangeResult}
                       fullWidth
                       className={classes.textField}
                    />
                 )}
              </Grid>
           </DialogContent>
           <DialogActions className={classes.flexBetween}>
              <Box>
                <Button
                  variant="outlined"
                  size="large"
                  startIcon={lockFields ? <LockOpenIcon /> : <LockIcon />}
                  onClick={() => setLockFields(!lockFields)}
                  color="primary"
                  style={{ marginLeft: 0, display: id === 0 ? "none" : "flex" }}
                  className={classes.button}
                >
                 {lockFields ? t("react.subscription.unlock") : t("react.subscription.lock")}
              </Button>
              </Box>
              <Box>
                 <Button
                    variant="outlined"
                    size="large"
                    onClick={handleClickEditDialogCancel}
                    color="primary"
                    startIcon={<CloseIcon />}
                    className={classes.button}
                 >
                    {t("react.button.cancel")}
                 </Button>
                 <Button
                    variant="outlined"
                    size="large"
                    color="primary"
                    startIcon={<SaveIcon />}
                    className={classes.button}
                    disabled={saving}
                    onClick={handleClickEditDialogConfirm}
                 >
                    {t("react.button.save")}
                    {saving && (
                       <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                       />
                    )}
                 </Button>
              </Box>
           </DialogActions>
        </Grid>
     </Dialog>
  );
}
