import React from "react";
import { Grid, Box, Typography, Tooltip, IconButton } from "@mui/material";
import { FiInfo, FiXCircle, FiAlertOctagon, FiCheckCircle  } from "react-icons/fi";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "tss-react/mui";
import dateFormat from 'dateformat';

const useStyles = makeStyles()((theme) => ({
   itemCenter: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
   },
   notificationProject: {
      color: "#0165FF",
      cursor: "pointer",
   },
   notification: {
      padding: "0 15px",
   },
   notificationDate: {
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "column",
      alignItems: 'flex-end',
   },
   notificationIcon: {
        height: "40px",
        width: "40px",
        borderRadius: "10px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
   },
   markAsReadIcon: {
    display: 'flex',
    justifyContent: 'end',
    right: '-10px',
   }
}));

const notificationCategory = (t) => ({
    success: { icon: <FiCheckCircle style={{ color: "#69E24B", height: "25px", width: "25px" }} />, backgroundColor: "#EDFBE9", },
    warning: { icon: <FiAlertOctagon style={{ color: "#FFA238", height: "25px", width: "25px" }} />, backgroundColor: "#FFF3E5", },
    error: { icon: <FiXCircle style={{ color: "#FF9999", height: "25px", width: "25px" }} />, backgroundColor: "#FFE5E5", },
    info: { icon: <FiInfo style={{ color: "#66A8FF", height: "25px", width: "25px" }} />, backgroundColor: "#E5F1FF", },
});

const calculateTimeDifference = (notificationDate, type) => {
    const currentDate = new Date();
    const diffTime = Math.abs(currentDate - notificationDate);
    const remainingAfterYears = diffTime % (1000 * 60 * 60 * 24 * 365);
    const remainingAfterMonths = remainingAfterYears % (1000 * 60 * 60 * 24 * 30);
    const remainingAfterDays = remainingAfterMonths % (1000 * 60 * 60 * 24);
    const remainingAfterHours = remainingAfterDays % (1000 * 60 * 60);
    const remainingAfterMinutes = remainingAfterHours % (1000 * 60);

    const diffYears = Math.floor(diffTime / (1000 * 60 * 60 * 24 * 365));
    const diffMonths = Math.floor(remainingAfterYears / (1000 * 60 * 60 * 24 * 30));
    const diffDays = Math.floor(remainingAfterMonths / (1000 * 60 * 60 * 24));
    const diffHours = Math.floor(remainingAfterDays / (1000 * 60 * 60));
    const diffMinutes = Math.floor(remainingAfterHours / (1000 * 60));
    const diffSeconds = Math.floor(remainingAfterMinutes / 1000);

    if (type === 'full') {
        let fullDate = dateFormat(notificationDate, "UTC:dd/mm/yyyy HH:mm");

        return `${fullDate}`;
    }

    if (diffYears > 0) {
        return `${diffYears}a`;
    } else if (diffMonths > 0) {
        return `${diffMonths}m`;
    } else if (diffDays > 0) {
        return `${diffDays}j`;
    } else if (diffHours > 0) {
        return `${diffHours}h`;
    } else if (diffMinutes > 0) {
        return `${diffMinutes}min`;
    } else {
        return `${diffSeconds}s`;
    }
};

const highlightQuotedText = (text) => {
    const parts = text.split(/(«.*?»)/);
    return parts.map((part, index) => {
        if (part.startsWith('«') && part.endsWith('»')) {
            return <span key={index} style={{ fontWeight: 600 }}>{part}</span>;
        }
        return <span key={index}>{part}</span>;
    });
};

const NotificationItem = (props) => {
    const { classes } = useStyles();

    const { handleClickMarkNotificationRead, notification, t } = props;
    const notificationDate = new Date(notification.creationDate);
    const { icon, backgroundColor } = notificationCategory(t)[notification.category] || notificationCategory(t).none;

    return (
        <Grid container key={notification.id} className={classes.notification}>
            <Grid item xs={1.5} className={classes.itemCenter}>
                <Box className={classes.notificationIcon} style={{backgroundColor}}>
                    {icon}
                </Box>
            </Grid>
            <Grid item xs={9.5} className={classes.itemCenter}>
                <Typography color="textSecondary">
                    {highlightQuotedText(notification.content)}
                </Typography>
            </Grid>
            <Grid item xs={1} className={classes.notificationDate}>
                <Tooltip title={t('react.dashboard.main.notifications.mark.as.read')} className={classes.markAsReadIcon} placement="top">
                    <IconButton
                        onClick={(e) => handleClickMarkNotificationRead(e, notification.id)}
                        size="small"
                    >
                        <CloseIcon fontSize="medium" color="textSecondary"/>
                    </IconButton>
                </Tooltip>
                <Tooltip data-testid="dateTooltip" title={calculateTimeDifference(notificationDate, 'full')}>
                    <Typography fontSize={"small"} color="textSecondary">{calculateTimeDifference(notificationDate)}</Typography>
                </Tooltip>
            </Grid>
        </Grid>
    );
};

export default NotificationItem;
