import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import SequenceService from '../../services/SequenceService';
import ProjectService from '../../services/ProjectService';
import { makeStyles } from 'tss-react/mui';
import DeleteIcon from '@mui/icons-material/Delete';
import PopupTwoButtons from '../shared/PopupTwoButtons/PopupTwoButtons';
import {LoadData} from '../../Constants.js'
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import DoneIcon from '@mui/icons-material/Done';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import SaveIcon from '@mui/icons-material/Save';
import SendIcon from '@mui/icons-material/Send';
import { indexOf } from '../../utils/utils.js';
import dateFormat from 'dateformat';

const sequenceService = new SequenceService();
const projectService = new ProjectService();

const useStyles = makeStyles()(theme => ({
  visitorList: {
    minHeight: '300px',
    maxHeight: '300px',
    overflow: 'auto',
    border: '1px solid #eee',
    marginTop: '10px',
  },
  stimulusList: {
    border: '1px solid #eee',
    minHeight: '400px',
    maxHeight: '600px',
    overflow: 'auto',
    marginLeft: '30px',
    paddingLeft: '10px',
  },
  listItemIcon: {
    minWidth: '30px',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  noMoreCredential: {
    marginBottom: theme.spacing(1),
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  buttonModified: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    color: '#e00',
    border: 'solid 1px #e00',
  },
  dateFieldInList: {
    marginTop: '10px',
  },
  visitorEmail: {
    cursor: 'pointer'
  }
}));

export default function PanelMultiAnswers(props) {
  const { t, showSpinner, openSnackbar, project } = props;

  const { classes } = useStyles();

  const [sequences, setSequences] = useState([]);
  const [visitorsConfiguration, setVisitorsConfiguration] = useState([]);
  const [maxAllowedVisitors, setMaxAllowedVisitors] = useState(0);

  const [visitorEmail, setVisitorEmail] = useState('');
  const [isModified, setModified] = useState(false);

  const [selectedVisitorIndex, setSelectedVisitorIndex] = useState(-1);
  const [checked, setChecked] = useState([]);
  const [allChecked, setAllChecked] = useState([]);

  const [loadData, setLoadData] = useState(LoadData.Load);
  useEffect(() => {
    if(loadData !== LoadData.Load) return;

    setLoadData(LoadData.Loading);

    var promises = [];
    promises.push(sequenceService.fetchProjectSequences(project.id, true, true));
    promises.push(projectService.fetchProjectVisitorsPrivileges(project.id));

    Promise.all(promises)
    .then(results => {
      // // get project's sequences and other them by date desc
      let result = results[0];
      setSequences(result.data.hits
          .filter(seq => seq.state === 'completed')
          .sort(function(seq1, seq2) { return seq2.id - seq1.id; }));

      // load initial config
      result = results[1].data;

      // init maxAllowedVisitors
      setMaxAllowedVisitors(result.configuration.maxAllowed);

      // read allocated and privileges
      const newAllVisitorsConfiguration = [];
      result.configuration.allocated
        // sort by email
        .sort((a, b) => {return a.email.localeCompare(b.email)})
        .forEach((item, i) => {
          // create entry
          var newVisitorConfiguration = {
            'visitorId': item.id,
            'visitorEmail': item.email,
            'expirationDate' : new Date(item.expirationDate),
            "permissions" : []
          };

          let privilegesIdx = indexOf(result.privileges, "visitor_id", item.id);
          if(privilegesIdx >= 0) {
            // attach stimulus privileges
            result.privileges[privilegesIdx].privileges
              .filter(e => e.type === 'stimulus')
              .forEach((subitem, i) => {
                newVisitorConfiguration.permissions.push(parseInt(subitem.id));
              });
          }

          // put to array
          newAllVisitorsConfiguration.push(newVisitorConfiguration);
      });

      setVisitorsConfiguration(newAllVisitorsConfiguration);

      if(newAllVisitorsConfiguration.length > 0) {
        setSelectedVisitorIndex(0);
        setChecked(newAllVisitorsConfiguration[0].permissions);
      } else {
        setSelectedVisitorIndex(-1);
        setChecked([]);
      }

      setVisitorEmail('');
    })
    .catch(err => {
      openSnackbar('error', t("react.project.error.while.loading"));
    }).finally(() => {
      setLoadData(LoadData.Loaded);
    });
  }, [loadData, project]);

  const handleClickSetSelected = (event, idx) => {
    event.preventDefault();

    setAllChecked([]);
    setSelectedVisitorIndex(idx);
    setChecked(visitorsConfiguration[idx].permissions);
  }

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(-1);
  const handleDeleteVisitor = (event, idx) => {
    event.preventDefault();
    setDeleteDialogOpen(idx);
  };
  const handleDeleteDialogCancel = () => {
      setDeleteDialogOpen(-1);
  }
  const onConfirmDeleteVisitor = () => {
    setModified(true);

    const newAllVisitorsConfiguration = [...visitorsConfiguration];
    newAllVisitorsConfiguration.splice(deleteDialogOpen, 1);

    setChecked([]);
    setAllChecked([]);
    setDeleteDialogOpen(-1);
    setSelectedVisitorIndex(-1);
    setVisitorsConfiguration(newAllVisitorsConfiguration);
  }

  const handleClickAddNewVisitor = (event, email) => {
    event.preventDefault();

    setModified(true);

    // add only 7 days to expiration date
    let expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 7);

    const newAllVisitorsConfiguration = [...visitorsConfiguration];
    const newVisitorConfiguration = {
      'visitorId': 0,
      'visitorEmail': visitorEmail,
      'expirationDate': expirationDate,
      'permissions' : []
    };
    newAllVisitorsConfiguration.push(newVisitorConfiguration);
    setVisitorsConfiguration(newAllVisitorsConfiguration);

    setVisitorEmail('');
    setAllChecked([]);
    setChecked(newVisitorConfiguration.permissions);
    setSelectedVisitorIndex(newAllVisitorsConfiguration.length - 1);
  };

  const handleToggle = (value) => () => {
    setModified(true);

    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    visitorsConfiguration[selectedVisitorIndex].permissions = newChecked;
  };

  const handleSelectUnselectAll = (sequenceId) => () => {
    setModified(true);

    const currentIndexOfAll = allChecked.indexOf(sequenceId);
    const newAllChecked = [...allChecked];

    if (currentIndexOfAll === -1) {
      newAllChecked.push(sequenceId);
    } else {
      newAllChecked.splice(currentIndexOfAll, 1);
    }

    const newChecked = [...checked];
    sequences
      .filter((sequence) => sequence.id === sequenceId)
      .forEach((sequence, i) => {
          sequence.stimuli.forEach((stimulus, i) => {
            let currentIndex = newChecked.indexOf(stimulus.id);
            // stimulus;
            if(currentIndexOfAll === -1) {
              if(currentIndex === -1) {
                newChecked.push(stimulus.id);
              }
            } else {
              if(currentIndex !== -1) {
                newChecked.splice(currentIndex, 1);
              }
            }
          });
      });

    setAllChecked(newAllChecked);
    setChecked(newChecked);
    visitorsConfiguration[selectedVisitorIndex].permissions = newChecked;
  };

  const handleDateChange = (event, selectedVisitorIndex, newDateValue) => {
    event.preventDefault();

    setModified(true);

    const newAllVisitorsConfiguration = [...visitorsConfiguration];
    newAllVisitorsConfiguration[selectedVisitorIndex].expirationDate = new Date(newDateValue);
    setVisitorsConfiguration(newAllVisitorsConfiguration);
  };

  const handleClickSaveVisitors = (event) => {
    event.preventDefault();

    showSpinner(true);

    const configurationToPost = [];
    visitorsConfiguration.forEach((item, i) => {
      let vConf = {
        'visitorEmail': item.visitorEmail,
        'expirationDate': item.expirationDate.getTime(),
        "privileges" : []
      };

      if(item.permissions.length > 0) {
        let privilege = {
          'type': 'project',
          'id': project.id,
          'permissions': 'read'
        };
        vConf.privileges.push(privilege);

        item.permissions.forEach((permissionId, i) => {
          let privilege = {
            'type': 'stimulus',
            'id': permissionId,
            'permissions': 'read'
          };
          vConf.privileges.push(privilege);
        });
      }
      configurationToPost.push(vConf);
    });

    projectService.updateProjectVisitorsPrivileges(project.id, configurationToPost)
    .then(results => {
      setModified(false);
      openSnackbar('success', t("react.project.edit.visitortab.save.success"));

      setLoadData(LoadData.Load);
    }).catch(err => {
      if(err.response.status === 400 && err.response.data.message === 'Number of active visitors exceed the quota') {
        openSnackbar('error', t("react.project.edit.visitortab.quota.exceed", {'maxAllowedVisitors': maxAllowedVisitors}));
      } else {
        openSnackbar('error', t("react.project.error.while.saving"));
      }
    }).finally(() => {
      showSpinner(false);
    });
  };

  const handleClickSendVisitorInvitation = (event, idx) => {
    event.preventDefault();

    showSpinner(true);

    projectService.sendProjectVisitorInvitationByEmail(project.id, visitorsConfiguration[idx].visitorEmail)
    .then(result => {
      openSnackbar('success', t("react.project.edit.visitortab.send.invitations.success"));
    }).catch(err => {
      openSnackbar('error', t("react.project.edit.visitortab.send.invitations.error"));
    }).finally(() => {
      showSpinner(false);
    });
  };

  const canBeAdded = visitorsConfiguration &&
    // if the email is valid
    visitorEmail && visitorEmail.length > 5 && visitorEmail.indexOf('@') > 0 &&
    // if not already in the displayed list
    visitorsConfiguration.filter(e => e.visitorEmail.toLowerCase() === visitorEmail.toLowerCase()).length == 0 &&
    // if total allocated is under the limit or if the visitorEmail
    visitorsConfiguration.filter(e => e.expirationDate.getTime() > Date.now()).length < maxAllowedVisitors;

  if(loadData !== LoadData.Loaded) {
    return (
      <Grid item xs={12} container>
        <Grid item xs={12}>
          <LinearProgress />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid item xs={12} container>
      <PopupTwoButtons
          variant='warning'
          openState={deleteDialogOpen >= 0}
          callbackOnclose={handleDeleteDialogCancel}
          callbackOnclickLeftButton={handleDeleteDialogCancel}
          callbackOnclickRightButton={onConfirmDeleteVisitor}
          title={t('react.project.edit.visitortab.confirm.delete.title')}
          content={t('react.project.edit.visitortab.confirm.delete.description')}
          leftButton={t('react.button.cancel')}
          rightButton={t('react.button.delete')}
      />
      <Grid item xs={6}>
        {visitorsConfiguration.filter(e => e.expirationDate.getTime() > Date.now()).length >= maxAllowedVisitors &&
          <Typography variant="subtitle1" color='error' className={classes.noMoreCredential}>{t("react.project.edit.visitortab.no.more.credential")}</Typography>
        }
        <FormControl fullWidth className={classes.textField}>
          <TextField
            id="visitoremail"
            fullWidth={true}
            value={visitorEmail}
            label={t("react.project.edit.visitortab.email")}
            onChange={e => setVisitorEmail(e.target.value)}
            placeholder={t("react.project.edit.visitortab.email")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                <Tooltip title={t("react.project.edit.visitortab.add.visitor")}>
                <span>
                  <IconButton
                    onClick={handleClickAddNewVisitor}
                    disabled={!canBeAdded}
                    size="large">
                    <DoneIcon style={{ color : canBeAdded ? '#66bb6a' : '#eee'}} />
                  </IconButton>
                  </span>
                  </Tooltip>
                </InputAdornment>
              )
            }}
          />
        </FormControl>
        <List component="nav" className={classes.visitorList}>
          {visitorsConfiguration.map((visitorConfiguration, idx) => { return (
            <ListItem key={`ListItem-${idx}`} role={undefined} onClick={e => handleClickSetSelected(e, idx)} className={classes.nested}
              selected={selectedVisitorIndex === idx}>
              <ListItemText id={`ListItemText-${idx}`} primary={visitorConfiguration.visitorEmail} className={classes.visitorEmail}/>
              <ListItemSecondaryAction>
                <TextField
                  margin="dense"
                  id={`expirationDate-${idx}`}
                  type="date"
                  value={dateFormat(visitorConfiguration.expirationDate, "UTC:yyyy-mm-dd")}
                  onChange={e => handleDateChange(e, idx, e.target.value)}
                  InputProps={{
                    error: visitorConfiguration.expirationDate.getTime() < Date.now()
                  }}
                  InputLabelProps={{
                    shrink: true
                  }}
                  className={classes.dateFieldInList}
                />
                <Tooltip title={t('react.button.delete')}>
                  <IconButton
                    edge="end"
                    onClick={e => handleDeleteVisitor(e, idx)}
                    size="large">
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t('react.project.edit.visitortab.button.send.invitations')}>
                <span>
                  <IconButton
                    edge="end"
                    onClick={e => handleClickSendVisitorInvitation(e, idx)}
                    disabled={isModified || visitorConfiguration.permissions.length == 0 || visitorConfiguration.expirationDate.getTime() < Date.now()}
                    size="large">
                    <SendIcon />
                  </IconButton>
                  </span>
                </Tooltip>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
        </List>
        <Button variant="outlined" size="large" onClick={handleClickSaveVisitors}
          startIcon={<SaveIcon />} className={isModified ? classes.buttonModified : classes.button}>{t("react.button.save")}</Button>
      </Grid>
      <Grid item xs={6}>
        <List className={classes.stimulusList}>
          {sequences.map((sequence) => { return (
          <Collapse key={sequence.id} in={true} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
            <ListItem button onClick={handleSelectUnselectAll(sequence.id)}
              disabled={selectedVisitorIndex < 0 || selectedVisitorIndex > visitorsConfiguration.length}>
              <ListItemIcon className={classes.listItemIcon}>
                <Checkbox
                  edge="start"
                  id={`checkbox-sequence-${sequence.id}`}
                  checked={allChecked.indexOf(sequence.id) !== -1}
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText primary={sequence.name} />
            </ListItem>
            {sequence.stimuli.map((stimulus) => {
                return (
                  <ListItem key={stimulus.id} role={undefined} dense button
                    disabled={selectedVisitorIndex < 0 || selectedVisitorIndex > visitorsConfiguration.length}
                    onClick={handleToggle(stimulus.id)} className={classes.nested}>
                    <ListItemIcon className={classes.listItemIcon}>
                      <Checkbox
                        edge="start"
                        id={`checkbox-stimulus-${stimulus.id}`}
                        checked={checked.indexOf(stimulus.id) !== -1}
                        tabIndex={-1}
                        disableRipple
                      />
                    </ListItemIcon>
                    <ListItemText primary={stimulus.name} />
                  </ListItem>
                );
              })}
              </List>
            </Collapse>
          )})}
        </List>
      </Grid>
    </Grid>
  );
}
