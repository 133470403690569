import React, { useState, useEffect, Fragment } from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { makeStyles } from 'tss-react/mui';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { appendSuffixIfGreaterThan, formatPValue } from '../../utils/utils';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DashboardService from '../../services/DashboardService';
import PopupTwoButtons from '../shared/PopupTwoButtons/PopupTwoButtons';
import { configIdParamAtom } from "../../recoil/ProjectDashboard/atom";
import { useSetRecoilState } from "recoil";

const dashboardService = new DashboardService();

const useStyles = makeStyles()(theme => ({
    table: {
        minWidth: 650,
        borderCollapse: 'separate'
    },
    boxRed: {
        marginRight: '20px',
        fontWeight: '700',
        color: 'rgb(255 62 60)',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center'
    },
    boxOrange: {
        marginRight: '20px',
        fontWeight: '700',
        color: 'rgb(248 199 57)',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center'
    },
    marginRightBox: {
        marginRight: '20px',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center'
    },
    imgArrow: {
        marginRight: '8px',
        width: "20px"
    },
    emptyImg: {
        width: '20px',
        opacity: '0',
        marginRight: '8px'
    },
    tableCellSeparations: {
        borderRight: "1px solid",
        borderRightColor: "rgba(0,0,0,0.2)",
        display: "tableRowGroup",
    },
    stickyTableHeader: {
        top: '0',
        zIndex: '2',
        position: 'sticky',
        backgroundColor: 'white',
    },
    borderRightTableCell: {
        borderRight: '1px solid #d2d2d2',
    },
    borderRightTableCellWithButton: {
        borderRight: '1px solid #d2d2d2',
        '&:hover': {
            cursor: 'pointer',
        }
    },
    hoverCell: {
        '&:hover': {
            cursor: 'pointer',
        }
    },
    tableCellStickyLeft: {
        top: '0',
        left: '0',
        zIndex: '1',
        position: 'sticky',
        backgroundColor: 'white',
        borderCollapse: 'separate',
        fontWeight: '500',
        borderRight: '1px solid #d2d2d2',
    },
    tableCell1: {
        top: '0',
        left: '0',
        zIndex: '3',
        position: 'sticky',
        borderCollapse: 'separate',
        backgroundColor: 'white',
        minWidth: '154px',
        maxWidth: '154px',
        borderBottom: '1px solid white',
        borderRight: '1px solid #d2d2d2',
    },
    tableCell2: {
        borderBottom: '1px solid white',
        borderRight: "1px solid rgba(0,0,0,0.2)",
        backgroundColor: 'white',
        display: "tableRowGroup",
        left: '154px',
        zIndex: '1',
        position: 'sticky',
    },
    tableCell3: {
        top: '0',
        left: '0',
        zIndex: '3',
        position: 'sticky',
        borderCollapse: 'separate',
        backgroundColor: 'white',
        minWidth: '154px',
        maxWidth: '154px',
        borderBottom: '1px solid white',
        borderRight: '1px solid #d2d2d2',
    },
    tableCell4: {
        borderBottom: 'none',
        borderRight: "1px solid rgba(0,0,0,0.2)",
        display: "tableRowGroup",
        left: '154px',
        zIndex: '1',
        backgroundColor: 'white',
        position: 'sticky',
    },
    tableCell5: {
        top: '0',
        left: '0',
        zIndex: '1',
        position: 'sticky',
        backgroundColor: 'white',
        borderCollapse: 'separate',
        fontWeight: '500',
        borderRight: '1px solid #d2d2d2',
        borderTop: '1px solid #d2d2d2',
        width: '180px',
    },
    tableCell6: {
        borderTop: '1px solid #d2d2d2',
        borderRight: '1px solid #d2d2d2',
        top: '0',
        left: '154px',
        zIndex: '1',
        position: 'sticky',
        backgroundColor: 'white',
        borderCollapse: 'separate',
    },
    tableCell7: {
        borderRight: '1px solid #d2d2d2',
        top: '0',
        left: '154px',
        zIndex: '1',
        position: 'sticky',
        backgroundColor: 'white',
        borderCollapse: 'separate',
    },
    buttonNewConfig: {
        position: 'absolute',
        transform: 'scale(0.9)',
        top: -10,
        right: 0,
        opacity: '0.9',
    },
}));

const emotionalFilters = [
    { name: 'Lovers', value: 'LOVER', number: 1 },
    { name: 'In between', value: 'NEUTRAL', number: 0 },
    { name: 'Rejectors', value: 'REJECTOR', number: -1 }
];

export default function AnalyticModeTwo(props) {

    const { t, data, configuration, openSnackbar, projectId, sequences } = props

    const { classes } = useStyles();

    const hasEmotionalFilters = data.analytics.find(analytics => analytics.emotional_filter_value !== undefined) !== undefined;

    const [filters, setFilters] = useState([]);
    const [stimulusIdParam, setStimulusIdParam] = useState(0);
    const [filterIdParam, setFilterIdParam] = useState(0);
    const [stimulusNameParam, setStimulusNameParam] = useState('');
    const [stimulusSequenceIdParam, setStimulusSequenceIdParam] = useState('');
    const [popupTwoButtonState, setPopupTwoButtonState] = useState(false);
    const [msgEmotion, setMsgEmotion] = useState(false);

    useEffect(() => {
        // keep visible filters
        let temporaryFilters = data.filters.filter(filter => filter.hidden === undefined || filter.hidden === false);
        for (let filterIdx in temporaryFilters) {
            // keep visible filter values
            temporaryFilters[filterIdx].values = temporaryFilters[filterIdx].values.filter(value => value.hidden === undefined || value.hidden === false);
        }
        setFilters(temporaryFilters);
    }, [data])

    const handleDeleteDialogCancel = () => {
        setPopupTwoButtonState(false);
    }

    const getAutoconfigName = (stimulusSequenceId, stimulusName, filterName) => {
        if (sequences.length > 1) {
            let sequenceName = sequences.find(s => s.id === stimulusSequenceId).name;
            return `${stimulusName} - ${sequenceName} - ${filterName}`;
        } else {
            return `${stimulusName} - ${filterName}`;
        }
    }

    const setConfigIdParam = useSetRecoilState(configIdParamAtom);

    const generateNewConfig = () => {
        let stimuli = [];
        let filter = data.filters.find(filter => filter.id === filterIdParam);

        let automaticConfig = { ...configuration };

        automaticConfig.stimulusIds.push(stimulusIdParam);
        if (filterIdParam !== "emotion") {
            automaticConfig.name = getAutoconfigName(stimulusSequenceIdParam, stimulusNameParam, filter.name);
            filter.values.forEach((filterValue, index) => {
                if (index === 0) {
                    // attach the first filter value to the "normal" stimulus (stimulusIdParam)
                    automaticConfig.filters[stimulusIdParam] = {
                        [filterIdParam]: [{
                            id: filterValue.id,
                            value: filterValue.value
                        }]
                    };
                    automaticConfig.compareWith[stimulusIdParam] = { id: -200 }
                    automaticConfig.names[stimulusIdParam] = getAutoconfigName(stimulusSequenceIdParam, stimulusNameParam, filterValue.value);
                } else {
                    // attach other values to virtual stimulus
                    automaticConfig.virtual.push({ virtualId: index, stimulusId: stimulusIdParam });
                    automaticConfig.filters[index] = {
                        [filterIdParam]: [{
                            id: filterValue.id,
                            value: filterValue.value
                        }]
                    };
                    automaticConfig.compareWith[index] = { id: -200 };
                    automaticConfig.names[index] = getAutoconfigName(stimulusSequenceIdParam, stimulusNameParam, filterValue.value);
                }
            })
        } else {
            automaticConfig.name = `${getAutoconfigName(stimulusSequenceIdParam, stimulusNameParam, t('react.projectanalytics.emotional.activation.title'))}`;
            emotionalFilters.forEach((emotionalFilter, index) => {
                if (index === 0) {
                    // attach the first filter value to the "normal" stimulus (stimulusIdParam)
                    automaticConfig.engagementFilters[stimulusIdParam] = [emotionalFilter.number];
                    automaticConfig.compareWith[stimulusIdParam] = { id: -200 };
                    automaticConfig.names[stimulusIdParam] = getAutoconfigName(stimulusSequenceIdParam, stimulusNameParam, emotionalFilter.value);
                } else {
                    // attach other values to virtual stimulus
                    automaticConfig.virtual.push({ virtualId: index, stimulusId: stimulusIdParam });
                    automaticConfig.engagementFilters[index] = [emotionalFilter.number];
                    automaticConfig.compareWith[index] = { id: -200 };
                    automaticConfig.names[index] = getAutoconfigName(stimulusSequenceIdParam, stimulusNameParam, emotionalFilter.value);
                }
            })
        }

        dashboardService.saveConfiguration(projectId, null, stimuli, automaticConfig)
            .then(res => {
                setConfigIdParam(res.id);
                props.navigate(`/projects/${projectId}/dashboard`);
            })
            .catch(err => {
                openSnackbar('error', t('react.snackbar.analytics.autoconfig'));
            })
    }

    const openPopupNewConfig = (event, stimulusId, filterId, stimulusName, sequenceId) => {
        event.preventDefault();
        setStimulusIdParam(stimulusId);
        setFilterIdParam(filterId);
        setStimulusNameParam(stimulusName);
        setStimulusSequenceIdParam(sequenceId);
        if (filterId === "emotion") {
            setMsgEmotion(true);
        } else {
            setMsgEmotion(false);
        }
        setPopupTwoButtonState(true);
    }

    const getTotalCell = (stimuliId) => {
        let analytics = data.totalAnalytics.find(entry => entry.of === stimuliId);
        let stimulusName = stimuliId === -100 ? 'Total' : data.stimulus.find(entry => entry.id === stimuliId).name;

        let valueTotal = analytics.of_score / 100;
        let tooltip = `${stimulusName}: Score = ${Math.round(valueTotal)} / Base = ${analytics.of_base}`;

        if (analytics.of_base < 5) {
            if (analytics.of_base === 0) {
                tooltip = `${stimulusName}: Base = 0`;
            }

            return (
                <Tooltip title={tooltip}>
                    <span>
                        {t("react.projectdashboard.no.data")}
                    </span>
                </Tooltip>
            );
        } else {
            return (
                <Tooltip title={tooltip}>
                    <span>
                        {Math.round(valueTotal)}
                    </span>
                </Tooltip>
            );
        }
    };

    const getTotalFilterCell = (filterValueId = 0, emotionalValue = undefined) => {
        let findTotal = undefined;

        if (filterValueId > 0) {
            findTotal = data.analytics.find(analytic => analytic.filter_value_id === filterValueId && analytic.of === -100);
        } else if (emotionalValue !== undefined) {
            findTotal = data.analytics.find(analytic => analytic.emotional_filter_value === emotionalValue && analytic.of === -100);
        } else {
            return;
        }

        var dataToDisplay = 0;
        var tooltipTitle = '';
        if (findTotal.of_base > 0) {
            dataToDisplay = Math.round(findTotal.of_score / 100);
            tooltipTitle = `Total: Score = ${dataToDisplay} / Base = ${findTotal.of_base}`;
        } else {
            dataToDisplay = t("react.projectdashboard.no.data")
        }

        return (
            <Tooltip title={tooltipTitle}>
                <Box style={{ color: findTotal.of_base > 0 ? '' : 'rgba(0,0,0,0.4)' }}>
                    {dataToDisplay}
                </Box>
            </Tooltip>
        )
    }

    const getStimulusFilterCell = (stimuliId, filterValueId = 0, emotionalValue = undefined, withRightBorder = false, stimuliSequenceId) => {
        let stimulusName = data.stimulus.find(stimulus => stimulus.id === stimuliId).name;

        let analytics = undefined;

        if (filterValueId > 0) {
            analytics = data.analytics.find(analytic => analytic.filter_value_id === filterValueId && analytic.of === stimuliId);
        } else if (emotionalValue !== undefined) {
            analytics = data.analytics.find(analytic => analytic.emotional_filter_value === emotionalValue && analytic.of === stimuliId);
        } else {
            return;
        }

        let pValue = analytics.significativity;
        let otherValue = analytics.with_score / 100;
        let valueCurrentStimulus = analytics.of_score / 100;
        let significativitySign = undefined;

        if (analytics.significativity < configuration.comparisonSignificativityValue / 100) {
            significativitySign = otherValue > valueCurrentStimulus ? '-' : '+';
        }

        const displayButtonAutoConfig = (stimuliId, filterId, show) => {
            if (filterId === "emotion") {
                document.getElementById(`button${stimuliId}-${filterId}`).style.display = show ? 'block' : 'none';
            } else if (stimuliId !== undefined && filterId !== undefined && filterId !== undefined && data.filters.find(filter => filter.id === filterId).values.length > 1) {
                document.getElementById(`button${stimuliId}-${filterId}`).style.display = show ? 'block' : 'none';
            }
        }

        let tooltip = `${stimulusName}: Score = ${Math.round(valueCurrentStimulus)} / pValue = ${formatPValue(pValue, configuration.pValueType)} / Base = ${analytics.of_base}`;

        if (analytics.of_base < 5) {
            if (analytics.of_base === 0) {
                tooltip = `${stimulusName}: Base = ${analytics.of_base}`;
            }

            return (
                <TableCell
                    style={{ color: 'rgba(0,0,0,0.4)', position: 'relative' }}
                    align="center"
                    className={withRightBorder ? classes.borderRightTableCell : classes.hoverCell}
                    onMouseEnter={() => displayButtonAutoConfig(stimuliId, emotionalValue !== undefined ? 'emotion' : analytics.filter_id, true)}
                    onMouseLeave={() => displayButtonAutoConfig(stimuliId, emotionalValue !== undefined ? 'emotion' : analytics.filter_id, false)}
                >
                    {withRightBorder &&
                        <Tooltip
                            title={t("react.analytics.tooltip.autoconfig")}
                            placement="top"
                        >
                            <IconButton
                                style={{ display: 'none' }}
                                id={`button${stimuliId}-${(emotionalValue !== undefined ? 'emotion' : analytics.filter_id)}`}
                                className={classes.buttonNewConfig}
                                onClick={(event) => openPopupNewConfig(event, stimuliId, analytics.filter_id, stimulusName, stimuliSequenceId)}
                                size="large">
                                <AddCircleIcon />
                            </IconButton>
                        </Tooltip>
                    }
                    <Tooltip title={tooltip}>
                        <Box>
                            {t("react.projectdashboard.no.data")}
                        </Box>
                    </Tooltip>
                </TableCell>
            );
        } else if (analytics.with_base < 5) {
            /* R3MSCORE-503: do not display arrow if the 'compare with' base is less than 5 */
            return (
                <TableCell align="center" className={withRightBorder ? classes.borderRightTableCell : ''}>
                    <Tooltip title={tooltip}>
                        <Box className={classes.marginRightBox}>
                            <img alt="" src="/img/ArrowY.png" className={classes.emptyImg} />
                            {Math.round(valueCurrentStimulus)}
                        </Box>
                    </Tooltip>
                </TableCell>
            );
        } else {
            return (
                <TableCell
                    align="center"
                    className={withRightBorder ? classes.borderRightTableCellWithButton : classes.hoverCell}
                    style={{ position: 'relative' }}
                    onMouseEnter={() => displayButtonAutoConfig(stimuliId, emotionalValue !== undefined ? 'emotion' : analytics.filter_id, true)}
                    onMouseLeave={() => displayButtonAutoConfig(stimuliId, emotionalValue !== undefined ? 'emotion' : analytics.filter_id, false)}
                >
                    {withRightBorder &&
                        <Tooltip
                            title={t("react.analytics.tooltip.autoconfig")}
                            placement="top"
                        >
                            <IconButton
                                style={{ display: 'none' }}
                                id={`button${stimuliId}-${(emotionalValue !== undefined ? 'emotion' : analytics.filter_id)}`}
                                className={classes.buttonNewConfig}
                                onClick={(event) => openPopupNewConfig(event, stimuliId, emotionalValue !== undefined ? 'emotion' : analytics.filter_id, stimulusName, stimuliSequenceId)}
                                size="large">
                                <AddCircleIcon />
                            </IconButton>
                        </Tooltip>
                    }
                    {significativitySign === '+' && pValue !== undefined && pValue >= 0 ?
                        <Tooltip title={tooltip}>
                            <Box className={classes.boxOrange}>
                                <img alt="" src="/img/ArrowY.png" className={classes.imgArrow} />
                                {Math.round(valueCurrentStimulus)}
                            </Box>
                        </Tooltip>
                        : significativitySign === '-' && pValue !== undefined && pValue >= 0 ?
                            <Tooltip title={tooltip}>
                                <Box className={classes.boxRed}>
                                    <img alt="" src="/img/ArrowR.png" className={classes.imgArrow} />
                                    {Math.round(valueCurrentStimulus)}
                                </Box>
                            </Tooltip> :
                            <Tooltip title={tooltip}>
                                <Box className={classes.marginRightBox}>
                                    <img alt="" src="/img/ArrowY.png" className={classes.emptyImg} />
                                    {Math.round(valueCurrentStimulus)}
                                </Box>
                            </Tooltip>
                    }

                </TableCell>
            );
        }
    }

    return (
        <TableContainer component={Paper} style={{ height: 'calc(100vh - 190px)' }}>
            <PopupTwoButtons
                variant='info'
                openState={popupTwoButtonState}
                callbackOnclose={handleDeleteDialogCancel}
                callbackOnclickLeftButton={handleDeleteDialogCancel}
                callbackOnclickRightButton={generateNewConfig}
                title={t("react.projectanalytics.popup.title")}
                content={t("react.projectanalytics.popup.message", { stimuliName: stimulusNameParam, filterName: msgEmotion ? t('react.projectanalytics.emotional.activation.title') : data.filters.find(filter => filter.id === filterIdParam) !== undefined ? data.filters.find(filter => filter.id === filterIdParam).name : '' })}
                leftButton={t('react.button.cancel')}
                rightButton={t('react.button.confirm')}
            />
            <Table className={classes.table} size="small">
                <TableHead className={classes.stickyTableHeader}>
                    {/* first line with loop over all filters names */}
                    <TableRow>
                        <TableCell className={classes.tableCell1}></TableCell>
                        <TableCell
                            align="center"
                            className={classes.tableCell2}
                        >
                            {t("react.projectanalytics.mode1.total.observations.title")}
                        </TableCell>
                        {filters.map((filter, filterIndex) => (
                            <TableCell
                                className={classes.tableCellSeparations}
                                colSpan={filter.values.length}
                                key={filterIndex}
                                align="center"
                            >
                                {filter.name}
                            </TableCell>
                        ))}
                        {hasEmotionalFilters && <TableCell
                            className={classes.tableCellSeparations}
                            colSpan={3}
                            align="center"
                        >
                            {t('react.projectanalytics.emotional.activation.title')}
                        </TableCell>
                        }
                    </TableRow>
                    {/* second line with loop over all filters values */}
                    <TableRow className={classes.customStickyHeader}>
                        <TableCell className={classes.tableCell3}></TableCell>
                        <TableCell className={classes.tableCell4}></TableCell>
                        {filters.map((filter) => {
                            return (
                                filter.values.map((filterValue, filterValueIdx) =>
                                    <Tooltip title={filterValue.value.length > 25 ? filterValue.value : ''} key={filterValueIdx}>
                                        <TableCell
                                            style={{ borderBottom: '1px solid white', fontWeight: '400', height: 90 }}
                                            className={filterValueIdx === filter.values.length - 1 ? classes.borderRightTableCell : ''}
                                            align="center"
                                        >
                                            {appendSuffixIfGreaterThan(filterValue.value, '...', 25)}
                                        </TableCell>
                                    </Tooltip>
                                ))
                        })}
                        {hasEmotionalFilters && emotionalFilters.map((emotionalFilter, emotionalFilterIndex) => (
                            <TableCell
                                key={`emotionalFilterValue-${emotionalFilterIndex}`}
                                className={emotionalFilterIndex === emotionalFilters.length - 1 ? classes.borderRightTableCell : ''}
                                style={{ borderBottom: '1px solid white', fontWeight: '400', height: 90 }}
                                align="center"
                            >
                                {emotionalFilter.name}
                            </TableCell>
                        ))}
                    </TableRow>
                    {/* third line with Total Simulis scores */}
                    <TableRow className={classes.customStickyHeader2}>
                        <TableCell className={classes.tableCell5}>
                            {t('react.projectanalytics.mode2.total.title')}
                        </TableCell>
                        <TableCell className={classes.tableCell6} align="center">
                            {getTotalCell(-100)}
                        </TableCell>
                        {filters.map((filter) => {
                            return (
                                filter.values.map((filterValue, filterValueIdx) =>
                                    <TableCell style={{ borderTop: '1px solid #d2d2d2' }} className={filterValueIdx === filter.values.length - 1 ? classes.borderRightTableCell : ''} key={filterValueIdx} align="center">
                                        {getTotalFilterCell(filterValue.id)}
                                    </TableCell>
                                ))
                        })}
                        {hasEmotionalFilters && emotionalFilters.map((emotion, emotionalIndex) => (
                            <TableCell style={{ borderTop: '1px solid #d2d2d2' }} className={emotionalIndex === emotionalFilters.length - 1 ? classes.borderRightTableCell : ''} key={emotionalIndex} align="center">
                                {getTotalFilterCell(0, emotion.value)}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.stimulus.map((stimuli, stimuliIndex) => {
                        return (
                            <TableRow key={stimuliIndex}>
                                {/* Print the stimulus name */}
                                <TableCell className={classes.tableCellStickyLeft}>
                                    {stimuli.name}
                                </TableCell>
                                {/* Print the total observations column */}
                                <TableCell className={classes.tableCell7} align="center">
                                    {getTotalCell(stimuli.id)}
                                </TableCell>
                                {/* Print data per filter */}
                                {filters.map((filter) => {
                                    return (
                                        filter.values.map((filterValue, filterValueIdx) =>
                                            <Fragment key={filterValueIdx}>
                                                {getStimulusFilterCell(stimuli.id, filterValue.id, undefined, (filterValueIdx === filter.values.length - 1), stimuli.sequenceId)}
                                            </Fragment>
                                        ))
                                })}
                                {/* lines (loop) over all Emotional values */}
                                {hasEmotionalFilters && emotionalFilters.map((emotion, emotionalIndex) => (
                                    <Fragment key={emotionalIndex}>
                                        {getStimulusFilterCell(stimuli.id, emotion.name, emotion.value, (emotionalIndex === emotionalFilters.length - 1), stimuli.sequenceId)}
                                    </Fragment>
                                ))}
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer >
    )
}
