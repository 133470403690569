import React, { Fragment, useState, useEffect, useReducer } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles, withStyles } from 'tss-react/mui';
import LinearProgress from '@mui/material/LinearProgress';
import CodificationService from '../../services/CodificationService'
import ReferenceService from '../../services/ReferenceService'
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import LayersIcon from '@mui/icons-material/Layers';
import IconButton from '@mui/material/IconButton';
import { findById, indexOf, capitalizeFirstLetter } from '../../utils/utils.js';
import EditCodifDialog from '../Codification/EditCodifDialog';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import DoneIcon from '@mui/icons-material/Done';
import CachedIcon from '@mui/icons-material/Cached';
import { LoadData } from '../../Constants.js'
import Toolbar from '@mui/material/Toolbar';
import { toolbarStyles, ExportIcon } from '../../common.js';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import EditIcon from '@mui/icons-material/Edit';
import Switch from '@mui/material/Switch';
import Input from '@mui/material/Input';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import HourglassEmptyOutlinedIcon from '@mui/icons-material/HourglassEmptyOutlined';
import {getItemAriaLabel} from '../../utils/pagination.js'
import {getShorthandNotation} from '../../utils/codificationUtils'

const referenceService = new ReferenceService();
const codificationService = new CodificationService();

const useStyles = makeStyles()(theme => ({
  button: {
    marginTop: theme.spacing(5),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  dividerSpace: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  tableCellActions: {
    display: 'flex',
    justifyContent: 'flex-start',
    paddingLeft: 0,
  },
  autocompleteInputField: {
    minWidth: '400px',
  },
  divPaginationTop: {
    display: 'flex',
    flexDirection: 'row-reverse'
  },
  formControl: {
    marginTop: 13
  },
  fastPagination: {
    marginTop: 17,
    marginRight: 11
  },
  containerSticky: {
    position: 'sticky',
    top: '64px',
    background: 'white',
    zIndex: '99',
    borderBottom: '1px solid rgba(0,0,0,0.2)',
  }
}));

export default function PanelCodif(props) {
  const { classes } = useStyles();
  const { classes: toolbarClasses } = toolbarStyles();

  // https://reactjs.org/docs/hooks-faq.html#is-there-something-like-forceupdate
  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

  const { project, openSnackbar, showSpinner, t, preferredLanguage } = props;

  const [libraries, setLibraries] = useState([]);
  const [codifPaths, setCodifPaths] = useState([]);
  const [unknownWords, setUnknownWords] = useState({ 'hits': [], 'total': 0 });
  const [projectSpecificLibraryId, setProjectSpecificLibraryId] = useState(0);
  const [projectLanguage, setProjectLanguage] = useState('');
  const [unknownWordsTranslations, setUnknownWordsTranslations] = useState({});
  const [sequences, setSequences] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(300);
  const [unfixedOnly, setUnfixedOnly] = useState(false);
  const [pendingOnly, setPendingOnly] = useState(false);
  const [forcedOnly, setForcedOnly] = useState(false);
  const [autoPrecodedOnly, setAutoPrecodedOnly] = useState(false);
  const [displayTranslations, setDisplayTranslations] = useState(false);
  const [pageSelectionArray, setpageSelectionArray] = useState([]);

  const [keywords, setKeywords] = useState('');

  const [loadData, setLoadData] = useState(LoadData.Load);
  useEffect(() => {
    if (loadData !== LoadData.Load) return;

    setLoadData(LoadData.Loading);

    if (project.id > 0) {
      showSpinner(true);

      var promises = [];
      promises.push(codificationService.fetchLibraries(project.codificationLibraryId, project.id));
      promises.push(codificationService.fetchProjectCodificationUnknownWords(project.id, unfixedOnly, pendingOnly, forcedOnly, autoPrecodedOnly, keywords, (page * rowsPerPage), rowsPerPage));
      promises.push(referenceService.fetchCoefficients());

      Promise.all(promises)
        .then(results => {
          var result = results[0];
          var _paths = Object.entries(result.data.paths).sort((a, b) => { return a[1].localeCompare(b[1]) });
          setCodifPaths(_paths);
          setLibraries(result.data.hits);
          setProjectSpecificLibraryId(result.data.projectSpecific.libraryId);

          var result = results[1];
          let concernedStimulus = result.data.stimulus;

          var newUnknownWords = result.data;
          for (var i = 0; i < newUnknownWords.hits.length; i++) {
            let hit = newUnknownWords.hits[i];
            hit.translations = [];
            hit.autocompleteSuggestionsHits = undefined;
            hit.autocompleteSelectedValue = hit.presetCode > 0 ? findById(hit.suggestions, hit.presetCode) : undefined;

            for (var j = 0; j < hit.answers.length; j++) {
              let answer = hit.answers[j];
              answer.stimulusName = findById(concernedStimulus, answer.projectStimulusId).name;
            }
          }
          setUnknownWords(newUnknownWords);

          // get the lang of the coefficient used by the project
          var result = results[2];
          let coefficient = findById(result.data, project.coefficientsId);
          if (coefficient !== undefined) setProjectLanguage(coefficient.language);
          setSequences(results[1].data.sequences);

          setLoadData(LoadData.Loaded);
          setLoadKeywordsChange(false);
        })
        .catch(err => {
          setLoadData(LoadData.Loaded);
          setLoadKeywordsChange(false);

          openSnackbar('error', t("react.project.error.while.loading"));
        }).finally(() => {
          showSpinner(false);
        });
    }
  }, [loadData, project, page, rowsPerPage]);

  useEffect(() => {
    if (!displayTranslations) {
      setUnknownWordsTranslations({});
      return;
    }

    showSpinner(true);
    let words = unknownWords.hits.map(e => e.originalWord);
    codificationService.translateEntries(words, projectLanguage, preferredLanguage)
      .then(translationsResult => {
        setUnknownWordsTranslations(translationsResult.data);
      }).catch(err => {
        openSnackbar('error', t("react.codification.entries.error.while.loading"));
      }).finally(() => {
        showSpinner(false);
      });
  }, [displayTranslations, unknownWords, projectLanguage, preferredLanguage]);

  useEffect(() => {
    let emptyArray = [];
    for (var i = 0; i < Math.ceil(unknownWords.total / rowsPerPage); i++) {
      emptyArray.push(`${(i * rowsPerPage) + 1}-${(i + 1) * rowsPerPage}`);
    }
    setpageSelectionArray(emptyArray);
  }, [unknownWords.total, rowsPerPage])

  const [loadKeywordsChange, setLoadKeywordsChange] = useState(false);
  const handleKeywordsChange = (newValue) => {
    let value = newValue ? newValue : '';

    setPage(0);
    setKeywords(value);
    if (value.length === 0 || value.length >= 2) {
      setLoadData(LoadData.Load);
      setLoadKeywordsChange(true);
    }
  };

  const handleChangePage = (event, newPage) => {
    event.preventDefault();
    setPage(newPage);
    setLoadData(LoadData.Load);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setLoadData(LoadData.Load);
  };

  const handleChangeAutocompleteInputValue = (unknownWordId, newValue, reason) => {
    // run a search if text is long enough
    if (reason === 'input' && newValue && newValue.length >= 3) {
      codificationService.searchBiblioEntries(0, 20, 'score', 'asc', `${project.codificationLibraryId},${projectSpecificLibraryId}`, newValue, undefined, undefined, undefined, true)
        .then(result => {
          findById(unknownWords.hits, unknownWordId).autocompleteSuggestionsHits = result.data.hits;
          forceUpdate();
        })
        .catch(err => {
          openSnackbar('error', t("react.codification.entries.error.while.loading"));
        });
    } else {
      findById(unknownWords.hits, unknownWordId).autocompleteSuggestionsHits = undefined;
      forceUpdate();
    }
  }

  /**
   * return null if no value
   **/
  const getAutocompleteSelectedValue = (unknownWordId) => {
    let r = findById(unknownWords.hits, unknownWordId).autocompleteSelectedValue;
    return r === undefined ? null : r;
  }

  const handleChangeAutocompleteSelectedValue = (unknownWordId, newValue, reason) => {
    findById(unknownWords.hits, unknownWordId).autocompleteSelectedValue = newValue;
    // push the autocompleteSelectedValue in the suggestion list to keep it displayed
    if (newValue && findById(findById(unknownWords.hits, unknownWordId).suggestions, newValue.id) === undefined) {
      findById(unknownWords.hits, unknownWordId).suggestions.push(newValue);
    }
    forceUpdate();
  }

  const getAutocompleteSuggestionsHits = (unknownWordId) => {
    // get matches from keywords if any
    let suggestions = findById(unknownWords.hits, unknownWordId).autocompleteSuggestionsHits;

    if (suggestions === undefined) {
      suggestions = Array.from(findById(unknownWords.hits, unknownWordId).suggestions);
    }

    return suggestions;
  }

  const handleApplySelection = (unknownWordId) => {
    let hit = findById(unknownWords.hits, unknownWordId);
    if (hit === undefined) return;

    let selectedBiblioEntry = getAutocompleteSelectedValue(unknownWordId);

    showSpinner(true);

    let payloadWithActions = [
      // Code only the current unknownWord with the selected codif
      {
        'action': 'fixUnknownWordsWithCode',
        'unknownWordId': unknownWordId,
        'codificationCode': selectedBiblioEntry ? selectedBiblioEntry.id : 0
      }];
    codificationService.applyProjectCodificationActions(project.id, payloadWithActions)
      .then(result => {
        hit.isPending = true;
        hit.isAutoPrecoded = false;
        hit.presetCode = selectedBiblioEntry ? selectedBiblioEntry.id : 0;
      }).catch(err => {
        openSnackbar('error', t("react.project.edit.codiftab.error.while.applying.actions"));
      }).finally(() => {
        showSpinner(false);
      });
  }

  const handleAddUnknownWordAsVariant = (unknownWordId) => {
    let hit = findById(unknownWords.hits, unknownWordId);
    if (hit === undefined) return;

    let selectedBiblioEntry = getAutocompleteSelectedValue(unknownWordId);
    if (selectedBiblioEntry === null) return;

    showSpinner(true);

    let payload = [hit.originalWord];
    codificationService.addVariantsToBiblioEntry(selectedBiblioEntry.libraryId, selectedBiblioEntry.id, payload)
      .then(result => {
        let payloadWithActions = [
          // Code all same unknownWords with the selected codif
          {
            'action': 'fixUnknownWordsWithCode',
            'unknownWordId': unknownWordId,
            'codificationCode': selectedBiblioEntry.id
          }];
        codificationService.applyProjectCodificationActions(project.id, payloadWithActions)
          .then(result => {
            hit.isPending = true;
            hit.isAutoPrecoded = false;
            hit.presetCode = selectedBiblioEntry.id;
          }).catch(err => {
            openSnackbar('error', t("react.project.edit.codiftab.error.while.applying.actions"));
          }).finally(() => {
            forceUpdate();
          });
      }).catch(err => {
        openSnackbar('error', t("react.project.edit.codiftab.error.while.add.new.variant"));
      }).finally(() => {
        showSpinner(false);
      });
  }

  const getAutocompleteOptionLabel = (option) => {
    let lib = findById(libraries, option.libraryId); // library may have been deleted
    let libName = lib !== undefined ? capitalizeFirstLetter(lib.name) : `Library id ${option.libraryId} NOT FOUND`;
    return `${option.biblioWord.word} (${getShorthandNotation(option.nature, option.logic, option.valence)}) - ${libName}`
  };

  /**
   * Now we have 3 states possibles : 
   * - DoneIcon = not treated or unknown state
   * - HourglassEmptyOutlinedIcon = Pending (coded but no applied/used by the system)
   * - LockOutlinedIcon = Applied and used by the system
   * 
   * Associated to those states, we can have colors:
   * - grey + DoneIcon = no information / nothing to do
   * - green + DoneIcon = need to apply the selection
   * - green + LockOutlinedIcon = normal UnknownWord => it's fine
   * - red + HourglassEmptyOutlinedIcon = UnknownWord NOT used by the system
   * - red + LockOutlinedIcon = UnknownWord is both used AND NOT used by the system (e.g new sequence)
   * - orange + HourglassEmptyOutlinedIcon = forced UnknownWord NOT used by the system
   * - orange + LockOutlinedIcon = forced UnknownWord used by the system
   * - blue + HourglassEmptyOutlinedIcon = UnknownWord precoded by a bot NOT used by the system
   * - blue + LockOutlinedIcon = UnknownWord precoded by a bot used by the system
   */
  const UnknownWordActionButton = (props) => {
    const { row, isDisabled } = props;

    let element = undefined;
    let tooltipTitle = '';

    let asv = getAutocompleteSelectedValue(row.id);
    if((asv !== null && asv.id !== row.presetCode) || (asv === null && row.presetCode > 0)) {
      // color is green = waiting to apply the selected value or modification (clear)
      element = <DoneIcon style={{ color: "#66bb6a" }} />;
      tooltipTitle = 'Save modification';
    } else if(asv !== null && asv.id === row.presetCode && row.isPending === true) {
      // color is red = the unknownWord is not yet used
      let iconColor = "red";
      tooltipTitle = 'Normal codification - not apply';

      if(row.isForced) {
        // color is orange = it was a forced association between an unknownWord and a biblioWord
        iconColor = "orange";
        tooltipTitle = 'Forced codification - not apply';
      } else if (row.isAutoPrecoded) {
        // color is blue = it was precoded by a bot
        iconColor = "blue";
        tooltipTitle = 'Automatic codification - not apply';
      }

      element = <HourglassEmptyOutlinedIcon style={{ color: iconColor }} />;
    } else if(asv !== null && asv.id === row.presetCode && row.isPending === false) {
      // color is green (clear) = it was a manual action
      let iconColor = "#8cd98c";
      tooltipTitle = 'Normal codification - fully applied';

      if(row.isForced) {
        // color is orange = it was a forced association between an unknownWord and a biblioWord
        iconColor = "orange";
        tooltipTitle = 'Force codification - fully applied';
      } else if (row.isAutoPrecoded) {
        // color is blue = it was precoded by a bot
        iconColor = "blue";
        tooltipTitle = 'Automatic codification - fully applied';
      }

      if(pendingOnly === true) {
        // it's a special case: there are answer with the code applied and some without (e.g new sequence)
        iconColor = "red";
        tooltipTitle = 'Normal codification - NOT fully applied';
      }

      element = <LockOutlinedIcon style={{ color: iconColor }} />;
    }

    // color is grey = nothing to do
    if(element === undefined) {
      element = <DoneIcon style={{ color: "#bbb" }} />;
    }

    return (
      <Tooltip title={tooltipTitle}>
        <span>
          <IconButton
            onClick={e => handleApplySelection(row.id)}
            disabled={isDisabled}
            size="large">
            {element}
          </IconButton>
        </span>
      </Tooltip>
    );
  };

  const headCells = [
    { id: 'originalWord', label: 'react.project.codif.table.header.originalWord', textAlign: 'left' },
    { id: 'suggestions', label: 'react.project.codif.table.header.suggestions', textAlign: 'center' },
    { id: 'actions', label: 'react.project.codif.table.header.actions', textAlign: 'center' },
  ];

  function UnknownWordsListHead(props) {
    const { t } = props;

    return (
      <TableHead>
        <TableRow>
          {headCells.map(headCell => (
            <TableCell
              key={headCell.id}
              align={headCell.align}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              style={{ textAlign: headCell.textAlign }}
            >
              {t(headCell.label)}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  const [unknownWordAsNewBiblioEntry, setUnknownWordAsNewBiblioEntry] = useState({});
  const [openAddUnknownWordAsNewBiblioEntryDialog, setOpenAddUnknownWordAsNewBiblioEntryDialog] = useState(false);
  const handleOpenAddUnknownWordAsNewBiblioEntry = (unknownWordAsNewBiblioEntry) => {
    setUnknownWordAsNewBiblioEntry(unknownWordAsNewBiblioEntry);
    setOpenAddUnknownWordAsNewBiblioEntryDialog(true);
  }
  const callbackAfterConfirmAddUnknownWordAsNewBiblioEntry = (biblioEntry) => {
    setOpenAddUnknownWordAsNewBiblioEntryDialog(false);

    showSpinner(true);

    let payloadWithActions = [
      // Code all same unknownWords with the selected codif
      {
        'action': 'fixUnknownWordsWithCode',
        'unknownWordId': 0,
        'codificationCode': biblioEntry.id
      }];
    codificationService.applyProjectCodificationActions(project.id, payloadWithActions)
      .then(result => {
        unknownWordAsNewBiblioEntry.suggestions.push(biblioEntry);
        unknownWordAsNewBiblioEntry.autocompleteSelectedValue = biblioEntry;
        unknownWordAsNewBiblioEntry.presetCode = biblioEntry.id;
        unknownWordAsNewBiblioEntry.isPending = true;
      }).catch(err => {
        openSnackbar('error', t("react.project.edit.codiftab.error.while.applying.actions"));
      }).finally(() => {
        setUnknownWordAsNewBiblioEntry({});
        showSpinner(false);
      });
  }
  const callbackAfterCancelAddUnknownWordAsNewBiblioEntry = () => {
    setOpenAddUnknownWordAsNewBiblioEntryDialog(false);
  }

  const handleApplyUnknownWordsCodification = () => {
    showSpinner(true);

    let payloadWithActions = [
      {
        'action': 'applyUnknownWordsCodification',
      }];
    codificationService.applyProjectCodificationActions(project.id, payloadWithActions)
      .then(result => {
        openSnackbar('info', t("react.project.edit.codiftab.info.unknownWord.codif.fired"));
      }).catch(err => {
        openSnackbar('error', t("react.project.edit.codiftab.error.while.applying.actions"));
      }).finally(() => {
        showSpinner(false);
      });
  }

  const [unknownWordEditSelectedBiblioEntry, setUnknownWordEditSelectedBiblioEntry] = useState({});
  const [openUnknownWordEditSelectedBiblioEntryDialog, setOpenUnknownWordEditSelectedBiblioEntryDialog] = useState(false);
  const handleOpenEditSelectedBiblioEntry = (unknownWordId) => {
    let hit = findById(unknownWords.hits, unknownWordId);
    if (hit === undefined) return;

    let selectedBiblioEntry = getAutocompleteSelectedValue(unknownWordId);
    if (selectedBiblioEntry === null) return;

    setUnknownWordEditSelectedBiblioEntry(selectedBiblioEntry);
    setOpenUnknownWordEditSelectedBiblioEntryDialog(true);
  }
  const callbackAfterConfirmUnknownWordEditSelectedBiblioEntry = (updatedBiblioEntry) => {
    setOpenUnknownWordEditSelectedBiblioEntryDialog(false);

    // everywhere this updatedBiblioEntry is used, we update the data
    for (var i = 0; i < unknownWords.hits.length; i++) {
      let hit = unknownWords.hits[i];

      // update the selected value
      if (hit.autocompleteSelectedValue && hit.autocompleteSelectedValue.id === updatedBiblioEntry.id) {
        hit.autocompleteSelectedValue = updatedBiblioEntry;
        hit.isAutoPrecoded = false;
      }

      // update in suggestions
      hit.suggestions.forEach((item, i) => {
        if (item.id === updatedBiblioEntry.id) {
          hit.suggestions.fill(updatedBiblioEntry, i);
        }
      });
    }

    // force refresh
    forceUpdate();
  }
  const callbackAfterCancelUnknownWordEditSelectedBiblioEntry = () => {
    setOpenUnknownWordEditSelectedBiblioEntryDialog(false);
  }

  const handleBlacklistUnknownWord = (unknownWordId) => {
    let hit = findById(unknownWords.hits, unknownWordId);
    if (hit === undefined) return;

    showSpinner(true);

    let payloadWithActions = [
      {
        'action': 'blacklistUnknownWord',
        'unknownWordId': unknownWordId,
      }];
    codificationService.applyProjectCodificationActions(project.id, payloadWithActions)
      .then(result => {
        let idx = indexOf(unknownWords.hits, 'id', unknownWordId);
        unknownWords.hits.splice(idx, 1);
        forceUpdate();
      }).catch(err => {
        openSnackbar('error', t("react.project.edit.codiftab.error.while.applying.actions"));
      }).finally(() => {
        showSpinner(false);
      });
  };

  const handleTrashUnknownWord = (unknownWordId) => {
    let hit = findById(unknownWords.hits, unknownWordId);
    if (hit === undefined) return;

    showSpinner(true);

    let payloadWithActions = [
      {
        'action': 'trashUnknownWord',
        'unknownWordId': unknownWordId,
      }];
    codificationService.applyProjectCodificationActions(project.id, payloadWithActions)
      .then(result => {
        let idx = indexOf(unknownWords.hits, 'id', unknownWordId);
        unknownWords.hits.splice(idx, 1);
        forceUpdate();
      }).catch(err => {
        openSnackbar('error', t("react.project.edit.codiftab.error.while.applying.actions"));
      }).finally(() => {
        showSpinner(false);
      });
  };

  const handleExportProjectCodification = () => {
    openSnackbar('info', t("react.project.edit.codiftab.button.export.starting"));

    codificationService.downloadProjectCodificationFile(project.id)
      .then(response => {
        var regExp = new RegExp('filename="([^"]+)"', 'i');
        let filename = regExp.exec(response.headers['content-disposition'])[1];
        let url = window.URL.createObjectURL(new Blob([response.data]));
        let link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        openSnackbar('success', t("react.project.edit.codiftab.export.finished"));
      })
      .catch(err => {
        openSnackbar('error', t("react.project.edit.codiftab.export.error"));
      });
  };

  const getHtmlTooltipContent = (answerRow) => {
    let seq = findById(sequences, answerRow.sequenceId);
    let seqName = seq === undefined ? 'not found' : seq.name;
    let content = `Stimulus = ${answerRow.stimulusName} / Sequence = ${seqName} / Word 1 = ${answerRow.word1} / Word 2 = ${answerRow.word2} / Word 3 = ${answerRow.word3} (id ${answerRow.id})`;
    return content;
  };

  const getTranslationOfOriginalWord = (originalWord) => {
    let trad = displayTranslations ? unknownWordsTranslations[originalWord] : undefined;
    return trad === undefined ? '' : ` (${trad.slice(0, 3).map(word => word).join(', ')})`;
  };

  const HtmlTooltip = withStyles(Tooltip, (theme) => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 600,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));

  const handleChangeSelectPage = (event) => {
    setPage(event.target.value);
    setLoadData(LoadData.Load);
  };

  const handleChangeFilterByState = (event) => {
    event.preventDefault();
    switch(event.target.value) {
      case 'unfixedOnly':
        setUnfixedOnly(true);
        setPendingOnly(false);
        setForcedOnly(false);
        setAutoPrecodedOnly(false);
        break;
      case 'pendingOnly':
        setUnfixedOnly(false);
        setPendingOnly(true);
        setForcedOnly(false);
        setAutoPrecodedOnly(false);
        break;
      case 'forcedOnly':
        setUnfixedOnly(false);
        setPendingOnly(false);
        setForcedOnly(true);
        setAutoPrecodedOnly(false);
        break;
      case 'autoPrecodedOnly':
        setUnfixedOnly(false);
        setPendingOnly(false);
        setForcedOnly(false);
        setAutoPrecodedOnly(true);
        break;
      case 'none':
        setUnfixedOnly(false);
        setPendingOnly(false);
        setForcedOnly(false);
        setAutoPrecodedOnly(false);
        break;
      }
      setPage(0);
      setLoadData(LoadData.Load);
  };

  const getFilterSelectedState = () => {
      if(unfixedOnly) return 'unfixedOnly';
      else if(pendingOnly) return 'pendingOnly';
      else if(forcedOnly) return 'forcedOnly';
      else if(autoPrecodedOnly) return 'autoPrecodedOnly';
      else return 'none';
  };

  if (loadData !== LoadData.Loaded && !loadKeywordsChange) {
    return (
      <Grid item xs={12} container>
        <Grid item xs={12}>
          <LinearProgress />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid item xs={12} container>
      <Grid item xs={12}>
        <Typography variant="h6">
          {t("react.project.edit.codiftab.title")}
        </Typography>
        <Select
          native
          value={project.codificationLibraryId}
          disabled={true}
          inputProps={{
            name: 'codifPathId',
            id: 'filled-codifPathId-native-simple',
          }}
        >
          <option value="0" >
            {t("react.generic.text.none")}
          </option>
          {
            codifPaths.filter((k, v) => parseInt(k) > 0).map(([key, value]) => (
              <option key={`listItemPath${key}`} value={parseInt(key)}>{value}</option>
            ))
          }
        </Select>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.containerSticky}>
          <Toolbar className={toolbarClasses.root}>
            <Typography variant="h6" className={toolbarClasses.title} id="tableTitle">
              {t("react.project.edit.codiftab.unknownwords")}: {unknownWords.total}
            </Typography>
            <Input
              id="search-filter-basic"
              value={keywords}
              className={classes.searchfield}
              placeholder={t('react.translationtool.search.title')}
              onChange={e => handleKeywordsChange(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={e => handleKeywordsChange('')} edge="end" size="large">
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
            <Tooltip title={t("react.project.edit.codiftab.translate.entries")}>
              <span>
                <Switch
                  disabled={projectLanguage === preferredLanguage}
                  checked={displayTranslations}
                  onChange={e => setDisplayTranslations(!displayTranslations)}
                  color="primary"
                  name="translate-switch"
                />
              </span>
            </Tooltip>
            <FormControl className={classes.formControlDashboard} sx={{minWidth: '200px'}}>
                <Select
                    id='filterByState'
                    value={getFilterSelectedState()}
                    onChange={handleChangeFilterByState}
                    autoWidth
                >
                  <MenuItem key={0} value={'none'}>{t("react.project.edit.codiftab.filter.unknown.words")}</MenuItem>
                  <MenuItem key={1} value={'unfixedOnly'}>{t("react.project.edit.codiftab.hide.treated")}</MenuItem>
                  <MenuItem key={2} value={'pendingOnly'}>{t("react.project.edit.codiftab.pending.only")}</MenuItem>
                  <MenuItem key={3} value={'forcedOnly'}>{t("react.project.edit.codiftab.forced.only")}</MenuItem>
                  <MenuItem key={4} value={'autoPrecodedOnly'}>{t("react.project.edit.codiftab.auto.only")}</MenuItem>
                </Select>
            </FormControl>
            <Tooltip title={t("react.project.edit.codiftab.export.codif")}>
              <span>
                <IconButton
                  onClick={e => handleExportProjectCodification()}
                  size="large">
                  <ExportIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title={t("react.project.edit.codiftab.run.codif")}>
              <span>
                <IconButton
                  onClick={e => handleApplyUnknownWordsCodification()}
                  size="large">
                  <DynamicFeedIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title={t("react.button.refresh")}>
              <span>
                <IconButton
                  onClick={e => setLoadData(LoadData.Load)}
                  size="large">
                  <CachedIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Toolbar>
          <div className={classes.divPaginationTop}>
            <TablePagination
              rowsPerPageOptions={[100, 300, 600]}
              component="div"
              count={unknownWords.total}
              rowsPerPage={rowsPerPage}
              page={page}
              labelRowsPerPage={t('react.list.number.lignes.per.page')}
              labelDisplayedRows={({ from, to, count }) => t('react.list.number.from.to.count', { 'from': from, 'to': to, 'count': count })}
              getItemAriaLabel={(type) => getItemAriaLabel(t, type)}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <FormControl size="small" className={classes.formControl} >
              <Select
                id="formControlTop"
                value={page}
                onChange={(e) => handleChangeSelectPage(e)}
                disableUnderline
                style={{ transform: 'scale(0.85)' }}
              >
                {pageSelectionArray.map((value, index) => (
                  <MenuItem key={index} value={index}>{value}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <div className={classes.fastPagination}>
              {t('react.dictionary.fast.pagination')}
            </div>
          </div>
        </div>
        <Table
          className={classes.table}
          size="small"
        >
          <UnknownWordsListHead
            {...props}
            classes={classes}
            rowCount={unknownWords.total}
          />
          <TableBody>

            {unknownWords.hits.map((row) => {
              let autocompleteSelectedValue = getAutocompleteSelectedValue(row.id);
              let isDisabled = !((autocompleteSelectedValue ? autocompleteSelectedValue.id : 0) !== row.presetCode);

              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={`TableRow-${row.id}`}
                >
                  <TableCell>
                    <HtmlTooltip
                      title={
                        <Fragment>
                          <Typography color="inherit">{row.originalWord}</Typography>
                          {row.answers.map((answerRow) => {
                            return (
                              <span key={`context-tooltip-${answerRow.id}`}>{getHtmlTooltipContent(answerRow)}<br /></span>
                            )
                          })}
                        </Fragment>
                      }
                    >
                      <Typography color="inherit">{row.originalWord} {getTranslationOfOriginalWord(row.originalWord)}</Typography>
                    </HtmlTooltip>
                  </TableCell>

                  <TableCell>
                    <Autocomplete
                      id={`Autocomplete-${row.id}`}
                      className={classes.autocompleteInputField}
                      autoHighlight
                      fullWidth
                      filterOptions={(x) => x}
                      options={getAutocompleteSuggestionsHits(row.id)}
                      noOptionsText={t('react.project.edit.codiftab.select.nooptions')}
                      isOptionEqualToValue={(option, value) => option !== undefined && value !== undefined ? option.id === value.id : false}
                      getOptionLabel={(option) => option !== undefined ? getAutocompleteOptionLabel(option) : ''}
                      // call when something is typed into the input
                      onInputChange={(event, newInputValue, reason) => {
                        handleChangeAutocompleteInputValue(row.id, newInputValue, reason);
                      }}
                      value={autocompleteSelectedValue}
                      // call when a value is selected in the dropdown
                      onChange={(event, newValue, reason) => {
                        handleChangeAutocompleteSelectedValue(row.id, newValue, reason);
                      }}
                      renderInput={(params) => <TextField {...params} label={t('react.project.edit.codiftab.input.select-search')}
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />}
                    />
                  </TableCell>

                  <TableCell className={classes.tableCellActions}>
                    <UnknownWordActionButton row={row} isDisabled = {isDisabled}/>
                    <Tooltip title={isDisabled ? '' : t("react.project.edit.codiftab.button.add.suggestion.as.variant")}>
                      <span>
                        <IconButton
                          onClick={e => handleAddUnknownWordAsVariant(row.id)}
                          disabled={isDisabled}
                          size="large">
                          <LayersIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                    <Tooltip title={t("react.project.edit.codiftab.button.add.new.entry")}>
                      <span>
                        <IconButton
                          onClick={e => handleOpenAddUnknownWordAsNewBiblioEntry(row)}
                          disabled={autocompleteSelectedValue !== null}
                          size="large">
                          <AddCircleOutlineIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                    <Tooltip title={t("react.project.edit.codiftab.button.edit.current.entry")}>
                      <span>
                        <IconButton
                          onClick={e => handleOpenEditSelectedBiblioEntry(row.id)}
                          disabled={getAutocompleteSelectedValue(row.id) === null || getAutocompleteSelectedValue(row.id).id !== row.presetCode}
                          size="large">
                          <EditIcon />
                        </IconButton>
                      </span>
                    </Tooltip>

                    <Tooltip title={t("react.project.edit.codiftab.button.blacklist")}>
                      <span>
                        <IconButton
                          onClick={e => handleBlacklistUnknownWord(row.id)}
                          disabled={(autocompleteSelectedValue !== null)}
                          size="large">
                          <NewReleasesIcon />
                        </IconButton>
                      </span>
                    </Tooltip>

                    <Tooltip title={t("react.project.edit.codiftab.button.trash")}>
                      <span>
                        <IconButton
                          onClick={e => handleTrashUnknownWord(row.id)}
                          disabled={(autocompleteSelectedValue !== null)}
                          size="large">
                          <DeleteIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}

          </TableBody>
        </Table>
      </Grid>
      <Grid item xs={12}>
        <EditCodifDialog
          {...props}
          openState={openAddUnknownWordAsNewBiblioEntryDialog}
          biblioEntryId={0}
          appendToLibraryId={project.codificationLibraryId}
          codificationPaths={codifPaths}
          languagePlaceHolder={projectLanguage}
          wordPlaceHolder={unknownWordAsNewBiblioEntry.originalWord}
          naturePlaceHolder={'Adjectivale'}
          logicPlaceHolder={'Caracterisation'}
          valencePlaceHolder={1}
          callbackAfterConfirm={callbackAfterConfirmAddUnknownWordAsNewBiblioEntry}
          callbackAfterCancel={callbackAfterCancelAddUnknownWordAsNewBiblioEntry}
        />
        <EditCodifDialog
          {...props}
          openState={openUnknownWordEditSelectedBiblioEntryDialog}
          biblioEntryId={unknownWordEditSelectedBiblioEntry.id}
          appendToLibraryId={unknownWordEditSelectedBiblioEntry.libraryId}
          codificationPaths={codifPaths}
          callbackAfterConfirm={callbackAfterConfirmUnknownWordEditSelectedBiblioEntry}
          callbackAfterCancel={callbackAfterCancelUnknownWordEditSelectedBiblioEntry}
        />
      </Grid>
    </Grid>
  );
}
