import React, { useState, useEffect } from 'react';
import {LoadData} from '../../Constants.js'
import { AuthService } from '../../services/AuthService';
import queryString from 'query-string';
import { isBlank, sleep } from '../../utils/utils.js';
import { Navigate } from "react-router-dom";

export default function BearerSignIn(props) {

  const { showSpinner, openSnackbar, t } = props;

  const [signedIn, setSignedIn] = useState(false);

  // get the provided token
  let params = queryString.parse(window.location.search)
  let bearerToken = params.token;
  let target = params.target;

  const [loadData, setLoadData] = useState(LoadData.Load);
  useEffect(() => {
    if(loadData !== LoadData.Load) return;

    setLoadData(LoadData.Loading);

    showSpinner(true);

    AuthService.signInBearer(decodeURIComponent(bearerToken))
      .then(user => {
        setSignedIn(user ? true : false);
        showSpinner(false);
        setLoadData(LoadData.Loaded);
      })
      .catch(err => {
        setSignedIn(false);
        showSpinner(false);
        openSnackbar('error', t('react.signin.bearer.with.token.error'));
        sleep(5000)
          .then(() => {
            setLoadData(LoadData.Loaded);
          });
      });
  }, [bearerToken]);

  if(loadData !== LoadData.Loaded) {
    return (
      <div >
      {t('react.signin.bearer.with.token.in.progress')}
      </div>
    );
  }

  if(signedIn) {
    return (<Navigate to={!isBlank(target) ? target : "/"} replace />);
  } else {
    return (<Navigate to="/logout" replace />);
  }
}
