
export const getItemAriaLabel = (t, type) => {
    if (type === 'next') {
        return t('react.list.button.next.page');
    }
    if (type === 'previous') {
        return t('react.list.button.previous.page');
    }
    if (type === 'first') {
        return t('react.list.button.first.page');
    }
    if (type === 'last') {
        return t('react.list.button.last.page');
    }
    return undefined;
};
