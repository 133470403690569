import React, { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import dateFormat from 'dateformat';
import DialogTitleWithCloseIcon from '../shared/DialogTitleWithCloseIcon/DialogTitleWithCloseIcon';
import UserService from '../../services/UserService';

const useStyles = makeStyles()(theme => ({
  formControlLabel: {
      color: '#b90000', 
      marginTop: 5, 
      marginBottom: -20
    },
}));

export default function ChangeExpirationDateDialog(props) {

    const { classes } = useStyles();

    const { openState, callbackAfterCancel, callbackAfterConfirm, t } = props;

    const [checkboxExpirationDate, setCheckboxExpirationDate] = useState(false);
    const [expirationDate, setExpirationDate] = useState(new Date());

    const displayAlertExpirationDate = () => {
      let today = dateFormat(new Date(), "UTC:yyyy-mm-dd'T'HH:MM");
      return today > expirationDate;
    }

    const handleClickConfirm = () => {
      callbackAfterConfirm(expirationDate);
    };

    return (
        <Dialog open={openState} onClose={callbackAfterCancel} maxWidth={"xs"} fullWidth={true}>
          <DialogTitleWithCloseIcon
            title={t("react.users.expirationDate.popup.title")}
            callbackOnclose={callbackAfterCancel}
          />
          <DialogContent>
            <TextField
              margin="dense"
              label={t('react.useredit.expirationdate')}
              type="datetime-local"
              value={dateFormat(expirationDate, "UTC:yyyy-mm-dd'T'HH:MM")}
              onChange={e => setExpirationDate(e.target.value)}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              className={classes.textField}
            />
            <FormControlLabel className={classes.formControlLabel} style={{ visibility: displayAlertExpirationDate()  ? 'visible' : 'hidden'}} 
              control={<Checkbox color="error" style={{color: '#b90000'}} checked={checkboxExpirationDate} onChange={() => setCheckboxExpirationDate(!checkboxExpirationDate)}/>} 
              label={t('react.users.expiration.help')}
            />
          </DialogContent>
          <DialogActions>
          <Button onClick={callbackAfterCancel} color="primary">
            {t("react.button.cancel")}
          </Button>
          <Button disabled={displayAlertExpirationDate() && !checkboxExpirationDate} onClick={handleClickConfirm} color="primary">
            {t("react.button.apply")}
          </Button>
          </DialogActions>
        </Dialog>
    )
}
