import React, { useState, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { AppBar, Tab, Tabs } from '@mui/material';
import {Helmet} from "react-helmet";
import { HomeRepairService } from '@mui/icons-material';
import TryIcon from '@mui/icons-material/Try';
import PromptsList from './PromptsList.js';
import PanelTools from './PanelTools.js';
import PromptEditDialog from './PromptEditDialog.js';
import { LoadData } from '../../Constants.js';


const useStyles = makeStyles()(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  textField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}));

export default function AiTools(props) {

  const { classes } = useStyles();

  const { t, showSpinner, openSnackbar } = props;
  const [openEditPromptDialog, setOpenEditPromptDialog] = useState({show:false, id:0});
  const [loadData, setLoadData] = useState(LoadData.Load);

  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setLoadData(LoadData.Load);
  };

  const editDialogcallback = () => {
    setLoadData(LoadData.Load);
  }

  return (<>
    <Paper className={classes.paper}>
      <Helmet title={t('react.helmet.aitools')} />
      <Grid container spacing={3}>
      <Grid item xs={12}>
        <AppBar position='static' color='default'>
          <Tabs value={tabValue} onChange={handleTabChange}>
            <Tab value={0} icon={<HomeRepairService/>} label={t('react.aitools.tab.tools')} />
            <Tab value={1} icon={<TryIcon/>} label={t('react.aitools.tab.promptlist')} />
          </Tabs>
        </AppBar>
      </Grid>
      {tabValue === 0 && <PanelTools {...props}/>}
      {tabValue === 1 && <PromptsList {...props} setOpenEditPromptDialog={setOpenEditPromptDialog} loadData={loadData} setLoadData={setLoadData} openEditPromptDialog={openEditPromptDialog}/>}
      </Grid>
    </Paper>
    <PromptEditDialog {...props} editDialogcallback={editDialogcallback} setOpenEditPromptDialog={setOpenEditPromptDialog} openEditPromptDialog={openEditPromptDialog}/>
    </>
  );
};
