import React, { useState, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import CodificationService from '../../services/CodificationService';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitleWithCloseIcon from '../shared/DialogTitleWithCloseIcon/DialogTitleWithCloseIcon';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import InputAdornment from '@mui/material/InputAdornment';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
import { cloneDeep, isBlank } from '../../utils/utils.js';
import { LoadData } from '../../Constants.js'

const codificationService = new CodificationService();

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  paddingTop: {
    paddingTop: 15,
  },
  formControl: {
    minWidth: 120,
    width: '100%',
    marginTop: '15px'
  },
}));

const emptyDictionaryWord = { id: 0, word: '', language: '', variants: [] };

export default function EditWordTranslations(props) {

  const {
    t,
    preferredLanguage,
    openSnackbar,
    showSpinner,
    openState,
    id,
    translateTo,
    callbackAfterConfirm,
    callbackAfterCancel,
  } = props;

  const { classes } = useStyles();

  const [dictionaryWord, setDictionaryWord] = useState(cloneDeep(emptyDictionaryWord));

  const [modified, setModified] = useState(false);
  const [translationFieldValue, setTranslationFieldValue] = useState('');
  const [translationsOfWord, setTranslationsOfWord] = useState([]);

  const [loadData, setLoadData] = useState(LoadData.Load);
  useEffect(() => {
    if (!openState) {
      setLoadData(LoadData.Load);
      return;
    }

    if (loadData !== LoadData.Load) return;

    setLoadData(LoadData.Loading);

    showSpinner(true);

    if (id > 0) {
      setModified(false);
      setTranslationFieldValue('');

      codificationService.getWord(id)
        .then(result => {
          let dw = cloneDeep(emptyDictionaryWord, result.data);
          setDictionaryWord(dw);

          codificationService.translateEntry(dw.word, dw.language, translateTo)
            .then(result => {
              setTranslationsOfWord(result.data);
            })
            .catch(err => {
              openSnackbar('error', t('react.generic.error.while.loading'));
            }).finally(() => {
              showSpinner(false);
              setLoadData(LoadData.Loaded);
            });
        })
        .catch(err => {
          openSnackbar('error', t('react.generic.error.while.loading'));
        });
    } else {
      setModified(false);
      setDictionaryWord(cloneDeep(emptyDictionaryWord, { 'language': preferredLanguage }));
      setTranslationFieldValue('');
      setTranslationsOfWord([]);
      showSpinner(false);
      setLoadData(LoadData.Loaded);
    }
  }, [loadData, id, openState]);

  const handleClickEditDialogCancel = (event) => {
    event.preventDefault();
    callbackAfterCancel();
  };

  const handleClickEditDialogConfirm = (event) => {
    event.preventDefault();

    codificationService.saveWordTranslations(dictionaryWord, translateTo, translationsOfWord)
      .then(result => {
        callbackAfterConfirm(dictionaryWord, translateTo, translationsOfWord);
      })
      .catch(err => {
        openSnackbar('error', t('react.generic.error.while.saving'));
      });
  };

  const handleClickAddToTranslations = (word) => {
    const newTranslationsOfWord = [...translationsOfWord];
    newTranslationsOfWord.push(word);
    setTranslationsOfWord(newTranslationsOfWord);
    setTranslationFieldValue('');
    setModified(true);
  };

  const handleClickDeleteTranslation = (event, idx) => {
    event.preventDefault();

    // remove from the displayed list
    const newTranslationsOfWord = [...translationsOfWord];
    newTranslationsOfWord.splice(idx, 1);

    setTranslationsOfWord(newTranslationsOfWord);
    setModified(true);
  };

  if (loadData !== LoadData.Loaded) {
    return null;
  }

  return (
    <Dialog fullWidth={true} maxWidth={'md'} open={openState} onClose={handleClickEditDialogCancel}>
      <DialogTitleWithCloseIcon
        title={t('react.dictionary.word.translation.title.modify', { "name": dictionaryWord.word, "id": dictionaryWord.id })}
        callbackOnclose={handleClickEditDialogCancel}
      />
      <DialogContent>
        <TextField
          disabled
          margin="dense"
          label={t('react.dictionary.field.word')}
          type="text"
          value={dictionaryWord.word}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          disabled
          margin="dense"
          id="language"
          value={dictionaryWord.language}
          label={t("react.dictionary.field.language")}
          type="text"
          fullWidth
        />
        <TextField
          disabled
          margin="dense"
          id="translateTo"
          value={translateTo}
          label={t("react.dictionary.field.translateTo")}
          type="text"
          fullWidth
        />
        <TextField
          margin="dense"
          label={t("react.dictionary.field.translation")}
          type="text"
          fullWidth
          autoFocus
          value={translationFieldValue}
          onChange={e => setTranslationFieldValue(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title={t("react.button.add")}>
                  <span>
                    <IconButton
                      edge="end"
                      onClick={e => handleClickAddToTranslations(translationFieldValue)}
                      disabled={isBlank(translationFieldValue) || translationsOfWord.indexOf(translationFieldValue) >= 0}
                      size="large">
                      <AddIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
        <List dense={true} className={classes.root}>
          <div style={{ height: '200px', overflowY: 'scroll', overflowX: 'hidden' }}>
            {translationsOfWord.map((translation, index) => {
              return (
                <ListItem key={`wordTranslation-${index}`}>
                  <ListItemText
                    primary={translation}
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      onClick={e => handleClickDeleteTranslation(e, index)}
                      size="large">
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })
            }
          </div>
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          size="large"
          startIcon={<CloseIcon />}
          onClick={handleClickEditDialogCancel} color="primary">
          {t('react.button.cancel')}
        </Button>
        <Button
          variant="outlined"
          size="large"
          startIcon={<SaveIcon />}
          disabled={!modified}
          onClick={handleClickEditDialogConfirm} color="primary">
          {t('react.button.save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
