import React, { useState, useEffect } from "react";
import { makeStyles } from 'tss-react/mui';
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Input from "@mui/material/Input";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import { isBlank } from "../../utils/utils.js";
import DescriptionIcon from "@mui/icons-material/Description";
import InputLabel from "@mui/material/InputLabel";
import { Select } from "@mui/material";
import AiCompanionService from "../../services/AiCompanionService.js";
import ListIcon from "@mui/icons-material/List";
import TextField from "@mui/material/TextField";
import PromptsService from "../../services/PromptsService.js";
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import ISO6391 from 'iso-639-1';

const promptsService = new PromptsService();
const aiCompanionService = new AiCompanionService();

const useStyles = makeStyles()((theme) => ({
   root: {
      width: "100%",
   },
   paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2),
   },
   button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
   },
   textField: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
   },
   flexBetween: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: theme.spacing(10),
   },
   flexBetweenTextField: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: theme.spacing(4),
   }
}));

export default function PanelTools(props) {
   const { classes } = useStyles();

   const { t, showSpinner, openSnackbar } = props;

   const [to3WordsLanguages, setTo3WordsLanguages] = useState([]);
   const [createCodeframeLanguages, setCreateCodeframeLanguages] = useState([]);
   const [improveCodeframeLanguages, setImproveCodeframeLanguages] = useState([]);
   const [thematizeLanguages, setThematizeLanguages] = useState([]);

   const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");

   useEffect(() => {
      showSpinner(true);

      var promises = [
         // get available lang by AI tool
         promptsService.fetchAllPromptsByType('review/to-3-words'),
         promptsService.fetchAllPromptsByType('review/to-thematization'),
         promptsService.fetchAllPromptsByType('review/improve-thematization'),
         promptsService.fetchAllPromptsByType('review/thematize')
      ];
  
      Promise.all(promises)
         .then(results => {
            setTo3WordsLanguages(results[0].data.hits.map(p => p.lang).sort());
            setCreateCodeframeLanguages(results[1].data.hits.map(p => p.lang).sort());
            setImproveCodeframeLanguages(results[2].data.hits.map(p => p.lang).sort());
            setThematizeLanguages(results[3].data.hits.map(p => p.lang).sort());
         })
         .catch(err => {
            setTo3WordsLanguages([]);
            setCreateCodeframeLanguages([]);
            setImproveCodeframeLanguages([]);
            setThematizeLanguages([]);
            openSnackbar("error", t("react.codification.error.while.loading"));
         }).finally(() => {
            showSpinner(false);
         });
   }, []);

   // Reviews to R3M input file

   const [reviewsInputFile, setReviewsInputFile] = useState({ name: "" });
   const [reviewsInputFileLang, setReviewInputFileLang] = useState("");

   const handleSelectUploadFileWithReviews = (event) => {
      event.preventDefault();
      if (event.target.files.length === 1) {
         let fnlc = event.target.files[0].name.toLowerCase();
         if (fnlc.endsWith(".xlsx")) {
            setReviewsInputFile(event.target.files[0]);
         } else {
            openSnackbar("error", "Ce type de fichier n'est pas supporté (Fichiers xlsx seulement)." );
         }
      }
   };

   const handleUploadFileWithReviews = (event) => {
      showSpinner(true);
      event.preventDefault();
      var formData = new FormData();
      formData.append("uploadedFile", reviewsInputFile);
      aiCompanionService
         .postReviewToR3m(reviewsInputFileLang, formData)
         .then((response) => {
            openSnackbar("success", t("react.codification.toolbar.button.import.started"));
         })
         .catch((err) => {
            openSnackbar("error", t("react.codification.toolbar.button.import.failed"));
         })
         .finally(() => {
            showSpinner(false);
            setReviewsInputFile({ name: "" });
            setReviewInputFileLang("");
         });
   };

   // Generate Code Frame

   const [verbatimsGenerateCodeFrameInputFile, setVerbatimsGenerateCodeFrameInputFile] = useState({ name: "" });
   const [verbatimsGenerateCodeFrameTabNumber, setVerbatimsGenerateCodeFrameTabNumber] = useState(1);
   const [verbatimsGenerateCodeFrameColumnNumber, setVerbatimsGenerateCodeFrameColumnNumber] = useState('A');
   const [verbatimsGenerateCodeFrameDataLineNumber, setVerbatimsGenerateCodeFrameDataLineNumber] = useState(1);
   const [generateCodeFrameInputFileLang, setGenerateCodeFrameInputFileLang] = useState("");

   const handleSelectUploadVerbatimsFileForGenerateCodeFrame = (event) => {
      event.preventDefault();
      if (event.target.files.length === 1) {
         let fnlc = event.target.files[0].name.toLowerCase();
         if (fnlc.endsWith(".xlsx")) {
            setVerbatimsGenerateCodeFrameInputFile(event.target.files[0]);
         } else {
            openSnackbar("error", "Ce type de fichier n'est pas supporté (Fichiers xlsx seulement).");
         }
      }
   };

   const handleUploadFileForGenerateCodeFrame = (event) => {
      showSpinner(true);
      event.preventDefault();
      var formData = new FormData();
      formData.append("verbatimFile", verbatimsGenerateCodeFrameInputFile);
      aiCompanionService.postGenerateCodeFrame(formData, generateCodeFrameInputFileLang, (verbatimsGenerateCodeFrameTabNumber - 1), alphabet.findIndex(letter => letter === verbatimsGenerateCodeFrameColumnNumber), 
         (verbatimsGenerateCodeFrameDataLineNumber - 1))
         .then((response) => {
            openSnackbar("success", t("react.codification.toolbar.button.import.started"));
         })
         .catch((err) => {
            openSnackbar("error", t("react.codification.toolbar.button.import.failed"));
         })
         .finally(() => {
            showSpinner(false);
            setVerbatimsGenerateCodeFrameInputFile({ name: "" });
            setVerbatimsGenerateCodeFrameTabNumber(1);
            setVerbatimsGenerateCodeFrameColumnNumber('A');
            setVerbatimsGenerateCodeFrameDataLineNumber(1);
            setGenerateCodeFrameInputFileLang("");
         });
   }

   // Upgrade code Frame

   const [verbatimsInputFileForUpgrade, setVerbatimsInputFileForUpgrade] = useState({ name: "" });
   const [codeFrameInputFileForUpgrade, setCodeFrameInputFileForUpgrade] = useState({ name: "" });
   const [verbatimsTabNumberForUpgrade, setVerbatimsTabNumberForUpgrade] = useState(1);
   const [verbatimsColumnNumberForUpgrade, setVerbatimsColumnNumberForUpgrade] = useState('A');
   const [verbatimsDataLineNumberForUpgrade, setVerbatimsDataLineNumberForUpgrade] = useState(1);
   const [codeFrameTabNumberForUpgrade, setCodeFrameTabNumberForUpgrade] = useState(1);
   const [codeFrameDataLineNumberForUpgrade, setCodeFrameDataLineNumberForUpgrade] = useState(1);
   const [thematizationInputFileLangForUpgrade, setThematizationInputFileLangForUpgrade] = useState("");

   const handleSelectUploadVerbatimsFileForUpgradeCodeFrame = (event) => {
      event.preventDefault();
      if (event.target.files.length === 1) {
         let fnlc = event.target.files[0].name.toLowerCase();
         if (fnlc.endsWith(".xlsx")) {
            setVerbatimsInputFileForUpgrade(event.target.files[0]);
         } else {
            openSnackbar("error", "Ce type de fichier n'est pas supporté (Fichiers xlsx seulement).");
         }
      }
   };

   const handleSelectUploadCodeFrameFileForUpgradeCodeFrame = (event) => {
      event.preventDefault();
      if (event.target.files.length === 1) {
         let fnlc = event.target.files[0].name.toLowerCase();
         if (fnlc.endsWith(".xlsx")) {
            setCodeFrameInputFileForUpgrade(event.target.files[0]);
         } else {
            openSnackbar("error", "Ce type de fichier n'est pas supporté (Fichiers xlsx seulement).");
         }
      }
   };

   const handleUploadFileForUpgradeCodeFrame = (event) => {
      showSpinner(true);
      event.preventDefault();
      var formData = new FormData();
      formData.append("codeFrameFile", codeFrameInputFileForUpgrade);
      formData.append("verbatimFile", verbatimsInputFileForUpgrade);
      aiCompanionService.postImproveCodeFrame(formData, (verbatimsTabNumberForUpgrade - 1), alphabet.findIndex(letter => letter === verbatimsColumnNumberForUpgrade), 
         (verbatimsDataLineNumberForUpgrade - 1), (codeFrameTabNumberForUpgrade - 1), (codeFrameDataLineNumberForUpgrade - 1), thematizationInputFileLangForUpgrade)
         .then((response) => {
            openSnackbar("success", t("react.codification.toolbar.button.import.started"));
         })
         .catch((err) => {
            openSnackbar("error", t("react.codification.toolbar.button.import.failed"));
         })
         .finally(() => {
            showSpinner(false);
            setVerbatimsInputFileForUpgrade({ name: "" });
            setCodeFrameInputFileForUpgrade({ name: "" });
            setVerbatimsTabNumberForUpgrade(1);
            setVerbatimsColumnNumberForUpgrade('A');
            setVerbatimsDataLineNumberForUpgrade(1);
            setCodeFrameTabNumberForUpgrade(1);
            setCodeFrameDataLineNumberForUpgrade(1);
            setThematizationInputFileLangForUpgrade("");
         });
   };

   // Thematize verbatims

   const [verbatimsInputFile, setVerbatimsInputFile] = useState({ name: "" });
   const [codeFrameInputFile, setCodeFrameInputFile] = useState({ name: "" });
   const [verbatimsTabNumber, setVerbatimsTabNumber] = useState(1);
   const [verbatimsColumnNumber, setVerbatimsColumnNumber] = useState('A');
   const [verbatimsDataLineNumber, setVerbatimsDataLineNumber] = useState(1);
   const [codeFrameTabNumber, setCodeFrameTabNumber] = useState(1);
   const [codeFrameDataLineNumber, setCodeFrameDataLineNumber] = useState(1);
   const [thematizationInputFileLang, setThematizationInputFileLang] = useState("");

   const handleSelectUploadVerbatimsFileForVerbatimsThematization = (event) => {
      event.preventDefault();
      if (event.target.files.length === 1) {
         let fnlc = event.target.files[0].name.toLowerCase();
         if (fnlc.endsWith(".xlsx")) {
            setVerbatimsInputFile(event.target.files[0]);
         } else {
            openSnackbar("error", "Ce type de fichier n'est pas supporté (Fichiers xlsx seulement).");
         }
      }
   };

   const handleSelectUploadCodeFrameFileForVerbatimsThematization = (event) => {
      event.preventDefault();
      if (event.target.files.length === 1) {
         let fnlc = event.target.files[0].name.toLowerCase();
         if (fnlc.endsWith(".xlsx")) {
            setCodeFrameInputFile(event.target.files[0]);
         } else {
            openSnackbar("error", "Ce type de fichier n'est pas supporté (Fichiers xlsx seulement).");
         }
      }
   };

   const handleUploadFileForVerbatimsThematization = (event) => {
      showSpinner(true);
      event.preventDefault();
      var formData = new FormData();
      formData.append("codeFrameFile", codeFrameInputFile);
      formData.append("verbatimFile", verbatimsInputFile);
      aiCompanionService.postThematizeWithCodeFrame(formData, (verbatimsTabNumber - 1), alphabet.findIndex(letter => letter === verbatimsColumnNumber), 
         (verbatimsDataLineNumber - 1), (codeFrameTabNumber - 1), (codeFrameDataLineNumber - 1), thematizationInputFileLang)
         .then((response) => {
            openSnackbar("success", t("react.codification.toolbar.button.import.started"));
         })
         .catch((err) => {
            openSnackbar("error", t("react.codification.toolbar.button.import.failed"));
         })
         .finally(() => {
            showSpinner(false);
            setVerbatimsInputFile({ name: "" });
            setCodeFrameInputFile({ name: "" });
            setVerbatimsTabNumber(1);
            setVerbatimsColumnNumber('A');
            setVerbatimsDataLineNumber(1);
            setCodeFrameTabNumber(1);
            setCodeFrameDataLineNumber(1);
            setThematizationInputFileLang("");
         });
   };

   return (
      <Grid item xs={12}>
         <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel1a-header">
               <DescriptionIcon style={{ marginRight: "10px" }} />
               <Typography className={classes.heading}>
                  Transformer un fichier de verbatims
               </Typography>
            </AccordionSummary>
            <AccordionDetails>
               <Grid container spacing={3}>
                  <Grid item xs={12}>
                     <Typography variant="body2" style={{ fontStyle: "italic" }}>
                        NOTE : Les colonnes attendues dans le fichier sont : [Participant identifier (number)][Stimulus name (text)] [verbatim (text)] [Filter 1] [Filter 2] [Filter xxx]
                     </Typography>
                  </Grid>
                  <Grid container item xs={12} spacing={3}>
                     <Grid item xs={4}>
                        <FormControl fullWidth>
                           <TextField
                              select
                              label="Langue"
                              id="reviewsInputFileLang"
                              value={reviewsInputFileLang}
                              data-testid="reviewsInputFileLang"
                              onChange={(e) => setReviewInputFileLang(e.target.value)}
                              helperText="Sélectionner la langue des verbatims"
                           >
                              {to3WordsLanguages.map((language) => {
                                 return (
                                    <MenuItem key={language} value={language}>
                                       {ISO6391.getNativeName(language.toLowerCase())}
                                    </MenuItem>
                                 );
                              })}
                           </TextField>
                        </FormControl>
                     </Grid>
                  </Grid>
                  <Grid item xs={6}>
                     <Typography>Charger un fichier de verbatims</Typography>
                     <FormControl fullWidth>
                        <Input
                           disabled
                           id="reviewsInputFileName"
                           value={reviewsInputFile.name}
                           className={classes.textField}
                           placeholder="Sélectionner un fichier"
                           fullWidth
                           endAdornment={
                              <InputAdornment position="end">
                                 <label htmlFor="contained-button-file_5">
                                    <Button variant="contained" color="primary" component="span">
                                       Choisir
                                    </Button>
                                 </label>
                              </InputAdornment>
                           }
                        />
                        <input
                           key={Date.now()}
                           onChange={handleSelectUploadFileWithReviews}
                           accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                           style={{ display: "none" }}
                           id="contained-button-file_5"
                           data-testid="reviewsInputFile"
                           type="file"
                        />
                     </FormControl>
                     <Button
                        variant="contained"
                        className={classes.button}
                        startIcon={<CloudUploadIcon />}
                        data-testid="buttonReviews"
                        onClick={handleUploadFileWithReviews}
                        disabled={isBlank(reviewsInputFileLang) || isBlank(reviewsInputFile.name)}
                     >
                        Envoyer
                     </Button>
                  </Grid>
               </Grid>
            </AccordionDetails>
         </Accordion>
         <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel1a-header">
               <ConfirmationNumberIcon style={{ marginRight: "10px" }} />
               <Typography className={classes.heading}>Générer un plan de code à partir d'un fichier de verbatims</Typography>
            </AccordionSummary>
            <AccordionDetails>
               <Grid container spacing={3}>
                  <Grid item xs={4}>
                     <TextField
                        select
                        fullWidth
                        label="Langue"
                        value={generateCodeFrameInputFileLang}
                        data-testid="generateCodeFrameInputFileLang"
                        onChange={(e) => setGenerateCodeFrameInputFileLang(e.target.value)}
                        helperText="Sélectionner la langue des verbatims"
                     >
                        {createCodeframeLanguages.map((language) => {
                                 return (
                                    <MenuItem key={language} value={language}>
                                       {ISO6391.getNativeName(language.toLowerCase())}
                                    </MenuItem>
                                 );
                              })}
                     </TextField>
                  </Grid>
                  <Grid item xs={12} className={classes.flexBetween}>
                     <Grid item xs={6}>
                        <Grid item xs={12}>
                           <Typography>Charger un fichier de verbatims</Typography>
                           <FormControl fullWidth>
                              <Input
                                 disabled
                                 id="verbatimsGenerateCodeFrameInputFile-01"
                                 value={verbatimsGenerateCodeFrameInputFile.name}
                                 className={classes.textField}
                                 placeholder="Sélectionner un fichier"
                                 fullWidth
                                 endAdornment={
                                    <InputAdornment position="end">
                                       <label htmlFor="verbatimsGenerateCodeFrameInputFile-button-01">
                                          <Button variant="contained" color="primary" component="span" >
                                             Choisir
                                          </Button>
                                       </label>
                                    </InputAdornment>
                                 }
                              />
                              <input
                                 key={Date.now()}
                                 onChange={handleSelectUploadVerbatimsFileForGenerateCodeFrame}
                                 accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                 style={{ display: "none" }}
                                 id="verbatimsGenerateCodeFrameInputFile-button-01"
                                 data-testid="verbatimsGenerateCodeFrameInputFile"
                                 type="file"
                              />
                           </FormControl>
                        </Grid>
                        <Grid item xs={12} paddingBlock={2}>
                           <Typography>Où commencent les données :</Typography>
                        </Grid>
                        <Grid item xs={12} className={classes.flexBetweenTextField}>
                           <TextField
                              id="outlined-number"
                              label="N° de l'onglet"
                              type="number"
                              fullWidth
                              data-testid="verbatimsGenerateCodeFrameTabNumber"
                              inputProps={{ min: 1, max: 20 }}
                              value={verbatimsGenerateCodeFrameTabNumber}
                              onChange={(e) => setVerbatimsGenerateCodeFrameTabNumber(e.target.value)}
                           />
                           <TextField
                              id="outlined-select"
                              select
                              label="N° de colonne"
                              fullWidth
                              data-testid="verbatimsGenerateCodeFrameColumnNumber"
                              value={verbatimsGenerateCodeFrameColumnNumber}
                              onChange={(e) => setVerbatimsGenerateCodeFrameColumnNumber(e.target.value)}
                           >
                              {alphabet.map((letter) => (
                                 <MenuItem key={letter} value={letter}>
                                    {letter}
                                 </MenuItem>
                              ))}
                           </TextField>
                           <TextField
                              id="outlined-number"
                              label="N° de ligne"
                              type="number"
                              fullWidth
                              data-testid="verbatimsGenerateCodeFrameDataLineNumber"
                              inputProps={{ min: 1, max: 50 }}
                              value={verbatimsGenerateCodeFrameDataLineNumber}
                              onChange={(e) => setVerbatimsGenerateCodeFrameDataLineNumber(e.target.value)}
                           />
                        </Grid>
                     </Grid>
                  </Grid>
                  <Grid item xs={12}>
                     <Button
                        variant="contained"
                        className={classes.button}
                        startIcon={<CloudUploadIcon />}
                        onClick={handleUploadFileForGenerateCodeFrame}
                        data-testid="buttonGenerateCodeFrame"
                        disabled={isBlank(verbatimsGenerateCodeFrameInputFile.name && verbatimsGenerateCodeFrameTabNumber 
                           && verbatimsGenerateCodeFrameColumnNumber && verbatimsGenerateCodeFrameColumnNumber 
                           && generateCodeFrameInputFileLang)}
                     >
                        Envoyer
                     </Button>
                  </Grid>
               </Grid>
            </AccordionDetails>
         </Accordion>
         <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel1a-header">
               <AutoFixHighIcon style={{ marginRight: "10px" }} />
               <Typography className={classes.heading}>Améliorer un plan de code avec des verbatims</Typography>
            </AccordionSummary>
            <AccordionDetails>
               <Grid container spacing={3}>
                  <Grid item xs={4}>
                     <TextField
                        select
                        fullWidth
                        label="Langue"
                        value={thematizationInputFileLangForUpgrade}
                        data-testid="thematizationInputFileLangForUpgrade"
                        onChange={(e) => setThematizationInputFileLangForUpgrade(e.target.value)}
                        helperText="Sélectionner la langue des verbatims"
                     >
                        {improveCodeframeLanguages.map((language) => {
                                 return (
                                    <MenuItem key={language} value={language}>
                                       {ISO6391.getNativeName(language.toLowerCase())}
                                    </MenuItem>
                                 );
                              })}
                     </TextField>
                  </Grid>
                  <Grid item xs={12} className={classes.flexBetween}>
                     <Grid item xs={12}>
                        <Grid item xs={12}>
                           <Typography>Charger un fichier de verbatims</Typography>
                           <FormControl fullWidth>
                              <Input
                                 disabled
                                 id="verbatimsInputFileForUpgrade-01"
                                 value={verbatimsInputFileForUpgrade.name}
                                 className={classes.textField}
                                 placeholder="Sélectionner un fichier"
                                 fullWidth
                                 endAdornment={
                                    <InputAdornment position="end">
                                       <label htmlFor="verbatimsInputFileForUpgrade-button-01">
                                          <Button variant="contained" color="primary" component="span" >
                                             Choisir
                                          </Button>
                                       </label>
                                    </InputAdornment>
                                 }
                              />
                              <input
                                 key={Date.now()}
                                 onChange={handleSelectUploadVerbatimsFileForUpgradeCodeFrame}
                                 accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                 style={{ display: "none" }}
                                 id="verbatimsInputFileForUpgrade-button-01"
                                 data-testid="verbatimsInputFileForUpgrade"
                                 type="file"
                              />
                           </FormControl>
                        </Grid>
                        <Grid item xs={12} paddingBlock={2}>
                           <Typography>Où commencent les données :</Typography>
                        </Grid>
                        <Grid item xs={12} className={classes.flexBetweenTextField}>
                           <TextField
                              id="outlined-number"
                              label="N° de l'onglet"
                              type="number"
                              fullWidth
                              data-testid="verbatimsTabNumberForUpgrade"
                              inputProps={{ min: 1, max: 20 }}
                              value={verbatimsTabNumberForUpgrade}
                              onChange={(e) => setVerbatimsTabNumberForUpgrade(e.target.value)}
                           />
                           <TextField
                              id="outlined-select"
                              select
                              label="N° de colonne"
                              fullWidth
                              data-testid="verbatimsColumnNumberForUpgrade"
                              value={verbatimsColumnNumberForUpgrade}
                              onChange={(e) => setVerbatimsColumnNumberForUpgrade(e.target.value)}
                           >
                              {alphabet.map((letter) => (
                                 <MenuItem key={letter} value={letter}>
                                    {letter}
                                 </MenuItem>
                              ))}
                           </TextField>
                           <TextField
                              id="outlined-number"
                              label="N° de ligne"
                              type="number"
                              fullWidth
                              data-testid="verbatimsDataLineNumberForUpgrade"
                              inputProps={{ min: 1, max: 50 }}
                              value={verbatimsDataLineNumberForUpgrade}
                              onChange={(e) => setVerbatimsDataLineNumberForUpgrade(e.target.value)}
                           />
                        </Grid>
                     </Grid>
                     <Grid item xs={12}>
                        <Grid item xs={12}>
                           <Typography>Charger un plan de code (PDC)</Typography>
                           <FormControl fullWidth>
                              <Input
                                 disabled
                                 id="codeFrameInputFileForUpgrade-02"
                                 value={codeFrameInputFileForUpgrade.name}
                                 className={classes.textField}
                                 placeholder="Sélectionner un fichier"
                                 fullWidth
                                 endAdornment={
                                    <InputAdornment position="end">
                                       <label htmlFor="codeFrameInputFileForUpgrade-button-02">
                                          <Button variant="contained" color="primary" component="span" >
                                             Choisir
                                          </Button>
                                       </label>
                                    </InputAdornment>
                                 }
                              />
                              <input
                                 key={Date.now()}
                                 onChange={handleSelectUploadCodeFrameFileForUpgradeCodeFrame}
                                 accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                 style={{ display: "none" }}
                                 id="codeFrameInputFileForUpgrade-button-02"
                                 data-testid="codeFrameInputFileForUpgrade"
                                 type="file"
                              />
                           </FormControl>
                        </Grid>
                        <Grid item xs={12} paddingBlock={2}>
                           <Typography>Où commencent les données :</Typography>
                        </Grid>
                        <Grid item xs={12} className={classes.flexBetweenTextField}>
                           <TextField
                              id="outlined-number"
                              label="N° de l'onglet"
                              type="number"
                              fullWidth
                              inputProps={{ min: 1, max: 20}}
                              value={codeFrameTabNumberForUpgrade}
                              data-testid="codeFrameTabNumberForUpgrade"
                              onChange={(e) => setCodeFrameTabNumberForUpgrade(e.target.value)}
                           />
                           <TextField
                              id="outlined-number"
                              label="N° de ligne"
                              type="number"
                              fullWidth
                              data-testid="codeFrameDataLineNumberForUpgrade"
                              inputProps={{ min: 1, max: 50 }}
                              value={codeFrameDataLineNumberForUpgrade}
                              onChange={(e) => setCodeFrameDataLineNumberForUpgrade(e.target.value)}
                           />
                        </Grid>
                     </Grid>
                  </Grid>
                  <Grid item xs={12}>
                     <Button
                        variant="contained"
                        className={classes.button}
                        startIcon={<CloudUploadIcon />}
                        onClick={handleUploadFileForUpgradeCodeFrame}
                        data-testid="buttonThematizationForUpgrade"
                        disabled={isBlank(verbatimsInputFileForUpgrade.name && codeFrameInputFileForUpgrade.name && verbatimsTabNumberForUpgrade && 
                           verbatimsDataLineNumberForUpgrade && verbatimsColumnNumberForUpgrade && codeFrameTabNumberForUpgrade && 
                           codeFrameDataLineNumberForUpgrade && thematizationInputFileLangForUpgrade)}
                     >
                        Envoyer
                     </Button>
                  </Grid>
               </Grid>
            </AccordionDetails>
         </Accordion>
         <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} id="panel1a-header">
               <ListIcon style={{ marginRight: "10px" }} />
               <Typography className={classes.heading}>Thématiser des verbatims selon un plan de code</Typography>
            </AccordionSummary>
            <AccordionDetails>
               <Grid container spacing={3}>
                  <Grid item xs={4}>
                     <TextField
                        select
                        fullWidth
                        label="Langue"
                        value={thematizationInputFileLang}
                        data-testid="thematizationInputFileLang"
                        onChange={(e) => setThematizationInputFileLang(e.target.value)}
                        helperText="Sélectionner la langue des verbatims"
                     >
                        {thematizeLanguages.map((language) => {
                                 return (
                                    <MenuItem key={language} value={language}>
                                       {ISO6391.getNativeName(language.toLowerCase())}
                                    </MenuItem>
                                 );
                              })}
                     </TextField>
                  </Grid>
                  <Grid item xs={12} className={classes.flexBetween}>
                     <Grid item xs={12}>
                        <Grid item xs={12}>
                           <Typography>Charger un fichier de verbatims</Typography>
                           <FormControl fullWidth>
                              <Input
                                 disabled
                                 id="verbatimsInputFile-01"
                                 value={verbatimsInputFile.name}
                                 className={classes.textField}
                                 placeholder="Sélectionner un fichier"
                                 fullWidth
                                 endAdornment={
                                    <InputAdornment position="end">
                                       <label htmlFor="verbatimsInputFile-button-01">
                                          <Button variant="contained" color="primary" component="span" >
                                             Choisir
                                          </Button>
                                       </label>
                                    </InputAdornment>
                                 }
                              />
                              <input
                                 key={Date.now()}
                                 onChange={handleSelectUploadVerbatimsFileForVerbatimsThematization}
                                 accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                 style={{ display: "none" }}
                                 id="verbatimsInputFile-button-01"
                                 data-testid="verbatimsInputFile"
                                 type="file"
                              />
                           </FormControl>
                        </Grid>
                        <Grid item xs={12} paddingBlock={2}>
                           <Typography>Où commencent les données :</Typography>
                        </Grid>
                        <Grid item xs={12} className={classes.flexBetweenTextField}>
                           <TextField
                              id="outlined-number"
                              label="N° de l'onglet"
                              type="number"
                              fullWidth
                              data-testid="verbatimsTabNumber"
                              inputProps={{ min: 1, max: 20 }}
                              value={verbatimsTabNumber}
                              onChange={(e) => setVerbatimsTabNumber(e.target.value)}
                           />
                           <TextField
                              id="outlined-select"
                              select
                              label="N° de colonne"
                              fullWidth
                              data-testid="verbatimsColumnNumber"
                              value={verbatimsColumnNumber}
                              onChange={(e) => setVerbatimsColumnNumber(e.target.value)}
                           >
                              {alphabet.map((letter) => (
                                 <MenuItem key={letter} value={letter}>
                                    {letter}
                                 </MenuItem>
                              ))}
                           </TextField>
                           <TextField
                              id="outlined-number"
                              label="N° de ligne"
                              type="number"
                              fullWidth
                              data-testid="verbatimsDataLineNumber"
                              inputProps={{ min: 1, max: 50 }}
                              value={verbatimsDataLineNumber}
                              onChange={(e) => setVerbatimsDataLineNumber(e.target.value)}
                           />
                        </Grid>
                     </Grid>
                     <Grid item xs={12}>
                        <Grid item xs={12}>
                           <Typography>Charger un plan de code (PDC)</Typography>
                           <FormControl fullWidth>
                              <Input
                                 disabled
                                 id="codeFrameInputFile-02"
                                 value={codeFrameInputFile.name}
                                 className={classes.textField}
                                 placeholder="Sélectionner un fichier"
                                 fullWidth
                                 endAdornment={
                                    <InputAdornment position="end">
                                       <label htmlFor="codeFrameInputFile-button-02">
                                          <Button variant="contained" color="primary" component="span" >
                                             Choisir
                                          </Button>
                                       </label>
                                    </InputAdornment>
                                 }
                              />
                              <input
                                 key={Date.now()}
                                 onChange={handleSelectUploadCodeFrameFileForVerbatimsThematization}
                                 accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                 style={{ display: "none" }}
                                 id="codeFrameInputFile-button-02"
                                 data-testid="codeFrameInputFile"
                                 type="file"
                              />
                           </FormControl>
                        </Grid>
                        <Grid item xs={12} paddingBlock={2}>
                           <Typography>Où commencent les données :</Typography>
                        </Grid>
                        <Grid item xs={12} className={classes.flexBetweenTextField}>
                           <TextField
                              id="outlined-number"
                              label="N° de l'onglet"
                              type="number"
                              fullWidth
                              inputProps={{ min: 1, max: 20}}
                              value={codeFrameTabNumber}
                              data-testid="codeFrameTabNumber"
                              onChange={(e) => setCodeFrameTabNumber(e.target.value)}
                           />
                           <TextField
                              id="outlined-number"
                              label="N° de ligne"
                              type="number"
                              fullWidth
                              data-testid="codeFrameDataLineNumber"
                              inputProps={{ min: 1, max: 50 }}
                              value={codeFrameDataLineNumber}
                              onChange={(e) => setCodeFrameDataLineNumber(e.target.value)}
                           />
                        </Grid>
                     </Grid>
                  </Grid>
                  <Grid item xs={12}>
                     <Button
                        variant="contained"
                        className={classes.button}
                        startIcon={<CloudUploadIcon />}
                        onClick={handleUploadFileForVerbatimsThematization}
                        data-testid="buttonThematization"
                        disabled={isBlank(verbatimsInputFile.name && codeFrameInputFile.name && verbatimsTabNumber && 
                           verbatimsDataLineNumber && verbatimsColumnNumber && codeFrameTabNumber && codeFrameDataLineNumber && thematizationInputFileLang)}
                     >
                        Envoyer
                     </Button>
                  </Grid>
               </Grid>
            </AccordionDetails>
         </Accordion>
      </Grid>
   );
}
