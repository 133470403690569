import React, { useEffect, useState, useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitleWithCloseIcon from '../shared/DialogTitleWithCloseIcon/DialogTitleWithCloseIcon';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import SequenceService from '../../services/SequenceService';
import { LoadData } from '../../Constants.js'
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { toolbarStyles } from '../../common.js';
import DeleteIcon from '@mui/icons-material/Delete';
import LayersIcon from '@mui/icons-material/Layers';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { AuthService } from '../../services/AuthService';
import EditIcon from '@mui/icons-material/Edit';
import { isBlank } from '../../utils/utils.js';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import DoneIcon from '@mui/icons-material/Done';
import PopupTwoButtons from '../shared/PopupTwoButtons/PopupTwoButtons';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';

const sequenceService = new SequenceService();

const useStyles = makeStyles()(theme => ({
  listItemIcon: {
    minWidth: '30px',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  stimulusList: {
    minHeight: '400px',
    maxHeight: '400px',
    overflow: 'auto',
  },
}));

export default function SelectStimulusDialog(props) {
  const { t, openSnackbar, showSpinner, project, stimuli, configuration, open, handleClose, handleApply, dashboardService } = props;

  const { classes } = useStyles();
  const { classes: toolbarClasses } = toolbarStyles();

  const [checked, setChecked] = useState([]);
  const [sequences, setSequences] = useState([]);
  const [loadData, setLoadData] = useState(LoadData.Load);

  const [selectedId, setSelectedId] = useState(0);
  const [editedId, setEditedId] = useState(0);
  const [editedContent, setEditedContent] = useState(undefined);

  const [confirmCreateAssembledCard, setConfirmCreateAssembledCard] = useState(false);
  const [confirmDeleteAssembledCard, setConfirmDeleteAssembledCard] = useState(false);

  useEffect(() => {
    if (!open || loadData !== LoadData.Load) return;

    setLoadData(LoadData.Loading);

    showSpinner(true);

    sequenceService.fetchProjectSequences(project.id, true, true)
      .then(result => {
        // get sequence and sort them by Id desc
        let sequences = result.data.hits.sort(function (seq1, seq2) { return seq2.id - seq1.id; })

        // precheck currently displayed stimuli
        let newChecked = stimuli.map(s => s.id);

        // ensure checked stimulus really exist
        newChecked = sequences.map(seq => seq.stimuli).flat()
          .map(s => s.id)
          .filter(sid => newChecked.includes(sid));

        setSequences(sequences);
        setChecked(newChecked);

        setSelectedId(0);
        setEditedId(0);
        setEditedContent(undefined);
        setConfirmCreateAssembledCard(false);
        setConfirmDeleteAssembledCard(false);
      }).catch(err => {
        setSequences([]);
        setChecked([]);

        setSelectedId(0);
        setEditedId(0);
        setEditedContent(undefined);
        setConfirmCreateAssembledCard(false);
        setConfirmDeleteAssembledCard(false);

        openSnackbar('error', t("react.project.error.while.loading"));
      }).finally(() => {
        setLoadData(LoadData.Loaded);
        showSpinner(false);
      });
  }, [open, loadData, project, stimuli]);

  const allChecked = useMemo(
    () => {
      var newAllChecked = [];

      sequences.forEach((sequence) => {
        let areAllCheckedInSequence = 
            sequence.stimuli.length > 0 && 
            sequence.stimuli.every(stimulus => checked.includes(stimulus.id));
  
        if(areAllCheckedInSequence) {
          newAllChecked.push(sequence.id);
        }
      });

      return newAllChecked;
    }, [checked]
  );

  const handleToggle = (stimulusId) => () => {
    const currentIndex = checked.indexOf(stimulusId);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(stimulusId);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    setSelectedId(stimulusId);
  };

  const handleSelectUnselectAll = (sequenceId) => () => {
    const currentIndexOfAll = allChecked.indexOf(sequenceId);
    const newAllChecked = [...allChecked];

    if (currentIndexOfAll === -1) {
      newAllChecked.push(sequenceId);
    } else {
      newAllChecked.splice(currentIndexOfAll, 1);
    }

    const newChecked = [...checked];
    sequences
      .filter((sequence) => sequence.id === sequenceId)
      .forEach((sequence, i) => {
        sequence.stimuli.forEach((stimulus, i) => {
          let currentIndex = newChecked.indexOf(stimulus.id);
          // stimulus;
          if (currentIndexOfAll === -1) {
            if (currentIndex === -1) {
              newChecked.push(stimulus.id);
            }
          } else {
            if (currentIndex !== -1) {
              newChecked.splice(currentIndex, 1);
            }
          }
        });
      });

    setChecked(newChecked);
  };

  const handleConfirm = (e) => {
    const newConfiguration = { ...configuration };

    // append checked stimulus
    newConfiguration.stimulusIds = [];
    checked.forEach(id => {
      newConfiguration.stimulusIds.push(id);
    });

    // maintains hidden list updated
    newConfiguration.hidden = [];
    configuration.hidden.forEach(id => {
      if (newConfiguration.stimulusIds.includes(id)) {
        newConfiguration.hidden.push(id);
      }
    });

    // set updated
    newConfiguration.updated = true;

    handleResetThenClose(e);

    return newConfiguration;
  }

  const canCreateAssembleCard = () => {
    // get checked stimuli
    let selectedStimuli = sequences.map(seq => seq.stimuli).flat()
        .filter(s => checked.includes(s.id));

    const hasAtLeast2RealStimuli = selectedStimuli
        .filter(s => !s.virtual && !s.assembled)
        .length >= 2;

    const hasSomeVirtualStimuliExceptTotal = selectedStimuli
        .filter(s => s.virtual && !s.totalOverall && !s.totalOverallExcept)
        .length > 0;

    const hasSomeAssembledStimuli = selectedStimuli
        .filter(s => s.assembled)
        .length > 0;

    return hasAtLeast2RealStimuli && !hasSomeVirtualStimuliExceptTotal && !hasSomeAssembledStimuli;
  };

  const createAssembledCardCallback = () => {
    if(!canCreateAssembleCard()) return;

    setConfirmCreateAssembledCard(false);

    let selectedStimuli = sequences.map(seq => seq.stimuli).flat()
        .filter(s => checked.includes(s.id));

    const assembledIds = selectedStimuli.map(s => s.id);
    const name = selectedStimuli.map(s => s.name).join(' - ');

    dashboardService.createAssembledStimulus(project.id, name, assembledIds)
      .then(result => {
        openSnackbar('success', t("react.project.assembled.card.create.success"));

        // clear 
        setChecked([]);

        // reload
        setLoadData(LoadData.Load);
      })
      .catch(err => {
        openSnackbar('error', t("react.project.assembled.card.create.error"));
      });
  };

  const canDeleteAssembleCard = () => {
    // get checked stimuli
    let selectedStimuli = sequences.map(seq => seq.stimuli).flat()
        .filter(s => checked.includes(s.id));

    const hasSomeAssembledStimuli = selectedStimuli
        .filter(s => s.assembled)
        .length > 0;

    const hasSomeRealStimuli = selectedStimuli
        .filter(s => !s.virtual && !s.assembled)
        .length > 0;

    const hasSomeVirtualStimuliExceptTotal = selectedStimuli
          .filter(s => s.virtual && !s.totalOverall && !s.totalOverallExcept)
          .length > 0;

    return hasSomeAssembledStimuli && !hasSomeRealStimuli && !hasSomeVirtualStimuliExceptTotal;
  };

  const deleteAssembledCardCallback = () => {
    if(!canDeleteAssembleCard()) return;

    setConfirmDeleteAssembledCard(false);

    var promises = [];
    for (var i = 0; i < checked.length; i++) {
      promises.push(dashboardService.deleteAssembledStimulus(project.id, checked[i]));
    }

    Promise.all(promises)
      .then(function (values) {
        openSnackbar('success', t("react.project.assembled.card.delete.success"));

        // clear 
        setChecked([]);

        // reload
        setLoadData(LoadData.Load);
      })
      .catch(err => {
        openSnackbar('error', t("react.project.assembled.card.delete.error"));
      });
  };

  const handleResetThenClose = (event) => {
    event.preventDefault();
    handleClose();

    setLoadData(LoadData.Load);
    setChecked([]);
    setSequences([]);

    setSelectedId(0);
    setEditedId(0);
    setEditedContent(undefined);
  };

  const canRenameAssembleCard = () => {
    // get checked stimulus
    let selectedStimulusIsAssembled = sequences.map(seq => seq.stimuli).flat()
        .filter(s => s.assembled && s.id === selectedId).length === 1;

    return selectedStimulusIsAssembled;
  };

  const startEditingStimulusName = () => {
    let selectedStimulus = sequences.map(seq => seq.stimuli).flat()
        .filter(s => s.assembled && s.id === selectedId)[0];

    setEditedContent(selectedStimulus.name);
    setEditedId(selectedId);
  };
  
  const ignoreOnClickOnTextField = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const disableEditionOnEscapeOrEnter = (event) => {
    if (/* event.key === 'Enter' || */ event.key === 'Escape') {
      event.preventDefault();
      event.stopPropagation();
      setSelectedId(0);
      setEditedId(0);
      setEditedContent(undefined);
    }
  };

  const handleClickRenameStimulus = () => {
    dashboardService.renameAssembledStimulus(project.id, editedId, editedContent)
      .then(result => {
        openSnackbar('success', t("react.project.assembled.card.rename.success"));

        // change the name in the list to avoid reload
        sequences.map(seq => seq.stimuli).flat()
          .filter(s => s.id === editedId)
          // should be only 1 match (the edited one)
          .forEach(s => s.name = editedContent);

        setSelectedId(0);
        setEditedId(0);
        setEditedContent(undefined);
      })
      .catch(err => {
        if (err.response.status === 409 /* 409 = Conflict */) {
          openSnackbar('error', t("react.project.assembled.card.rename.error.exists"));
        } else {
          openSnackbar('error', t("react.generic.error.while.saving"));
        }
      });
  };

  const selectUnselectAll = (event) => {
    event.preventDefault();

    let newChecked = sequences
      .map(seq => seq.stimuli)
      .flat()
      .map(s => s.id);

    if(checked.length < newChecked.length) {
      setChecked(newChecked);
    } else {
      setChecked([]);
    }
  };

  return (<>
    <PopupTwoButtons 
        variant='question'
        openState={confirmCreateAssembledCard}
        callbackOnclose={() => setConfirmCreateAssembledCard(false)}
        callbackOnclickLeftButton={() => setConfirmCreateAssembledCard(false)}
        callbackOnclickRightButton={createAssembledCardCallback}
        title={t('react.dashboard.selectstimulusdialog.create.assembled.title')}
        content={t('react.dashboard.selectstimulusdialog.create.assembled.body')}
        leftButton={t('react.button.cancel')}
        rightButton={t('react.button.confirm')}
    />
    <PopupTwoButtons 
        variant='question'
        openState={confirmDeleteAssembledCard}
        callbackOnclose={() => setConfirmDeleteAssembledCard(false)}
        callbackOnclickLeftButton={() => setConfirmDeleteAssembledCard(false)}
        callbackOnclickRightButton={deleteAssembledCardCallback}
        title={t('react.dashboard.selectstimulusdialog.delete.assembled.title')}
        content={t('react.dashboard.selectstimulusdialog.delete.assembled.body')}
        leftButton={t('react.button.cancel')}
        rightButton={t('react.button.confirm')}
    />
    <Dialog open={open && loadData === LoadData.Loaded} onClose={handleResetThenClose} fullWidth={true} maxWidth='sm'>
      <DialogTitleWithCloseIcon
        title={t('react.dashboard.selectstimulusdialog.title')}
        callbackOnclose={handleResetThenClose}
      />
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography>
            {t('react.dashboard.selectstimulusdialog.content')}
            </Typography>
          </Grid>
          {/* Creating and deleting assembled stimulus is limited to owner or manager */}
          {(project.userId === AuthService.getUser().id || AuthService.isManager()) &&
          <Grid item xs={12}>
            <Toolbar className={toolbarClasses.rootDashboard} >
              <Tooltip title={t('react.dashboard.selectstimulusdialog.select.unselect.all')}><span>
                  <IconButton size='small' onClick={selectUnselectAll}>
                      <LibraryAddCheckIcon />
                  </IconButton></span>
              </Tooltip>
              <Tooltip title={t('react.dashboard.selectstimulusdialog.create.assembled')}><span>
                  <IconButton size='small' onClick={() => setConfirmCreateAssembledCard(true)} disabled={!canCreateAssembleCard()}>
                      <LayersIcon />
                  </IconButton></span>
              </Tooltip>
              <Tooltip title={t(`react.button.rename`)}><span>
                  <IconButton size='small' onClick={() => startEditingStimulusName()} disabled={!canRenameAssembleCard() || editedId > 0}>
                      <EditIcon className={classes.icon} />
                  </IconButton></span>
              </Tooltip>
              <Tooltip title={t('react.button.delete')}><span>
                  <IconButton size='small' onClick={() => setConfirmDeleteAssembledCard(true)} disabled={!canDeleteAssembleCard()}>
                      <DeleteIcon />
                  </IconButton></span>
              </Tooltip>
            </Toolbar>
          </Grid>
          }
          <Grid item xs={12}>
            <List className={classes.stimulusList}>
              {sequences.filter(sequence => sequence.stimuli.length > 0).map((sequence) => {
                return (
                  <Collapse key={sequence.id} in={true} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItemButton onClick={handleSelectUnselectAll(sequence.id)}>
                        <ListItemIcon className={classes.listItemIcon}>
                          <Checkbox
                            edge="start"
                            id={`checkbox-sequence-${sequence.id}`}
                            checked={allChecked.indexOf(sequence.id) !== -1}
                            tabIndex={-1}
                            disableRipple
                          />
                        </ListItemIcon>
                        <ListItemText primary={sequence.name} />
                      </ListItemButton>
                      {sequence.stimuli.map((stimulus) => {
                        return (
                          <ListItemButton 
                            key={stimulus.id} 
                            role={undefined} 
                            dense 
                            onClick={handleToggle(stimulus.id)} 
                            className={classes.nested}
                            selected={selectedId === stimulus.id}
                          >
                            <ListItemIcon className={classes.listItemIcon}>
                              <Checkbox
                                edge="start"
                                id={`checkbox-stimulus-${stimulus.id}`}
                                checked={checked.indexOf(stimulus.id) !== -1}
                                tabIndex={-1}
                                disableRipple
                              />
                            </ListItemIcon>
                            {stimulus.id === editedId ?
                            <TextField
                                onClick={(event) => ignoreOnClickOnTextField(event)}
                                value={editedContent}
                                onChange={(event) => setEditedContent(event.target.value)}
                                onKeyDown={disableEditionOnEscapeOrEnter}
                                error={isBlank(editedContent) /* || hasDuplicateStimulusNamess() */}
                                helperText={isBlank(editedContent) && t('react.project.assembled.card.rename.error.empty')}
                                autoFocus={true}
                                fullWidth={true}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                    <Tooltip title={t("react.project.assembled.save.rename.error")}><span>
                                      <IconButton
                                        onClick={handleClickRenameStimulus}
                                        disabled={sequence.id === 0 || editedContent === stimulus.name || isBlank(editedContent)}
                                        size="large">
                                        <DoneIcon style={{ color : (stimulus.id !== 0 && stimulus.name !== editedContent && !isBlank(editedContent)) ? '#66bb6a' : '#eee'}} />
                                      </IconButton></span>
                                      </Tooltip>
                                    </InputAdornment>
                                  )
                                }}
                            />
                            : <ListItemText primary={stimulus.name} />
                            }
                          </ListItemButton>
                        );
                      })}
                    </List>
                  </Collapse>
                )
              })}
            </List>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleResetThenClose} color="primary">
          {t('react.button.cancel')}
        </Button>
        <Button onClick={(e) => handleApply(handleConfirm(e))} autoFocus>
          {t('react.button.apply')}
        </Button>
      </DialogActions>
    </Dialog></>
  )
}
