import React from 'react';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

export const TOTAL_STIMULUS_ID = -100;
export const TOTAL_EXCEPT_STIMULUS_ID = -200;

export const HIGHT_SCORE_LIMIT = 9999;
export const LOW_SCORE_LIMIT = -9999;

// Minimum length allowed is 2 chars (e.g. FR, US, UK, ...)
export const MIN_SEQUENCE_LENGTH = 2;

export const LoadData = {
  Load: 0,
  Loading: 1,
  Loaded: 2
};

export const DashboardConstants = {
  highScore: 60,
  lowScore: -35,
  positiveColor: 'rgb(255, 190, 0)',
  negativeColor: 'rgb(255, 73, 0)',
  negativeGradient: `linear-gradient(to left, white , rgb(255, 73, 0))`,
  positiveGradient: `linear-gradient(to right, white , rgb(255, 190, 0))`,
};

export const getDefaultConfiguration = () => {
  return {
    id: -1,
    name: 'new configuration',
    stimulusIds: [],
    virtual: [],
    include: [],
    filters: {},
    engagementFilters: {},
    names: {},
    compareWith: {},
    low: -20,
    high: 60,
    comparisonTriggeringValue: 100,
    comparisonSignificativityValue: 10,
    significativity: 'T',
    exportCompareWith: false,
    exportFilters: true,
    showTotalCard: false,
    hidden: [],
    showHiddenCards: false,
    showSequenceName: false,
    alignView: true,
    showAddicts: true,
    addictsLimit: 30,
    hatersLimit: 0,
    groupByTheme: false,
    showThemeMode: false,
    showMissingGroups: false,
    displayEmotionalDriver: false,
    missingGroupsUseTheme: true,
    missingGroupsShowDiamonds: false,
    missingGroupsDiamondsThreshold: 20,
    showReportingMode: false,
    dashboardFontSize: 14,
    sortStimulusBy: 'name',
    sortStimulusOrder: 'asc',
    pValueType: 'numbers',
    absMaxScoreLimit: 0,
    showAsTagcloud: false,
  }
}

export const getViewThemes = () =>{
  return {
      weather: {
        name: 'weather',
        label: 'Weather',
        upIcon: <img alt='' src='/img/sun.png' width='23px'/>,
        downIcon: <img alt='' src='/img/cloud.png' width='23px'/>,
        missingIcon: <HighlightOffIcon color='disabled' style={{opacity: '0.4'}} width='23px'/>,
        upMissingIcon: <img alt='' src='/img/sun_missing.png' width='23px'/>,
        downMissingIcon: <img alt='' src='/img/cloud_missing.png' width='23px'/>,
        diamondIcon: <img alt='' src='/img/diamond.png' width='23px'/>,
      },
      like: {
        name: 'like',
        label: 'Like',
        upIcon: <img alt='' src='/img/thumbup.png' width='23px'/>,
        downIcon: <img alt='' src='/img/thumbdown.png' width='23px'/>,
        missingIcon: <HighlightOffIcon color='disabled' style={{opacity: '0.4'}} width='23px'/>,
        upMissingIcon: <img alt='' src='/img/thumbup_missing.png' width='23px'/>,
        downMissingIcon: <img alt='' src='/img/thumbdown_missing.png' width='23px'/>,
        diamondIcon: <img alt='' src='/img/diamond.png' width='23px'/>,
      },
      default: 'weather'
  };
}

export const getTopicsThemes = () => {
  return {
    balticamber: {
      name: 'balticamber',
      label: 'Baltic amber',
      full: ['#ff6f00', '#ff8f00', '#ffa000', '#ffb300', '#ffc107', '#ffca28', '#ffd54f', '#ffe082', '#ffecb3', '#fff8e1'],
      two: ['#ff8f00', '#ffe082'],
      three: ['#ff8f00', '#ffc107', '#ffecb3'],
      five: ['#ff6f00', '#ffa000', '#ffc107', '#ffd54f', '#fff8e1']
    },
    caribbeansea: {
      name: 'caribbeansea',
      label: 'Caribbean Sea',
      full: ['#01579b', '#0277bd', '#0288d1', '#039be5', '#03a9f4', '#29b6f6', '#4fc3f7', '#81d4fa', '#b3e5fc', '#e1f5fe'],
      two: ['#0277bd', '#b3e5fc'],
      three: ['#0277bd', '#03a9f4', '#b3e5fc'],
      five: ['#0277bd', '#039be5', '#29b6f6', '#81d4fa', '#e1f5fe']
    },
    celticsea: {
      name: 'celticsea',
      label: 'Celtic Sea',
      full: ['#203864', '#2f5597', '#8faadc', '#b4c7e7', '#dae3f3', '#d0cece', '#afabab', '#767171', '#3b3838', '#181717', '#333f4f'],
      two: ['#203864', '#8faadc'],
      three: ['#203864', '#8faadc', '#dae3f3'],
      five: ['#203864', '#2f5597', '#8faadc', '#b4c7e7', '#d0cece']
    },
    vintagesunrise: {
      name: 'vintagesunrise',
      label: 'Vintage Sunrise',
      full: ['#293133', '#21393b', '#20584f', '#317b6c', '#6fa990', '#c3cd8e', '#efbe31', '#f98700', '#e00523'],
      two: ['#6fa990', '#efbe31'],
      three: ['#6fa990', '#efbe31', '#f98700'],
      five: ['#20584f', '#6fa990', '#efbe31', '#f98700', '#e00523']
    },
    default: 'celticsea'
  };
}

export const emotionalDriverFakeDataHelp = () => {
  return [
    {group: {id: 620, title: "malbouffe"}, negativeDriver: 213, positiveDriver: 0},
    {group: {id: 586, title: "délicieux"}, negativeDriver: 0, positiveDriver: 202},
    {group: {id: 624, title: "gras"}, negativeDriver: 185, positiveDriver: 0},
    {group: {id: 628, title: "pas mal"}, negativeDriver: 0, positiveDriver: 176},
    {group: {id: 620, title: "bon"}, negativeDriver: 0, positiveDriver: 145},
    {group: {id: 586, title: "correct"}, negativeDriver: 30, positiveDriver: 113},
    {group: {id: 624, title: "burger"}, negativeDriver: 114, positiveDriver: 0},
    {group: {id: 628, title: "goût"}, negativeDriver: 0, positiveDriver: 86},
    {group: {id: 624, title: "beurk"}, negativeDriver: 83, positiveDriver: 0},
    {group: {id: 628, title: "bof, moyen"}, negativeDriver: 63, positiveDriver: 12},
    {group: {id: 620, title: "inconnu"}, negativeDriver: 0, positiveDriver: 65},
    {group: {id: 586, title: "mondialisation"}, negativeDriver: 52, positiveDriver: 0},
    {group: {id: 624, title: "cher"}, negativeDriver: 41, positiveDriver: 0},
    {group: {id: 628, title: "pratique"}, negativeDriver: 0, positiveDriver: 24}
  ];
} 
