import React from 'react';
import { styled } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Button from '@mui/material/Button';

const useStyles = makeStyles()(theme => ({
    focusVisible: {
      backgroundColor: '#eee'
    },
    customButton: {
      marginTop: 20,
      position: 'absolute',
      right: 0,
      paddingLeft: 15,
    },
}));

const CustomArrowForwardIosIcon = styled(ArrowForwardIosIcon)({
  fontSize: '1em',
  marginLeft: 5,
});

export default function CustomNextButton(props) {

  const { classes } = useStyles();

  const { t, id, handleNextBlock, disabled } = props;

  return (
    <Button
      id={id}
      onClick={() => handleNextBlock()}
      disabled={disabled}
      className={classes.customButton}
      focusVisibleClassName={classes.focusVisible}
      disableRipple>
        {t('react.button.next')} <CustomArrowForwardIosIcon/>
    </Button>
  );
}
