import React, { useEffect, useState, Fragment } from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official';

export default function BarChart(props) {

    const {
        t,
        index,
        completionStates,
        nbMaxAnswers
    } = props;

    const numberOfRespondants = completionStates.COMPLETED + completionStates.ONGOING + completionStates.QUOTA_FULL + completionStates.SCREEN_OUT

    const chartData = () => {
        return {
            chart: {
                type: 'column',
            },
            title: {
                margin: 45,
                align: 'left',
                text: t("react.reporting.survey.repartition.title")
            },
            subtitle: {
                style: {
                    fontWeight: 'bold'
                },
                align: 'left',
                text: t('react.reporting.survey.repartition.text', { 'numberRespondants': numberOfRespondants, 'completed' : completionStates.COMPLETED, 'maxAnswers' : nbMaxAnswers})
            },
            accessibility: {
                announceNewData: {
                    enabled: true
                }
            },
            xAxis: {
                type: 'category'
            },
            yAxis: {
                title: {
                    text: ''
                }
            },
            legend: {
                enabled: false
            },
            tooltip: {
                valueSuffix: '%',
                formatter: function () {
                    return this.point.name + ':<b> ' + Math.round(this.y / numberOfRespondants * 100) + '%' + '</b>'

                }
            },
            plotOptions: {
                series: {
                    borderWidth: 0,
                    dataLabels: {
                        enabled: true,
                    }
                }
            },
            series: [
                {
                    colorByPoint: true,
                    data: [
                        {
                            name: t("react.reporting.survey.completed"),
                            y: completionStates.COMPLETED,
                        },
                        {
                            name: t("react.reporting.survey.screenout"),
                            y: completionStates.SCREEN_OUT,
                        },
                        {
                            name: t("react.reporting.survey.quotafull"),
                            y: completionStates.QUOTA_FULL,
                        },
                        {
                            name: t("react.reporting.survey.ongoing"),
                            y: completionStates.ONGOING,
                        },
                    ]
                }
            ],
            drilldown: {
                breadcrumbs: {
                    position: {
                        align: 'right'
                    }
                },
            },
            credits: {
                enabled: false
            },

        }
    }

    return (
        <HighchartsReact highcharts={Highcharts} options={chartData(index)} />
    );
};