import React, { useState, useEffect } from 'react';
import ReferenceService from '../../../services/ReferenceService'
import { makeStyles } from 'tss-react/mui';
import { LoadData } from '../../../Constants.js'
import { Select, InputLabel, MenuItem, Box, FormHelperText, FormControl } from '@mui/material';
import ISO6391 from 'iso-639-1';

const referenceService = new ReferenceService();

const useStyles = makeStyles()(theme => ({
  coefficients: {
    marginBottom: theme.spacing(2),
  }, formControl: {
    minWidth: 120,
    width: '100%',
  },
}));

export default function Coefficients(props) {
  const { t, coefficientsId, callbackAfterSelection, error, disabled } = props;

  const { classes } = useStyles();
  const [coefficientsList, setCoefficientsList] = useState([]);

  const [loadData, setLoadData] = useState(LoadData.Load);
  useEffect(() => {
    if (loadData !== LoadData.Load) return;

    setLoadData(LoadData.Loading);

    referenceService.fetchCoefficients()
      .then(result => {
        setCoefficientsList(result.data);
      })
      .catch(err => {
      }).finally(() => {
        setLoadData(LoadData.Loaded);
      });
  }, [loadData]);

  if(loadData !== LoadData.Loaded) {
    return <></>;
  }

  return (
    <Box className={classes.coefficients}>
      <FormControl className={classes.formControl} error={error}>
        <InputLabel id="coefficientsId-label" variant='standard'>{t("react.coefficients.dropbox.title")}</InputLabel>
        <Select
          id="coefficientsId"
          labelId="coefficientsId-label"
          value={coefficientsId === 0 ? '' : coefficientsId}
          onChange={e => callbackAfterSelection(e.target.value)}
          disabled={disabled}
          fullWidth
        >
          {coefficientsList.filter(c => c.language && c.language.length > 0).map(coefficients => (
            <MenuItem key={coefficients.id} value={coefficients.id} >
              {ISO6391.getNativeName(coefficients.language.toLowerCase())}
            </MenuItem>
          ))}
        </Select>
        {error && <FormHelperText>{t("react.validation.coefficientsId.empty")}</FormHelperText> }
      </FormControl>
    </Box >
  );
}
