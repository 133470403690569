import React, { useState, useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import axios from 'axios'
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
  alert: {
    padding: 10,
  },
  button: {
    minWidth: 100,
    color: 'white',
    padding: '0px 5px',
    margin: '0px 5px',
  },
}));

export default function NewVersionWatcher(props) {

    const {
        t,
      } = props;

    const { classes } = useStyles();

    // use axios because it's not a call to API - it's a simple call to file /version
    const axiosService = axios.create();

    const [delay, setDelay] = useState(1000);
    const [currentVersion, setCurrentVersion] = useState(undefined);
    const [newVersionAvailable, setNewVersionAvailable] = useState(false);

    useEffect(() => {
      // security if disabled or to avoid to many calls
      if (delay < 1000) return;
  
      const interval = setInterval(() => {
         axiosService.get('/version')
          .then(response => {
            // on success keep (or reset after error) delay to 60000
            setDelay(60000);

            if(response.data !== undefined) {
              if(currentVersion === undefined) {
                setCurrentVersion(response.data)
              } else if(currentVersion !== response.data && !newVersionAvailable) {
                setNewVersionAvailable(true);
              }
            }
          })
          .catch(err => {
            if(err.response.status === 404) {
              // version is not available
              setDelay(0);
              return;
            }
            // double delay on error and disable if >= 30 min
            let newDelay = delay < 60000 /* after first run */ ? 60000 /* delay to 1 min */ : delay * 2 /* else double */;
            setDelay(newDelay < 1800000 /* 30 min */ ? newDelay : 0);
            console.error(err);
          });
      }, delay);
      return () => clearInterval(interval);
    }, [delay, currentVersion, newVersionAvailable]);

    const forceRefresh = () => {
      window.location.reload();
    }
  
    return (
        <Snackbar 
          open={newVersionAvailable}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
        <Alert
          variant="filled"
          severity="warning"
          className={classes.alert}
          action={
            <Button
              variant="outlined"
              onClick={() => forceRefresh()}
              className={classes.button}
            >
              {t('react.new.version.alert.button')}
            </Button>
          }
        >
          <Box display="flex" alignItems="center">
            {t('react.new.version.alert.message')}
          </Box>
        </Alert>
      </Snackbar>
    );
  }