import React from 'react';
import { Droppable, Draggable } from "react-beautiful-dnd";
import Group from './Group';
import { Trans } from 'react-i18next';

export default function Bucket(props) {

  const { bucket } = props;

  const getListStyle = (isDraggingOver, bucket) => ({
    background: isDraggingOver ? '#EBF5FB' : '#fff',
    minHeight: bucket.index === 0 ? '800px' : '2500px'
  });

  const getNbRemainingtoClassify = () => {
    // only the left column
    if(bucket.index === 0) {
      // search group with unclassify words (the 1rst group)
      const groups = bucket.groups.filter(g => !g.trashbin);
      if(groups.length > 0 && groups[0].items) {
        return <Trans 
          i18nKey='react.grouping.remaining.to.classify' 
          values={{'count': groups[0].items.length}} />;
      }
    }
    return null;
  };

  return (
      <Droppable
        droppableId={`bucket-${bucket.index}`}
        key={bucket.index}
        index= {bucket.index}
        type={"group"}
        isDropDisabled={bucket.index === 0 /* it's not possible to move a group in the left column */}
      >
      {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver, bucket)}
          >
          {bucket.groups.map((group, index) => (
                    <Draggable 
                      draggableId={`bucket-${bucket.index}-group-${group.id}`}
                      key={`bucket-${bucket.index}-group-${group.id}`}
                      index={index}
                      type={"group"}
                      isDragDisabled={bucket.index === 0}
                      className={(bucket.groups.length === index + 1) ? 'extendedarea' : 'regulararea'}
                    >
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        <Group
                          {...props}
                          group={group}
                          />
                      </div>
                    )}
                    </Draggable>
          ))}
          {provided.placeholder}
          {getNbRemainingtoClassify()}
        </div>
      )}
      </Droppable>
    );
};
