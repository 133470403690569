import React, { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { capitalizeFirstLetter } from '../../utils/utils.js';
import Checkbox from '@mui/material/Checkbox';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import DeleteIcon from '@mui/icons-material/Delete';

const useStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'right',
    flexWrap: 'unset',
    cursor: 'context-menu',
    margin: theme.spacing(0.2),
    padding: theme.spacing(0.5),
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: 'beige',
    backgroundColor: '#fbfbfb',
    fontSize: 'medium',
    fontFamily: 'unset',
  },
}));

const contextMenuInitialState = {
  mouseX: null,
  mouseY: null,
};

export default function WordCard(props) {

  const { t, group, word, multiselectMode, getTranslationOfOriginalWord } = props;

  const [contextMenustate, setContextMenuState] = useState(contextMenuInitialState);

  const { classes } = useStyles();

  const handleContextMenuClose = () => {
    setContextMenuState(contextMenuInitialState);
  };

  const handleCreateGroup = (e) => {
    e.preventDefault();
    props.handleCreateGroupFromWord();
    handleContextMenuClose();
  };

  const handleSelectItem = (e) => {
    e.preventDefault();
    props.handleSelectItem(word);
    handleContextMenuClose();
  }

  const handleSelectUnselectAll = (e) => {
    e.preventDefault();
    props.handleSelectUnselectAllWords();
    handleContextMenuClose();
  };

  const handleRemoveFromGroup = (e) => {
    e.preventDefault();
    props.handleRemoveFromGroup();
    handleContextMenuClose();
  };

  const handleMoveToTrashbin = (e) => {
    e.preventDefault();
    props.handleMoveToTrashbin();
    handleContextMenuClose();
  };

  const handleChangeMultiselectMode = (e, enabled) => {
    e.preventDefault();
    props.handleChangeMultiselectMode(enabled);
    handleContextMenuClose();
  }

  const handleRightClick = (e) => {
    e.preventDefault();
    setContextMenuState({
      mouseX: e.clientX - 2,
      mouseY: e.clientY - 4,
    });
  };

  const getTextColor = () => {
    return word.belongsToLastSequence ? '#A0522D' : '#000';
  };

  const getBackgroundColor = () => {
    return word.ambivalent ? '#d7fadb' : '#fff';
  };

  return (
    <div id={word.id}>
      <div onContextMenu={handleRightClick} className={classes.root} style={{ color: getTextColor(), backgroundColor: getBackgroundColor() }}>
        <div style={{ width: '100%' }}>
        {multiselectMode &&
          <span>
                <Checkbox
                  style={{ padding: '1px', paddingLeft: '2px' }}
                  checked={word.checked === true /* can be undefined */ ? true : false}
                  onChange={handleSelectItem}
                />
                </span>
              }
          {capitalizeFirstLetter(word.word)} {getTranslationOfOriginalWord(word.word)}
        </div>
        <div>{(isNaN(word.weightedScore)) ? word.weightedScore : Math.round(word.weightedScore)}</div>
      </div>
      <Menu
        id={word.id}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenustate.mouseY !== null && contextMenustate.mouseX !== null
            ? { top: contextMenustate.mouseY, left: contextMenustate.mouseX }
            : undefined
        }
        open={contextMenustate.mouseY !== null}
        onClose={handleContextMenuClose}
      >
        <MenuItem onClick={handleCreateGroup}>
          <ListItemIcon>
            <PlaylistAddIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={t('react.button.creategroup')} />
        </MenuItem>

        <MenuItem onClick={e => handleChangeMultiselectMode(e, !multiselectMode)}>
          <ListItemIcon>
            <PlaylistAddCheckIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={!multiselectMode ? t('react.button.multiselecton') : t('react.button.multiselectoff')} />
        </MenuItem>

        <MenuItem onClick={handleSelectUnselectAll}>
          <ListItemIcon>
            <DoneAllIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={t('react.grouping.button.select-unselect-all')} />
        </MenuItem>

        {group.bucket > 0 && <MenuItem onClick={handleRemoveFromGroup}>
          <ListItemIcon>
            <HighlightOffIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={t('react.grouping.button.removefromgroup')} />
        </MenuItem>}

        {!group.trashbin && <MenuItem onClick={handleMoveToTrashbin}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={t('react.button.movetotrashbin')} />
        </MenuItem>}
      </Menu>
    </div>
  );
}
