import React, { useState, useEffect, useContext } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import CircleIcon from '@mui/icons-material/Circle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { makeStyles } from 'tss-react/mui';
import { CollectorContext } from './context';

const useStyles = makeStyles()(theme => ({
    checkCircleIcon: {
      color: '#003945'
    },
    circleIcon: {
      color: 'grey'
    },
    styleBox: {
        width: '100%', 
        marginTop: 5 
    },
    styleStep: {
      '&:hover': {
        cursor: 'pointer',
      },
    }
}));

export default function CustomSlider(props) {

  const { classes } = useStyles();

  const {
    embeddedReplaceDynamicElements } = useContext(CollectorContext);

  const { identifier /* used to identify when to reload because 'answers' may reload unexpectedly (= question or item id) */, 
    answers, selectAnswer } = props;

  const [mouseOverIndex, setMouseOverIndex] = useState(-1);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  useEffect(() => {
    setMouseOverIndex(-1);
    setSelectedIndex(-1);
  }, [identifier]);

  const handleMouseClick = (index) => {
    setSelectedIndex(index);
    selectAnswer(answers[index]);
  };

  const CustomStepIcon = ({ index }) => {
    // checked if before or equals to the selected index
    var isChecked = index === selectedIndex;
    var isIlluminated = index <= selectedIndex;

    // follow the mouse if hover
    if(mouseOverIndex >= 0) {
      isChecked = index === mouseOverIndex;
      isIlluminated = index <= mouseOverIndex;
    }

    if(isChecked) {
      return <CheckCircleIcon className={classes.checkCircleIcon}/>;
    } else if (isIlluminated) {
      return <CircleIcon className={classes.checkCircleIcon}/>
    } else {
      return <CircleIcon className={classes.circleIcon}/>
    }
  };

  const isActive = (index) => {
    var isActive = index <= selectedIndex;

    if(mouseOverIndex >= 0) {
      isActive = index <= mouseOverIndex;
    }

    return isActive;
  }

  return (
    <Box className={classes.styleBox}>
        <Stepper activeStep={mouseOverIndex} alternativeLabel className={classes.styleStep}>
            {answers.map((answer, index) => (
                <Step 
                  key={answer.id} 
                  onMouseOver={() => setMouseOverIndex(index)} 
                  onMouseOut={() => setMouseOverIndex(-1)} 
                  active={isActive(index)}
                >
                    <StepLabel onClick={() => handleMouseClick(index)} StepIconComponent={() => <CustomStepIcon index={index} />}>{embeddedReplaceDynamicElements(answer.text)}</StepLabel>
                </Step>
            ))}
        </Stepper>
    </Box>
  );
}
