import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import SaveIcon from '@mui/icons-material/Save';
import Button from '@mui/material/Button';

export default function DialogFreeField(props) {

    const {
        t, openPopupDialogFreeField, handleCloseDialogFreeField, labelAnswerFreeField
    } = props;

    const [userValueFreeField, setUserValueFreeField] = useState('');

    useEffect(() => {
        setUserValueFreeField('');
    }, [openPopupDialogFreeField])

    if(!openPopupDialogFreeField) return null;

    return (
        <Dialog open={openPopupDialogFreeField}>
            <DialogTitle>{t('react.collectors.freeField.label', {answer: labelAnswerFreeField})}</DialogTitle>
            <DialogContent>
            <div style={{display: 'flex'}}>
                <TextField
                    fullWidth={true}
                    variant="outlined"
                    autoFocus={true}
                    autoComplete="off"
                    size="small"
                    value={userValueFreeField}
                    onChange={(e) => setUserValueFreeField(e.target.value)}
                    color="primary"
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </div>
            </DialogContent>
            <DialogActions>
            <Button
                variant="outlined"
                size="large"
                color="primary"
                startIcon={<SaveIcon />}
                onClick={() => handleCloseDialogFreeField(userValueFreeField)}
            >
                {t("react.button.valid")}
            </Button>
            </DialogActions>
        </Dialog>
    )
}