import DialogTitleWithCloseIcon from '../shared/DialogTitleWithCloseIcon/DialogTitleWithCloseIcon';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { makeStyles } from 'tss-react/mui';
import InputAdornment from '@mui/material/InputAdornment';

const useStyles = makeStyles()(theme => ({
    textFieldCopy: {
        width: 400,
        marginLeft: 25,
        marginBottom: 30,
        marginTop: 15,
    },
    titleIcon: {
        marginRight: '12px',
        verticalAlign: 'sub'
    },
}));

export default function DialogDuplicateForm(props) {

    const { classes } = useStyles();

    const { t, copyDialogOpen, handleCopyDialogCancel, handleCopyDialogConfirm, spinnerCopy, nameCopyForm, setNameCopyForm } = props

    return (
        <Dialog
            open={copyDialogOpen}
            onClose={handleCopyDialogCancel}
            fullWidth={true}
            maxWidth='sm'
        >
            <DialogTitleWithCloseIcon
                startIcon={<FileCopyIcon color='secondary' className={classes.titleIcon} />}
                title={t('react.collectors.list.confirm.copy.title')}
                callbackOnclose={handleCopyDialogCancel}
            />
            <TextField
                variant="outlined"
                label={t('react.collectors.list.confirm.copy.textfield')}
                value={nameCopyForm}
                onChange={(e) => setNameCopyForm(e.target.value)}
                className={classes.textFieldCopy}
                disabled={spinnerCopy}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {spinnerCopy && (
                                <CircularProgress
                                    size={24}
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: '-8px',
                                        marginLeft: '-12px',
                                    }}
                                />
                            )}
                        </InputAdornment>
                    ),
                }}
            />
            <DialogActions>
                <Button onClick={handleCopyDialogCancel} color="primary" disabled={spinnerCopy}>
                    {t('react.button.cancel')}
                </Button>
                <Button onClick={handleCopyDialogConfirm} color="primary" autoFocus disabled={spinnerCopy || nameCopyForm.length === 0}>
                    {t('react.button.apply')}
                </Button>
            </DialogActions>
        </Dialog>
    )

}