import { useContext } from 'react';
import { makeStyles } from 'tss-react/mui';
import { SurveyContext } from './context';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import SettingsIcon from '@mui/icons-material/Settings';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import Tooltip from '@mui/material/Tooltip';
import ListItemIcon from '@mui/material/ListItemIcon';
import ViewAgendaIcon from '@mui/icons-material/ViewAgenda';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { cloneDeep, isBlank, appendSuffixIfGreaterThan } from '../../utils/utils';
import { v4 as uuidv4 } from 'uuid';
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';
import PlayCircleFilledOutlinedIcon from '@mui/icons-material/PlayCircleFilledOutlined';
import Looks3Icon from '@mui/icons-material/Looks3';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import MessageIcon from '@mui/icons-material/Message';
import TextsmsIcon from '@mui/icons-material/Textsms';
import PictureInPictureIcon from '@mui/icons-material/PictureInPicture';

const useStyles = makeStyles()(theme => ({
    marginTopForm: {
        marginTop: 10,
    },
    marginParams: {
        marginBottom: 20,
        padding: 10,
    },
    paramButton: {
        padding: 10,
        width: '100%',
    },
    flexBetween: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    listOverflow: {
        height: 'calc(100vh - 250px)', 
        overflowY: 'scroll'
    },
    styleGroups: {
        paddingTop: 5, 
        paddingBottom: 5, 
        paddingLeft: 20, 
        borderTop: '1px dashed #ddd'
    },
    listItemIcon: {
        minWidth: '35px',
    },
    questionProfileIndex: {
        marginLeft: "5px",
        color: "#BBB",
        fontStyle: "italic"
    },
}));

export default function ListOfBlocks(props) {
    
  const { classes } = useStyles();

  const { t, selectedIndexGroup, setSelectedIndexGroup, selectedIndex, setSelectedIndex, setBlocksForm, blocksForm, 
    isBlockInMiddleOfGroups, getQuestionIndexByBlockUuid, setOpenPopupGroupsOfBlocks, createBlock, surveysService } = props

  const { formIdParameter, setNeedToSave, computeTitle, notifyStructuralChange, getFormConfiguration } = useContext(SurveyContext);
    
    const handleListItemClick = (event, index) => {
        event.preventDefault();
        setSelectedIndex(index);
        setSelectedIndexGroup(-1);
    };

    const handleGroupClick = (event, index) => {
        event.preventDefault();
        setSelectedIndex(-1);
        setSelectedIndexGroup(index);
    }

    const upwardPosition = (event) => {
        event.preventDefault();
        let array = [...blocksForm];
        let newSelectedIndex = selectedIndex;

        if (selectedIndexGroup !== -1) {
            let valueTopBelongToGroup = false;

            array.forEach((value, index) => {
                if (value.configuration.groupId === selectedIndexGroup) {
                    if (array[index - 1].configuration.groupId !== selectedIndexGroup) {
                        if (array[index - 1].configuration.groupId !== 0) {
                            valueTopBelongToGroup = true;
                        }
                    }
                }
            });

            if (valueTopBelongToGroup === false) {
                array.forEach((item, index) => {
                    if (item.configuration.groupId === selectedIndexGroup && array[index - 1].configuration.groupId === 0) {
                        let newIndex = index - 1;
                        let thisItem = array[newIndex + 1];
                        array.splice(index, 1);
                        array.splice(newIndex, 0, thisItem);
                    }
                })
            } else {
                let groupIdBeforeActualGroup = 0;

                array.forEach((item, index) => {
                    if (index > 0 && item.configuration.groupId === selectedIndexGroup && array[index - 1].configuration.groupId !== selectedIndexGroup) {
                        groupIdBeforeActualGroup = array[index - 1].configuration.groupId;
                    }
                });

                const lengthOfArrayBefore = array.filter(value => value.configuration.groupId === groupIdBeforeActualGroup).length;
                const lengthOfArrayCurrentGroup = array.filter(value => value.configuration.groupId === selectedIndexGroup).length;
                const indexFirstValueGroupIdBefore = array.findIndex(value => value.configuration.groupId === groupIdBeforeActualGroup);
                const arrayPreviousValues = array.filter(item => item.configuration.groupId === groupIdBeforeActualGroup);

                array.splice(indexFirstValueGroupIdBefore, lengthOfArrayBefore)
                array.splice(indexFirstValueGroupIdBefore + lengthOfArrayCurrentGroup, 0, ...arrayPreviousValues);
            }
        } else if(selectedIndex !== -1) {
            if(array[selectedIndex].configuration.groupId !== array[selectedIndex - 1].configuration.groupId) {
                // if the previous block belongs to another group, then just include the current block into the previous group (= NO MOVE)
                array[selectedIndex].configuration.groupId = array[selectedIndex - 1].configuration.groupId;
            } else {
                let newIndex = selectedIndex - 1;
                let thisItem = array[newIndex + 1];
                array.splice(selectedIndex, 1);
                array.splice(newIndex, 0, thisItem);
                newSelectedIndex = selectedIndex - 1;
            }
        }

        setBlocksForm(array);
        setSelectedIndex(newSelectedIndex);
        setNeedToSave(true);
        notifyStructuralChange();
    }

    const downwardPosition = (event) => {
        // a element moved down must jump over a group if any
        event.preventDefault();
        let array = [...blocksForm];
        let newSelectedIndex = selectedIndex;

        if (selectedIndexGroup !== -1) {
            let valueBottomBelongToGroup = false;

            array.forEach((value, index) => {
                if (value.configuration.groupId === selectedIndexGroup) {
                    if (array[index + 1].configuration.groupId !== selectedIndexGroup) {
                        if (array[index + 1].configuration.groupId !== 0) {
                            valueBottomBelongToGroup = true;
                        }
                    }
                }
            });

            if (valueBottomBelongToGroup === false) {
                let valuesNewArray = [];
                let indexFirstValue = 0;

                array.forEach((item, index) => {
                    if (array[index + 1] !== undefined && item.configuration.groupId === selectedIndexGroup) {
                        if (index === 0 || array[index - 1].configuration.groupId !== selectedIndexGroup) {
                            indexFirstValue = index;
                        }
                        valuesNewArray.push(item)
                    }
                })
                array.splice(indexFirstValue, valuesNewArray.length)
                array.splice(indexFirstValue + 1, 0, ...valuesNewArray)
            } else {
                let groupIdAfterActualGroup = 0;

                array.forEach((item, index) => {
                    if (index > 0 && item.configuration.groupId === selectedIndexGroup && array[index + 1].configuration.groupId !== selectedIndexGroup) {
                        groupIdAfterActualGroup = array[index + 1].configuration.groupId;
                    }
                });

                const lengthOfArrayAfter = array.filter(value => value.configuration.groupId === groupIdAfterActualGroup).length;
                const lengthOfArrayCurrentGroup = array.filter(value => value.configuration.groupId === selectedIndexGroup).length;
                const indexFirstValueGroupIdAfter = array.findIndex(value => value.configuration.groupId === groupIdAfterActualGroup);
                const arrayPreviousValues = array.filter(item => item.configuration.groupId === groupIdAfterActualGroup);

                array.splice(indexFirstValueGroupIdAfter, lengthOfArrayAfter)
                array.splice(indexFirstValueGroupIdAfter - lengthOfArrayCurrentGroup, 0, ...arrayPreviousValues);
            }

        } else {
            if(array[selectedIndex].configuration.groupId !== array[selectedIndex + 1].configuration.groupId) {
                // if the next block belongs to another group, then just include the current block into the followin group (= NO MOVE)
                array[selectedIndex].configuration.groupId = array[selectedIndex + 1].configuration.groupId;
            } else {
                let newIndex = selectedIndex + 1;
                let thisItem = array[newIndex - 1];
                array.splice(selectedIndex, 1);
                array.splice(newIndex, 0, thisItem);
                newSelectedIndex = selectedIndex + 1;
            }
        }

        setBlocksForm(array);
        setSelectedIndex(newSelectedIndex);
        setNeedToSave(true);
        notifyStructuralChange();
    }

    const canIMoveUp = () => {
        if (selectedIndexGroup !== -1) {
            const introductionBlock = blocksForm.find(b => b.configuration.type === "introduction");
            const selectedIndexGroupIndex = blocksForm.findIndex(b => b.configuration.groupId === selectedIndexGroup);

            if (introductionBlock !== undefined) {
                return selectedIndexGroupIndex > 1;
            } else {
                return selectedIndexGroupIndex >= 1;
            }
        } else if (selectedIndex !== -1) {
            var block = blocksForm[selectedIndex];

            if (block === undefined || block.movable === false) {
                return false;
            }
            if (selectedIndex === 0) {
                return false;
            }
            if (selectedIndex === 1 && blocksForm[0].type === 'introduction' && block.configuration.groupId === 0) {
                return false;
            }

            return true;
        }
    }

    const canIMoveDown = () => {
        if (selectedIndexGroup !== -1) {
            const conclusionBlock = blocksForm.find(b => b.configuration.type === "thankyou");
            const firstIndex = blocksForm.findIndex(b => b.configuration.groupId === selectedIndexGroup);
            const groupLength = blocksForm.filter(b => b.configuration.groupId === selectedIndexGroup).length;

            if (conclusionBlock !== undefined) {
                return firstIndex + groupLength < blocksForm.length - 1;
            } else {
                return firstIndex + groupLength < blocksForm.length;
            }
        } else if (selectedIndex !== -1) {
            var block = blocksForm[selectedIndex];

            if (block === undefined || block.movable === false) {
                return false;
            }
            if (selectedIndex === blocksForm.length - 1) {
                return false;
            }
            if (selectedIndex === blocksForm.length - 2 && blocksForm[blocksForm.length - 1].type === 'thankyou' && block.configuration.groupId === 0) {
                return false;
            }

            return true;
        }
    }

    const deleteBlock = (event) => {
        event.preventDefault();

        let newblocksForm = [...blocksForm];

        if (selectedIndex !== -1) {
            newblocksForm.splice(selectedIndex, 1);
            setBlocksForm(newblocksForm);
        } else if(selectedIndexGroup !== -1) {
            let newArray = newblocksForm.filter(block => block.configuration.groupId !== selectedIndexGroup);
            setBlocksForm(newArray);
        }

        setSelectedIndex(-1);
        setSelectedIndexGroup(-1);
        setNeedToSave(true);
        notifyStructuralChange();
    }

    const canIDuplicate = () => {
        return selectedIndexGroup !== -1 || // TRUE if a group is selected
            // TRUE if the selected block is NOT an intro NOR a thankyou
            (selectedIndex !== -1 && (blocksForm[selectedIndex].type !== "introduction" && blocksForm[selectedIndex].type !== "thankyou"));
    };

    const isBeginOfGroup = (blockIdx) => {
        if(blockIdx < 0 || blockIdx >= blocksForm.length) return false;
        if(blocksForm[blockIdx].configuration.groupId === 0) return false;
        // we have a configuration.groupId > 0 for the block at position blockIdx
        if(blocksForm[blockIdx - 1] === undefined || blocksForm[blockIdx - 1].configuration.groupId !== blocksForm[blockIdx].configuration.groupId) {
            return true;
        }
        return false;
    };

    const isEndOfGroupWithNoGroupAfter = (blockIdx) => {
        if(blockIdx < 0 || blockIdx >= blocksForm.length) return false;
        if(blocksForm[blockIdx].configuration.groupId === 0) return false;
        // we have a configuration.groupId > 0 for the block at position blockIdx
        if(blocksForm[blockIdx + 1] !== undefined && blocksForm[blockIdx + 1].configuration.groupId === 0) {
            return true;
        }
        return false;
    };

    const duplicateBlock = (event) => {
        event.preventDefault();

        // duplicate normal block
        if (selectedIndex !== -1) {
            // get the config of the block
            let config = cloneDeepBlockConfig(blocksForm[selectedIndex].configuration);

            // create a block with the new configuration
            var clone = createBlock(blocksForm[selectedIndex].type, config, true);

            let newblocksForm = [...blocksForm];
            newblocksForm.splice(selectedIndex + 1, 0, clone);
            setBlocksForm(newblocksForm);
            setSelectedIndex(selectedIndex + 1);
            setNeedToSave(true);
            notifyStructuralChange();
        } else if(selectedIndexGroup !== -1) {
            // duplicate a group
            surveysService.createFormGroup(formIdParameter)
                .then(result => {
                    result.data.name = getFormConfiguration().groups.find(g => g.id === selectedIndexGroup).name;
                    result.data.shuffleBlocks = getFormConfiguration().groups.find(g => g.id === selectedIndexGroup).shuffleBlocks;
                    result.data.blockIndices = [];
                    getFormConfiguration().groups.push(result.data)
                    var newGroupId = result.data.id;
                    var clones = [];
                    var indexToCreateBlock = 0;                
        
                    blocksForm.forEach((b, index) => {
                        if (b.configuration.groupId === selectedIndexGroup) {
                            indexToCreateBlock = index;
                            let config = cloneDeepBlockConfig(b.configuration);
        
                            // set groupId
                            config.groupId = newGroupId;

                            // create a block with the new configuration
                            clones.push(createBlock(blocksForm[index].type, config, true))
                        }
                    })
                    let newblocksForm = [...blocksForm];
                    // add a block for each block in the group
                    clones.reverse().forEach(clone => {
                        newblocksForm.splice(indexToCreateBlock + 1, 0, clone);
                    });
                    setBlocksForm(newblocksForm);
                    setNeedToSave(true);
                    notifyStructuralChange();
                })
                .catch(err => {
                    openSnackbar('error', t("react.generic.error.while.loading"));
                });
        }
    }

    const goToParams = (event) => {
        event.preventDefault();
        setSelectedIndex(-1);
        setSelectedIndexGroup(-1);
    }

    const cloneDeepBlockConfig = (blockConfig) => {
        // get the config of the block
        let config = cloneDeep(blockConfig);

        // reset some fields
        config.id = 0;
        config.uuid = uuidv4();
        config.imageId = 0;
        config.imagePosition = 'left';
        config.imageWidth = 400;
        config.openImageFullscreen = false;

        if (config.openQuestion !== undefined && config.openQuestion !== null) {
            config.openQuestion.id = 0;
        }

        if (config.experience !== undefined && config.experience !== null) {
            config.experience.id = 0;
        }

        if (config.question !== undefined && config.question !== null) {
            config.question.id = 0;
            config.question.answers.forEach(answer => (answer.questionId = 0, answer.id = 0, answer.imageId = 0, answer.uuid = uuidv4()));
        }

        if (config.battery !== undefined && config.battery !== null) {
            config.battery.id = 0;
            config.battery.answers.forEach(answer => (answer.batteryId = 0, answer.id = 0, answer.imageId = 0, answer.uuid = uuidv4()));
            config.battery.items.forEach(item => (item.batteryId = 0, item.id = 0, item.imageId = 0, item.uuid = uuidv4()));
        }

        if (config.hotspot !== undefined && config.hotspot !== null) {
            config.hotspot.id = 0;
            config.hotspot.answers.forEach(answer => (answer.id = 0, answer.uuid = uuidv4()));
            config.hotspot.zones = [];
        }
        return config;
    };

    const computeIcon = element => {
        if (element.type === 'introduction') {
            return <PlayCircleFilledOutlinedIcon />;
        } else if (element.type === 'thankyou') {
            return <EmojiPeopleIcon />;
        } else if (element.type == 'experience') {
            return <Looks3Icon />;
        } else if (element.type == 'openQuestion') {
            return <MessageIcon />;
        } else if (element.type == 'question') {
            return <QuestionAnswerIcon />;
        } else if (element.type == 'text') {
            return <TextsmsIcon />;
        } else if (element.type == 'hotspot') {
            return <PictureInPictureIcon />;
        } else if (element.type == 'battery') {
            return <CalendarViewMonthIcon />;
        }
    };

    const renderBlockGroup = (block, blockIndex) => {
        // only before the first block of a group
        if (!isBeginOfGroup(blockIndex)) return;

        const group = getFormConfiguration().groups
            .find(g => g.id === block.configuration.groupId);

        return (
            <ListItem
                selected={selectedIndexGroup === group.id}
                onClick={(event) => handleGroupClick(event, group.id)}
                button
                className={classes.styleGroups}
                style={{
                    backgroundColor: isBlank(group.name) ? "#ffe1e7" : "",
                    height: 40
                }}
            >
                <Typography>{appendSuffixIfGreaterThan(group.name, '...', 25)}</Typography>
            </ListItem>);
    };

    return(
    <Grid item xs={3} className={classes.marginTopForm}>
        <Paper className={classes.marginParams}>
            <Typography variant="h6" component="h6" >
                <Button className={classes.paramButton}
                    variant="text" color="primary"
                    startIcon={<SettingsIcon />}
                    onClick={(e) => goToParams(e)}>{t('react.project.collectforms.parameters.button')}</Button>
            </Typography>
        </Paper>
        <Paper>
            <div className={classes.flexBetween}>
                <div>
                    <Tooltip title={t("react.project.collectforms.moveDownQuestion.tooltip")}><span>
                        <IconButton
                            disabled={!canIMoveDown()}
                            onClick={(e) => downwardPosition(e)}
                            component="span">
                            <ArrowDownwardIcon />
                        </IconButton></span>
                    </Tooltip>
                    <Tooltip title={t("react.project.collectforms.moveUpQuestion.tooltip")}><span>
                        <IconButton
                            disabled={!canIMoveUp()}
                            onClick={(e) => upwardPosition(e)}
                            component="span">
                            <ArrowUpwardIcon />
                        </IconButton></span>
                    </Tooltip>
                </div>
                <div>
                    <Tooltip title={t("react.project.collectforms.groups.tooltip")}><span>
                        <IconButton
                            disabled={formIdParameter === 'add' || blocksForm.length < 2}
                            onClick={() => setOpenPopupGroupsOfBlocks(true)}
                            component="span">
                            <ViewAgendaIcon />
                        </IconButton></span>
                    </Tooltip>
                    <Tooltip title={t("react.project.collectforms.copy.block.tooltip")}><span>
                        <IconButton
                            disabled={!canIDuplicate()}
                            onClick={(e) => duplicateBlock(e)}
                            component="span">
                            <FileCopyIcon />
                        </IconButton></span>
                    </Tooltip>
                    <Tooltip title={t("react.project.collectforms.delete.block.tooltip")}><span>
                        <IconButton
                            disabled={selectedIndex === -1 && selectedIndexGroup === -1}
                            onClick={(e) => deleteBlock(e)}
                            component="span">
                            <DeleteIcon />
                        </IconButton></span>
                    </Tooltip>
                </div>
            </div>
            <List component="nav" className={classes.listOverflow}>
                {blocksForm.map((element, index) => (
                    <div key={index}>
                        {renderBlockGroup(element, index)}
                    <ListItem
                        button
                        selected={selectedIndex === index}
                        onClick={(event) => handleListItemClick(event, index)}
                        key={`list-component-${index}`}
                            style={{
                                paddingLeft: (element.configuration.groupId !== 0 ? '40px' : '16px'),
                                borderBottom: (isEndOfGroupWithNoGroupAfter(index) ? '1px dashed #ddd' : 'none'),
                                marginLeft: -10,
                                width: 'calc(100% + 10px)',
                                backgroundColor: isBlockInMiddleOfGroups(blocksForm).includes(index) ? "#ffe1e7": element.incomplete === true ? "#ffe1e7" : "",
                            }}
                    >
                        <ListItemIcon children={computeIcon(element)} className={classes.listItemIcon} />
                        <ListItemText primary={computeTitle(element)} />
                            {element.type !== "introduction" && element.type !== "thankyou" && element.type !== "text" && <div className={classes.questionProfileIndex}>Q{getQuestionIndexByBlockUuid(element.uuid)}</div>}
                    </ListItem>
                    </div>
                ))}
            </List>
        </Paper>
    </Grid>
  )
}
