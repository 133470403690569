import React, {useState, useEffect} from 'react';
import Tooltip from '@mui/material/Tooltip';
import {capitalizeFirstLetter, orderBy} from '../../utils/utils';
import {DashboardConstants, getViewThemes} from '../../Constants';
import Popover from '@mui/material/Popover';

const viewThemes = getViewThemes();

export default function ThemeChart(props) {

  const {t, tags, groups, specifications, compareWith, configuration, hideArrows, dashboardService} = props;

  const [blocs, setBlocs] = useState([]);
  const [iconTheme, setIconTheme] = useState(viewThemes[viewThemes.default]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [popupTag, setPopupTag] = useState('');

  const width = 100;
  const reducer = (previousValue, currentValue) => previousValue + currentValue;

  const handleClick = (event, tagParam) => {
    setPopupTag(tagParam.color);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setPopupTag('');
  };

  useEffect(() => {
    if (configuration.iconTheme && viewThemes[configuration.iconTheme]) {
      setIconTheme(viewThemes[configuration.iconTheme]);
    }
  }, [configuration.iconTheme]);

  useEffect(() => {
    const newblocks = [];

    tags.forEach(tag => {
      // ONLY skip missing groups
      let groupOfTags = (groups[tag.color] || []).filter(d => !d.missing);

      if(groupOfTags.length === 0) {
        return;
      }

      const tagScore = Math.round(groupOfTags.map(d => d.score).reduce(reducer, 0));
      const nbOccurrences = groupOfTags.map(d => d.nbOccurrences).reduce(reducer, 0);

      // compute title and tooltip
      const title = capitalizeFirstLetter((tag.label.length < 35 ? tag.label : (tag.label.substring(0, 35) + '...')).toLowerCase());
      const tooltip = t("react.dashboard.groupchart.tooltip", {title: tag.label, score: tagScore, nbOccurrences: nbOccurrences})

      // compute size of the bar
      let ratio = dashboardService.computeBarScoreRatio(tagScore, specifications.tags.absMaxScore);

      var size = Math.round(Math.abs(width * ratio) * 0.5);
      if (size < 4) {
        size = 4;
      }

      let groupIcon = undefined;
      let compareWithValue = undefined;
      if (compareWith && !hideArrows) {
        let scoreDiff = 0;

        compareWithValue = specifications['tags']['sizes'][tag.color][compareWith.id];
        if(compareWithValue !== undefined) {
          scoreDiff = compareWithValue - tagScore;
          groupIcon = scoreDiff < 0 ? iconTheme['upIcon'] : iconTheme['downIcon'];

          // do not diplay the icon if under the activation limit
          if(Math.abs(scoreDiff) <= configuration.comparisonTriggeringValue) {
            groupIcon = undefined;
          }
        }
      }

      var block = (
        <Tooltip title={tooltip} key={tag.id} style={{height: '25px'}}>
          <div>
            <div style={{display:'inline-flex', marginLeft:'10px'}}>
              <div onClick={(e) => handleClick(e, tag)} id="bar-horzontal" style={{
                width: `${width}px`,
                height: `20px`,
                marginRight:'10px',
                marginBottom:'3px',
                marginTop:'2px',
                borderWidth: '1px',
                cursor: 'pointer'
              }}>
                    <div  style={{
                          width: `${size}px`,
                          height: `19px`,
                          left:`${(ratio > 0) ? 50 : (50 - size)}px`, position:'relative',
                          backgroundColor: `${ratio > 0 ? DashboardConstants.positiveColor : DashboardConstants.negativeColor}`,
                          borderStyle: "solid",
                          borderRadius: '5px',
                          borderWidth:'1px'
                        }}
                    >
                      &nbsp;
                    </div>
                 </div>
                 <div style={{
                        width: '30px',
                        color: compareWithValue < tagScore ? DashboardConstants.positiveColor : DashboardConstants.negativeColor
                    }}
                    >
                    { groupIcon ? groupIcon : <div></div> }
                 </div>
                 <div style={{whiteSpace: 'nowrap'}}>{title}</div>
            </div>
          </div>
        </Tooltip>);

      newblocks.push({component: block, tagScore: tagScore});
    });

    // set new bloc list ordered by score desc
    setBlocs(orderBy(newblocks, 'tagScore', true).map(block => block.component));
  }, [configuration, tags, groups, specifications, compareWith, hideArrows]);

  return (
    <div>
      <Popover
        open={anchorEl !== null}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <div style={{margin: 7}}>
          {popupTag !== '' && groups[popupTag].filter(grp => !grp.missing).map((element, index) => (
            <div style={{ margin: 5 }} key={index}>{t("react.dashboard.groupchart.tooltip", {title: capitalizeFirstLetter(element.title), score: Math.round(element.score), nbOccurrences: element.nbOccurrences})}</div>
          ))}
        </div>
      </Popover>
      {blocs.map((element, index) => (
        <div key={`themechart-${index}`}>{element}</div>
      ))}
    </div>
  );
}
