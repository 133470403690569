import React, { useState, useEffect, useReducer } from 'react';
import { makeStyles, withStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import WordCard from './WordCard';
import Accordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Badge from '@mui/material/Badge';
import { removeDiacritics, indexOf, orderBy, isBlank } from '../../utils/utils.js';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitleWithCloseIcon from '../shared/DialogTitleWithCloseIcon/DialogTitleWithCloseIcon';
import TextField from '@mui/material/TextField';
import Input from '@mui/material/Input';
import ClearIcon from '@mui/icons-material/Clear';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MenuIcon from '@mui/icons-material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import { Droppable, Draggable } from "react-beautiful-dnd";

const AccordionSummary = withStyles(MuiAccordionSummary, (_theme, _params, classes) => ({
  root: {
    padding: '0px 22px 0px 14px',
    minHeight: 44,
    maxHeight: 44,
    [`&.${classes.expanded}`]: {
      minHeight: 44,
      maxHeight: 44,
    },
  },
  expanded: {},
}));

const useStyles = makeStyles()(theme => ({
  root: {
  },
  dndZoneHeader: {
    position: "absolute",
    height: "45px",
    width: '200px',
    top: '0px',
    left: '30px',
  },
  title: {
    fontSize: 12,
    width: "100%",
    textAlign: "center",
    fontWeight: "bold",
  },
  groupHeader: {
    display: 'flex',
    width: '100%',
  },
  MuiBadgeBadge: {
    position: 'absolute',
    right: '-50px',
    top: '-15px',
  },
  badge: {
    top: '15px',
    margin: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  groupRoot: {
    marginBottom: '10px',
  },
  group: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  sticky: {
    position: 'sticky',
    top: '0px',
    zIndex: 5
  },
  expansionPanelDetails: {
    padding: '0px 0px 0px',
  },
  searchfield: {
    marginRight: '12px',
    minWidth: '200px !important',
  },
}));

const calculateScore = (items) => {
  if (!items) return 0;
  return items.reduce((a, b) => a + b.weightedScore || 0, 0);
}

const contextMenuInitialState = {
  mouseX: null,
  mouseY: null,
};

export default function Group(props) {

  const { t, bucket, group, deleteGroupCallback, openSnackbar, 
    groupingService, keywords, expandedBucketEventValue } = props;

  const isFilterable = bucket.index === 0 && !group.trashbin /* only in itemToTreat */;

  const [items, setItems] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [filter, setFilter] = useState('');
  const [scoreFilter, setScoreFilter] = useState([-5, 10]);

  const [loading, setLoading] = useState(false);

  const [multiselectMode, setMultiselectMode] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openRenameDialog, setOpenRenameDialog] = useState(false);

  const [contextMenustate, setContextMenuState] = useState(contextMenuInitialState);

  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

  const score = group.id <= 0 ? undefined : calculateScore(group.items);

  const { classes } = useStyles();

  useEffect(() => {
    // expandedBucketEventValue is treated like an event, it is a numeric value with 'pair' = open, 'impair' = close
    let newBucketExpandedState = expandedBucketEventValue % 2 === 0;
    if(newBucketExpandedState !== expanded) {
      setExpanded(newBucketExpandedState);
    }
  }, [expandedBucketEventValue]);

  useEffect(() => {
    let filteredItems = getWordsSortedAndFilteredByFiltersOrKeywords();

    if(!isBlank(keywords) && keywords.length > 2) {
      setExpanded(filteredItems.length > 0);
    }

    setItems(filteredItems);
  }, [group.modifiedAt, filter, scoreFilter, keywords]);

  const getWordsSortedAndFilteredByFiltersOrKeywords = () => {
    if (group === undefined || group.items === undefined || group.items.length === 0) return [];

    return orderBy(group.items, 'weightedScore', true)
      .filter(w => {
        if(isBlank(w.word)) return false;

        if(isFilterable) {
          if(scoreFilter && scoreFilter.length === 2) {
            if(scoreFilter[0] < w.weightedScore && w.weightedScore < scoreFilter[1]) {
              return false;
            }
          }

          if(!isBlank(filter)) {
            let wordTxt = removeDiacritics(w.word).toLowerCase()
            let filterTxt = removeDiacritics(filter).toLowerCase();

            if(wordTxt.indexOf(filterTxt) < 0) {
              return false;
            }
          }
        } else {
          if(!isBlank(keywords) && keywords.length > 2) {
            let wordTxt = removeDiacritics(w.word).toLowerCase()
            let keywordsTxt = removeDiacritics(keywords).toLowerCase();

            if(wordTxt.indexOf(keywordsTxt) < 0) {
              return false;
            }
          }
        }

        return true;
      });
  };

  const handleChangeMultiselectMode = (enabled) => {
    setMultiselectMode(enabled);
  }

  const handleSelectItem = (word) => {
    let wordIdx = indexOf(group.items, 'id', word.id);
    group.items[wordIdx].checked = word.checked === true /* can be undefined */ ? false : true;
    group.modifiedAt = Date.now();
    forceUpdate();
  }

  const handleSelectUnselectAllWords = () => {
    if (!multiselectMode) {
      setMultiselectMode(true);
    }
    // loop over visible items
    items.forEach((word, i) => {
      let wordIdx = indexOf(group.items, 'id', word.id);
      group.items[wordIdx].checked = word.checked === true /* can be undefined */ ? false : true;
    });

    group.modifiedAt = Date.now();
    forceUpdate();
  }

  const handleDeleteTag = () => {
    handleContextMenuClose();
    setLoading(true);
    groupingService.mapTagGroup(group.projectId, group.id, null)
      .then(() => {
        group.tag = null;
      }).catch(err => {
          openSnackbar('error', t('react.snackbar.groups.deletetag.error', { error: err.message }));
      }).finally(() => {
        setLoading(false);
      });
  };

  const handleOpenRenameGroup = (e) => {
    e.preventDefault();
    handleContextMenuClose();
    setOpenRenameDialog(true);
  };

  const handleCloseRenameGroup = (e) => {
    e.preventDefault();
    setOpenRenameDialog(false);
  };

  const handleApplyRenameGroupCallback = (newTitle) => {
    setLoading(true);
    groupingService.renameGroup(group.projectId, group.id, newTitle)
    .then(result => {
      group.title = newTitle;
      setOpenRenameDialog(false);
    }).catch(err => {
      openSnackbar('error', t('react.snackbar.groups.renamegroup.error', { error: err.message }));
    }).finally(() => {
      setLoading(false);
    });
  };

  const handleOpenDeleteGroup = (e) => {
    e.preventDefault();
    handleContextMenuClose();
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteGroup = (e) => {
    e.preventDefault();
    setOpenDeleteDialog(false);
  };

  const handleApplyDeleteGroupCallback = () => {
    setLoading(true);
    deleteGroupCallback(group)
    .then(result => {
      setOpenDeleteDialog(false);
    }).catch(err => {
      openSnackbar('error', t('react.generic.error.while.deleting', { error: err.message }));
    }).finally(() => {
      setLoading(false);
    });
  };

  function valuetext(value) {
    return `${value}`;
  }

  const scoreRangeMarks = [
    {
      value: -30,
      label: '-30',
    },
    {
      value: -20,
      label: '-20',
    },
    {
      value: -10,
    },
    {
      value: -8,
    },
    {
      value: -5,
      label: '-5',
    },
    {
      value: -2,
    },
    {
      value: 0,
      label: '0',
    },
    {
      value: 2,
    },
    {
      value: 5,
      label: '5',
    },
    {
      value: 8,
    },
    {
      value: 10,
    },
    {
      value: 20,
      label: '20',
    },
    {
      value: 30,
      label: '30',
    },
  ];

  const handleChangeScoreFilter = (e, newValue) => {
    e.preventDefault();
    setScoreFilter(newValue);
  }

  const handleContextMenuClose = () => {
    setContextMenuState(contextMenuInitialState);
  };

  const handleOpenMenuClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setContextMenuState({
      mouseX: e.clientX - 2,
      mouseY: e.clientY - 4,
    });
  };

  return (
    <div className={classes.groupRoot}>
      {
        isFilterable &&
        <div className={classes.sticky} style={{ backgroundColor: '#FFF' }}>
          <Input
            id="search-filter-basic"
            value={filter}
            style={{ marginLeft: '20px', marginBottom: '10px', marginTop: '5px', width: '90%' }}
            className={classes.searchfield}
            placeholder={t('react.grouping.filter.label')}
            onChange={e => setFilter(e.target.value)}
            autoComplete="off"
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={() => setFilter('')} edge="end" size="large">
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            }
          />
          <Typography id="non-linear-slider" style={{ marginLeft: '20px' }} variant="subtitle1" gutterBottom>
            {t('react.grouping.filter.score', { lowLimit: scoreFilter[0], hightLimit: scoreFilter[1] })}
          </Typography>
          <Slider
            style={{ marginLeft: '20px', marginBottom: '20px', width: '90%' }}
            value={scoreFilter}
            onChange={handleChangeScoreFilter}
            getAriaValueText={valuetext}
            step={null}
            min={-30}
            max={30}
            marks={scoreRangeMarks}
            track="inverted"
          />
        </div>
      }
      {group.id > 0 && !group.trashbin &&
        <Menu
          id={group.id}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenustate.mouseY !== null && contextMenustate.mouseX !== null
              ? { top: contextMenustate.mouseY, left: contextMenustate.mouseX }
              : undefined
          }
          open={contextMenustate.mouseY !== null}
          onClose={handleContextMenuClose}
        >
          <MenuItem onClick={e => handleDeleteTag()}>
            <ListItemIcon>
              <ClearAllIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={t('react.button.resettag')} />
          </MenuItem>
          <MenuItem onClick={handleOpenRenameGroup}>
            <ListItemIcon>
              <EditIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={t('react.button.rename')} />
          </MenuItem>
          <MenuItem onClick={handleOpenDeleteGroup}>
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={t('react.button.delete')} />
          </MenuItem>
        </Menu>
      }

      <GroupPanel
        {...props}
        items={items}
        score={score}
        loading={loading}
        expanded={expanded}
        setExpanded={setExpanded}
        classes={classes}
        handleOpenMenuClick={handleOpenMenuClick}
        multiselectMode={multiselectMode}
        handleChangeMultiselectMode={handleChangeMultiselectMode}
        handleSelectItem={handleSelectItem}
        handleSelectUnselectAllWords={handleSelectUnselectAllWords}
      />
      <DeleteDialog
        {...props}
        open={openDeleteDialog}
        handleApply={handleApplyDeleteGroupCallback}
        handleClose={handleCloseDeleteGroup}
      />
      <RenameDialog
        {...props}
        open={openRenameDialog}
        handleApply={handleApplyRenameGroupCallback}
        handleClose={handleCloseRenameGroup}
      />
    </div>
  );
}

function RenameDialog(props) {

  const { t, open, handleApply, handleClose, group } = props;

  const [newTitle, setNewTitle] = useState((group.title || '').toUpperCase());

  const save = (e) => {
    e.preventDefault();
    if (newTitle) handleApply(newTitle);
  };

  return (
    <form noValidate autoComplete="off">
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth='xs'
      >
        <DialogTitleWithCloseIcon
          title={t('react.grouping.renamedialog.title')}
          callbackOnclose={handleClose}
        />
        <DialogContent>
          <TextField id='renameTextField' autoFocus={true} onChange={e => { setNewTitle(e.target.value) }} value={newTitle} label={t('react.grouping.renamedialog.newtitle.label')} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('react.button.close')}
          </Button>
          <Button onClick={save} autoFocus>
            {t('react.button.rename')}
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  )
}

function DeleteDialog(props) {

  const { t, group, open, handleApply, handleClose } = props;

  const deleteGroup = (e) => {
    e.preventDefault();
    handleApply();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogTitleWithCloseIcon
        title={t('react.grouping.deletedialog.title')}
        callbackOnclose={handleClose}
      />
      <DialogContent>
        <DialogContentText id="delete-dialog-description">
          {t('react.grouping.deletedialog.content', { grouptitle: group.title })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t('react.button.close')}
        </Button>
        <Button onClick={deleteGroup} autoFocus>
          {t('react.button.delete')}
        </Button>
      </DialogActions>
    </Dialog>)
}

const PanelDetails = (props) => {

  const { items, bucket, group, expanded, classes,
    createGroupFromWordCallback, moveToTrashbinCallback, handleRemoveFromGroupCallback } = props;

  const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? '#EBF5FB' : '#fff',
    minHeight: '50px'
  });

  return <AccordionDetails className={classes.expansionPanelDetails}>
    <div className={classes.group}>
    <Droppable
      droppableId={`bucket-${bucket.index}-group-body-${group.id}`}
      key={group.id}
      index= {group.id}
      type={"word"}
      isDropDisabled={bucket.index > 0 && !expanded /* disable dropping zone for closed groups (except left column) */}
     >
     {(provided, snapshot) => (
         <div ref={provided.innerRef} {...provided.droppableProps} style={getListStyle(snapshot.isDraggingOver)}>
         {
         items.map((p, index) => {
           return (
             <Draggable
               draggableId={`group-${group.id}-word-${p.id}`}
               key={`group-${group.id}-word-${p.id}`}
               index={index}
               type={"word"}
             >
             {(provided, snapshot) => (
                 <div
                   ref={provided.innerRef}
                   {...provided.draggableProps}
                   {...provided.dragHandleProps}
                 >
               <WordCard
                {...props}
                word={p}
                className="draggable-item"
                handleCreateGroupFromWord={() => createGroupFromWordCallback(group, p)}
                handleMoveToTrashbin={() => moveToTrashbinCallback(group, p)}
                handleRemoveFromGroup={() => handleRemoveFromGroupCallback(group, p)}
               />
               </div>
                 )}
             </Draggable>
           );
         })
       }
       {provided.placeholder}
       </div>
     )}
     </Droppable>
   </div>
  </AccordionDetails>
}

const PanelSummary = (props) => {

  const { loading, bucket, group, expanded, setExpanded, handleOpenMenuClick, score, classes } = props;

  return <AccordionSummary
    onClick={() => setExpanded(!expanded)}
    disabled={loading}
    style={{ backgroundColor: (group.tag != null ? group.tag.color : group.trashbin ? 'rgba(0, 0, 0, 0.12)' : '#FFF') }}
  >

    {!group.trashbin && 
      <MenuIcon fontSize="small" onClick={handleOpenMenuClick} />
    }
    <div className={classes.groupHeader}>
      <div className={classes.title}>
        <div>{loading && <CircularProgress size={15}/>}
          {group.trashbin ? <DeleteSweepIcon /> : group.title.toUpperCase()}
        </div>
      </div>
      <div className={classes.dndZoneHeader}>
        <Droppable
          droppableId={`bucket-${bucket.index}-group-header-word-${group.id}`}
          key={1000}
          type={"word"}
        >
        {(provided, snapshot) => (
          <div ref={provided.innerRef} {...provided.droppableProps} style={{minHeight: '100%', minWidth: '100%'}}>
          {provided.placeholder}
          </div>
        )}
        </Droppable>
      </div>
      <div className={classes.dndZoneHeader}>
        <Droppable
          droppableId={`bucket-${bucket.index}-group-header-tag-${group.id}`}
          key={1001}
          type={"tag"}
        >
        {(provided, snapshot) => (
          <div ref={provided.innerRef} {...provided.droppableProps} style={{minHeight: '100%', minWidth: '100%'}}>
          {provided.placeholder}
          </div>
        )}
        </Droppable>
      </div>
      {(group.id !== -1 && !group.trashbin) && (<Badge anchorOrigin={{ vertical: 'top', horizontal: 'left', }} classes={{ badge : classes.MuiBadgeBadge}} className={classes.badge} badgeContent={Math.round(score)} color={(score < 0) ? "error" : "primary"} max={5000} overlap="rectangular"></Badge>)}
    </div>
  </AccordionSummary>
}

const GroupPanel = (props) => {

  const { group, expanded, setExpanded, classes } = props;

  if (group.id === -1) {
    return <Accordion id={group.id} defaultExpanded={true} expanded={true} className={classes.root}>
      <PanelDetails
      key={`PanelDetails-${group.id}`}
        {...props}
      />
    </Accordion>
  }

  return <Accordion id={group.id} defaultExpanded={expanded} expanded={expanded} className={classes.root}>
    <PanelSummary
      {...props}
      onHeaderClick={() => setExpanded(!expanded)}
    />
    <PanelDetails
      {...props}
      key={`PanelDetails-${group.id}`}
    />
  </Accordion>
}
