import React, { useState, useEffect } from 'react';
import {toolbarStyles} from '../../common.js';
import clsx from 'clsx';
import { makeStyles } from 'tss-react/mui';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import CachedIcon from '@mui/icons-material/Cached';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import LinearProgress from '@mui/material/LinearProgress';
import Link from '@mui/material/Link';
import GroupIcon from '@mui/icons-material/Group';
import ApiService from '../../services/ApiService'
import EditCustomersDialog from './EditCustomersDialog'
import { useParams } from "react-router-dom";
import TableSortLabel from '@mui/material/TableSortLabel';
import Input from '@mui/material/Input';
import ClearIcon from '@mui/icons-material/Clear';
import InputAdornment from '@mui/material/InputAdornment';
import {LoadData} from '../../Constants.js'
import { AuthService } from '../../services/AuthService';
import dateFormat from 'dateformat';
import {getItemAriaLabel} from '../../utils/pagination.js'
import PopupTwoButtons from '../shared/PopupTwoButtons/PopupTwoButtons';
import {Helmet} from "react-helmet";

const apiService = new ApiService();
const restClient = apiService.getClient();

function createData(id, name, description, resellerId, resellerName, creationDateValue, updateDateValue) {
  const creationDate = new Date();
  creationDate.setTime(creationDateValue);
  const updateDate = new Date();
  updateDate.setTime(updateDateValue);
  return { id, name, description, resellerId, resellerName, creationDate, updateDate };
}

const headCells = [
  { id: 'name', label: 'react.customers.table.header.name', minWidth: 170 },
  { id: 'description', label: 'react.customers.table.header.description', minWidth: 200 },
  {
    id: 'resellerId',
    label: 'react.customers.table.header.reseller',
    minWidth: 150,
    format: (props, row) => (
      <Link href='#' onClick={() => props.navigate(`/resellers/${row['resellerId']}`)}>{row['resellerName']}</Link>
    ),
    adminView: true,
  },
  {
    id: 'resellerName',
    label: 'react.customers.table.header.reseller',
    minWidth: 150,
    format: (props, row) => (
      <span>{row['resellerName']}</span>
    ),
    adminView: false,
  },
  {
    id: 'creationDate',
    label: 'react.customers.table.header.creationdate',
    minWidth: 170,
    align: 'right',
    format: (props, row) => dateFormat(row['creationDate'], "UTC:dd/mm/yyyy"),
  },
  {
    id: 'updateDate',
    label: 'react.customers.table.header.updatedate',
    minWidth: 170,
    align: 'right',
    format: (props, row) => dateFormat(row['updateDate'], "UTC:dd/mm/yyyy"),
  },
];

function EnhancedTableHead(props) {
  const { t, classes, onSelectAllClick, numSelected, rowCount, sortBy, sortDirection, handleChangeSort } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            id='checkbox-all'
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
        {headCells
          .filter(function(headCell) {
            if(headCell.adminView === true && !AuthService.isAdmin()) {
              return false; // skip
            } else if(headCell.adminView === false && AuthService.isAdmin()) {
              return false; // skip
            }
            return true;
          })
          .map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={sortBy === headCell.id ? sortDirection : false}
          >
          <TableSortLabel
              active={sortBy === headCell.id}
              direction={sortDirection}
              onClick={e => handleChangeSort(e, headCell.id)}
            >
            {t(headCell.label)}
          {sortBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {sortDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const EnhancedTableToolbar = props => {
  const { classes } = toolbarStyles();

  const { t, numSelected, countForPagination, handleDeleteCustomers, handleEditCustomer, handleFilterChange, setLoadData } = props;

  const handleClickRefresh = (event) => {
    event.preventDefault();
    setLoadData(LoadData.Load);
  };

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <GroupIcon className={classes.mainIcon}/>
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1">
          {t("react.customerlist.number.of.selected", {'numSelected': numSelected})}
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle">
          {t('react.customers.title', {'size': countForPagination})}
        </Typography>
      )}
      <Input
        id="search-filter-basic"
        className={classes.searchfield}
        placeholder={t('react.translationtool.search.title')}
        onChange={e => handleFilterChange(e.target.value)}
        endAdornment={
          <InputAdornment position="end">
            <IconButton onClick={e => handleFilterChange('')} edge="end" size="large">
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        }
      />
      {numSelected > 0 &&
  		<Tooltip title={t('react.button.delete')}>
    		<IconButton onClick={handleDeleteCustomers} size="large">
    			<DeleteIcon />
    		</IconButton>
  		</Tooltip>
      }
  		<Tooltip title={t('react.button.add')}>
  			<IconButton onClick={(e) => handleEditCustomer(e, 0)} size="large">
  			<ControlPointIcon />
  			</IconButton>
  		</Tooltip>
  		<Tooltip title={t('react.button.refresh')}>
  			<IconButton onClick={handleClickRefresh} size="large">
  			<CachedIcon />
  			</IconButton>
  		</Tooltip>
    </Toolbar>
  );
};

const useStyles = makeStyles()(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableRowCursorPointer : {
    cursor: 'pointer',
  },
}));

const CustomersList = (props) =>  {
  // URL parameter
  const { customerIdParameter } = useParams();

  const { t, openSnackbar, closeSnackbar} = props;

  const { classes } = useStyles();
  const [countForPagination, setCountForPagination] = useState(0);
  const [rows, setRows] = useState([]);
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortBy, setSortBy] = useState('name');
  const [sortDirection, setSortDirection] = useState('asc');
  const [keywords, setKeywords] = useState('');

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    event.preventDefault();
    setPage(newPage);
    setLoadData(LoadData.Load);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setLoadData(LoadData.Load);
  };

  const handleChangeSort = (event, headCellId) => {
    event.preventDefault();
    if(sortBy !== headCellId) {
      setSortBy(headCellId);
      setSortDirection('asc')
    } else {
      setSortDirection(sortDirection === 'desc' ? 'asc' : 'desc');
    }
    setLoadData(LoadData.Load);
  };

  const handleFilterChange = (newValue) => {
    setFilterApplied(newValue);
    setPage(0);
    setKeywords(newValue);
    setLoadData(LoadData.Load);
  };

  const [loadData, setLoadData] = useState(LoadData.Load);
  const [filterApplied, setFilterApplied] = useState(false);
  useEffect(() => {
    if(loadData !== LoadData.Load) return;

    setLoadData(LoadData.Loading);

    restClient.get('/api/customers/v1.0/?include=resellers&from=' + (page*rowsPerPage) + '&size=' + rowsPerPage + '&sort=' + sortBy+ '&order=' + sortDirection + '&keywords=' + encodeURIComponent(keywords))
    .then(result => {
      setCountForPagination(result.data.total);

      var newRows = [];
      for(var i = 0; i < result.data.hits.length; i++) {
        let hit = result.data.hits[i];
        let resellerName = result.data.resellers.find(r => r.id === hit.resellerId).name;
        newRows.push(createData(hit.id, hit.name, hit.description, hit.resellerId, resellerName, hit.creationDate, hit.updateDate));
      }

      setRows(newRows);

      if(customerIdParameter > 0) {
        setEditDialogCustomerId(customerIdParameter);
        setEditDialogOpen(true);
      }
    })
    .catch(err => {
      setRows([]);
      setCountForPagination(0);
      openSnackbar('error', t("react.customerslist.error.while.loading"));
    }).finally(() => {
      setLoadData(LoadData.Loaded);
    });
  }, [loadData, page, rowsPerPage, customerIdParameter, sortBy, sortDirection, keywords, openSnackbar, t]);

  const isSelected = id => selected.indexOf(id) !== -1;

  const [editDialogCustomerId, setEditDialogCustomerId] = useState(0);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const handleEditCustomer = (event, customerIdToEdit) => {
    event.preventDefault();
    setEditDialogCustomerId(customerIdToEdit);
    setEditDialogOpen(true);
  };
  const editDialogCallbackAfterConfirm = () => {
    setEditDialogOpen(false);
    if(customerIdParameter) {
      props.navigate('/customers');
    }
    setLoadData(LoadData.Load);
  };
  const editDialogCallbackAfterCancel = () => {
    setEditDialogOpen(false);
    if(customerIdParameter) {
      props.navigate('/customers');
    }
  };

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const handleDeleteCustomers = (event, row) => {
    event.preventDefault();
    setDeleteDialogOpen(true)
  };
  const handleDeleteDialogConfirm = () => {
    var promises = [];
    for(var i = 0; i < selected.length; i++) {
      promises.push(restClient.delete('/api/customers/v1.0/' + selected[i]));
    }

    Promise.all(promises)
    .then(function(values) {
      setDeleteDialogOpen(false);
      setLoadData(LoadData.Load);
      setSelected([]);
    })
    .catch(err => {
      openSnackbar('error', t("react.customerslist.error.while.deleting"));
    });
  };
  const handleDeleteDialogCancel = () => {
      setDeleteDialogOpen(false);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length);

  return (
    <div className={classes.root}>
      <Helmet title={t('react.helmet.customerslist')} />
      { loadData !== LoadData.Loaded && !filterApplied ? ( <LinearProgress /> )
      : (
      <Paper className={classes.paper}>
      <PopupTwoButtons
          variant='warning'
          openState={deleteDialogOpen}
          callbackOnclose={handleDeleteDialogCancel}
          callbackOnclickLeftButton={handleDeleteDialogCancel}
          callbackOnclickRightButton={handleDeleteDialogConfirm}
          title={t('react.customerslist.confirm.delete.title')}
          content={t('react.customerslist.confirm.delete.description')}
          leftButton={t('react.button.cancel')}
          rightButton={t('react.button.delete')}
          unlockCheckboxLabel={t('react.popuptwobuttons.delete.help.for.the.disabled')}
        />
        <EditCustomersDialog
          {...props}
          customerId={editDialogCustomerId}
          openState={editDialogOpen}
          callbackAfterConfirm={editDialogCallbackAfterConfirm}
          callbackAfterCancel={editDialogCallbackAfterCancel}
        />
        <EnhancedTableToolbar
          {...props}
          numSelected={selected.length}
          countForPagination={countForPagination}
          handleDeleteCustomers={handleDeleteCustomers}
          handleEditCustomer={handleEditCustomer}
          handleFilterChange={handleFilterChange}
          setLoadData={setLoadData} />
        <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            size="medium"
          >
            <EnhancedTableHead
              {...props}
              classes={classes}
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={rows.length}
              sortBy={sortBy}
              sortDirection={sortDirection}
              handleChangeSort={handleChangeSort}
            />
            <TableBody>
              {rows.map((row, index) => {
                  const isItemSelected = isSelected(row.id);

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                      className={classes.tableRowCursorPointer}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          id={`checkbox-${row.id}`}
                          onClick={event => handleClick(event, row.id)}
                          checked={isItemSelected}
                        />
                      </TableCell>
                      {headCells
                        .filter(function(headCell) {
                          if(headCell.adminView === true && !AuthService.isAdmin()) {
                            return false; // skip
                          } else if(headCell.adminView === false && AuthService.isAdmin()) {
                            return false; // skip
                          }
                          return true;
                        })
                        .map(column => {
                        let value = row[column.id];
                        // when it is the reseller column let the <Link> do the redirect
                        let isResellerColumn = column.id === 'resellerId';
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            onClick={event => isResellerColumn ? '' : handleEditCustomer(event, row.id)} >
                            {column.format ? column.format(props, row) : value}
                          </TableCell>
                        );
                      })}
                        </TableRow>
                      );
                    })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={countForPagination}
          rowsPerPage={rowsPerPage}
          page={page}
          labelRowsPerPage={t('react.list.number.lignes.per.page')}
          labelDisplayedRows={({ from, to, count }) => t('react.list.number.from.to.count', {'from':from, 'to':to, 'count':count})}
          getItemAriaLabel={(type) => getItemAriaLabel(t, type)}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      ) }
    </div>
  );
}

export default CustomersList;
