import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from 'tss-react/mui';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import CachedIcon from '@mui/icons-material/Cached';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import LinearProgress from '@mui/material/LinearProgress';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import ApiService from '../../services/ApiService'
import PopupTwoButtons from '../shared/PopupTwoButtons/PopupTwoButtons';
import TableSortLabel from '@mui/material/TableSortLabel';
import {toolbarStyles} from '../../common.js';
import { capitalizeFirstLetter } from '../../utils/utils.js';
import Input from '@mui/material/Input';
import ClearIcon from '@mui/icons-material/Clear';
import InputAdornment from '@mui/material/InputAdornment';
import { AuthService } from '../../services/AuthService';
import {LoadData} from '../../Constants.js'
import dateFormat from 'dateformat';
import {getItemAriaLabel} from '../../utils/pagination.js'
import {Helmet} from "react-helmet";

const restClient = new ApiService().getClient();

function createData(id, userId, name, clientName, caseStudy, description, sectorTypeId, stimulusTypeId, state, creationDateValue, updateDateValue) {
  const creationDate = new Date()
  creationDate.setTime(creationDateValue)
  const updateDate = new Date()
  updateDate.setTime(updateDateValue)
  return { id, userId, name, clientName, caseStudy, description, sectorTypeId, stimulusTypeId, state, creationDate, updateDate };
}

const headCells = [
  { id: 'name', label: 'react.projects.list.column.name', minWidth: 200 },
  { id: 'userId', label: 'react.projects.list.column.user', minWidth: 200, sortable: false},
  // VISIBLE FOR ADMIN
  { id: 'customerId', label: 'react.projects.list.column.customer', minWidth: 200, sortable: false, adminView: true },
  // VISIBLE FOR USERS
  { id: 'clientName', label: 'react.projects.list.column.client', minWidth: 200, adminView: false },
  // END SPECIFICS
  { id: 'state', label: 'react.projects.list.column.state', minWidth: 200 },
  {
    id: 'creationDate',
    label: 'react.projects.list.column.creation.date',
    minWidth: 200,
    align: 'right',
    format: row => dateFormat(row['creationDate'], "UTC:dd/mm/yyyy"),
  },
];

function ProjectsListHead(props) {
  const { classes, t, onSelectAllClick, numSelected, rowCount, sortBy, sortDirection, handleChangeSort } = props;

  return (
    <TableHead>
      <Helmet title={t('react.helmet.projectslist')} />
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            id='checkbox-all'
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
        {headCells.filter(function(headCell) {
          if(headCell.adminView === true && !AuthService.isAdmin()) {
            return false; // skip admin only to normal user
          } else if(headCell.adminView === false && AuthService.isAdmin()) {
            return false; // skip useless columns to admin
          }
          return true;
        }).map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={sortBy === headCell.id ? sortDirection : false}
          >
          {headCell.sortable === false ? (
            t(headCell.label)
            ) : (
              <TableSortLabel
                  active={sortBy === headCell.id}
                  direction={sortDirection}
                  onClick={e => handleChangeSort(e, headCell.id)}
                >
              {t(headCell.label)}
              {sortBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {sortDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const ProjectsListToolbar = props => {
  const { classes } = toolbarStyles();
  const { t, selected, countForPagination, handleDeleteProjects, keywords, handleKeywordsChange, setLoadData } = props;
  const numSelected = selected.length;

  const handleClickRefresh = (event) => {
    event.preventDefault();
    setLoadData(LoadData.Load);
  };

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <AllInboxIcon className={classes.mainIcon}/>
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1">
          {t("react.projects.list.number.of.selected", {'numSelected': numSelected})}
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle">
          {t("react.projects.list.number.title", {'countForPagination': countForPagination})}
        </Typography>
      )}

      <Input
        id="search-filter-basic"
        value={keywords}
        className={classes.searchfield}
        placeholder={t('react.translationtool.search.title')}
        onChange={e => handleKeywordsChange(e.target.value)}
        endAdornment={
          <InputAdornment position="end">
            <IconButton onClick={e => handleKeywordsChange('')} edge="end" size="large">
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        }
      />

      {numSelected > 0 &&
          <Tooltip title={t('react.button.delete')}>
            <IconButton onClick={handleDeleteProjects} size="large">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
      }
      <Tooltip title={t('react.button.add')}>
        <IconButton
          onClick={() => props.navigate('/projects/add')}
          size="large">
          <ControlPointIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={t('react.button.refresh')}>
        <IconButton onClick={handleClickRefresh} size="large">
          <CachedIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
};

const useStyles = makeStyles()(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableRowCursorPointer : {
    cursor: 'pointer',
  },
}));

export default function ProjectsList(props) {

  const { classes } = useStyles();

  const { t, openSnackbar, showSpinner } = props

  const [countForPagination, setCountForPagination] = useState(0);
  const [rows, setRows] = useState([]);
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [users, setUsers] = useState([]);
  const [customers, setCustomers] = useState([]);

  const [sortBy, setSortBy] = useState(AuthService.isAdmin() ? 'state' : 'name');
  const [sortDirection, setSortDirection] = useState(AuthService.isAdmin() ? 'asc': 'asc');
  const [keywords, setKeywords] = useState('');

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    event.preventDefault();
    setPage(newPage);
    setLoadData(LoadData.Load);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setLoadData(LoadData.Load);
  };

  const handleChangeSort = (event, headCellId) => {
    event.preventDefault();
    if(sortBy !== headCellId) {
      setSortBy(headCellId);
      setSortDirection('asc')
    } else {
      setSortDirection(sortDirection === 'desc' ? 'asc' : 'desc');
    }
    setLoadData(LoadData.Load);
  };

  const [loadKeywordsChange, setLoadKeywordsChange] = useState(false);
  const handleKeywordsChange = (newValue) => {
    setPage(0);
    setKeywords(newValue);
    if(newValue && newValue.length > 0) {
      setLoadKeywordsChange(true);
    }
    setLoadData(LoadData.Load);
  };

  const [loadData, setLoadData] = useState(LoadData.Load);
  useEffect(() => {
    if(loadData !== LoadData.Load) return;

    setLoadData(LoadData.Loading);

    restClient.get('/api/projects/v1.0/?include=users,customers&from=' + (page*rowsPerPage) + '&size=' + rowsPerPage + '&sort=' + sortBy+ '&order=' + sortDirection + '&keywords=' + encodeURIComponent(keywords))
    .then(function(result) {
      // parse projects
      setUsers(users.concat(result.data.users));
      setCustomers(customers.concat(result.data.customers));

      const newRows = [];
      for(let i = 0; i < result.data.hits.length; i++) {
        let hit = result.data.hits[i];
        let data = createData(
            hit.id, hit.userId, hit.name, hit.clientName, hit.caseStudy,
            hit.description, hit.sectorTypeId, hit.stimulusTypeId, hit.state,
            hit.creationDate, hit.updateDate);
        newRows.push(data);
      }
      setRows(newRows);
      setCountForPagination(result.data.total);
    })
    .catch(err => {
      setRows([]);
      setCountForPagination(0);
      openSnackbar('error', t("react.projectlist.error.while.loading"));
    }).finally(() => {
      setLoadData(LoadData.Loaded);
      setLoadKeywordsChange(false);
    });
  }, [loadData, page, rowsPerPage, sortBy, sortDirection, loadKeywordsChange, keywords, users, customers]);

  const isSelected = id => selected.indexOf(id) !== -1;

  const handleEditProject = (event, projectIdToEdit) => {
    event.preventDefault();
    props.navigate('/projects/' + projectIdToEdit);
  };

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const handleDeleteProjects = (event, row) => {
    event.preventDefault();
    setDeleteDialogOpen(true)
  };

  const handleDeleteDialogConfirm = () => {
    setDeleteDialogOpen(false);
    showSpinner(true);

    var promises = [];
    for(var i = 0; i < selected.length; i++) {
      promises.push(restClient.delete('/api/projects/v1.0/' + selected[i]));
    }

    Promise.all(promises)
      .then(function(values) {
        showSpinner(false);
        setLoadData(LoadData.Load);
        setSelected([]);
      })
      .catch(err => {
        showSpinner(false);
        openSnackbar('error', t("react.projectlist.error.while.deleting"));
      });
  };

  const handleDeleteDialogCancel = () => {
      setDeleteDialogOpen(false);
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length);

  return (
    <div className={classes.root}>
      { loadData !== LoadData.Loaded && !loadKeywordsChange ? ( <LinearProgress /> )
      : (
      <Paper className={classes.paper}>
        <PopupTwoButtons
            variant='warning'
            openState={deleteDialogOpen}
            callbackOnclose={handleDeleteDialogCancel}
            callbackOnclickLeftButton={handleDeleteDialogCancel}
            callbackOnclickRightButton={handleDeleteDialogConfirm}
            title={t('react.project.list.confirm.delete.title')}
            content={t('react.project.list.confirm.delete.description')}
            leftButton={t('react.button.cancel')}
            rightButton={t('react.button.delete')}
        />
        <ProjectsListToolbar
          {...props}
          keywords={keywords}
          selected={selected}
          countForPagination={countForPagination}
          handleDeleteProjects={handleDeleteProjects}
          handleKeywordsChange={handleKeywordsChange}
          setLoadData={setLoadData} />
        <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            size="medium"
          >
            <ProjectsListHead
              t={t}
              classes={classes}
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={rows.length}
              sortBy={sortBy}
              sortDirection={sortDirection}
              handleChangeSort={handleChangeSort}
            />
            <TableBody>
              {rows.map((row, index) => {
                  const isItemSelected = isSelected(row.id);

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                      className={classes.tableRowCursorPointer}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          id={`checkbox-${row.id}`}
                          onClick={event => handleClick(event, row.id)}
                          checked={isItemSelected}
                        />
                      </TableCell>
                      {headCells
                        .filter(function(headCell) {
                          if(headCell.adminView === true && !AuthService.isAdmin()) {
                            return false; // skip
                          } else if(headCell.adminView === false && AuthService.isAdmin()) {
                            return false; // skip
                          }
                          return true;
                        })
                        .map(column => {
                          let value = '';

                          if(column.id === 'userId') {
                            let realUser = users.filter(function (el) { return el.id === row['userId']});
                            if(realUser && realUser.length > 0) {
                              // avoid problem of load that breaks the page
                              value = realUser[0].firstname + ' ' + realUser[0].lastname;
                            }
                          } else if(column.id === 'customerId') {
                            let realUser = users.filter(function (el) { return el.id === row['userId']});
                            if(realUser && realUser.length > 0) {
                              // avoid problem of load that breaks the page
                              let realCustomer = customers.filter(function (el) { return el.id === realUser[0].customerId});
                              if(realCustomer && realCustomer.length > 0) {
                                // avoid problem of load that breaks the page
                                value = realCustomer[0].name;
                              }
                            }
                          } else if(column.id === 'state') {
                            if(AuthService.isAdmin()) {
                              // display all states to admin
                              value = capitalizeFirstLetter(row['state']);
                            } else if(['draft', 'validation', 'completed', 'error', 'cancelled'].includes(row['state'])) {
                              value = capitalizeFirstLetter(row['state']);
                            } else {
                              // display in inProgress label for all other states
                              value = t('react.projects.list.column.state.value.inProgress')
                            }
                          } else {
                            value = row[column.id];
                          }

                        return (
                          <TableCell key={column.id} align={column.align}
                            onClick={event => handleEditProject(event, row.id)} >
                            { column.format ? column.format(row) : value }
                          </TableCell>
                        );
                      })}
                        </TableRow>
                      );
                    })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={countForPagination}
          rowsPerPage={rowsPerPage}
          page={page}
          labelRowsPerPage={t('react.list.number.lignes.per.page')}
          labelDisplayedRows={({ from, to, count }) => t('react.list.number.from.to.count', {'from':from, 'to':to, 'count':count})}
          getItemAriaLabel={(type) => getItemAriaLabel(t, type)}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      ) }
    </div>
  );
}
