import React, { useCallback, useState, useRef, useEffect } from 'react';
import { Controlled as ControlledZoom } from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css'
import { Paper } from '@mui/material';
import './Image.css';
import useMediaQuery from '@mui/material/useMediaQuery';
import { IMAGE_WIDTH_AUTO, IMAGE_WIDTH_100_PERCENT } from '../../utils/surveysUtils';

export default function Image(props) {

  const { imageUrl, width = 0, position = 'center', openFullscreen = false, maxWidth = undefined, maxHeight = undefined, title = undefined } = props;

  const isLargeScreen = useMediaQuery('(min-width:900px)');
  const containerRef = useRef(null);
  const [computedWidth, setComputedWidth] = useState(false);

  // this is the zoom mode
  const [isZoomed, setIsZoomed] = useState(false)
  const handleZoomChange = useCallback(shouldZoom => {
    setIsZoomed(shouldZoom)
  }, [])

  useEffect(() => {
      if (containerRef.current) {
        const parentWidth = containerRef.current.clientWidth;

        var newComputedWidth = !width /* 0, undefined or null */ ? IMAGE_WIDTH_AUTO : width;

        // convert to real width value
        if (newComputedWidth === IMAGE_WIDTH_AUTO) {
          newComputedWidth = 'auto';
        } else if (newComputedWidth === IMAGE_WIDTH_100_PERCENT || newComputedWidth > parentWidth) {
          newComputedWidth = '100%';
        }

        setComputedWidth(newComputedWidth);
      }
  }, [imageUrl]);

  const getPositionImage = (imagePosition) => {
    const positions = {
      left: 'flex-start',
      center: 'center',
      right: 'flex-end'
    };
    return positions[imagePosition];
  };
  
  // this is the full screen mode without zoom
  if(openFullscreen && isLargeScreen) {
    return (
      <Paper elevation={1} square={false} className='openFullscreen'>
        <img src={imageUrl} width='100%'/>
      </Paper>
    );
  } else {
    return (
      <div ref={containerRef} style={{width: '100%', display: 'flex', justifyContent: getPositionImage(position)}}>
        {computedWidth && <ControlledZoom isZoomed={isZoomed} onZoomChange={handleZoomChange} zoomMargin={25}>
          <img src={imageUrl} width={computedWidth} style={{maxWidth: maxWidth, maxHeight: maxHeight}} title={title}/>
        </ControlledZoom>}
      </div>
    );
  };
};