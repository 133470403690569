import axios from 'axios'
require('dotenv').config()

const axiosService = axios.create({
  baseURL: process.env.REACT_APP_R3MSCORE_API_BASEURL,
  headers: { 'Content-Type': 'application/json' },
  logoutAfter403RedirectLoop: false
});

axiosService.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (401 === error.response.status || axiosService.defaults.logoutAfter403RedirectLoop) {
    window.location = '/logout';
  } else if (403 === error.response.status) {
    axiosService.defaults.logoutAfter403RedirectLoop = true;
    window.location = '/dashboard?error=403';
  } else {
    return Promise.reject(error);
  }
});

class ApiService {

  getClient() {
    return axiosService;
  }

  getAnonymousClient() {
    return axios.create({
      baseURL: process.env.REACT_APP_R3MSCORE_API_BASEURL,
      headers: { 'Content-Type': 'application/json' }
    });
  }

  setBasicAuth(authdata) {
    axiosService.defaults.headers.common['Authorization'] = 'Basic ' + authdata;
  }

  setBearerAuth(token) {
    axiosService.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  }

  processBinaryResponse(response) {
    var regExp = new RegExp('filename="([^"]+)"', 'i');
    let filename = regExp.exec(response.headers['content-disposition'])[1];
    let url = window.URL.createObjectURL(new Blob([response.data]));
    let link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  }
}

export default ApiService;
