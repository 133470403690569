import React, { useState, Fragment } from 'react';
import Button from '@mui/material/Button';
import { makeStyles } from 'tss-react/mui';
import ApiService from '../../services/ApiService'
import queryString from 'query-string';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import PasswordConfirmation from '../../components/shared/PasswordConfirmation/PasswordConfirmation';

const useStyles = makeStyles()((theme) => {
  return {
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  icon: {
    '&:hover': {
      cursor: 'pointer'
    },
    zIndex: '1000'
  }
}
});

export default function ResetPasswordPanel(props) {
  // receive props
  const { setRedirectToHome, openSnackbar } = props;
  const { t } = useTranslation();

  // read expected parameter: resetToken
  let params = queryString.parse(window.location.search)
  let resetToken = params.resetToken;

  const { classes } = useStyles();
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [notSamePassword, setNotSamePassword] = useState('');
  const [passwordCorrect, setPasswordCorrect] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    
    if(password !== passwordConfirm) {
      setNotSamePassword(t('react.validation.changepassword.dontmatch'));
    } else {
      let resetBody = {
        'token' : resetToken,
        'password' : password,
      };
      let restClient = new ApiService().getClient();
      restClient.post('/api/auth/v1.0/reset-password/change/with-token', JSON.stringify(resetBody))
        .then(response => {
          openSnackbar('success', t('react.reset.password.changed'));
          setTimeout(() => { setRedirectToHome(true); }, 2000);
        }).catch(err => {
          if(err.response.status === 400 && err.response.data.message === 'This password is not valid') {
            openSnackbar('error', t("react.users.changepassword.invalid"));
          } else {
            openSnackbar('error', t('react.reset.password.error', {reason: err.response.data.message}));
          }
        });
    }
  }

  const handleCancel = (event) => {
    event.preventDefault();
    setRedirectToHome(true);
  }

  if(resetToken === undefined || resetToken.length === 0) {
    return (
      <Fragment>
        <Typography>
          {t('react.reset.password.error.not.allowed')}
        </Typography>
        <Button fullWidth variant="contained" color="primary" onClick={handleCancel} className={classes.submit} > {t('react.button.cancel')} </Button>
      </Fragment>
    );
  }

  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <Typography component="h1" variant="h5" style={{textAlign: 'center'}}>
         {t("react.reset.password.title")}
      </Typography>
      <PasswordConfirmation 
           t={t}
           password={password}
           setPassword={setPassword}
           notSamePassword={notSamePassword}
           passwordConfirm={passwordConfirm}
           setPasswordConfirm={setPasswordConfirm}
           setPasswordCorrect={setPasswordCorrect} 
        />
      <Button
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
        type="submit"
        disabled={!passwordCorrect}
      >
        {t('react.button.save')}
      </Button>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        onClick={handleCancel}
      >
        {t('react.button.cancel')}
      </Button>
    </form>
  );
}