import ApiService from './ApiService';
require('dotenv').config()


const apiService = new ApiService();
const restClient = apiService.getClient();

class GroupingService {

    async fetchBiblio(projectId){
        return restClient.get(`/api/grouping/v1.0/${projectId}/biblio`);
    }

    async fetchGroups(projectId){
        return restClient.get(`/api/grouping/v1.0/${projectId}`);
    }

    async createGroup(projectId, bucket, newGroup){
        newGroup.projectId = projectId;
        newGroup.bucket = bucket;
        return restClient.post(`/api/grouping/v1.0/${projectId}`, newGroup);
    }

    async mapToGroup(projectId, groupId, positionMap){
        return restClient.put(`/api/grouping/v1.0/${projectId}/${groupId}/positions`, positionMap);
    }

    async deleteGroup(projectId, groupId){
        return restClient.delete(`/api/grouping/v1.0/${projectId}/${groupId}`);
    }

    async reorderGroups(projectId, bucketId, positionMap){
        return restClient.put(`/api/grouping/v1.0/${projectId}/positions/${bucketId}`, positionMap);
    }

    async reorderTags(projectId, positionsMap){
        return restClient.put(`/api/grouping/v1.0/${projectId}/tags/positions`, positionsMap);
    }

    async renameGroup(projectId, groupId, title){
        return restClient.patch(`/api/grouping/v1.0/${projectId}/${groupId}/title`, title);
    }

    async fetchTags(projectId){
        return restClient.get(`/api/grouping/v1.0/${projectId}/tags`);
    }

    async deleteTag(projectId, tagId){
        return restClient.delete(`/api/grouping/v1.0/${projectId}/tags/${tagId}`);
    }

    async createTag(projectId, label, color){
        return restClient.post(`/api/grouping/v1.0/${projectId}/tags`, {label:label, color: color});
    }

    async updateTag(projectId, id, label, color){
        return restClient.put(`/api/grouping/v1.0/${projectId}/tags/${id}`, {label:label, color: color});
    }

    async mapTagGroup(projectId, groupId, tagId){
        return restClient.patch(`/api/grouping/v1.0/${projectId}/${groupId}/tag`, tagId);
    }

    async getGroupingAsExcelFile(projectId) {
        return restClient.get('/api/grouping/v1.0/' + projectId + '/export/xlsx', {responseType: 'blob'})
    }

    async generateGrouping(projectId, generateTags = false, codificationLibraryId = 0, includedProjectIds = []) {
        return restClient.post(`/api/grouping/v1.0/${projectId}/generate?generateTags=${generateTags}&codificationLibraryId=${codificationLibraryId}&includedProjectIds=${includedProjectIds}`);
    }
}

export default GroupingService;
