import ApiService from './ApiService';
require('dotenv').config()

const apiService = new ApiService();
const restClient = apiService.getClient();

class CustomerService {

  async get500CustomersOrderByNameAsc() {
      return restClient.get('/api/customers/v1.0/?from=0&size=500&sort=name');
  }

  async fetchCustomer(customerId) {
      return restClient.get('/api/customers/v1.0/' + customerId);
  }

  async fetchAllResellers() {
      return restClient.get('/api/resellers/v1.0/?from=0&size=500&sort=name');
  }

  async saveCustomer(customer) {
    if(customer.id === 0) {
        return restClient.post('/api/customers/v1.0/', customer);
    } else {
        return restClient.put('/api/customers/v1.0/' + customer.id, customer);
    }
  }
}

export default CustomerService;
