import React, { useEffect, useState, useContext, useReducer } from 'react';
import { SurveyContext } from './context';
import { TextField } from '@mui/material';
import { isBlank } from '../../utils/utils';
import Paper from '@mui/material/Paper';
import { makeStyles } from 'tss-react/mui';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

const useStyles = makeStyles()(theme => ({
    marginParams: {
        marginBottom: 20,
        padding: 15,
    },
    marginTopSwitch: {
        marginTop: 10
    }
}));

export default function EditGroup(props) {

    const { classes } = useStyles();

    const { t, selectedIndexGroup, conditionners, conditionService } = props;

    const { getAllBlocks, setNeedToSave, getFormConfiguration } = useContext(SurveyContext);

    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
    const [presenceOfCondition, setPresenceOfCondition] = useState(false);

    const thisGroup = getFormConfiguration().groups.find(g => g.id === selectedIndexGroup);

    useEffect(() => {
        // get all blocks of the current group
        let blocksInThisGroup = getAllBlocks()
            .filter(b => b.configuration.groupId === thisGroup.id);

        // check if one of these blocks is used in conditions by another of the same group
        var hasConditionOnAnyBlock = conditionners
            // keep conditions applied on blocks of the group
            .filter(c => c.conditionedUuids.some(uuid => blocksInThisGroup.find(b=> b.uuid == uuid)))
            // get concerned parentUuid (block, zone or battery item)
            .map(c => conditionService.getConcernedParentUuids(c))
            .flat()
            // check if parentUuid refer to one of the block of the group
            .some(parentUuid => blocksInThisGroup.some(b => conditionService.isConditionableElementOfBlock(b, parentUuid)));

        setPresenceOfCondition(hasConditionOnAnyBlock)
    }, [selectedIndexGroup])

    const changeField = (fieldName, value) => {
        switch (fieldName) {
            case "shuffleBlocks": 
                thisGroup.shuffleBlocks = !thisGroup.shuffleBlocks;
                break;
            case "name":
                thisGroup.name = value;
                break;
        }
        setNeedToSave(true);
        forceUpdate();
    };

    return (
        <Paper className={classes.marginParams}>
            <TextField
                error={isBlank(thisGroup.name)}
                label={`${t("react.project.collectforms.group.name")} (${(thisGroup.name || '').length} / 255)`}
                fullWidth
                value={thisGroup.name}
                onChange={e => changeField('name', e.target.value)}
                inputProps={{ maxLength: 255 }}
                style={{marginTop: 15}}
            ></TextField>
            <div className={classes.marginTopSwitch}>
                <FormControlLabel style={{ marginLeft: '0px' }}
                    control={<Switch
                        checked={thisGroup.shuffleBlocks}
                        color="secondary"
                        onChange={() => changeField('shuffleBlocks')}
                        disabled={presenceOfCondition}
                    />}
                    label={t("react.project.collectforms.shuffle.group.questions")}
                    labelPlacement="start"
                />
            </div>
        </Paper>
    )
}
