import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const fallbackLng = ['en'];
const availableLanguages = ['en', 'fr'];

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .on('languageChanged', (lng) => {document.documentElement.setAttribute('lang', lng);})
  .init({
    backend: {
      loadPath: (lng) => `${process.env.REACT_APP_R3MSCORE_API_BASEURL}/api/translations/v1.0/react/${lng}?key=react.&version=${process.env.REACT_APP_R3MSCORE_VERSION}`
    },
    fallbackLng,
    keySeparator: false,
    whitelist: availableLanguages,
    interpolation: {
      escapeValue: false
    },
  });

export default i18n;
