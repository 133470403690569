// READ: 
// https://mui.com/material-ui/migration/migration-v4/
// https://mui.com/material-ui/migration/v5-style-changes/
// https://mui.com/material-ui/guides/interoperability/

import React, { Component, Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import ApplicationRoutes from '../../routes';
import theme from '../../theme/r3mscore-theme.js';
import { ThemeProvider,StyledEngineProvider  } from '@mui/material/styles';
import { RecoilRoot } from 'recoil';
import {Helmet} from "react-helmet";
import './stylesReactQuill.css';

const Loader = () => {
  return <div>loading...</div>
};

export default class App extends Component {
  render() {
    return (
      <StyledEngineProvider injectFirst>
        <Helmet defaultTitle={"R3m Score"} titleTemplate="%s - R3m Score"/>
        <ThemeProvider theme={theme}>
          <Suspense fallback={<Loader />}>
            <RecoilRoot>
              <BrowserRouter basename={process.env.REACT_APP_ROUTER_BASE || ''}>
                <ApplicationRoutes />
              </BrowserRouter>
            </RecoilRoot>
          </Suspense>
          </ThemeProvider>
      </StyledEngineProvider>
    );
  }
}
