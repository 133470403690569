import React, { useState, useEffect, useContext } from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { makeStyles } from 'tss-react/mui';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Checkbox } from '@mui/material';
import Image from './Image';
import CustomNextButton from './CustomNextButton';
import DialogFreeField from './DialogFreeField';
import { CollectorContext } from './context';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import MobileStepper from '@mui/material/MobileStepper';
import { isBlank, stripHtmlTags } from '../../utils/utils';
import { calculateWidthCol, IMAGE_WIDTH_AUTO } from '../../utils/surveysUtils';
import BarTimeout from './BarTimeout';
import { range } from 'lodash';
import CustomSlider from './CustomSlider';
import { styled } from '@mui/material/styles';
import ButtonBase from '@mui/material/ButtonBase';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";

const useStyles = makeStyles()(theme => ({
  positionRelative: {
    position: 'relative',
  },
  helperTextnbExpectedAnswers: {
    marginTop: 10,
    fontStyle: 'italic',
    opacity: '0.5',
    fontSize: '90%',
    textAlign: 'center'
  },
  containerButtonCarousel: {
    display: 'flex', 
    flexDirection: 'row', 
    justifyContent: 'center', 
    marginTop: 17,
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',  // change to flexDirection column for smaller screens
      }
  },
  button: {
    textTransform: 'none',
  },
  mobileStepper: {
    width: 600, 
    height: 20, 
    backgroundColor: 'white', 
    marginLeft: '320px',
      [theme.breakpoints.down('md')]: {
        width: '300px',
        display: 'flex',
        justifyContent: 'center',
        marginLeft: 0
      }
  },
  textItem: {
    fontWeight: 500, 
    fontSize: '1.3em', 
    marginTop: 20
  },
  withLeftBorder: {
    textAlign: 'center', 
    borderRight: '1px solid rgba(224, 224, 224, 1)', 
    borderLeft: '1px solid rgba(224, 224, 224, 1)'
  },
  withoutLeftBorder: {
    textAlign: 'center', 
    borderRight: '1px solid rgba(224, 224, 224, 1)', 
  },
  marginHorizontalZero: {
    marginLeft: 0,
    marginRight: 0
  }
}));

const ImageButton = styled(ButtonBase)(({ theme }) => ({
  position: 'relative',
  '&:hover, &.Mui-focusVisible': {
    zIndex: 1,
    '& .MuiImageBackdrop-root': {
      opacity: 0.15,
    },
    '& .MuiImageMarked-root': {
      opacity: 0,
    },
    '& .MuiTypography-root': {
      border: '4px solid currentColor',
    },
  },
}));

export default function BatteryOfItems(props) {

  const { classes } = useStyles();

  const {
    t, showSpinner, openSnackbar, block
  } = props;

  const {
    collectParameter, collectorsService, handleNextBlock, handleQuotaFull,
    handleScreenOut, participantId, imagesOfForm, allUserAnswers, embeddedReplaceDynamicElements } = useContext(CollectorContext);

  const [step, setStep] = useState(0);
  const [animationKey, setAnimationKey] = useState(0);
  const [arrayToSave, setArrayToSave] = useState([]);
  const [batteryBlockImage, setBatteryBlockImage] = useState(undefined);
  const [nbColumnsCata, setNbColumnsCata] = useState(0);
  const [arrayCata, setArrayCata] = useState([[], [], [], [], []]);
  const [optimalCataButtonHeight, setOptimalCataButtonHeight] = useState(50);
  const [disabledMenu, setDisabledMenu] = useState(true);
  const [dataReady, setDataReady] = useState(false);
  const [openPopupDialogFreeField, setOpenPopupDialogFreeField] = useState(false);
  const [editedItemFreeField, setEditedItemFreeField] = useState(0);
  const [labelAnswerFreeField, setLabelAnswerFreeField] = useState(undefined);

  const [imageReady, setImageReady] = useState(false);
  const [answersImagesReady, setAnswersImagesReady] = useState(false);
  const [answersImages, setAnswersImages] = useState([]);
  const [batteryItemImages, setBatteryItemImages] = useState([]);
  const [batteryItemImagesReady, setBatteryItemImagesReady] = useState(false);

  const ControlComponent = block.battery.type === 'single' ? Radio : Checkbox;

  useEffect(() => {
    setDataReady(false);

    if (!block) return;

    // always reset all fields when a new bloc comes
    const newArrayToSave = block.battery.items
      .map(item => ({
        itemId: item.id,
        answerIds: [],
        enteredValue: '',
      }));
    setArrayToSave(newArrayToSave);
    setStep(0);
    setAnimationKey(0);

    let maxAnswerLength = 0;
    let newArrayCata = [[], [], [], [], []];
    var numberOfColumns = block.battery.nbCatalogColumns;

    if (window.screen.width < 800) {
      block.battery.mode = "carousel";
      numberOfColumns = 1;
    } 

    if (numberOfColumns > 0) {
      newArrayCata = Array.from({ length: numberOfColumns }, () => []);
      for (let i = 0; i < block.battery.answers.length; i++) {
        const answer = block.battery.answers[i];
        const indiceTableauDestination = i % numberOfColumns;
        newArrayCata[indiceTableauDestination].push(answer);

        if (answer.text.length > maxAnswerLength) {
          maxAnswerLength = answer.text.length;
        }
      }
    }
    if (window.screen.width < 800) {
      setOptimalCataButtonHeight(maxAnswerLength < 35 ? 35 : maxAnswerLength)
    } else if (numberOfColumns === 5) {
      setOptimalCataButtonHeight(maxAnswerLength < 25 ? 50 : maxAnswerLength * 2)
    } else {
      setOptimalCataButtonHeight(maxAnswerLength < 25 ? 35 : maxAnswerLength * 1.4)
    }
    setArrayCata(newArrayCata);
    setNbColumnsCata(numberOfColumns);
    setDisabledMenu(block.timeout > 0);

    setDataReady(true);
  }, [block])

  /*
   * This useEffect() is only used to control the display of the block image
   */
  useEffect(() => {
    setImageReady(false);

    if (!block || block.imageId === 0) {
      setBatteryBlockImage(undefined)
      setImageReady(true);
      return;
    }

    let img = imagesOfForm.get(block.imageId);
    if (img !== undefined) {
      setBatteryBlockImage(img);
      setImageReady(true);
      return;
    }

    showSpinner(true);

    collectorsService.getPublicFormFileUrl(collectParameter, block.imageId)
      .then(result => {
        imagesOfForm.set(block.imageId, result.url);
        setBatteryBlockImage(result.url);
        setImageReady(true);
        showSpinner(false);
      }).catch(e => {
        setBatteryBlockImage(undefined);
        setImageReady(false);
        showSpinner(false);
        openSnackbar('error', t('react.error.fetch.message'));
        handleScreenOut();
      });
  }, [block]);

  /*
   * This useEffect() is only used to control the display of answers images
   */
  useEffect(() => {
    setAnswersImagesReady(false);

    var promises = block.battery.answers
      .filter(answer => answer.imageId && answer.imageId > 0)
      .map(answer => collectorsService.getPublicFormFileUrl(collectParameter, answer.imageId));

    if (promises.length === 0) {
      setAnswersImages([]);
      setAnswersImagesReady(true);
      return;
    }

    showSpinner(true);

    Promise.all(promises)
      .then(results => {
        setAnswersImages(results);
        setAnswersImagesReady(true);
        showSpinner(false);
      }).catch(e => {
        setAnswersImages([]);
        setAnswersImagesReady(false);
        showSpinner(false);
        openSnackbar('error', t('react.error.fetch.message'));
        handleScreenOut();
      });
  }, [block]);

  /*
   * This useEffect() is only used to control the display of items images
   */
  useEffect(() => {
    setBatteryItemImagesReady(false);

    var promises = block.battery.items
      .filter(item => item.imageId && item.imageId > 0)
      .map(item => collectorsService.getPublicFormFileUrl(collectParameter, item.imageId));

    if (promises.length === 0) {
      setBatteryItemImages([]);
      setBatteryItemImagesReady(true);
      return;
    }

    showSpinner(true);

    Promise.all(promises)
      .then(results => {
        setBatteryItemImages(results);
        setBatteryItemImagesReady(true);
        showSpinner(false);
      }).catch(e => {
        setBatteryItemImages([]);
        setBatteryItemImagesReady(false);
        showSpinner(false);
        openSnackbar('error', t('react.error.fetch.message'));
        handleScreenOut();
      });
  }, [block]);

  const isSelected = (answer, item) => {
    const pos = arrayToSave.findIndex(i => i.itemId === item.id);
    return pos >= 0 ? arrayToSave[pos].answerIds.includes(answer.id) : false;
  };

  const saveBlock = async (autoSaveSelectedAnswers = undefined) => {
    // pass to next item (step) until this end of the list of items
    if (block.battery.mode === "carousel" && step < block.battery.items.length-1) {
      setStep(step+1);
      window.scrollTo({top: 0});
      setAnimationKey(animationKey+1);
      return;
    }

    showSpinner(true);

    var userResponse = autoSaveSelectedAnswers || [...arrayToSave];

    await collectorsService.collectBatteryOfItemsAnswer(participantId, block.id, userResponse)
      .then(response => {
        setDataReady(false);
        showSpinner(false);
        pushAnswersToUserHistory();
        handleNextBlock();
      }).catch(error => {
        setDataReady(false);
        showSpinner(false);
        if (error.response.status === 302) {
          handleQuotaFull();
        } else if (error.response.status === 307) {
          handleScreenOut();
        } else {
          openSnackbar('error', t('react.error.save.message'));
          handleScreenOut();
        }
      });
  };

  const pushAnswersToUserHistory = () => {
    // append user answers of the question to the user answers list for text replacement
    arrayToSave.forEach(it => {
      const answers = it.answerIds.map(answer => block.battery.answers.find(ba => ba.id === answer).text);
      const userAnswerToAdd = { questionRef: `${block.ref}.${block.battery.items.find(i => i.id === it.itemId).ref}`, answers: answers };
      allUserAnswers.push(userAnswerToAdd);
    })
  };

  const selectAnswer = (answer, item, autoSave = false) => {
    let tempArr = [...arrayToSave];
    let openFreeFieldDialog = false;

    let indexToChange = tempArr.findIndex(i => i.itemId === item.id);

    if (block.battery.type === "single") {
      tempArr[indexToChange].answerIds = [answer.id];

      if(answer.freeField) {
        // do not enable the freefield in slider mode for the moment
        openFreeFieldDialog = block.battery.itemsStyle !== "slider";
      } else {
        // always clear the enteredValue if it's not the freefield
        tempArr[indexToChange].enteredValue = '';
      }
    } else {
      let existsAtIdx = tempArr[indexToChange].answerIds.indexOf(answer.id);
      if (existsAtIdx >= 0) {
        // remove if exists and clear the enteredValue if it is the freeField
        tempArr[indexToChange].answerIds.splice(existsAtIdx, 1);
        if(answer.freeField) tempArr[indexToChange].enteredValue = '';
      } else {
        tempArr[indexToChange].answerIds.push(answer.id);
        openFreeFieldDialog = answer.freeField;
      }
    }

    setArrayToSave(tempArr);

    if (openFreeFieldDialog) {
      setEditedItemFreeField(item.id);
      setLabelAnswerFreeField(answer.text);
      setOpenPopupDialogFreeField(true);

      // never do the auto-save when the user has to enter a freefield value
      return;
    }

    if (autoSave) {
      saveBlock(tempArr)
    }
  };

  const isButtonDisabled = () => {
    if (arrayToSave.length === 0) {
      return
    }

    let state = false;  
      if (block.battery.mode === "row" || block.battery.mode === "column") {
        if (block.battery.type === "single") {
          if (!arrayToSave.every(i => i.answerIds.length > 0)) {
            state = true;
          }
        } else {
          if (block.battery.nbMinExpectedAnswers && !arrayToSave.every(i => i.answerIds.length >= block.battery.nbMinExpectedAnswers)) {
            state = true;
          } 
          if (block.battery.nbMaxExpectedAnswers && !arrayToSave.every(i => i.answerIds.length <= block.battery.nbMaxExpectedAnswers)) {
            state = true;
          } 
        }
      } else {
        if (block.battery.type === "single") {
          if (!arrayToSave[step].answerIds.length > 0) {
            state = true;
          }
        } else {          
          if (block.battery.nbMinExpectedAnswers && arrayToSave[step].answerIds.length < block.battery.nbMinExpectedAnswers) {
            state = true;
          }
          if (block.battery.nbMaxExpectedAnswers && arrayToSave[step].answerIds.length > block.battery.nbMaxExpectedAnswers) {
            state = true;
          }
        }
      }
    return state;
  };

  const handleBarTimeoutExpired = () => {
    setDisabledMenu(false);
  };

  const handleSelectAnswerCustomSlider = (answer) => {
    selectAnswer(answer, block.battery.items[step], true)
  };

  const getCorrespondingAnswerImage = (idP) => {
    const image = answersImages.find(img => img.id === idP);
    return image ? image.url : undefined;
  };

  const getCorrespondingItemImage = (idP) => {
    const image = batteryItemImages.find(img => img.id === idP);
    return image ? image.url : undefined;
  };

  const helperText = () => {
    if(disabledMenu || block.battery.type !== "multiple") return null;

    const { nbMinExpectedAnswers, nbMaxExpectedAnswers } = block.battery;
    const suffixTableOrNot = (block.battery.mode === "row" || block.battery.mode === "column") ? '.table' : '';

    var textToReturn = null;

    if (nbMinExpectedAnswers > 0 && nbMinExpectedAnswers === nbMaxExpectedAnswers) {
      textToReturn = t(`react.collectors.questions.mandatory.answers${suffixTableOrNot}`, {
        numAnswers: nbMinExpectedAnswers,
      });
    } else if (nbMinExpectedAnswers > 0 && nbMaxExpectedAnswers < block.battery.answers.length) {
      textToReturn = t(`react.collectors.questions.minimumAndMaximum.answers${suffixTableOrNot}`, {
        numMinAnswers: nbMinExpectedAnswers,
        numMaxAnswers: nbMaxExpectedAnswers,
      });
    } else if (nbMinExpectedAnswers) {
      textToReturn = t(`react.collectors.questions.minimum.answers${suffixTableOrNot}`, {
        numAnswers: nbMinExpectedAnswers,
      });
    } else if (nbMaxExpectedAnswers) {
      textToReturn = t(`react.collectors.questions.maximum.answers${suffixTableOrNot}`, {
        numAnswers: nbMaxExpectedAnswers,
      });
    }

    return textToReturn ? <div className={classes.helperTextnbExpectedAnswers}>{textToReturn}</div> : null;
  };

  const displayNextButton = () => {
    // while the time-out is not expired, do not display button
    if (disabledMenu) return false;

    // do not enable the freefield in slider mode for the moment
    if(block.battery.itemsStyle === "slider") return false;

    // always display the next button when there is a freeField
    if(block.battery.answers.findIndex(a => a.freeField) >= 0) return true;

    // the NextButton is hidden only when it's a carrousel in single mode (what ever is the itemsStyle)
    const isCarouselSingle = block.battery.mode === "carousel" && block.battery.type === "single";

    return !isCarouselSingle;
  };

  const handleCloseDialogFreeField = (userEnteredValue) => {
    let tempArr = [...arrayToSave];
    const indexToChange = tempArr.findIndex(it => it.itemId === editedItemFreeField);
    tempArr[indexToChange].enteredValue = userEnteredValue;
    setArrayToSave(tempArr);

    // close and clean the popup
    setOpenPopupDialogFreeField(false);
    setEditedItemFreeField(0);
    setLabelAnswerFreeField(undefined);
  };

  const getUserEnteredValueAt = (answerParam, indexOfAnswer, displayAsIconWithTooltip = false) => {
    // if it's not a freefield or if the answer index doesn't exist or empty
    if (!answerParam.freeField || indexOfAnswer >= arrayToSave.length || isBlank(arrayToSave[indexOfAnswer].enteredValue)) {
      return '';
    }

    return displayAsIconWithTooltip
      ? <Tooltip title={arrayToSave[indexOfAnswer].enteredValue}><IconButton><MoreHorizIcon/></IconButton></Tooltip>
      :  ` (${arrayToSave[indexOfAnswer].enteredValue})`
  }

  if(!dataReady || !imageReady || !answersImagesReady || !batteryItemImagesReady) return null;

  return (
    <Container className={classes.positionRelative}>
      <DialogFreeField
        {...props}
        openPopupDialogFreeField={openPopupDialogFreeField}
        setOpenPopupDialogFreeField={setOpenPopupDialogFreeField}
        labelAnswerFreeField={labelAnswerFreeField}
        handleCloseDialogFreeField={handleCloseDialogFreeField}
      />
      <Grid container spacing={6}>
        {batteryBlockImage !== undefined &&
          <Grid item xs={12}>
            <Image
              imageUrl={batteryBlockImage}
              position={block.imagePosition}
              width={block.imageWidth}
            />
          </Grid>
        }
        {disabledMenu && <Grid item xs={12} md={8} className={classes.positionRelative}>
          <BarTimeout
            {...props}
            handleBarTimeoutExpired={handleBarTimeoutExpired}
          />
        </Grid>}
        {!disabledMenu && <Grid item xs={12} key={`Grid-${block.id}`}>
          {block.question.length > 0 && <div className="ql-view" style={{marginBottom: 20, marginTop: 10, }} dangerouslySetInnerHTML={{ __html: embeddedReplaceDynamicElements(block.question) }} />}
          {block.question.length > 0 && block.battery.mode === "carousel" && <Divider/>}
          {block.battery.mode !== "carousel" && arrayToSave.length > 0 ?  
          <TableContainer>
            <Table>
              <TableHead>
                {block.battery.mode === "row" ? 
                  <TableRow>
                    <TableCell></TableCell>
                    {block.battery.answers.map((answer, index) => (
                      <TableCell key={index} className={index === 0 ? classes.withLeftBorder : classes.withoutLeftBorder}>
                        {!block.battery.displayOnlyAnswerImages && embeddedReplaceDynamicElements(answer.text)}
                        {answer.imageId > 0 &&
                          <div style={{width: '100%', marginTop: 3}}>
                            <Image
                              imageUrl={getCorrespondingAnswerImage(answer.imageId)}
                              width={IMAGE_WIDTH_AUTO}
                              maxWidth={200}
                              maxHeight={200}
                              title={stripHtmlTags(embeddedReplaceDynamicElements(answer.text))}
                            /> 
                          </div>
                        }
                      </TableCell>
                    ))}
                    {block.battery.itemsStyle === "thisOrThat" && <TableCell></TableCell>}
                  </TableRow> : 
                  <TableRow>
                    <TableCell></TableCell>
                    {block.battery.items.map((item, index) => (
                      <TableCell key={index} className={index === 0 ? classes.withLeftBorder : classes.withoutLeftBorder}>
                        <div dangerouslySetInnerHTML={{ __html: embeddedReplaceDynamicElements(item.text)}}/>
                        {item.imageId > 0 &&
                          <div style={{width: '100%', marginTop: 3}}>
                            <Image
                              imageUrl={getCorrespondingItemImage(item.imageId)}
                              width={IMAGE_WIDTH_AUTO}
                              maxWidth={200}
                              maxHeight={200}
                              title={stripHtmlTags(embeddedReplaceDynamicElements(item.text))}
                            /> 
                          </div>
                        }
                      </TableCell>
                    ))}
                  </TableRow>
                }
              </TableHead>
              {block.battery.mode === "row" ?
                <TableBody>
                  {block.battery.items.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        {block.battery.itemsStyle === "thisOrThat" ? 
                          <div dangerouslySetInnerHTML={{ __html: embeddedReplaceDynamicElements(item.textLeft)}}/> : 
                          <div dangerouslySetInnerHTML={{ __html: embeddedReplaceDynamicElements(item.text)}}/>
                        }
                        {item.imageId > 0 &&
                          <div style={{width: '100%', marginTop: 3}}>
                            <Image
                              imageUrl={getCorrespondingItemImage(item.imageId)}
                              width={IMAGE_WIDTH_AUTO}
                              maxWidth={200}
                              maxHeight={200}
                              title={stripHtmlTags(embeddedReplaceDynamicElements(item.text))}
                            /> 
                          </div>
                        }
                        </TableCell>
                      {block.battery.answers.map((answer, subIndex) => (
                        <TableCell key={subIndex} className={subIndex === 0 ? classes.withLeftBorder : classes.withoutLeftBorder}>
                          <FormControlLabel 
                            className={classes.marginHorizontalZero} control={<ControlComponent checked={isSelected(answer, item)} onClick={() => selectAnswer(answer, item)}/>}
                          /> 
                          {getUserEnteredValueAt(answer, index, true)}
                        </TableCell>
                      ))}
                       {block.battery.itemsStyle === "thisOrThat" && <TableCell>
                        <div dangerouslySetInnerHTML={{ __html: embeddedReplaceDynamicElements(item.textRight)}}/>
                      </TableCell>}
                    </TableRow>
                  ))}
                </TableBody> :
                <TableBody>
                  {block.battery.answers.map((answer, index) => (
                    <TableRow key={index}>
                      <TableCell >
                        {!block.battery.displayOnlyAnswerImages && embeddedReplaceDynamicElements(answer.text)}
                        {answer.imageId > 0 &&
                          <div style={{width: '100%', marginTop: 3}}>
                            <Image
                              imageUrl={getCorrespondingAnswerImage(answer.imageId)}
                              width={IMAGE_WIDTH_AUTO}
                              maxWidth={200}
                              maxHeight={200}
                              title={stripHtmlTags(embeddedReplaceDynamicElements(answer.text))}
                            /> 
                          </div>
                        }
                        </TableCell>
                      {block.battery.items.map((item, subIndex) => (
                        <TableCell key={subIndex} className={subIndex === 0 ? classes.withLeftBorder : classes.withoutLeftBorder}>
                          <FormControlLabel 
                            className={classes.marginHorizontalZero} control={<ControlComponent checked={isSelected(answer, item)} onClick={() => selectAnswer(answer, item)}/>}
                          /> 
                          {getUserEnteredValueAt(answer, subIndex, true)}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              }
            </Table>
          </TableContainer> : block.battery.mode === "carousel" && arrayToSave.length > 0 &&
          <div>
             <div
              key={animationKey}
              className={step > 0 ? "animate" : undefined}
              style={{ textAlign: 'center'}} 
            >
              <div className={classes.textItem} dangerouslySetInnerHTML={{ __html: embeddedReplaceDynamicElements(block.battery.items[step].text)}}/> 
              {block.battery.items[step].imageId > 0 &&
                <Image
                  imageUrl={getCorrespondingItemImage(block.battery.items[step].imageId)}
                  width={IMAGE_WIDTH_AUTO}
                  maxWidth={400}
                  maxHeight={400}
                  title={stripHtmlTags(embeddedReplaceDynamicElements(block.battery.items[step].text))}
                  />
              }
            </div>
            {!isBlank(block.battery.instructions) && block.battery.mode === "carousel" && <div style={{marginTop: 30, marginBottom: -20, textAlign: 'center'}}>{block.battery.instructions}</div>}
            {nbColumnsCata !== 0 ? 
              <div style={{marginTop: 20}}>
                <Grid style={calculateWidthCol(nbColumnsCata)} container spacing={nbColumnsCata > 1 ? 3 : 0} columns={nbColumnsCata === 1 ? 3 : nbColumnsCata === 2 ? 6 : nbColumnsCata === 3 ? 9 : nbColumnsCata === 4 ? 12 : 15}>
                  {range(nbColumnsCata).map(indexCol => (
                    <Grid item xs={3} key={indexCol}>
                      {arrayCata[indexCol].length > 0 && arrayCata[indexCol].map((answer) => (
                        <div key={answer.id}>
                          {!block.battery.displayOnlyAnswerImages && <Button 
                            variant={arrayToSave[step].answerIds.includes(answer.id) ? "contained" : "outlined"} 
                            style={{width: '100%', textTransform: 'none', height: optimalCataButtonHeight, marginBottom: 13}} 
                            onClick={() => selectAnswer(answer, block.battery.items[step], block.battery.type === "single")}
                            key={answer.id}
                          >
                            {embeddedReplaceDynamicElements(answer.text)}{getUserEnteredValueAt(answer, step)}
                          </Button>}
                          {answer.imageId > 0 &&
                            <ImageButton
                              onClick={() => selectAnswer(answer, block.battery.items[step], block.battery.type === "single")}
                              focusRipple
                              title={`${stripHtmlTags(embeddedReplaceDynamicElements(answer.text))}${getUserEnteredValueAt(answer, step)}`}
                              style={{ 
                                width: '100%', 
                                border: arrayToSave[step].answerIds.includes(answer.id) ? '2px solid #003945' : '2px solid rgba(0,0,0,0)', 
                                marginTop: block.battery.displayOnlyAnswerImages ? 0 : -13, 
                                marginBottom: block.battery.displayOnlyAnswerImages ? 0 : 10, 
                                padding: 2 
                              }}
                            >
                              <img src={getCorrespondingAnswerImage(answer.imageId)} width={'auto'} style={{maxHeight: 200, maxWidth: 200}}/>
                            </ImageButton>
                          }
                        </div>
                      ))}
                    </Grid>
                  ))}
                </Grid>
              </div>
              : block.battery.itemsStyle === "slider" ?
                <div style={{marginTop: 20}}>
                  <CustomSlider 
                    identifier={block.battery.items[step].id}
                    selectAnswer={handleSelectAnswerCustomSlider}
                    answers={block.battery.answers.map(a => ({text: a.text, id: a.id}))}
                  />
                </div> :
              <div className={classes.containerButtonCarousel}>
                {block.battery.answers.map((answer, index) => (
                  <div style={{position: 'relative', display: 'flex', flexDirection: 'column', width: '100%'}} key={index}>
                    {!block.battery.displayOnlyAnswerImages &&
                      <Button 
                        key={index}
                        style={{margin: 10, width: '96%', marginLeft: '2%', height: '100%'}} 
                        variant={arrayToSave[step].answerIds.includes(answer.id) ? "contained": "outlined"}
                        onClick={() => selectAnswer(answer, block.battery.items[step], block.battery.type === "single")}
                        className={classes.button}
                      >{embeddedReplaceDynamicElements(answer.text)}{getUserEnteredValueAt(answer, step)}</Button>
                    } 
                    {answer.imageId > 0 &&
                      <ImageButton
                        onClick={() => selectAnswer(answer, block.battery.items[step], block.battery.type === "single")}
                        focusRipple
                        title={`${stripHtmlTags(embeddedReplaceDynamicElements(answer.text))}${getUserEnteredValueAt(answer, step)}`}
                        style={{ 
                          width: block.battery.displayOnlyAnswerImages ? '100%' : '96%',
                          marginLeft: block.battery.displayOnlyAnswerImages ? '0%' : '2%',
                          border: arrayToSave[step].answerIds.includes(answer.id) ? '2px solid #003945' : '2px solid rgba(0,0,0,0)',
                          marginTop: block.battery.displayOnlyAnswerImages ? 0 : -10,
                          padding: 2
                        }}
                      >
                        <img src={getCorrespondingAnswerImage(answer.imageId)} width={'auto'} style={{maxHeight: 200, maxWidth: 200}} />
                      </ImageButton>
                    }
                  </div>
                ))}
              </div>                          
            }
          </div>
          }           
        </Grid>}
      </Grid>
      {!disabledMenu && block.battery.mode === "carousel" && block.battery.displayStepper &&
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <MobileStepper
            variant="progress"
            steps={block.battery.items.length+1}
            position="static"
            activeStep={step+1}
            className={classes.mobileStepper}
          />
        </div>
      }
      {helperText()}
      {displayNextButton() &&
        <CustomNextButton
          {...props}
          disabled={isButtonDisabled()}
          handleNextBlock={() => saveBlock()}
        />
      }
      <style>
        {`
          @keyframes slideAnimation {
            from {
              opacity: 0;
            }
            to {
              opacity: 1;
            }
          }
          .animate {
            animation: slideAnimation 0.8s ease-in-out;
          }
        `}
      </style>
    </Container>
  )
};
