import React, { useState, useEffect, useMemo } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { makeStyles } from 'tss-react/mui';
import DialogTitleWithCloseIcon from '../shared/DialogTitleWithCloseIcon/DialogTitleWithCloseIcon.js';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ProjectService from '../../services/ProjectService';
import { AttachFile } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';

const projectService = new ProjectService();

const useStyles = makeStyles()(theme => ({
    textField: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
        cursor: 'none',
    },
}));

export default function ImportImageStimulusDialog(props) {

    const { classes } = useStyles();

    const { t, project, sequences, openImportVisual, setOpenImportVisual, openSnackbar,
        handleUploadStimulusVisualCallback, handleSelectStimulusVisualCallback, stimulusVisuals } = props;

    const [imageToImport, setImageToImport] = useState('');
    const [imageSelected, setImageSelected] = useState('');

    const [imageToBePreviewed, setImageToBePreviewed] = useState('');
    const [imagePreview, setImagePreview] = useState({show: false, src: ''});

    const openImagePreview = () => {
        if (imageSelected !== '') {
            projectService.openProjectFile('miscellaneous', imageToBePreviewed)
            .then(response => {
                setImagePreview({ show: true, src: response });
            })
            .catch(err => {
                setImagePreview({ show: false, src: undefined });
                openSnackbar('error', t("react.project.error.download.file"));
            });
        }
        else {
            setImagePreview({show: true, src: imageToBePreviewed});
        }
    }

    const isReadyToSubmit = useMemo(() => {
        if (imageToImport || imageSelected !== '') {
            openImagePreview();
            return true;
        }
        else {
            return false;
        }
    }, [imageSelected, imageToImport]);

    useEffect(() => {
        if(!openImportVisual) return;
        setImagePreview('');
        setImageSelected('')
        setImagePreview({show:false, src:''});
        setImageToBePreviewed('');
    }, [openImportVisual]);
    
    const handleSelectUploadFile = (event) => {
        event.preventDefault();
        const file = event.target.files[0];
        document.getElementById('contained-button-file_4').value = '';
        
        if (file && file.type.startsWith('image/')) {
            setImageSelected('');
            setImageToImport(file);
            setImageToBePreviewed(URL.createObjectURL(file));
        } 
        else {
            openSnackbar('error', t('react.project.dashboard.importimagedialog.wrong.format'));
        }
    };
    
    const handleSelectImage = (event) => {
        event.preventDefault();
        setImageToImport('');
        setImageSelected(event.target.value);
        setImageToBePreviewed(event.target.value);
    };

    const handleApply = (event) => {
        event.preventDefault();
        if (imageToImport !== '') {
            let imageToUpload = new File([imageToImport], imageToImport.name, { type: imageToImport.type });
            handleclose();

            handleUploadStimulusVisualCallback(imageToUpload, openImportVisual.stimulus);
        }
        else {
            handleclose();

            handleSelectStimulusVisualCallback(imageSelected, openImportVisual.stimulus);
        }
    }

    const handleclose = () => {
        setOpenImportVisual({ open: false, stimulus: undefined });
        setImageToImport('');
        setImageSelected('');
        setImagePreview({show: false, src: undefined});
    }

    if(!openImportVisual) return null;
    return (
        <Dialog maxWidth={'sm'} fullWidth={true} onClose={handleclose} open={openImportVisual.open} >
            <DialogTitleWithCloseIcon
                title={t("react.dashboard.import.image.popup.title")}
                callbackOnclose={handleclose}
            />
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={8} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                        <FormControl style={{width: '100%', marginTop: '20px'}}>
                            <InputLabel id="demo-simple-select-label">{t('react.dashboard.import.image.popup.select.existing.image')}</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={imageSelected}
                                label="Image"
                                className={classes.textField}
                                onChange={handleSelectImage}
                            >
                                <MenuItem value={''}>{t('react.dashboard.import.image.popup.selectimage')}</MenuItem>
                                {stimulusVisuals.map((f, index) => {
                                    return (<MenuItem key={index} value={f.id}>{f.name}</MenuItem>);
                                })}
                            </Select>
                        </FormControl>
                        <Typography component={'h3'} style={{margin: 20, fontWeight: 'bold'}}>{t('react.dashboard.import.image.popup.or')}</Typography>
                        <FormControl style={{ width: '100%' }}>
                            <InputLabel id="translateInputFileName">{t('react.dashboard.import.image.popup.select.local.image')}</InputLabel>
                            <Input
                                value={imageToImport !== '' ? imageToImport.name : ''}
                                disabled
                                className={classes.textField}
                                fullWidth
                                endAdornment={
                                    <InputAdornment position="end">
                                        <label htmlFor="contained-button-file_4">
                                            <Button variant="contained" color="primary" component="span" style={{marginLeft: 10}}>
                                                {t("react.dashboard.import.image.popup.chose")}
                                            </Button>
                                        </label>
                                    </InputAdornment>
                                    }
                                />
                            <input
                                accept="image/*"
                                onChange={handleSelectUploadFile}
                                style={{ display: 'none' }}
                                id="contained-button-file_4"
                                type="file"
                           />
                        </FormControl>
                    </Grid>
                    <Grid item xs={4} sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <img src={imagePreview.src} style={{ maxHeight: 130, maxWidth: '100%', objectFit: 'cover', borderRadius: 4 }} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleclose}>{t('react.button.cancel')}</Button>
                <Button variant="contained" startIcon={imageToImport !== '' ? <CloudUploadIcon /> : <AttachFile />} onClick={handleApply} disabled={!isReadyToSubmit}>{imageToImport !== '' ? t("react.dashboard.import.image.popup.send") : t("react.generic.select")}</Button>
            </DialogActions>
        </Dialog>
    );
}
