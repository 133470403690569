import React, { useState, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { toolbarStyles, FilterIcon, ExportIcon } from '../../common.js';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitleWithCloseIcon from '../shared/DialogTitleWithCloseIcon/DialogTitleWithCloseIcon';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import SettingsIcon from '@mui/icons-material/Settings';
import FilterDialog from './FilterDialog';
import InboxIcon from '@mui/icons-material/Inbox';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import { findById, indexOf } from '../../utils/utils.js';
import CompareIcon from '@mui/icons-material/Compare';
import { AuthService } from '../../services/AuthService';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import SecurityIcon from '@mui/icons-material/Security';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import WidgetsIcon from '@mui/icons-material/Widgets';
import SelectStimulusDialog from './SelectStimulusDialog'
import RestoreIcon from '@mui/icons-material/Restore';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Badge from '@mui/material/Badge';
import ConfigurationDialog from './ConfigurationDialog';
import WebIcon from '@mui/icons-material/Web';
import { TOTAL_EXCEPT_STIMULUS_ID } from '../../Constants.js';
import { RiAiGenerate } from "react-icons/ri";
import Menu from '@mui/material/Menu';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import SummarizeIcon from '@mui/icons-material/Summarize';

const extendedStyles = makeStyles()(theme => ({
    MuiBadgeDot: {
        backgroundColor: '#ff9800'
    },
    inboxIcon: {
        verticalAlign: 'sub',
        marginRight: '8px',
    },
    buttonsAIAnalysis: {
        display: 'flex', 
        flexDirection: 'column'
    }
}));

export default function DashboardToolbar(props) {

    const { t, project, configuration, configurations, setConfiguration, 
        handleClickDownloadDashboardDataFile, handleClickClearProjectCaches, 
        showAiAnalysis, setShowAiAnalysis, aiAnalysisLoading} = props;

    const { classes } = toolbarStyles();
    const { classes: classesExtended } = extendedStyles();

    const [openSettingsDialog, setOpenSettingsDialog] = useState(false);
    const [openSaveDialog, setOpenSaveDialog] = useState(false);
    const [openResetDialog, setOpenResetDialog] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openFilterDialog, setOpenFilterDialog] = useState(false);
    const [openCompareWithDialog, setOpenCompareWithDialog] = useState(false);
    const [openSelectStimulusDialog, setOpenSelectStimulusDialog] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(-1);
    const [anchorEl, setAnchorEl] = useState(null)

    useEffect(() => {
        if (configuration && configurations) {
            const currentindex = indexOf(configurations, "id", configuration.id);
            setCurrentIndex(currentindex);
        }
    }, [configurations, configuration]);

    const goToAnalytics = () => {
        props.navigate(`/projects/${project.id}/dashboard/analytics`);
    }

    const openAnchor = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    const openAiAnalysisPopup = (option) => {
        setShowAiAnalysis(option);
        setAnchorEl(null);
    }

    return (
        <>
        <Link href='#' onClick={() => props.navigate(`/projects/${project.id}`)} style={{ width: '100%', color: "black" }}>
            <Typography variant="h6" id="projectTitle" className={classes.title} style={{ position: 'relative', left: '10px', minWidth: '500px' }}>
            <InboxIcon className={classesExtended.inboxIcon} />
                {project.name}
                {configuration.hidden.length > 0 &&
                    <Tooltip title={t('react.dashboard.toolbar.title.warning.hiddencards')}><span>
                        <IconButton size='small'>
                            <Badge badgeContent={configuration.hidden.length} color="error" overlap="rectangular">
                                <VisibilityOffIcon />
                            </Badge>
                        </IconButton></span>
                    </Tooltip>
                }
            </Typography>
        </Link>
        <Toolbar className={classes.rootDashboard}>
            {(configurations ? configurations.length : 0) > 0 &&
                <FormControl className={classes.formControlDashboard}>
                    <Select
                        labelId='selectconfig-label'
                        id='selectconfig'
                        value={currentIndex >= 0 ? (configurations[currentIndex] || '') : ''}
                        onChange={(e) => {
                            setConfiguration(e.target.value)
                        }}
                        style={{ position: 'relative', top: '-2px' }}
                    >
                        {configurations.map(c => <MenuItem key={c.id} value={c}>{c.id <= 0 ? t('react.dashboard.toolbar.configuration.default.name') : c.name}</MenuItem>)}
                    </Select>
                </FormControl>
            }
            <Tooltip title={t('react.dashboard.toolbar.button.save.configuration')}><span>
                {/* Display a badge with a '1' to attract the attention to save the configuration */}
                <Badge badgeContent={configuration.updated && configurations.length <= 1 ? 1 : 0} color="error" overlap="rectangular">
                    <IconButton size='small' onClick={() => setOpenSaveDialog(true)} >
                        {configuration.updated ? <SaveIcon color='error' /> : <SaveIcon />}
                    </IconButton>
                </Badge></span>
            </Tooltip>
            <Tooltip title={t('react.dashboard.toolbar.button.reset.configuration')}><span>
                <IconButton size='small' onClick={() => setOpenResetDialog(true)} >
                    <RotateLeftIcon />
                </IconButton></span>
            </Tooltip>
            <Tooltip title={t('react.dashboard.toolbar.button.delete.configuration')}><span>
                <IconButton size='small' onClick={() => setOpenDeleteDialog(true)} disabled={configuration.id < 0}>
                    <DeleteIcon />
                </IconButton></span>
            </Tooltip>
            <Divider orientation="vertical" variant='middle' light={true} className={classes.divider} />
            <Tooltip title={t('react.dashboard.toolbar.button.configuration.settings')}>
                <IconButton size='small' onClick={() => setOpenSettingsDialog(true)}>
                    <SettingsIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title={t('react.dashboard.toolbar.button.select.sequences')}><span>
                <IconButton size='small' onClick={() =>
                    setOpenSelectStimulusDialog(true)}>
                    <WidgetsIcon />
                </IconButton></span>
            </Tooltip>
            <Tooltip title={t('react.dashboard.toolbar.button.global.comparewith')}><span>
                <IconButton size='small' onClick={() =>
                    setOpenCompareWithDialog(true)}>
                    <CompareIcon />
                </IconButton></span>
            </Tooltip>
            <Tooltip title={t('react.dashboard.toolbar.button.global.filters')}><span>
                <IconButton size='small' onClick={() =>
                    setOpenFilterDialog(true)} >
                    <FilterIcon />
                </IconButton></span>
            </Tooltip>
            {!AuthService.isVisitor() &&
                <Tooltip title={t('react.dashboard.toolbar.button.global.analytics')}><span>
                    <IconButton size='small' onClick={() => goToAnalytics()} disabled={project.state !== 'completed'}>
                        <WebIcon />
                    </IconButton></span>
                </Tooltip>
            }
            <Tooltip title={t('react.dashboard.toolbar.button.global.exportallimages')}><span>
                <IconButton size='small' onClick={() => props.handleSaveAll()}>
                    <PermMediaIcon />
                </IconButton></span>
            </Tooltip>
            {(project.userId === AuthService.getUser().id || AuthService.isManager()) &&
                <Tooltip title={t('react.dashboard.toolbar.button.export')}>
                    <IconButton size='small' onClick={handleClickDownloadDashboardDataFile}>
                        <ExportIcon />
                    </IconButton>
                </Tooltip>
            }
            {AuthService.hasAccessToAIFeatures() &&
             <>
                <Tooltip title={t('react.dashboard.toolbar.button.global.chatgpt')}><span>
                    <IconButton size='small' onClick={(e) => openAnchor(e)}>
                        <RiAiGenerate size={24}/>
                    </IconButton></span>
                </Tooltip>
                <Menu
                    open={anchorEl !== null}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <MenuItem disabled={showAiAnalysis === 1 || aiAnalysisLoading} onClick={() => openAiAnalysisPopup(1)}>
                        <ListItemIcon>
                            <ViewCarouselIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>{t('react.dashboard.toolbar.button.popover.option1')}</ListItemText>
                    </MenuItem>
                    <MenuItem disabled={showAiAnalysis === 2 || aiAnalysisLoading} onClick={() => openAiAnalysisPopup(2)}>
                        <ListItemIcon>
                            <SummarizeIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>{t('react.dashboard.toolbar.button.popover.option2')}</ListItemText>
                    </MenuItem>
                </Menu>
              </>
            }
            {AuthService.isAdmin() &&
                <Tooltip title={t('Nettoyer le cache')} >
                    <IconButton size='small'
                        onClick={() => handleClickClearProjectCaches()}
                    >
                        <RestoreIcon style={{ color: '#F00' }} />
                    </IconButton>
                </Tooltip>
            }
            {AuthService.hasRightToManageGrouping() && /* Manager with the GroupingManagement Role can access to this resource */
                <Tooltip title={t('Modifier les regroupements')}><span>
                    <IconButton size='small' disabled={project.state !== 'grouping' && project.state !== 'completed'}
                        onClick={() => props.navigate(`/grouping/${project.id}`)}>
                        <BubbleChartIcon style={{ color: '#F00' }} />
                    </IconButton></span>
                </Tooltip>
            }
            {AuthService.isAdmin() &&
                <Tooltip title={t('react.dashboard.toolbar.button.project.admin')}>
                    <IconButton size='small' onClick={() => props.navigate(`/projects/${project.id}/admin`)}>
                        <SecurityIcon style={{ color: '#F00' }} />
                    </IconButton>
                </Tooltip>
            }
            <SaveConfigurationDialog
                {...props}
                open={openSaveDialog}
                configuration={configuration}
                handleClose={e => setOpenSaveDialog(false)}
                handleApply={e => props.handleSaveConfiguration(e)}
            />
            <ResetConfigurationDialog
                {...props}
                open={openResetDialog}
                configuration={configuration}
                handleClose={e => setOpenResetDialog(false)}
                handleApply={e => props.handleResetConfiguration(e)}
            />
            <DeleteConfigurationDialog
                {...props}
                open={openDeleteDialog}
                configuration={configuration}
                handleClose={e => setOpenDeleteDialog(false)}
                handleApply={e => props.handleDeleteConfiguration(e)}
            />
            <ConfigurationDialog
                {...props}
                open={openSettingsDialog}
                configuration={configuration}
                handleClose={e => setOpenSettingsDialog(false)}
                handleApply={e => props.handleChangeConfigurationSettings(e)}
            />
            <FilterDialog
                {...props}
                open={openFilterDialog}
                configuration={configuration}
                handleClose={e => setOpenFilterDialog(false)}
                handleApply={e => props.handleApplyConfiguration(e)}
            />
            <GlobalCompareWithDialog
                {...props}
                open={openCompareWithDialog}
                compareWiths={props.compareWiths}
                configuration={configuration}
                handleClose={e => setOpenCompareWithDialog(false)}
                handleApply={e => props.handleApplyConfiguration(e)}
            />
            <SelectStimulusDialog
                {...props}
                open={openSelectStimulusDialog}
                configuration={configuration}
                handleClose={e => setOpenSelectStimulusDialog(false)}
                handleApply={e => props.handleApplyConfiguration(e)}
            />
        </Toolbar >
        </>
    );
};

function SaveConfigurationDialog(props) {
    const { t, configuration } = props;

    const [name, setName] = useState('');

    const handleApply = (e) => {
        const newConfiguration = { ...configuration };
        if (newConfiguration.name !== (name)) newConfiguration.id = -1;
        newConfiguration.name = (name);
        props.handleClose(e);
        return newConfiguration;
    }

    useEffect(() => {
        if (configuration && configuration.id >= 0) {
            setName(configuration.name);
        }
        else setName('');
    }, [configuration]);

    return (
        <form noValidate autoComplete="off">
            <Dialog
                fullWidth={true}
                maxWidth='xs'
                open={props.open}
                onClose={props.handleClose}
            >
                <DialogTitleWithCloseIcon
                    title={t('react.grouping.saveconfigurationdialog.title')}
                    callbackOnclose={props.handleClose}
                />
                <DialogContent>
                    <TextField fullWidth onChange={e => { setName(e.target.value) }} label={t('react.grouping.saveconfigurationdialog.label')} defaultValue={name || ''} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleClose} color="primary">
                        {t('react.button.cancel')}
                    </Button>
                    <Button disabled={!name} onClick={(e) => props.handleApply(handleApply(e))} autoFocus>
                        {t('react.button.save')}
                    </Button>
                </DialogActions>
            </Dialog>
        </form>
    )
}

function ResetConfigurationDialog(props) {
    const { t, configuration } = props;

    const handleApply = (e) => {
        props.handleClose(e);
        return e;
    }

    return (
        <form noValidate autoComplete="off">
            <Dialog
                fullWidth={true}
                maxWidth='xs'
                open={props.open}
                onClose={props.handleClose}
            >
                <DialogTitleWithCloseIcon
                    title={t('react.grouping.resetconfigurationdialog.title')}
                    callbackOnclose={props.handleClose}
                />
                <DialogContent>
                    {t('react.grouping.resetconfigurationdialog.content')}
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleClose} color="primary">
                        {t('react.button.cancel')}
                    </Button>
                    <Button onClick={(e) => props.handleApply(handleApply(configuration))} autoFocus>
                        {t('react.button.confirm')}
                    </Button>
                </DialogActions>
            </Dialog>
        </form>
    )
}

function DeleteConfigurationDialog(props) {
    const { t, configuration } = props;

    const handleApply = (e) => {
        props.handleClose(e);
        return e;
    }

    return (
        <form noValidate autoComplete="off">
            <Dialog
                fullWidth={true}
                maxWidth='xs'
                open={props.open}
                onClose={props.handleClose}
            >
                <DialogTitleWithCloseIcon
                    title={t('react.grouping.deleteconfigurationdialog.title')}
                    callbackOnclose={props.handleClose}
                />
                <DialogContent>
                    {t('react.grouping.deleteconfigurationdialog.content')}
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleClose} color="primary">
                        {t('react.button.cancel')}
                    </Button>
                    <Button onClick={(e) => props.handleApply(handleApply(configuration))} autoFocus>
                        {t('react.button.delete')}
                    </Button>
                </DialogActions>
            </Dialog>
        </form>
    )
}

const useStyles = makeStyles()(theme => ({
    compareWithRoot: {
        width: 300
    },
    comparewithSelector: {
        marginLeft: theme.spacing(1),
        marginBottom: theme.spacing(1),
        width: '95%',
    },
}));

function GlobalCompareWithDialog(props) {
    const { t, configuration, compareWiths, stimuli, sequences, dashboardService } = props;

    const { classes } = useStyles();

    const [compareWith, setCompareWith] = useState();

    const handleApply = (e) => {
        const newConfiguration = { ...configuration };

        // reset configuration
        newConfiguration.compareWith = {};
        newConfiguration.globalCompareWith = null;

        if (compareWith) {
            // apply selected to all real stimuli
            compareWiths.filter(s => s.id > 0).forEach(s => {
                if (s.id !== compareWith.id) {
                    newConfiguration.compareWith[s.id] = compareWith;
                }
            });
            newConfiguration.globalCompareWith = compareWith.id;
        }

        newConfiguration.updated = true;
        props.handleClose(e);
        return newConfiguration;
    }

    const handleCompareWith = event => {
        setCompareWith(event.target.value);
    };

    useEffect(() => {
        setCompareWith(findById(compareWiths, configuration.globalCompareWith));
    }, [configuration, compareWiths]);

    return (
        <form noValidate autoComplete="off">
            <Dialog
                fullWidth={true}
                maxWidth='xs'
                open={props.open}
                onClose={props.handleClose}
            >
                <DialogTitleWithCloseIcon
                    title={t('react.grouping.comparewithdialog.title')}
                    callbackOnclose={props.handleClose}
                />
                <DialogContent className={classes.compareWithRoot}>
                    <FormControl className={classes.comparewithSelector}>
                        <TextField
                            select
                            label={t('react.dashboard.compare.with')}
                            id={'global_compare'}
                            value={!compareWith ? '' : compareWith}
                            onChange={e => handleCompareWith(e)}
                        >
                            <MenuItem value="">
                                <em>{t('react.generic.text.none')}</em>
                            </MenuItem>
                            {/* NOTE: XF20200420 The compare with full total is now disabled, only Total Except */}
                            <MenuItem value={findById(compareWiths, TOTAL_EXCEPT_STIMULUS_ID)} key={TOTAL_EXCEPT_STIMULUS_ID}>
                                {t('react.projectdashboard.totaloverallexcept.title')}
                            </MenuItem>
                            {compareWiths
                                // display only true stimulus & clones
                                .filter(s => s.id > 0)
                                .map(s => {
                                    let name = dashboardService.getStimulusName(s, configuration, stimuli, sequences, t);
                                    return { embeddedStimulus: s, embeddedName: name };
                                })
                                .sort((it1, it2) => it1.embeddedName.localeCompare(it2.embeddedName))
                                .map(it => (
                                    <MenuItem key={it.embeddedStimulus.id} value={it.embeddedStimulus}>{it.embeddedName}</MenuItem>
                                ))
                            }
                        </TextField>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleClose} color="primary">
                        {t('react.button.cancel')}
                    </Button>
                    <Button onClick={(e) => props.handleApply(handleApply(e))} autoFocus>
                        {t('react.button.save')}
                    </Button>
                </DialogActions>
            </Dialog>
        </form>
    )
}
