import axios from 'axios'
require('dotenv').config()

const promptAxiosService = axios.create({
  baseURL: process.env.REACT_APP_R3MSCORE_API_IABRIDGE_BASEURL,
  headers: { 
    'Content-Type': 'application/json',
  },
});

promptAxiosService.defaults.headers.common['Authorization'] = 'Bearer ' + process.env.REACT_APP_R3MSCORE_API_IABRIDGE_TOKEN;

class PromptsService {

  // -- ADMINISTRATIVE METHODS - Auth required -------------------------------------------------------------------------------

  async fetchPrompts(page, rowsPerPage, sortBy, sortDirection, keywords) {
    return promptAxiosService.get('/prompts/v1.0/?from=' + (page*rowsPerPage) + '&size=' + rowsPerPage + '&sort=' + sortBy+ '&order=' + sortDirection + '&keywords=' + encodeURIComponent(keywords));
  }

  async fetchAllPromptsByType(ofType) {
    return promptAxiosService.get('/prompts/v1.0/?from=0&size=100&type=' + encodeURIComponent(ofType));
  }

  async fetchAllPromptsByTypeAndLang(ofType, lang) {
    return promptAxiosService.get('/prompts/v1.0/?from=0&size=100&type=' + encodeURIComponent(ofType) + '&lang=' + lang);
  }

  async deletePrompt(promptId) {
    return promptAxiosService.delete(`/prompts/v1.0/${promptId}`);
  }

  async fetchPrompt(promptId) {
      return promptAxiosService.get(`/prompts/v1.0/${promptId}`);
  }

  async savePrompt(promptId, prompt) {
    if(promptId > 0) {
      // existing prompt
      return promptAxiosService.put(`/prompts/v1.0/${promptId}`, prompt);
    } else {
      // new prompt
      return promptAxiosService.post('/prompts/v1.0/', prompt);
    }
  }

  async askSimpleQuestion(lang, question) {
    const data = {lang: lang, question: question};
    return promptAxiosService.post('/questions/v1.0/simple', data);
  }
}

export default PromptsService;
