import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import { TreeItem, SimpleTreeView } from '@mui/x-tree-view'
import CodificationService from '../../services/CodificationService'
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import {toolbarStyles, ExportIcon} from '../../common.js';
import { findById, orderBy, capitalizeFirstLetter } from '../../utils/utils.js';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import CachedIcon from '@mui/icons-material/Cached';
import EditLibraryDialog from './EditLibraryDialog';
import MoveLibraryDialog from './MoveLibraryDialog';
import CodificationTable from './CodificationTable';
import ImportLibraryDialog from './ImportLibraryDialog';
import PopupTwoButtons from '../shared/PopupTwoButtons/PopupTwoButtons';
import FolderIcon from '@mui/icons-material/Folder';
import Divider from '@mui/material/Divider';
import MoreIcon from '@mui/icons-material/More';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import EditIcon from '@mui/icons-material/Edit';
import BackupIcon from '@mui/icons-material/Backup';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import {Helmet} from "react-helmet";
import Flag from "../shared/Flag/Flag";

const codificationService = new CodificationService();

const useStyles = makeStyles()(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  label: {
    fontWeight: 'inherit',
    color: 'inherit',
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
  },
  labelIcon: {
    marginRight: theme.spacing(1),
    color: '#ffb74d'
  },
  labelText: {
    fontWeight: 'inherit',
    flexGrow: 1,
  },
  divider: {
      margin: theme.spacing(3, 3),
    },
  treeView: {
      marginLeft: '24px',
      marginRight: '24px',
  },
}));

const TreeToolbar = props => {
  const { classes } = toolbarStyles();
  const { t, libraryId, setLoadLibrariesData, handleEditLibrary, handleDeleteLibrary, handleMoveLibrary, handleExportLibrary, handleImportLibrary } = props;

  const handleClickRefresh = (event) => {
    event.preventDefault();
    setLoadLibrariesData(true);
  };

  return (
    <Toolbar>
      <Typography className={classes.title} variant="h6" id="tableTitle">
        {t('react.dashboard.menu.codification')}
      </Typography>
      <Tooltip title={t("react.codification.button.edit.hint")}>
        <span>
        <IconButton
          onClick={e => handleEditLibrary(e, libraryId)}
          disabled={libraryId <= 0}
          size="large">
          <EditIcon />
        </IconButton>
        </span>
      </Tooltip>
      <Tooltip title={t("react.codification.button.move.hint")}>
        <span>
        <IconButton
          onClick={handleMoveLibrary}
          disabled={libraryId <= 0}
          size="large">
          <MoreIcon style={{transform: 'scaleX(-1)'}} />
        </IconButton>
        </span>
      </Tooltip>
      <Tooltip title={t("react.codification.button.delete.hint")}>
        <span>
        <IconButton
          onClick={handleDeleteLibrary}
          disabled={libraryId <= 0}
          size="large">
          <DeleteIcon />
        </IconButton>
        </span>
      </Tooltip>
      <Tooltip title={t("react.codification.button.add.hint")}>
        <IconButton onClick={e => handleEditLibrary(e, 0)} size="large">
          <CreateNewFolderIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={t("react.codification.button.import.library.hint")}>
        <span>
        <IconButton
          onClick={handleImportLibrary}
          disabled={libraryId <= 0}
          size="large">
          <BackupIcon />
        </IconButton>
        </span>
      </Tooltip>
      <Tooltip title={t("react.codification.button.export.library.hint")}>
        <span>
        <IconButton
          onClick={handleExportLibrary}
          disabled={libraryId <= 0}
          size="large">
          <ExportIcon />
        </IconButton>
        </span>
      </Tooltip>
      <Tooltip title={t("react.codification.button.dictionay.hint")}>
        <IconButton
          onClick={() => props.navigate('/codification/dictionay')}
          size="large">
          <MenuBookIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={t("react.codification.button.refresh.hint")}>
        <IconButton onClick={handleClickRefresh} size="large">
          <CachedIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
};

export default function Codification(props) {

  const { t, showSpinner, openSnackbar } = props;

  const { classes } = useStyles();

  // tree of libraries
  const [libraries, setLibraries] = useState([]);
  const [loadLibrariesData, setLoadLibrariesData] = useState(true);
  const [supportedLanguages, setSupportedLanguages] = useState(null);
  const [paths, setPaths] = useState(new Map());

  // current selected library Id
  const [libraryId, setLibraryId] = useState(0);
  const [loadData, setLoadData] = useState(false);

  useEffect(() => {
    if(loadLibrariesData) {
      codificationService.fetchLibraries()
      .then(result => {
        setLibraries(result.data.hits);
        setPaths(result.data.paths);
        setLoadLibrariesData(false);
        if(libraryId > 0) {
          setLoadData(true);
        }
      })
      .catch(err => {
        openSnackbar('error', t("react.codification.error.while.loading"));
        setLoadLibrariesData(false);
      });
    }
  }, [loadLibrariesData]);

  useEffect(() => {
    codificationService.getSupportedLanguages()
    .then(result => {
      setSupportedLanguages(result.data);
    })
    .catch(err => {
      openSnackbar('error', t("react.codification.error.while.loading"));
      setSupportedLanguages([]);
    });
  }, []);

  const handleSelectLibrary = (event, libraryId) => {
    event.preventDefault();
    setLibraryId(libraryId);
    setLoadData(true);
  };

  const [editLibraryId, setEditLibraryId] = useState(0);
  const [openEditLibraryDialog, setOpenEditLibraryDialog] = useState(false);
  const handleEditLibrary = (event, editLibraryId) => {
    event.preventDefault();
    setEditLibraryId(editLibraryId);
    setOpenEditLibraryDialog(true);
  };
  const callbackAfterConfirmEditLibrary = () => {
    setOpenEditLibraryDialog(false);
    setLoadLibrariesData(true);
  };
  const callbackAfterCancelEditLibrary = () => {
    setOpenEditLibraryDialog(false);
  }

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const handleDeleteLibrary = (event) => {
    event.preventDefault();
    setDeleteDialogOpen(true)
  };
  const handleDeleteDialogCancel = () => {
    setDeleteDialogOpen(false);
  }
  const handleDeleteDialogConfirm = () => {
    codificationService.deleteLibrary(libraryId)
    .then(result => {
      setLibraryId(0);
      setDeleteDialogOpen(false);
      setLoadLibrariesData(true);
    })
    .catch(err => {
      setDeleteDialogOpen(false);
      openSnackbar('error', t("react.codification.error.while.deleting"));
    });
  };

  const handleExportLibrary = () => {
    showSpinner(true);
    openSnackbar('info', t("react.codification.toolbar.button.export.starting"));

    codificationService.downloadLibraryDataFile(libraryId)
      .then(response => {
        var regExp = new RegExp('filename="([^"]+)"', 'i');
        let filename = regExp.exec(response.headers['content-disposition'])[1];
        let url = window.URL.createObjectURL(new Blob([response.data]));
        let link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        openSnackbar('success', t("react.codification.toolbar.button.export.finished"));
      })
      .catch(err => {
        openSnackbar('error', t("react.codification.toolbar.button.export.error"));
      })
      .finally(err => {
        showSpinner(false);
      });
  };

  const [openMoveLibraryDialog, setOpenMoveLibraryDialog] = useState(false);
  const handleMoveLibrary = (event) => {
    event.preventDefault();
    setOpenMoveLibraryDialog(true);
  };
  const callbackAfterConfirmMoveLibrary = (moveToLibraryId) => {
    if(libraryId === moveToLibraryId) {
      openSnackbar('error', t("react.codification.error.move.library"));
      return;
    }

    codificationService.fetchLibrary(libraryId)
    .then(result => {
      var libraryAsJson = result.data;
      libraryAsJson.parentLibraryId = moveToLibraryId;
      codificationService.updateLibrary(libraryId, libraryAsJson)
      .then(result => {
        setOpenMoveLibraryDialog(false);
        setLoadLibrariesData(true);
      })
      .catch(err => {
        openSnackbar('error', t("react.codification.error.while.saving"));
          setOpenMoveLibraryDialog(false);
        });
    })
    .catch(err => {
      openSnackbar('error', t("react.codification.error.while.loading"));
      setOpenMoveLibraryDialog(false);
    });
  };
  const callbackAfterCancelMoveLibrary = () => {
    setOpenMoveLibraryDialog(false);
  }

  const [openImportLibraryDialog, setOpenImportLibraryDialog] = useState(false);
  const handleImportLibrary = (event) => {
    event.preventDefault();
    setOpenImportLibraryDialog(true);
  };
  const callbackAfterConfirmImportLibrary = (formData) => {
    if(libraryId === 0 || !formData) {
      openSnackbar('error', t("react.codification.error.import.library"));
      return;
    }

    showSpinner(true);
    setOpenImportLibraryDialog(false);
    openSnackbar('info', t("react.codification.toolbar.button.import.starting"));

    codificationService.importLibraryDataFile(libraryId, formData)
    .then(result => {
      openSnackbar('success', t("react.codification.toolbar.button.import.started"));
    })
    .catch(err => {
      openSnackbar('error', t("react.codification.error.while.importing"));
    })
    .finally(err => {
      showSpinner(false);
    });
  };
  const callbackAfterCancelImportLibrary = () => {
    setOpenImportLibraryDialog(false);
  }

  // -- sort directories with sense -------------------------

  function containsOnlyProjectIdRanges(libraries) {
    // regex to match ranges is '101-200' or '1001-1100', etc...
    const regex = /^\d+-\d+$/;

    // returns true if all libraries match the given format
    return libraries.length === libraries.filter(lib => regex.test(lib.name)).length;
  };

  function projectIdRangesComparator(lib1, lib2) {
    const num1 = parseInt(lib1.name.split("-")[0]);
    const num2 = parseInt(lib2.name.split("-")[0]);
    if (num1 < num2) {
      return -1;
    } else if (num1 > num2) {
      return 1;
    } else {
      return dir1.localeCompare(dir2);
    }
  };

  function isOnlyNumericalIds(libraries) {
    // regex to match '123', '456', etc...
    const regex = /^\d+$/;

    // returns true if all libraries match the given format
    return libraries.length === libraries.filter(lib => regex.test(lib.name)).length;
  };

  function onlyNumericalIdsComparator(lib1, lib2) {
    const num1 = parseInt(lib1.name);
    const num2 = parseInt(lib2.name);
    if (num1 < num2) {
      return -1;
    } else if (num1 > num2) {
      return 1;
    } else {
      return dir1.localeCompare(dir2);
    }
  };

  function RecursiveTreeItem({library, libraries}) {
    // get sub libraries
    var subLibrairies = libraries.filter(lib => lib.parentLibraryId === library.id);

    // well sort sub libraries
    if(containsOnlyProjectIdRanges(subLibrairies)) {
      subLibrairies.sort(projectIdRangesComparator);
    } else if (isOnlyNumericalIds(subLibrairies)) {
      subLibrairies.sort(onlyNumericalIdsComparator);
    } else {
      subLibrairies = orderBy(subLibrairies, 'name', false, true);
    }

    var subTreeItems = subLibrairies.map(lib => {
      return <RecursiveTreeItem key={lib.id} libraries={libraries} library={lib}/>
    });

    return (
      <TreeItem
        key={library.id}
        itemId={''+library.id}
        label={
          <div className={classes.labelRoot} onClick={event => handleSelectLibrary(event, library.id)}>
            <FolderIcon color="inherit" className={classes.labelIcon} />
            <Tooltip placement='top-start' title={`${library.id} - ${library.name}`}>
              <Typography variant="body2" className={classes.labelText}>
                {capitalizeFirstLetter(library.name)}{library.description !== undefined && library.description.length > 0 && library.description !== library.name ? ' ('+library.description+')': ''}
                {library.optionalLanguage && <>&nbsp;-&nbsp;<Flag language={library.optionalLanguage} height={12}/></>}
              </Typography>
            </Tooltip>
          </div>
        }
      >
        {subTreeItems}
      </TreeItem>
    )
  };

  const getDefaultExpendedLevels = () => {
    // XF200211129: just deploy the root because there is too many entries now
    let r = ['0'];
    /*
    // deploy level 1
    let r = libraries.filter(l => l.parentLibraryId == 0).map(l => String(l.id)).concat(['0']);

    // deploy tree to selection if any
    if(libraryId > 0) {
      r.push(String(libraryId));
      var targetId = libraryId;
      while(targetId > 0) {
        var targetLib = libraries.filter(l => l.id == targetId)[0];
        r.push(String(targetLib.parentLibraryId));
        targetId = targetLib.parentLibraryId;
      }
    }
    */

    return r;
  };

  const getDefaultSelectedLevel = () => {
    return [String(libraryId)];
  };

  return (
    <div className={classes.root}>
      <Helmet title={t('react.helmet.codification')} />
      { loadLibrariesData ? ( <LinearProgress /> )
      : (
      <Paper className={classes.paper}>
        <PopupTwoButtons
            variant='warning'
            openState={deleteDialogOpen}
            callbackOnclose={handleDeleteDialogCancel}
            callbackOnclickLeftButton={handleDeleteDialogCancel}
            callbackOnclickRightButton={handleDeleteDialogConfirm}
            title={t('react.codification.deletedialog.title')}
            content={t('react.codification.deletedialog.body')}
            leftButton={t('react.button.cancel')}
            rightButton={t('react.button.delete')}
        />
        <EditLibraryDialog
          {...props}
          editLibraryId={editLibraryId}
          appendToLibraryId={libraryId}
          supportedLanguages={supportedLanguages}
          openState={openEditLibraryDialog}
          callbackAfterConfirm={callbackAfterConfirmEditLibrary}
          callbackAfterCancel={callbackAfterCancelEditLibrary}
          libraries={libraries}
        />
        <MoveLibraryDialog
          {...props}
          paths={paths}
          libraryId={libraryId}
          openState={openMoveLibraryDialog}
          callbackAfterConfirm={callbackAfterConfirmMoveLibrary}
          callbackAfterCancel={callbackAfterCancelMoveLibrary}
        />
        <ImportLibraryDialog
          {...props}
          supportedLanguages={supportedLanguages}
          appendToLibraryId={libraryId}
          openState={openImportLibraryDialog}
          callbackAfterConfirm={callbackAfterConfirmImportLibrary}
          callbackAfterCancel={callbackAfterCancelImportLibrary}
        />
        <Grid container>
          <Grid item xs={12}>
            <TreeToolbar
              {...props}
              libraryId={libraryId}
              setLoadLibrariesData={setLoadLibrariesData}
              handleEditLibrary={handleEditLibrary}
              handleDeleteLibrary={handleDeleteLibrary}
              handleMoveLibrary={handleMoveLibrary}
              handleExportLibrary={handleExportLibrary}
              handleImportLibrary={handleImportLibrary}
            />
            <SimpleTreeView
              multiSelect={false}
              className={classes.treeView}
              defaultExpandedItems={getDefaultExpendedLevels()}
              defaultSelectedItems={getDefaultSelectedLevel()}
              selectedItems={getDefaultSelectedLevel()}
            >
              <RecursiveTreeItem
                key={0}
                library={{'id': 0, 'name': t('react.codification.root.folder')}}
                libraries={libraries}
              />
            </SimpleTreeView>
          </Grid>
          <Grid item xs={12}>
            <Divider
              variant="middle"
              className={classes.divider}
              sx={{
                opacity: "0.6"
              }} />
            <CodificationTable
              {...props}
              paths={paths}
              library={findById(libraries, libraryId)}
              loadData={loadData}
              setLoadData={setLoadData}
              supportedLanguages={supportedLanguages}
              libraries={libraries}
            />
          </Grid>
        </Grid>
      </Paper>
  ) }
    </div>
  );
};
