import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { toolbarStyles } from '../../common.js';
import { makeStyles } from 'tss-react/mui';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import CachedIcon from '@mui/icons-material/Cached';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import LinearProgress from '@mui/material/LinearProgress';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import TableSortLabel from '@mui/material/TableSortLabel';
import EditSubscriptionDialog from './EditSubscriptionDialog';
import PopupTwoButtons from '../shared/PopupTwoButtons/PopupTwoButtons';
import { LoadData } from '../../Constants.js'
import dateFormat from 'dateformat';
import SubscriptionService from '../../services/SubscriptionService';
import TripleStateButton from '../shared/TripleStateButton/TripleStateButton';
import EventIcon from '@mui/icons-material/Event';
import { TbClockPause, TbClock, TbClockPlay } from "react-icons/tb";
import {getItemAriaLabel} from '../../utils/pagination.js'
import {Helmet} from "react-helmet";
import AnalyticsIcon from '@mui/icons-material/Analytics';

const subscriptionService = new SubscriptionService();

function createData(hit, customer) {
  const startDate = new Date();
  startDate.setTime(hit.startDate);
  const endDate = new Date();
  endDate.setTime(hit.endDate);
  return { 
    id: hit.id, 
    customerName: customer.name, 
    description: hit.description,
    maxProjects: `${hit.remainingProjects} / ${hit.maxProjects}`, 
    maxObservations: `${hit.remainingObservations} / ${hit.maxObservations}`, 
    startDate: startDate, 
    endDate: endDate
   };
}

const headCells = [
  { id: 'customerName', label: 'react.subscriptions.table.header.customer', minWidth: 170 },
  { id: 'description', label: 'react.subscriptions.table.header.subscription', minWidth: 200 },
  { id: 'maxProjects', label: 'react.subscriptions.table.header.projects', minWidth: 200, sortable: false },
  { id: 'maxObservations', label: 'react.subscriptions.table.header.observations', minWidth: 200, sortable: false },
  {
    id: 'startDate',
    label: 'react.subscriptions.table.header.startdate',
    minWidth: 170,
    align: 'left',
    format: value => dateFormat(value, "UTC:dd/mm/yyyy"),
  },
  {
    id: 'endDate',
    label: 'react.subscriptions.table.header.enddate',
    minWidth: 170,
    align: 'left',
    format: value => dateFormat(value, "UTC:dd/mm/yyyy"),
  },
];

function EnhancedTableHead(props) {

  const { t, classes, onSelectAllClick, numSelected, rowCount, sortBy, sortDirection, handleChangeSort } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            id='checkbox-all'
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={sortBy === headCell.id ? sortDirection : false}
          >
            {headCell.sortable === false ? (
              t(headCell.label)
            ) : (
              <TableSortLabel
                active={sortBy === headCell.id}
                direction={sortDirection}
                onClick={e => handleChangeSort(e, headCell.id)}
              >
                {t(headCell.label)}
                {sortBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {sortDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const EnhancedTableToolbar = props => {

  const { classes } = toolbarStyles();

  const { t, numSelected, countForPagination, handleDeleteSubscriptions, handleEditSubscriptions, setLoadData, 
    stateFilter, handleChangeStateFilter, handleApplyExpirationDate } = props;

  const handleClickRefresh = (event) => {
    event.preventDefault();
    setLoadData(LoadData.Load);
  };

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <AccountBalanceWalletIcon className={classes.mainIcon} />
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1">
          {t("react.subscriptions.number.of.selected", { 'numSelected': numSelected })}
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle">
          {t('react.subscriptions.title', { 'size': countForPagination })}
        </Typography>
      )}
      <Tooltip title={t('react.button.delete')}><span>
        <IconButton
          onClick={handleDeleteSubscriptions}
          disabled={numSelected === 0}
          size="large">
          <DeleteIcon />
        </IconButton></span>
      </Tooltip>
      <Tooltip title={t('react.snackbar.subscription.apply.expiration.date')}><span>
        <IconButton
          onClick={handleApplyExpirationDate}
          disabled={numSelected === 0}
          size="large">
          <EventIcon />
        </IconButton></span>
      </Tooltip>
      <Tooltip title={t('react.snackbar.subscription.analytics')}><span>
        <IconButton
          onClick={() => props.navigate('/subscriptions/reporting')}
          size="large">
          <AnalyticsIcon />
        </IconButton></span>
      </Tooltip>
      <Tooltip title={t('react.button.add')}>
        <IconButton
          onClick={(e) => handleEditSubscriptions(e, 0)}
          size="large">
          <ControlPointIcon />
        </IconButton>
      </Tooltip>
      <TripleStateButton
        stateValues={['onlyExpired', 'all', 'onlyActive']}
        stateIcons={[<TbClockPause/>, <TbClock/>,<TbClockPlay/>]}
        stateLabels={[t('react.subscriptions.display.outdated.subscriptions'), t('react.subscriptions.display.all.subscriptions'), t('react.subscriptions.display.active.subscriptions')]}
        stateValue={stateFilter}
        setStateValue={handleChangeStateFilter}
        style = {{height: '30px'}}
      />
      <Tooltip title={t('react.button.refresh')}>
        <IconButton onClick={handleClickRefresh} size="large">
          <CachedIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
};

const useStyles = makeStyles()(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableRowCursorPointer: {
    cursor: 'pointer',
  },
}));

export default function SubscriptionsList(props) {

  const { classes } = useStyles();

  const { t, openSnackbar } = props

  const [countForPagination, setCountForPagination] = useState(0);
  const [editDialogSubscriptionId, setEditDialogSubscriptionId] = useState(0);
  const [rows, setRows] = useState([]);
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortBy, setSortBy] = useState('customerName');
  const [sortDirection, setSortDirection] = useState('asc');
  const [loadData, setLoadData] = useState(LoadData.Load);
  const [stateFilter, setStateFilter] = useState('onlyActive');

  useEffect(() => {
    if (loadData !== LoadData.Load) return;

    setLoadData(LoadData.Loading);

    subscriptionService.getAllSubscriptions((page * rowsPerPage), rowsPerPage, sortBy, sortDirection, (stateFilter === 'onlyActive'), (stateFilter === 'onlyExpired'))
      .then(result => {
        setCountForPagination(result.data.total);
        const newRows = [];
        for (let i = 0; i < result.data.hits.length; i++) {
          let hit = result.data.hits[i];
          let customer = result.data.customers.find(customer => customer.id === hit.customerId);
          newRows.push(createData(hit, customer));
        }
        setRows(newRows);
      })
      .catch(err => {
        setRows([]);
        setCountForPagination(0);
        openSnackbar('error', t('react.snackbar.subscriptions.fetchdata', { error: err.message }));
      }).finally(() => {
        setLoadData(LoadData.Loaded);
      });
  }, [loadData, page, rowsPerPage, sortBy, sortDirection])

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    event.preventDefault();
    setPage(newPage);
    setLoadData(LoadData.Load);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setLoadData(LoadData.Load);
  };

  const handleChangeSort = (event, headCellId) => {
    event.preventDefault();
    if (sortBy !== headCellId) {
      setSortBy(headCellId);
      setSortDirection('asc')
    } else {
      setSortDirection(sortDirection === 'desc' ? 'asc' : 'desc');
    }
    setLoadData(LoadData.Load);
  };

  const handleChangeStateFilter = (newStateValue) => {
    setPage(0);
    setStateFilter(newStateValue);
    setLoadData(LoadData.Load);
  };

  const isSelected = id => selected.indexOf(id) !== -1;

  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const handleEditSubscriptions = (event, subscriptionsToEdit) => {
    event.preventDefault();
    setEditDialogSubscriptionId(subscriptionsToEdit);
    setEditDialogOpen(true);
  };
  const editDialogCallbackAfterConfirm = () => {
    setEditDialogOpen(false);
    setEditDialogSubscriptionId(0);
    setLoadData(LoadData.Load);
  }
  const editDialogCallbackAfterCancel = () => {
    setEditDialogOpen(false);
    setEditDialogSubscriptionId(0);
  }

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const handleDeleteSubscriptions = (event, row) => {
    event.preventDefault();
    setDeleteDialogOpen(true)
  };
  const handleDeleteDialogConfirm = () => {
    var promises = [];
    for (var i = 0; i < selected.length; i++) {
      promises.push(subscriptionService.deleteSubscription(selected[i]));
    }
    Promise.all(promises)
      .then(function (values) {
        setDeleteDialogOpen(false);
        setLoadData(LoadData.Load);
        setSelected([]);
      })
      .catch(err => {
        openSnackbar('error', t('react.snackbar.subscription.deletesubscription.error'));
      })
      .finally(() => {
        openSnackbar('success', t('react.snackbar.subscription.deletesubscription'));
      });
  };
  const handleDeleteDialogCancel = () => {
    setDeleteDialogOpen(false);
  }

  const [openDialogApplyExpirationDate, setOpenDialogApplyExpirationDate] = useState(false);
  const handleApplyExpirationDateConfirm = () => {
    var promises = [];
    for (var i = 0; i < selected.length; i++) {
      promises.push(subscriptionService.applySubscriptionExpirationToAllCustomerUsers(selected[i]));
    }
    Promise.all(promises)
      .then(function (values) {
        openSnackbar('success', t('react.snackbar.subscription.confirm.apply.expiration.date.success'));
      })
      .catch(err => {
        openSnackbar('error', t('react.snackbar.subscription.confirm.apply.expiration.date.error'));
      })
      .finally(() => {
        setOpenDialogApplyExpirationDate(false);
      });
  }
  const handleApplyExpirationDateCancel = () => {
    setOpenDialogApplyExpirationDate(false);
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length);

  return (
    <div className={classes.root}>
      <Helmet title={t('react.helmet.subscriptionslist')} />
      {loadData !== LoadData.Loaded ? (<LinearProgress />)
        : (
          <Paper className={classes.paper}>
            <PopupTwoButtons
              variant='warning'
              openState={deleteDialogOpen}
              callbackOnclose={handleDeleteDialogCancel}
              callbackOnclickLeftButton={handleDeleteDialogCancel}
              callbackOnclickRightButton={handleDeleteDialogConfirm}
              title={t('react.subscriptions.confirm.delete.title')}
              content={t('react.subscriptions.confirm.delete.description')}
              leftButton={t('react.button.cancel')}
              rightButton={t('react.button.delete')}
            />
            <PopupTwoButtons
              variant='question'
              openState={openDialogApplyExpirationDate}
              callbackOnclose={handleApplyExpirationDateCancel}
              callbackOnclickLeftButton={handleApplyExpirationDateCancel}
              callbackOnclickRightButton={handleApplyExpirationDateConfirm}
              title={t('react.subscriptions.confirm.apply.expiration.date.title')}
              content={t('react.subscriptions.confirm.apply.expiration.date.description')}
              leftButton={t('react.button.cancel')}
              rightButton={t('react.button.apply')}
            />
            <EditSubscriptionDialog
              {...props}
              editDialogOpen={editDialogOpen}
              setEditDialogOpen={setEditDialogOpen}
              callbackAfterConfirm={editDialogCallbackAfterConfirm}
              callbackAfterCancel={editDialogCallbackAfterCancel}
              subscriptionId={editDialogSubscriptionId}
            />
            <EnhancedTableToolbar
              {...props}
              numSelected={selected.length}
              countForPagination={countForPagination}
              handleEditSubscriptions={handleEditSubscriptions}
              handleDeleteSubscriptions={handleDeleteSubscriptions}
              handleApplyExpirationDate={() => setOpenDialogApplyExpirationDate(true)}
              setLoadData={setLoadData}
              stateFilter={stateFilter}
              handleChangeStateFilter={handleChangeStateFilter}
              setPage={setPage}
            />
            <div className={classes.tableWrapper}>
              <Table
                className={classes.table}
                size="medium"
              >
                <EnhancedTableHead
                  {...props}
                  classes={classes}
                  numSelected={selected.length}
                  onSelectAllClick={handleSelectAllClick}
                  rowCount={rows.length}
                  sortBy={sortBy}
                  sortDirection={sortDirection}
                  handleChangeSort={handleChangeSort}
                />
                <TableBody>
                  {rows.map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                        className={classes.tableRowCursorPointer}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            id={`checkbox-${row.id}`}
                            onClick={event => handleClick(event, row.id)}
                            checked={isItemSelected}
                          />
                        </TableCell>
                        {headCells.map(column => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}
                              onClick={event => handleEditSubscriptions(event, row.id)} >
                              {column.format ? column.format(value) : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: (53) * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={countForPagination}
              rowsPerPage={rowsPerPage}
              page={page}
              labelRowsPerPage={t('react.list.number.lignes.per.page')}
              labelDisplayedRows={({ from, to, count }) => t('react.list.number.from.to.count', { 'from': from, 'to': to, 'count': count })}
              getItemAriaLabel={(type) => getItemAriaLabel(t, type)}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        )}
    </div>
  )
}
