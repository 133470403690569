import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { makeStyles } from 'tss-react/mui';
import ApiService from '../../services/ApiService'
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { isValidEmail, isBlank } from '../../utils/utils';

const useStyles = makeStyles()((theme) => {
  return {
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}
});

export default function RememberPasswordPanel(props) {
  const { setShowRememberPasword, openSnackbar } = props;
  const { t } = useTranslation();
  const [displayErrors, setDisplayErrors] = useState({ emailLength: false, emailValidation: false });

  const { classes } = useStyles();
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    let errorsInFields = { ...displayErrors };
    errorsInFields.emailLength = isBlank(email);
    errorsInFields.emailValidation = !isValidEmail(email);
    setDisplayErrors(errorsInFields);

    let nbErrors = Object.entries(errorsInFields).filter(value => value[1] === true).length;
    if (nbErrors > 0) {
      return;
    }

    let restClient = new ApiService().getClient();
    restClient.post('/api/auth/v1.0/reset-password/send-token/by-email', JSON.stringify({ email }))
      .then(response => {
        openSnackbar('success', t('react.remember.password.email.ok'));
        setShowRememberPasword(false);
      })
      .catch(err => {
        openSnackbar('error', t('react.remember.password.email.error', { reason: err.response.data.message }));
      });
  }

  const handleCancel = (event) => {
    event.preventDefault();
    setShowRememberPasword(false);
  }

  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <Typography component="h1" variant="h5" style={{ textAlign: 'center' }}>
        {t("react.remember.password.title")}
      </Typography>
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="email"
        label={t('react.remember.password.email')}
        name="email"
        autoComplete="email"
        autoFocus
        onChange={e => setEmail(e.target.value)}
        error={displayErrors.emailLength || displayErrors.emailValidation}
        helperText={displayErrors.emailLength && t("react.validation.email.empty") || displayErrors.emailValidation && t("react.validation.email.invalid")}
      />
      <Button
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
        type="submit"
      >
        {t('react.remember.password.button.send.instructions')}
      </Button>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        onClick={handleCancel}
      >
        {t('react.button.cancel')}
      </Button>
    </form>
  );
}
