import React, { useState, useEffect, useContext } from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { makeStyles } from 'tss-react/mui';
import CustomNextButton from './CustomNextButton';
import { isBlank } from '../../utils/utils';
import { validateInputControlThreeWordQuestion } from '../../utils/surveysUtils';
import { CollectorContext } from './context';

const useStyles = makeStyles()(theme => ({
    input: {
        fontSize: '17px'
    },
    margin: {
        marginBottom: '20px',
        marginTop: '20px',
    },
    positionRelative: {
        position: 'relative',
    },
    alignItemCenter: {
        position: 'relative',
        display: ' flex',
        justifyContent: 'center',
        maxWidth: 450,
        display: 'flex-column',
        alignItems: 'center',
    },
    instructions: {
        fontStyle: 'italic',
        marginLeft: 20,
        marginTop: 10
    },
    textHelper: {
        fontStyle: 'italic',
        color: 'rgb(50,50,50)',
        marginLeft: 25,
        fontSize: 16,
        marginTop: 10,
        position: 'absolute'
    }
}));

export default function QuestionR3m(props) {

    const { classes } = useStyles();

    const {
        t, showSpinner, openSnackbar, block
    } = props;

    const {
        collectorsService, handleNextBlock, embeddedReplaceDynamicElements,
        handleScreenOut, participantId, handleQuotaFull
    } = useContext(CollectorContext);

    const [firstAnswer, setFirstAnswer] = useState('');
    const [secondAnswer, setSecondAnswer] = useState('');
    const [thirdAnswer, setThirdAnswer] = useState('');
    const [showHelperMsg, setShowHelperMsg] = useState(false);

    const [dataReady, setDataReady] = useState(false);

    useEffect(() => {
        setDataReady(false);
        if (!block) return;

        // always reset all fields when a new bloc comes
        setFirstAnswer('');
        setSecondAnswer('');
        setThirdAnswer('');
        setShowHelperMsg(false);

        setDataReady(true);
    }, [block]);

    useEffect(() => {
        if (!block.experience.enableInputControls) return
        // this useEffect will check if we need to displayHelperMsg, if displayHelperMsg is true, it will start a timeout of 1 sec and then display the msg
        if (displayHelperMsg()) {
            const timeoutId = setTimeout(() => {
                setShowHelperMsg(true);
            }, 1000);

            return () => clearTimeout(timeoutId);
        } else {
            setShowHelperMsg(false);
        }
    }, [firstAnswer, secondAnswer, thirdAnswer]); 

    const saveBlock = async () => {
        showSpinner(true);

        await collectorsService.collectExperienceAnswer(participantId, block.id, block.rankInSequence, [firstAnswer, secondAnswer, thirdAnswer])
            .then(response => {
                setDataReady(false);
                showSpinner(false);
                handleNextBlock();
            }).catch(error => {
                setDataReady(false);
                showSpinner(false);
                if (error.response.status === 302) {
                    handleQuotaFull();
                } else if (error.response.status === 307) {
                    handleScreenOut();
                } else {
                    openSnackbar('error', t('react.error.save.message'));
                    handleScreenOut();
                }
            });
    };

    const handleEnter = (event, answer) => {
        if (event.keyCode === 13) {
            if (answer === 'first' && !isBlank(event.target.value)) {
                document.getElementById(`question2-${block.id}`).focus();
            } else if (answer === 'second' && !isBlank(event.target.value)) {
                document.getElementById(`question3-${block.id}`).focus();
            } else if (answer === 'third' && !isBlank(event.target.value)) {
                document.getElementById(`buttonNext-2-${block.id}`).focus();
            }
            event.preventDefault();
        }
    };

    const AnswersValid = () => {
        if (isBlank(firstAnswer) || isBlank(secondAnswer) || isBlank(thirdAnswer)) {
            return false;
        }
        if (block.experience.enableInputControls) {
            return validateInputControlThreeWordQuestion(firstAnswer, secondAnswer, thirdAnswer)
        } 
        return true;
    }

    const displayHelperMsg = () => {
        if (!isBlank(firstAnswer) && !isBlank(secondAnswer) && !isBlank(thirdAnswer)) {
            return !validateInputControlThreeWordQuestion(firstAnswer, secondAnswer, thirdAnswer);
        }
        return false
    }

    if(!dataReady) return null;

    return (
        <Container className={classes.positionRelative}>
            <>
                {block.experience.threeWordQuestion && <div className="ql-view" dangerouslySetInnerHTML={{ __html: embeddedReplaceDynamicElements(block.experience.threeWordQuestion) }} />}
                {block.experience.displayInstructions && <div className={classes.instructions}>{t('react.collectform.r3mblock.instructions')}</div>}
                <Container className={classes.alignItemCenter}>
                    <Grid item xs={12} className={classes.positionRelative}>
                        <TextField
                            variant='outlined'
                            autoFocus
                            inputProps={{ maxLength: '50' }}
                            onKeyDown={() => (handleEnter(event, 'first'))}
                            required={true}
                            margin="dense"
                            id={`question1-${block.id}`}
                            value={firstAnswer}
                            onChangeCapture={(e) => setFirstAnswer(e.target.value)}
                            label={t('react.collectform.block.word1')}
                            autoComplete='off'
                            style={{ marginTop: "30px", maxWidth: '450px' }}
                            type='text'
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                                classes: {
                                    root: classes.input,
                                }
                            }}
                        />
                        <br />
                        <TextField
                            variant='outlined'
                            value={secondAnswer}
                            onChangeCapture={(e) => setSecondAnswer(e.target.value)}
                            inputProps={{ maxLength: '50' }}
                            onKeyDown={() => (handleEnter(event, 'second'))}
                            required={true}
                            margin="dense"
                            id={`question2-${block.id}`}
                            label={t('react.collectform.block.word2')}
                            autoComplete='off'
                            style={{ marginTop: "20px", maxWidth: '450px' }}
                            type='text'
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                                classes: {
                                    root: classes.input,
                                }
                            }}
                        />
                        <br />
                        <TextField
                            variant='outlined'
                            value={thirdAnswer}
                            onChangeCapture={(e) => setThirdAnswer(e.target.value)}
                            inputProps={{ maxLength: '50' }}
                            onKeyDown={() => (handleEnter(event, 'third'))}
                            required={true}
                            margin="dense"
                            id={`question3-${block.id}`}
                            label={t('react.collectform.block.word3')}
                            autoComplete='off'
                            style={{ marginTop: "20px", maxWidth: '450px' }}
                            type='text'
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                                classes: {
                                    root: classes.input,
                                }
                            }}
                        />
                    </Grid>
                </Container>
                {showHelperMsg && <div className={classes.textHelper}>{t('react.collectform.r3mquestion.texthelper')}</div>}
                <CustomNextButton
                    {...props}
                    id={`buttonNext-2-${block.id}`}
                    disabled={!AnswersValid()}
                    handleNextBlock={() => saveBlock()}
                />
            </>
        </Container>
    )
};
