import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { makeStyles } from 'tss-react/mui';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import PasswordConfirmation from '../shared/PasswordConfirmation/PasswordConfirmation';
import DialogTitleWithCloseIcon from '../shared/DialogTitleWithCloseIcon/DialogTitleWithCloseIcon';

const useStyles = makeStyles()(theme => ({
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  icon: {
    '&:hover': {
      cursor: 'pointer'
    },
    zIndex: '1000'
  },
  marginTop: {
    marginTop: 10
  }
}));

export default function UserEditPassword(props) {

  const {
    t,
    open,
    firstname,
    lastname,
    saving,
    callbackAfterConfirm,
    callbackAfterCancel
  } = props;

  const { classes } = useStyles();

  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [notSamePassword, setNotSamePassword] = useState('');
  const [passwordCorrect, setPasswordCorrect] = useState(false);

  const handleClickConfirm = (event) => {
    event.preventDefault();
    if (password !== passwordConfirm) {
      setNotSamePassword(t('react.validation.changepassword.dontmatch'));
    } else {
      setPassword('');
      setPasswordConfirm('');
      setNotSamePassword('')
      callbackAfterConfirm(password);
    }
  };

  const handleClickCancel = (event) => {
    event.preventDefault();
    setPassword('');
    setPasswordConfirm('');
    setNotSamePassword('');
    callbackAfterCancel();
  };

  return (
    <Dialog open={open} onClose={handleClickCancel}>
      <DialogTitleWithCloseIcon
          title={t("react.users.changepassword.title")}
          callbackOnclose={handleClickCancel}
      />
      <DialogContent className={classes.marginTop}>
        <DialogContentText>
          {t('react.users.changepassword.description', { 'firstname': firstname, 'lastname': lastname })}
        </DialogContentText>
        <PasswordConfirmation 
          {...props}
           password={password}
           setPassword={setPassword}
           notSamePassword={notSamePassword}
           passwordConfirm={passwordConfirm}
           setPasswordConfirm={setPasswordConfirm}
           setPasswordCorrect={setPasswordCorrect} 
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          size="large"
          onClick={handleClickCancel}
          color="primary"
          startIcon={<CloseIcon />}
          className={classes.button}
        >
          {t('react.button.cancel')}
        </Button>
        <Button
          variant="outlined"
          size="large"
          onClick={handleClickConfirm}
          startIcon={<SaveIcon />}
          className={classes.button}
          disabled={saving || !passwordCorrect}
        >
          {t('react.button.save')}
          {saving && <CircularProgress size={24} className={classes.buttonProgress} />}
        </Button>
      </DialogActions>
    </Dialog>
  );
}