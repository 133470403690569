import React, { useState, useEffect } from 'react';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import CustomizedSnackbars from '../../components/CustomizedSnackbars/CustomizedSnackbars'
import { AuthService } from '../../services/AuthService';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import {LoadData} from '../../Constants.js'
import LinearProgress from '@mui/material/LinearProgress';

const useStyles = makeStyles()((theme) => {
  return {
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}
});

const defaultPreferedLanguage = 'en';

export default function RouteWithLayout(props) {

  // read properties
  const { layout: Layout, component: Component, ...rest } = props;

  const authRequired = props.authRequired === false ? false : true;
  const visitorAllowed = props.visitorAllowed === true ? true : false;

  // init classes
  const { classes } = useStyles();

  // init translation tool
  const { t } = useTranslation();
  const translate = (key, args) =>{
    return t(key, args);
  };

  // init snackbar tool
  const [snackbarVariant, setSnackbarVariant] = useState('error');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const openSnackbar = (variant, message) => {
    setSnackbarVariant(variant);
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  // init spinner tool
  const [openSpinner, setOpenSpinner] = useState(false);
  const showSpinner = (value)=>{
    setOpenSpinner(value);
  };
  const handleCloseSpinner = () => {
    setOpenSpinner(false);
  };

  let navigate = useNavigate();
  let location = useLocation();

  const [user, setUser] = useState(undefined);
  const [preferredLanguage, setPreferredLanguage] = useState(defaultPreferedLanguage);

  const [loadData, setLoadData] = useState(LoadData.Load);
  useEffect(() => {
    // if no auth is required or authentication in progress = skip
    if(!authRequired || loadData !== LoadData.Load) return;

    // mark as loading
    setLoadData(LoadData.Loading);

    AuthService.refreshUser()
    .then(function(freshUser) {
      setUser(freshUser);
      setPreferredLanguage(freshUser.preferredLanguage || defaultPreferedLanguage)
    })
    .catch(err => {
      setUser(null);
    }).finally(() => {
      setLoadData(LoadData.Loaded);
    });
  }, [loadData, authRequired]);

  // wait for authentication to be done
  if(authRequired && loadData !== LoadData.Loaded) {
    return (<LinearProgress />);
  }

  if(authRequired === true) {
    // Redirect to login page if auth is not set when required
    if(user == null) {
      return (<Navigate to={"/logout"} replace/>);
    }

    // disconnect visitor trying to go where it's not possible
    if(AuthService.isVisitor() && !visitorAllowed) {
      return (<Navigate to={"/logout"} replace/>);
    }
  }

  // ok, display the component in the layout
  return (
      <Layout
        {...props}
        t={translate}
        navigate={navigate}
        openSnackbar={openSnackbar}
        closeSnackbar={handleCloseSnackbar}
        showSpinner={showSpinner}
        preferredLanguage={preferredLanguage.toLowerCase()}
        maxWidth={props.maxWidth ? props.maxWidth : "lg"}
      >
        <Backdrop className={classes.backdrop}
          open={openSpinner}
          onClick={handleCloseSpinner}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <CustomizedSnackbars
          variant={snackbarVariant}
          open={snackbarOpen}
          handleClose={handleCloseSnackbar}
          message={snackbarMessage}
          vertical='top'
          horizontal='center'
        />
        <Component
          {...props}
          t={translate}
          /* this is a hack to force reload when using navigate() on the same route */
          key={location.key}
          navigate={navigate}
          openSnackbar={openSnackbar}
          closeSnackbar={()=>setSnackbarOpen(false)}
          showSpinner={showSpinner}
          preferredLanguage={preferredLanguage.toLowerCase()}
        />
      </Layout>);
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  authRequired: PropTypes.bool,
  visitorAllowed: PropTypes.bool,
  maxWidth: PropTypes.string,
};
