import React, { useState, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import CodificationService from '../../services/CodificationService';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import SaveIcon from '@mui/icons-material/Save';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import InputAdornment from '@mui/material/InputAdornment';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
import { indexOf, orderBy, cloneDeep, isBlank } from '../../utils/utils.js';
import { LoadData } from '../../Constants.js';
import DialogTitleWithCloseIcon from '../shared/DialogTitleWithCloseIcon/DialogTitleWithCloseIcon';
import {getShorthandNotationFromBiblioEntry} from '../../utils/codificationUtils'

const codificationService = new CodificationService();

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  paddingTop: {
    paddingTop: 15,
  },
  formControl: {
    minWidth: 120,
    width: '100%',
    marginTop: '15px'
  },
  variantIsOk: {
  },
  variantExistAsDuplicate: {
    backgroundColor: "#ffff99"
  },
}));

const emptyDictionaryWord = {id: 0, word: '', language: '', variants: []};

export default function EditDictionaryWord(props) {

  const {
      t,
      preferredLanguage,
      openSnackbar,
      showSpinner,
      openState,
      id,
      callbackAfterConfirm,
      callbackAfterCancel,
      language,
      supportedLanguages
  } = props;

  const { classes } = useStyles();

  const [dictionaryWord, setDictionaryWord] = useState(cloneDeep(emptyDictionaryWord));
  const changeField = (name, value) => {
    let newDictionaryWord = cloneDeep(dictionaryWord, {[name]: value});
    setDictionaryWord(newDictionaryWord);
  };

  const [variantFieldValue, setVariantFieldValue] = useState('');
  const [associatedBiblioEntries, setAssociatedBiblioEntries] = useState([]);
  const [biblioEntriesToDelete, setBiblioEntriesToDelete] = useState([]);
  const [translationsOfWord, setTranslationsOfWord] = useState([]);
  const [translationsOfVariants, setTranslationsOfVariants] = useState([]);
  const [duplicateVariants, setDuplicateVariants] = useState([]);

  const [loadData, setLoadData] = useState(LoadData.Load);
  useEffect(() => {
    if(!openState) {
      setLoadData(LoadData.Load);
      return;
    }

    if(loadData !== LoadData.Load) return;

    setLoadData(LoadData.Loading);

    showSpinner(true);

    if(id > 0) {
      var promises = [
        codificationService.getWord(id),
        codificationService.searchBiblioEntries(0, 100, 'id', 'asc', undefined, undefined, undefined, undefined, undefined, undefined, undefined, id, 'paths'),
        codificationService.getVariantsExistingAsDuplicate(id)
      ];

      Promise.all(promises)
      .then(results => {
        let dw = cloneDeep(emptyDictionaryWord, results[0].data);
        setDictionaryWord(dw);
        setVariantFieldValue('');

        let abe = [];
        results[1].data.hits.forEach((item, i) => {
          // add the path to the item
          item.path = results[1].data.paths[item.libraryId];
          abe.push(item);
        });
        setAssociatedBiblioEntries(orderBy(abe, 'path'));

        setDuplicateVariants(results[2].data);
        if(results[2].data.length > 0) {
          openSnackbar('warning', t('react.dictionary.warning.variants.existing.as.duplicate'));
        }

        setBiblioEntriesToDelete([]);
      }).catch(err => {
        openSnackbar('error', t('react.generic.error.while.loading'));
      }).finally(() => {
        showSpinner(false);
        setLoadData(LoadData.Loaded);
      });
    } else {
      setDictionaryWord(cloneDeep(emptyDictionaryWord, {'language': language}));
      setVariantFieldValue('');
      setAssociatedBiblioEntries([]);
      setBiblioEntriesToDelete([]);
      showSpinner(false);
      setLoadData(LoadData.Loaded);
    }
  }, [loadData, id, openState]);

  useEffect(() => {
    if(dictionaryWord.id > 0 && dictionaryWord.language !== preferredLanguage) {
      codificationService.translateEntry(dictionaryWord.word, dictionaryWord.language, preferredLanguage)
      .then(result => {
        setTranslationsOfWord(result.data);
      })
      .catch(err => {
        openSnackbar('error', t('react.generic.error.while.loading'));
      });
    } else {
      setTranslationsOfWord([]);
    }
  }, [dictionaryWord]);

  useEffect(() => {
    if(dictionaryWord.id > 0 && dictionaryWord.language !== preferredLanguage && dictionaryWord.variants) {
      codificationService.translateEntries(dictionaryWord.variants.map(v => v.variant), dictionaryWord.language, preferredLanguage)
      .then(result => {
        setTranslationsOfVariants(result.data);
      })
      .catch(err => {
        openSnackbar('error', t('react.generic.error.while.loading'));
      });
    } else {
      setTranslationsOfVariants([]);
    }
  }, [dictionaryWord]);

  const handleClickEditDialogCancel = (event) => {
    event.preventDefault();
    callbackAfterCancel();
  };

  const handleClickEditDialogConfirm = (event) => {
    event.preventDefault();

    codificationService.saveWord(dictionaryWord)
    .then(result => {
      // if it's OK delete entries selected to delete
      let promises = [];
      biblioEntriesToDelete.forEach((biblioEntryToDelete) => {
        codificationService.deleteBiblioEntry(biblioEntryToDelete.libraryId, biblioEntryToDelete.id);
      });

      Promise.all(promises)
      .then(results => {
        callbackAfterConfirm(result.data);
      })
      .catch(err => {
        openSnackbar('error', t('react.generic.error.while.saving'));
      });
    })
    .catch(err => {
      if (err.response.data && !isBlank(err.response.data.message)) {
        openSnackbar('error', t('react.generic.error.while.saving.with.message', {message: err.response.data.message}));
      } else {
        openSnackbar('error', t('react.generic.error.while.saving'));
      }
    });
  };

  const handleClickDeleteVariant = (event, idx) => {
    event.preventDefault();
    const newvariants = [...dictionaryWord.variants];
    newvariants.splice(idx, 1);
    changeField('variants', newvariants);
  };

  const handleClickDeleteBiblioEntry = (event, idx) => {
    event.preventDefault();

    // move the selected entry to the list to delete
    const newbiblioEntriesToDelete = [...biblioEntriesToDelete];
    newbiblioEntriesToDelete.push(associatedBiblioEntries[idx]);

    // remove from the displayed list
    const newassociatedBiblioEntries = [...associatedBiblioEntries];
    newassociatedBiblioEntries.splice(idx, 1);

    setBiblioEntriesToDelete(newbiblioEntriesToDelete);
    setAssociatedBiblioEntries(newassociatedBiblioEntries);
  };

  const addWordToVariants = (word) => {
    const newvariants = [...dictionaryWord.variants];

    if(indexOf(newvariants, 'variant', word) >= 0) {
      return;
    }

    let newVariant = {
      'id' : 0,
      'variant' : word,
      'creationDate': undefined
    };
    newvariants.push(newVariant);
    changeField('variants', newvariants);
  };

  const getTranslationOfVariant = (variant) => {
    if(dictionaryWord.language !== preferredLanguage && translationsOfVariants[variant]) {
      return <Typography variant="caption" gutterBottom>({preferredLanguage} : {translationsOfVariants[variant].join(', ')})</Typography>;
    }
    return undefined;
  };

  const isVariantDuplicated = (variant) => {
    return duplicateVariants.includes(variant);
  };
 
  if(loadData !== LoadData.Loaded) {
    return null;
  }

  return (
    <Dialog fullWidth={true} maxWidth={'md'} open={openState} onClose={handleClickEditDialogCancel}>
      {id > 0 ?
        <DialogTitleWithCloseIcon
          title={t('react.dictionary.word.title.modify', { "name": dictionaryWord.word, "id": dictionaryWord.id })}
          callbackOnclose={handleClickEditDialogCancel}
        /> :
        <DialogTitleWithCloseIcon
          title={t('react.dictionary.word.title.new')}
          callbackOnclose={handleClickEditDialogCancel}
        />
      }
    <DialogContent>
      <TextField
        autoFocus
        margin="dense"
        label={t('react.dictionary.field.word')}
        type="text"
        value={dictionaryWord.word}
        onChange={e => changeField('word', e.target.value)}
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
      />
      {dictionaryWord.language !== preferredLanguage && translationsOfWord.length > 0 &&
        <Typography variant="caption" gutterBottom>({preferredLanguage} : {translationsOfWord.join(', ')})</Typography>
      }
      <TextField
        margin="dense"
        id="language"
        value={dictionaryWord.language}
        onChange={e => changeField('language', e.target.value.toLowerCase())}
        label={t("react.dictionary.field.language")}
        type="text"
        fullWidth
      />
      <TextField
        margin="dense"
        label={t("react.dictionary.field.variant")}
        type="text"
        fullWidth
        value={variantFieldValue}
        onChange={e => setVariantFieldValue(e.target.value)}
        InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title={t("react.dictionary.editword.field.add.to.variants")}>
                        <span>
                        <IconButton
                          edge="end"
                          onClick={e => addWordToVariants(variantFieldValue)}
                          disabled={variantFieldValue.length === 0}
                          size="large">
                          <AddIcon />
                        </IconButton>
                        </span>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
      />
      <List dense={true} className={classes.root}>
        <div style={{ height: '200px', overflowY: 'scroll', overflowX: 'hidden' }}>
        {dictionaryWord.variants.map((variant, index) => {
          return (
            <ListItem key={`dictionaryWord-${index}`}>
              <ListItemText
                primary={variant.variant}
                secondary={getTranslationOfVariant(variant.variant)}
                className={isVariantDuplicated(variant.variant) ? classes.variantExistAsDuplicate : classes.variantIsOk}
              />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  onClick={e => handleClickDeleteVariant(e, index)}
                  size="large">
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          );
            })
        }
        </div>
      </List>
      <Typography variant="subtitle1" gutterBottom>{t('react.dictionary.editword.usages')}</Typography>
      <List dense={true} className={classes.root}>
        <div style={{ height: '150px', overflowY: 'scroll', overflowX: 'hidden' }}>
        {associatedBiblioEntries.map((biblioEntry, index) => {
            return (
              <ListItem key={`biblioEntry-${index}`}>
                <ListItemText
                  primary={`${getShorthandNotationFromBiblioEntry(biblioEntry)} : ${biblioEntry.path}`}
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    onClick={e => handleClickDeleteBiblioEntry(e, index)}
                    size="large">
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            );
        })}
        </div>
      </List>
    </DialogContent>
    <DialogActions>
      <Button
        variant="outlined"
        size="large"
        startIcon={<CloseIcon />}
        onClick={handleClickEditDialogCancel} color="primary">
        {t('react.button.cancel')}
      </Button>
      <Button
        variant="outlined"
        size="large"
        startIcon={<SaveIcon />}
        disabled={dictionaryWord.word.length === 0 || dictionaryWord.language.length !== 2}
        onClick={handleClickEditDialogConfirm} color="primary">
        {t('react.button.save')}
      </Button>
    </DialogActions>
  </Dialog>
  );
};
