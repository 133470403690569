/**
 * NOTE: This page is restricted to user having one of the following rights:
 *  - Super admin
 *  - Admin
 *  - Manager + GroupingManagement right
 *
**/
import React, { useState, useEffect, useReducer } from 'react';
import GroupingService from '../../services/GroupingService';
import ProjectService from '../../services/ProjectService';
import CodificationService from '../../services/CodificationService'
import ReferenceService from '../../services/ReferenceService'
import LinearProgress from '@mui/material/LinearProgress';
import Chip from '@mui/material/Chip';
import Bucket from './Bucket';
import { useParams } from "react-router-dom";
import { findById, indexOf } from '../../utils/utils.js';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { LoadData } from '../../Constants.js'
import CopyGroupingDialog from './CopyGroupingDialog';
import { makeStyles } from 'tss-react/mui';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import CreateEditTagDialog from './CreateEditTagDialog';
import CreateGroupDialog from './CreateGroupDialog';
import GroupingToolbar from './GroupingToolbar';
import {Helmet} from "react-helmet";

const groupingService = new GroupingService();
const projectService = new ProjectService();
const codificationService = new CodificationService();
const referenceService = new ReferenceService();

const useStyles = makeStyles()(theme => ({
    root: {
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'stretch',
    },
    groupsarea: {
        minWidth: "1200px",
        display: 'flex',
        justifyContent: 'stretch',
    },
    tagsarea: {
        overflowY: "auto",
        overflowX: "hidden",
        minWidth: "10%",
        display: 'flex',
        justifyContent: 'stretch',
        height: "90vh",
        position: 'sticky',
        top: 60,
        zIndex: 5
    },
    scrollerarea: {
        minWidth: "300px",
        overflowY: "auto",
        overflowX: "hidden",
        height: "90vh",
        position: 'sticky',
        top: 0,
        zIndex: 5
    },
    groupStyle: {
      marginLeft: '5px',
      marginRight: '5px',
      flex: 1
      },
}));

export default function Grouping(props) {

  const {
    t,
    preferredLanguage,
    openSnackbar,
    showSpinner,
  } = props;

  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

  const { classes } = useStyles();

  const { projectIdParameter } = useParams();

  const [project, setProject] = useState(undefined);
  const [buckets, setBuckets] = useState([]);
  const [tags, setTags] = useState([]);

  const [keywords, setKeywords] = useState('');

  // default expanded states by columns (is a numeric value with 'pair' = open, 'impair' = close)
  const [expandedBuckets, setExpandedBuckets] = useState([2, 1, 1, 1, 1]);

  const getAllGroups = () => {
    return buckets[1].groups
      .concat(buckets[2].groups)
      .concat(buckets[3].groups)
      .concat(buckets[4].groups);
  };

  const [loadData, setLoadData] = useState(LoadData.Load);
  useEffect(() => {
    if (loadData !== LoadData.Load) return;

    setLoadData(LoadData.Loading);

    const promises = [
        projectService.fetchProject(projectIdParameter),
        groupingService.fetchBiblio(projectIdParameter),
        groupingService.fetchGroups(projectIdParameter),
        groupingService.fetchTags(projectIdParameter),
        referenceService.fetchCoefficients()
    ];

    Promise.all(promises)
      .then(results => {
        let newProject = results[0].data;
        let newBiblio = results[1].data;
        let newGroups = results[2].data;
        let newTags = results[3].data;
        let newCoefficients = results[4].data;

        let coefficient = findById(newCoefficients, newProject.coefficientsId);
        if (coefficient !== undefined) newProject.language = coefficient.language;

        let newBuckets = [
          { index: 0, groups: [] },
          { index: 1, groups: [] },
          { index: 2, groups: [] },
          { index: 3, groups: [] },
          { index: 4, groups: [] }
        ];

        // in bucket 1 we have a group with item to treat
        let groupOfItemsToTreat = { id: -1, bucket: 0, items: [] };
        (newBiblio || []).forEach(w => {
            w.checked = false;
            if (w.word) groupOfItemsToTreat.items.push(w);
        });
        newBuckets[0].groups.push(groupOfItemsToTreat);
        // and then the trashbin
        let indexOfTrashbin = indexOf(newGroups, 'trashbin', true);
        newBuckets[0].groups.push(newGroups[indexOfTrashbin]);

        newBuckets[1].groups = newGroups.filter(g => g.bucket && g.bucket === 1);
        newBuckets[2].groups = newGroups.filter(g => g.bucket && g.bucket === 2);
        newBuckets[3].groups = newGroups.filter(g => g.bucket && g.bucket === 3);
        newBuckets[4].groups = newGroups.filter(g => g.bucket && g.bucket === 4);

        setProject(newProject);
        setBuckets(newBuckets);
        setTags(newTags);

        setLoadData(LoadData.Loaded);
      })
      .catch(err => {
        console.error(err)
        openSnackbar('error', t("react.project.error.while.loading"));

        setProject(undefined);
        setTags([]);
        setBuckets([[], [], [], [], []]);
        setLoadData(LoadData.Loaded);
      });
  }, [loadData, projectIdParameter]);

  // -- Tag -------------------------------------

  const [selectedTag, setSelectedTag] = useState(undefined);
  const [openTagDialog, setOpenTagDialog] = useState(false);

  const handleOpenCreateEditTag = (e, tag) => {
    e.preventDefault();
    setSelectedTag(tag);
    setOpenTagDialog(true);
  };

  const handleCreateTagCallback = (id, label, color, applyTo) => {
    setOpenTagDialog(false);
    showSpinner(true);
    if (!id) {
      groupingService.createTag(project.id, label, color)
        .then(result => {
          let currentTags = [...tags];
          currentTags.push(result.data);
          setTags(currentTags);
          applyTagToGroups(result.data, applyTo);
        }).catch(err => {
          console.error(err)
          openSnackbar('error', t('react.snackbar.groups.createtag.error', { error: err.message }));
        }).finally(() => {
          showSpinner(false);
        });
    } else {
      groupingService.updateTag(project.id, id, label, color)
        .then(result => {
          let currentTags = [...tags];
          const index = indexOf(currentTags, 'id', id);
          if (index === -1) return;
          currentTags[index] = result.data;
          setTags(currentTags);
          applyTagToGroups(result.data, applyTo);
        }).catch(err => {
          console.error(err)
          openSnackbar('error', t('react.snackbar.groups.updatetag.error', { error: err.message }));
        }).finally(() => {
          showSpinner(false);
        });
    }
  };

  const applyTagToGroups = (tag, groupIds) => {
    getAllGroups().forEach(g => {
      let isSelected = groupIds && groupIds.indexOf(g.id) >= 0;

      if(isSelected) {
        // keep a copy
        let oldTag = { ...g.tag };

        // check if we have to change the mapping
        let updateMapping = !g.tag || g.tag.id !== tag.id;

        // apply the tag to the group
        g.tag = tag;

        // apply if needed
        if(updateMapping) {
          groupingService.mapTagGroup(project.id, g.id, tag.id)
            .catch(err => {
              g.tag = oldTag;
              console.error(err)
            });
        }
      } else if(!isSelected && g.tag && g.tag.id === tag.id) {
        // keep a copy
        let oldTag = { ...g.tag };

        // remove tag from this groups
        g.tag = undefined;

        // apply
        groupingService.mapTagGroup(project.id, g.id, null)
          .catch(err => {
            g.tag = oldTag;
            console.error(err)
            openSnackbar('error', t('react.snackbar.groups.deletetag.error', { error: err.message }));
          });
      }
    });
  };

  const handleDeleteTag = (e, tagId) => {
    e.preventDefault();
    groupingService.deleteTag(project.id, tagId)
      .then(() => {
        getAllGroups().filter(g => g.tag && g.tag.id === tagId).map(g => g.tag = undefined);

        let newTags = [...tags];
        let index = indexOf(newTags, 'id', tagId);
        newTags.splice(index, 1);
        setTags(newTags);
      }).catch(err => {
        console.error(err)
        openSnackbar('error', t('react.snackbar.groups.deletetag.error', { error: err.message }));
      });
  };

  const mapTagToGroup = (group, tag) => {
    groupingService.mapTagGroup(project.id, group.id, tag.id)
      .then(() => {
        group.tag = tag;
        forceUpdate();
      }).catch(err => {
        console.error(err)
        openSnackbar('error', t('react.snackbar.groups.save.error', { error: err.message }));
      });
  };

  // -- Group -------------------------------------

  const [openNewGroupDialog, setOpenNewGroupDialog] = useState(false);

  const handleCreateGroupCallback = (title) => {
    groupingService.createGroup(project.id, 1, { title: title })
      .then(result => {
        let newGroup = {...result.data};
        newGroup.score = 0;
        newGroup.items = [];
        buckets[1].groups.splice(0, 0, newGroup);
        setOpenNewGroupDialog(false);
      }).catch(err => {
        console.error(err)
        openSnackbar('error', t('react.snackbar.groups.creategroup.error', { error: err.message }));
      });
  }

  const handleDeleteGroupCallback = async (group) => {
    return groupingService.deleteGroup(group.projectId, group.id)
      .then(result => {
        // move items of group to items to treat
        let newBuckets = [...buckets];

        group.items.forEach(item => {
          newBuckets[0].groups[0].items.push(item);
        });
        newBuckets[0].groups[0].modifiedAt = Date.now();

        // remove the group from the bucket
        let idx = indexOf(newBuckets[group.bucket].groups, 'id', group.id);
        newBuckets[group.bucket].groups.splice(idx, 1)[0];
        newBuckets[group.bucket].groups.modifiedAt = Date.now();

        setBuckets(newBuckets);
      }).catch(err => {
        console.error(err)
        openSnackbar('error', t('react.snackbar.groups.deletegroup.error', { error: err.message }));
      });
  };

  const handleCreateGroupFromContextMenuCallback = (group, word) => {
    groupingService.createGroup(project.id, 1, { bucket: 1, title: word.word })
      .then(result => {
        let newGroup = {...result.data};
        newGroup.score = 0;
        newGroup.items = [];
        group.modifiedAt = Date.now();

        let newBuckets = [...buckets];

        let groupIdx = indexOf(newBuckets[group.bucket].groups, 'id', group.id);

        let selectedItems = getCurrentSelectedInGroup(newBuckets[group.bucket].groups[groupIdx]);

        if(selectedItems.length > 0 && indexOf(selectedItems, 'id', word.id) >= 0) {
          selectedItems.forEach((wordSelected) => {
            let sourceWordIdx = indexOf(newBuckets[group.bucket].groups[groupIdx].items, 'id', wordSelected.id);
            let wordToMove = newBuckets[group.bucket].groups[groupIdx].items.splice(sourceWordIdx, 1)[0];
            wordToMove.checked = false;
            newGroup.items.push(wordToMove);
          });
        } else {
          let wordIdx = indexOf(newBuckets[group.bucket].groups[groupIdx].items, 'id', word.id);
          let wordToMove = newBuckets[group.bucket].groups[groupIdx].items.splice(wordIdx, 1)[0];
          wordToMove.checked = false;
          newGroup.items.push(wordToMove);
        }

        // insert the created group at position 0 in the bucket N°1
        newBuckets[1].groups.splice(0, 0, newGroup);

        if(group.bucket === 1) {
          // WARNING:
          // if the 'word' is extracted from a 'group' (the modified group) that were in the bucket N°1,
          // as we have inserted the 'newGroup' in the bucket N°1
          // WE HAVE MUST increase de 'groupIdx' to point to the modifed 'group' (it has been shifted !!!).
          groupIdx++;
        }

        saveConnections(group.id, newBuckets[group.bucket].groups[groupIdx].items);
        saveConnections(newGroup.id, newGroup.items);

        setBuckets(newBuckets);
      }).catch(err => {
        console.error(err)
        openSnackbar('error', t('react.snackbar.groups.creategroup.error', { error: err.message }));
      });
  };

  /**
   * Hack: by incrementing the value of the expanded field, it
   * fire a change of the state each time, like an event.
   * 
   * @see Group.js in the useEffect
   * 
   * @param {*} bucketIndex 
   * @param {*} expanded 
   * @returns 
   */
  const handleExpandBucket = (bucketIndex, expanded) => {
    if(bucketIndex < 1 || bucketIndex > 4) return;

    let newExpandedBuckets = [...expandedBuckets];
    let currentValue = newExpandedBuckets[bucketIndex];

    if(currentValue % 2 === 0) {
      // currentValue is 'pair', add a new 'pair' value (2 + 2 = 4) to force open or an 'impair' to close (6 + 1 = 7)
      currentValue += expanded ? 2 : 1;
    } else {
      // currentValue is 'impair', add a new 'pair' value (7 + 1 = 8) to force open or an 'impair' to close (11 + 2 = 13)
      currentValue += expanded ? 1 : 2;
    }

    newExpandedBuckets[bucketIndex] = currentValue;

    setExpandedBuckets(newExpandedBuckets);
  };

  // -- Item -------------------------------------

  const handleRemoveFromGroupFromContextMenuCallback = (group, word) => {
    let newBuckets = [...buckets];

    let groupIdx = indexOf(newBuckets[group.bucket].groups, 'id', group.id);

    let selectedItems = getCurrentSelectedInGroup(newBuckets[group.bucket].groups[groupIdx]);
    if(selectedItems.length > 0 && indexOf(selectedItems, 'id', word.id) >= 0) {
      selectedItems.forEach((wordSelected) => {
        let sourceWordIdx = indexOf(newBuckets[group.bucket].groups[groupIdx].items, 'id', wordSelected.id);
        let removedWord = newBuckets[group.bucket].groups[groupIdx].items.splice(sourceWordIdx, 1)[0];
        removedWord.checked = false;
        newBuckets[0].groups[0].items.push(removedWord);
      });
    } else {
      let wordIdx = indexOf(newBuckets[group.bucket].groups[groupIdx].items, 'id', word.id);
      let removedWord = newBuckets[group.bucket].groups[groupIdx].items.splice(wordIdx, 1)[0];
      removedWord.checked = false;
      newBuckets[0].groups[0].items.push(removedWord);
    }

    newBuckets[0].groups[0].modifiedAt = Date.now();
    group.modifiedAt = Date.now();

    saveConnections(group.id, newBuckets[group.bucket].groups[groupIdx].items);
    saveConnections(newBuckets[0].groups[0].id, newBuckets[0].groups[1].items);

    setBuckets(newBuckets);
  };

  const handleToTrashbinFromContextMenuCallback = (group, word) => {
    let newBuckets = [...buckets];

    let groupIdx = indexOf(newBuckets[group.bucket].groups, 'id', group.id);

    let selectedItems = getCurrentSelectedInGroup(newBuckets[group.bucket].groups[groupIdx]);
    if(selectedItems.length > 0 && indexOf(selectedItems, 'id', word.id) >= 0) {
      selectedItems.forEach((wordSelected) => {
        let sourceWordIdx = indexOf(newBuckets[group.bucket].groups[groupIdx].items, 'id', wordSelected.id);
        let removedWord = newBuckets[group.bucket].groups[groupIdx].items.splice(sourceWordIdx, 1)[0];
        removedWord.checked = false;
        newBuckets[0].groups[1].items.push(removedWord);
      });
    } else {
      let wordIdx = indexOf(newBuckets[group.bucket].groups[groupIdx].items, 'id', word.id);
      let removedWord = newBuckets[group.bucket].groups[groupIdx].items.splice(wordIdx, 1)[0];
      removedWord.checked = false;
      newBuckets[0].groups[1].items.push(removedWord);
    }

    newBuckets[0].groups[1].modifiedAt = Date.now();
    group.modifiedAt = Date.now();

    saveConnections(group.id, newBuckets[group.bucket].groups[groupIdx].items);
    saveConnections(newBuckets[0].groups[1].id, newBuckets[0].groups[1].items);

    setBuckets(newBuckets);
  };

  const saveConnections = async (groupId, items) => {
    if(groupId <= 0) return;

    let weightMap = items.map((item, index) => [item.id, index]);

    await groupingService.mapToGroup(project.id, groupId, weightMap)
      .catch(err => {
          openSnackbar('error', t('react.snackbar.groups.save.error', { error: err.message }));
      });
  };

  const saveGroups = (bucketId, items) => {
      let weightMap = items.map((item, index) => [item.id, index]);
      groupingService.reorderGroups(project.id, bucketId, weightMap)
      .then(result => {
      }).catch(err => {
        console.error(err)
        openSnackbar('error', t('react.snackbar.groups.save.error', { error: err.message }));
      });
  };

  const handleClickDownloadGroupingFile = (e) => {
    e.preventDefault();
    openSnackbar('info', t("react.grouping.toolbar.button.export.starting"));
    groupingService.getGroupingAsExcelFile(project.id)
        .then(response => {
            var regExp = new RegExp('filename="([^"]+)"', 'i');
            let filename = regExp.exec(response.headers['content-disposition'])[1];
            let url = window.URL.createObjectURL(new Blob([response.data]));
            let link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        })
        .catch(err => {
          console.error(err)
          openSnackbar('error', t("react.grouping.toolbar.button.export.error"));
        });
    };

  const handleAutoGenGroupingCallback = () => {
      showSpinner(true);
      openSnackbar('info', t("react.generic.inprogress.please.wait"));
      groupingService.generateGrouping(project.id, true, 0, [])
          .then(result => {
              showSpinner(false);
              openSnackbar('success', t("react.grouping.autogengrouping.success"));
              setLoadData(LoadData.Load);
          })
          .catch(err => {
            console.error(err)
            showSpinner(false);
            openSnackbar('error', t("react.grouping.copygroupingdialog.error.while.saving"));
          });
    };

  const handleConfirmCopyGroupingCallback = (selectedProjectIds) => {
    setOpenCopyGroupingDialog(false);
    showSpinner(true);
    openSnackbar('info', t("react.generic.inprogress.please.wait"));
    groupingService.generateGrouping(project.id, true, 0, selectedProjectIds)
      .then(result => {
          showSpinner(false);
          openSnackbar('success', t("react.grouping.copygroupingdialog.success"));
          setLoadData(LoadData.Load);
      })
      .catch(err => {
        console.error(err)
        showSpinner(false);
        openSnackbar('error', t("react.grouping.copygroupingdialog.error.while.saving"));
      });
    };

    // -- Translations -------------------------------------

  const [wordsTranslations, setWordsTranslations] = useState(new Map());
  const [displayTranslations, setDisplayTranslations] = useState(false);

  const handleDisplayTranslations = (newState) => {
    setDisplayTranslations(newState);

    // get translations of all displayed words
    if (newState) {
        let allWords = [];

        // get words from the left column
        buckets[0].groups[0].items.forEach(item => {
            allWords.push(item.word);
        });
        // get words from the trashbin
        if (buckets[0].groups[1].length > 1) {
            buckets[0].groups[1].items.forEach(item => {
                allWords.push(item.word);
            });
        }

        // get words from all groups (4 columns)
        getAllGroups().forEach(group => {
          group.items.forEach(item => {
              allWords.push(item.word);
          });
        });

        // call the translation service
        codificationService.translateEntries(allWords, project.language, preferredLanguage)
            .then(translationsResult => {
                setWordsTranslations(new Map(Object.entries(translationsResult.data)));
            });
    } else {
        setWordsTranslations(new Map());
    }
  };

  // returns only 3 translations max
  const getTranslationOfOriginalWord = (originalWord) => {
      let trad = wordsTranslations.get(originalWord);
      return trad === undefined ? '' : ` (${trad.slice(0, 3).map(word => word).join(', ')})`;
  };

  // -- Drag & Drop -------------------------------------

  const [draggedElement, setDraggedElement] = useState(undefined);

  const onDragStart = (start) => {
    setDraggedElement(start)
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (source === null || destination === null) {
      setDraggedElement(undefined);
      return;
    }

    let bucketRegex = /^bucket-([-0-9]+)$/;
    let tagRegex = /^tagzone-([-0-9]+)$/;
    let groupBodyRegex = /^bucket-([-0-9]+)-group-body-([-0-9]+)$/;
    let groupHeaderWordRegex = /^bucket-([-0-9]+)-group-header-word-([-0-9]+)$/;
    let groupHeaderTagRegex = /^bucket-([-0-9]+)-group-header-tag-([-0-9]+)$/;

    if(bucketRegex.test(source.droppableId) && bucketRegex.test(destination.droppableId)) {
      onDragEndGroupBetweenBuckets(bucketRegex, source, destination);
    } else if(tagRegex.test(source.droppableId) && tagRegex.test(destination.droppableId)) {
      onDragEndReorderTag(tagRegex, source, destination);
    } else if(groupBodyRegex.test(source.droppableId) && groupBodyRegex.test(destination.droppableId)) {
      onDragEndItemToGroupBody(groupBodyRegex, source, destination);
    } else if(groupBodyRegex.test(source.droppableId) && groupHeaderWordRegex.test(destination.droppableId)) {
      onDragEndItemToGroupHeaderWord(groupBodyRegex, groupHeaderWordRegex, source, destination);
    } else if(tagRegex.test(source.droppableId) && groupHeaderTagRegex.test(destination.droppableId)) {
      onDragEndTagToGroup(tagRegex, groupHeaderTagRegex, source, destination);
    }

    setDraggedElement(undefined);
  };

  const onDragEndGroupBetweenBuckets = (bucketRegex, source, destination) => {
    let sourceBucketIdx = Number(bucketRegex.exec(source.droppableId)[1]);
    let sourceBucket = buckets[sourceBucketIdx];
    let sourceGroupIdx = source.index;

    let destBucketIdx = Number(bucketRegex.exec(destination.droppableId)[1]);
    let destBucket = buckets[destBucketIdx];
    let destGroupIdx = destination.index;

    let itemToMove = sourceBucket.groups.splice(sourceGroupIdx, 1)[0];
    itemToMove.bucket = destBucket.index;
    destBucket.groups.splice(destGroupIdx, 0, itemToMove);

    saveGroups(sourceBucketIdx, sourceBucket.groups);
    saveGroups(destBucketIdx, destBucket.groups);
  };

  const onDragEndReorderTag = (tagRegex, source, destination) => {
    let sourceTagIdx = source.index;
    let destTagIdx = destination.index;

    let itemToAdd = tags.splice(sourceTagIdx, 1)[0];
    tags.splice(destTagIdx, 0, itemToAdd);

    const positionsMap = {};
    tags.forEach((tag, index) => {
        positionsMap[tag.id] = index;
    });
    groupingService.reorderTags(project.id, positionsMap);
  };

  const onDragEndItemToGroupBody = (groupBodyRegex, source, destination) => {
    // AVOID USE OF source.index because words are visualy sorted
    let draggedElementRegex = /^group-([-0-9]+)-word-([-0-9]+)$/;

    let newBuckets = [...buckets];

    let sourceBucketIdx = Number(groupBodyRegex.exec(source.droppableId)[1]);
    let sourceGroupId = Number(groupBodyRegex.exec(source.droppableId)[2]);
    let sourceWordId = Number(draggedElementRegex.exec(draggedElement.draggableId)[2]);
    let sourceGroupIdx = indexOf(newBuckets[sourceBucketIdx].groups, 'id', sourceGroupId);

    let destBucketIdx = Number(groupBodyRegex.exec(destination.droppableId)[1]);
    let destGroupId = Number(groupBodyRegex.exec(destination.droppableId)[2]);
    let destGroupIdx = indexOf(buckets[destBucketIdx].groups, 'id', destGroupId);

    let selectedItems = getCurrentSelectedInGroup(newBuckets[sourceBucketIdx].groups[sourceGroupIdx]);

    // if there are selected Items : drag&drop only selected ONLY IF THE DRAGGED ONE IS IN THE LIST
    if(selectedItems.length > 0 && indexOf(selectedItems, 'id', sourceWordId) >= 0) {
        selectedItems.forEach((word) => {
          let sourceWordIdx = indexOf(newBuckets[sourceBucketIdx].groups[sourceGroupIdx].items, 'id', word.id);
          let wordToMove = newBuckets[sourceBucketIdx].groups[sourceGroupIdx].items.splice(sourceWordIdx, 1)[0];
          wordToMove.checked = false;
          newBuckets[destBucketIdx].groups[destGroupIdx].items.push(wordToMove);
        });
    } else {
      // drag the given item
      let sourceWordIdx = indexOf(newBuckets[sourceBucketIdx].groups[sourceGroupIdx].items, 'id', sourceWordId);
      let wordToMove = newBuckets[sourceBucketIdx].groups[sourceGroupIdx].items.splice(sourceWordIdx, 1)[0];
      wordToMove.checked = false;
      newBuckets[destBucketIdx].groups[destGroupIdx].items.push(wordToMove);
    }

    newBuckets[sourceBucketIdx].groups[sourceGroupIdx].modifiedAt = Date.now();
    newBuckets[destBucketIdx].groups[destGroupIdx].modifiedAt = Date.now();

    saveConnections(sourceGroupId, newBuckets[sourceBucketIdx].groups[sourceGroupIdx].items);
    saveConnections(destGroupId, newBuckets[destBucketIdx].groups[destGroupIdx].items);

    setBuckets(newBuckets);
  };

  const onDragEndItemToGroupHeaderWord = (groupBodyRegex, groupHeaderWordRegex, source, destination) => {
    // AVOID USE OF source.index because words are visualy sorted
    let draggedElementRegex = /^group-([-0-9]+)-word-([-0-9]+)$/;

    let newBuckets = [...buckets];

    let sourceBucketIdx = Number(groupBodyRegex.exec(source.droppableId)[1]);
    let sourceGroupId = Number(groupBodyRegex.exec(source.droppableId)[2]);
    let sourceWordId = Number(draggedElementRegex.exec(draggedElement.draggableId)[2]);
    let sourceGroupIdx = indexOf(newBuckets[sourceBucketIdx].groups, 'id', sourceGroupId);

    let destBucketIdx = Number(groupHeaderWordRegex.exec(destination.droppableId)[1]);
    let destGroupId = Number(groupHeaderWordRegex.exec(destination.droppableId)[2]);
    let destGroupIdx = indexOf(buckets[destBucketIdx].groups, 'id', destGroupId);

    let selectedItems = getCurrentSelectedInGroup(newBuckets[sourceBucketIdx].groups[sourceGroupIdx]);

    // if there are selected Items : drag&drop only selected ONLY IF THE DRAGGED ONE IS IN THE LIST
    if(selectedItems.length > 0 && indexOf(selectedItems, 'id', sourceWordId) >= 0) {
        selectedItems.forEach((word) => {
          let sourceWordIdx = indexOf(newBuckets[sourceBucketIdx].groups[sourceGroupIdx].items, 'id', word.id);
          let wordToMove = newBuckets[sourceBucketIdx].groups[sourceGroupIdx].items.splice(sourceWordIdx, 1)[0];
          wordToMove.checked = false;
          newBuckets[destBucketIdx].groups[destGroupIdx].items.push(wordToMove);
        });
    } else {
      // drag the given item
      let sourceWordIdx = indexOf(newBuckets[sourceBucketIdx].groups[sourceGroupIdx].items, 'id', sourceWordId);
      let wordToMove = newBuckets[sourceBucketIdx].groups[sourceGroupIdx].items.splice(sourceWordIdx, 1)[0];
      wordToMove.checked = false;
      newBuckets[destBucketIdx].groups[destGroupIdx].items.push(wordToMove);
    }

    newBuckets[sourceBucketIdx].groups[sourceGroupIdx].modifiedAt = Date.now();
    newBuckets[destBucketIdx].groups[destGroupIdx].modifiedAt = Date.now();

    saveConnections(sourceGroupId, newBuckets[sourceBucketIdx].groups[sourceGroupIdx].items);
    saveConnections(destGroupId, newBuckets[destBucketIdx].groups[destGroupIdx].items);

    setBuckets(newBuckets);
  };

  const onDragEndTagToGroup = (tagRegex, groupHeaderTagRegex, source, destination) => {
    let sourceTagIdx = source.index;
    let tagToApply = tags[sourceTagIdx];

    let destBucketIdx = Number(groupHeaderTagRegex.exec(destination.droppableId)[1]);
    let destBucket = buckets[destBucketIdx];

    let destGroupId = Number(groupHeaderTagRegex.exec(destination.droppableId)[2]);
    let destGroup = findById(destBucket.groups, destGroupId);

    if (destGroup === undefined) return;

    if (destGroup.trashbin) return;

    mapTagToGroup(destGroup, tagToApply);
  };

  const getCurrentSelectedInGroup = (group) => {
    return group.items.filter(word => word.checked === true);
  }

  const removeAllEmptyGroups = () => {
    getAllGroups()
      .filter(g => g.items.length === 0 && g.id > 0)
      .forEach(g => handleDeleteGroupCallback(g));
  }

  // -- Copy grouping ------------------------------------------------------------

  const [openCopyGroupingDialog, setOpenCopyGroupingDialog] = useState(false);

  if (loadData !== LoadData.Loaded) {
    return (
      <LinearProgress />
    );
  }

  return (<>
      <Helmet title={project.name} />
              <GroupingToolbar
                  {...props}
                  title={project.name}
                  projectId={project.id}
                  handleNewTag={e => handleOpenCreateEditTag(e, undefined)}
                  handleNewGroup={() => setOpenNewGroupDialog(true)}
                  handleClickDownloadGroupingFile={handleClickDownloadGroupingFile}
                  handleClickCopyGrouping={() => setOpenCopyGroupingDialog(true)}
                  enableTranslations={project.language !== preferredLanguage}
                  displayTranslations={displayTranslations}
                  handleDisplayTranslations={() => handleDisplayTranslations(!displayTranslations)}
                  handleAutoGenGroupingCallback={handleAutoGenGroupingCallback}
                  isDisabled={project === undefined}
                  handleExpandBucket={handleExpandBucket}
                  removeAllEmptyGroups={removeAllEmptyGroups}
                  keywords={keywords}
                  setKeywords={setKeywords}
              />
              <DragDropContext
                  onDragStart={onDragStart}
                  onDragEnd={onDragEnd}
                >
                  <div className={classes.root}>
                  <div className={classes.scrollerarea}>
                    <Bucket
                      {...props}
                      bucket={buckets[0]}
                      groupingService={groupingService}
                      createGroupFromWordCallback={handleCreateGroupFromContextMenuCallback}
                      moveToTrashbinCallback={handleToTrashbinFromContextMenuCallback}
                      handleRemoveFromGroupCallback={handleRemoveFromGroupFromContextMenuCallback}
                      deleteGroupCallback={handleDeleteGroupCallback}
                      getTranslationOfOriginalWord={getTranslationOfOriginalWord}
                      expandedBucketEventValue={expandedBuckets[0]}
                      keywords={keywords}
                    />
                  </div>
                  <div className={classes.groupsarea}>
                      {
                          [1, 2, 3, 4].map(i => {
                              return <div key={i} className={classes.groupStyle}>
                              <Bucket
                                {...props}
                                bucket={buckets[i]}
                                groupingService={groupingService}
                                createGroupFromWordCallback={handleCreateGroupFromContextMenuCallback}
                                moveToTrashbinCallback={handleToTrashbinFromContextMenuCallback}
                                handleRemoveFromGroupCallback={handleRemoveFromGroupFromContextMenuCallback}
                                deleteGroupCallback={handleDeleteGroupCallback}
                                getTranslationOfOriginalWord={getTranslationOfOriginalWord}
                                expandedBucketEventValue={expandedBuckets[i]}
                                keywords={keywords}
                              />
                             </div>
                          })
                      }
                  </div>
                  <div>
                      <div className={classes.tagsarea}>
                      <Droppable
                        droppableId={'tagzone-0'}
                        key={3}
                        index= {3}
                        type={"tag"}
                      >
                      {(provided, snapshot) => (
                          <div ref={provided.innerRef} {...provided.droppableProps} style={{minHeight: '50px'}}>
                          {
                              tags.map((tag, index) => {
                                  return (
                                      <Draggable
                                        draggableId={`tagzone-0-tag-${tag.id}`}
                                        key={`tagzone-0-tag-${tag.id}`}
                                        index={index}
                                        type={"tag"}
                                      >
                                      {(provided, snapshot) => (
                                          <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                          >
                                          <Chip key={tag.id} label={tag.label}
                                              icon={<FiberManualRecordIcon fontSize="large" style={{ color: tag.color }} />}
                                              onDelete={e => handleDeleteTag(e, tag.id)}
                                              variant="outlined"
                                              onClick={e => handleOpenCreateEditTag(e, tag)}
                                              style={{marginBottom: '5px'}}
                                          />
                                          </div>
                                          )}
                                      </Draggable>
                                  )
                              })
                          }
                          {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                      </div>
                  </div>
              </div>
              </DragDropContext>
              <CreateEditTagDialog
                  {...props}
                  open={openTagDialog}
                  handleApply={handleCreateTagCallback}
                  handleClose={() => setOpenTagDialog(false)}
                  tag={selectedTag}
                  groups={getAllGroups()}
              />
              <CreateGroupDialog
                  {...props}
                  open={openNewGroupDialog}
                  handleApply={handleCreateGroupCallback}
                  handleClose={() => setOpenNewGroupDialog(false)}
                  projectId={project.id}
              />
              <CopyGroupingDialog
                  {...props}
                  openState={openCopyGroupingDialog}
                  callbackAfterConfirm={handleConfirmCopyGroupingCallback}
                  callbackAfterCancel={() => setOpenCopyGroupingDialog(false)}
              />
          </>
  );
};
