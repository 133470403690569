import React, { useState, useEffect } from 'react';
import { tss } from 'tss-react/mui';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { Typography } from '@mui/material';
import PromptsService from '../../services/PromptsService';
import CircularProgress from '@mui/material/CircularProgress';
import { RiAiGenerate } from "react-icons/ri";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { capitalizeFirstLetter } from '../../utils/utils';

const promptsService = new PromptsService();

const useStyles = tss
  .withParams()
  .create(({ theme, fontSize }) => ({
    paper: {
      color: theme.palette.text.secondary,
      marginLeft: theme.spacing(2),
      marginBottom: theme.spacing(2),
      fontSize: `${fontSize}px`, 
    },
    paddingComponent: {
      margin: 20
    },
    typo: {
      fontSize: 18, 
      fontWeight: 500, 
      marginLeft: 5,
      color: theme.palette.text.primary,
    },
    divTypo: {
      backgroundColor: '#cae2e2', 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center',
      paddingTop: 15, 
      paddingBottom: 10,
      paddingLeft: 5,
      paddingRight: 5
    },
    rowReverse: {
      display: 'flex', 
      flexDirection: 'row-reverse',
      padding: '15px 0px',
    },
    justify: {
      textAlign: 'justify'
    },
    paddingBottomCenter: {
      textAlign: 'center', 
      paddingBottom: 15
    }
}));

export default function AiAnalysisCard(props) {

  const { t, preferredLanguage, stimuli, configuration, openSnackbar, dashboardService, sequences, showAiAnalysis, setAiAnalysisLoading } = props;

  const { classes } = useStyles();

  const [analysis, setAnalysis] = useState(undefined);
  const [tallestStimulusCard, setTallestStimulusCard] = useState(0);
  const [displayArrowExtend, setDisplayArrowExtend] = useState(false);
  const [realHeightOfAiAnalysisCard, setRealHeightOfAiAnalysisCard] = useState(0);

  useEffect(() => {
    setAiAnalysisLoading(true);
    setAnalysis(undefined);
    setRealHeightOfAiAnalysisCard(0);
    setDisplayArrowExtend(false);

    var arrayStimulus = [];
    stimuli.filter(stimulus => (configuration.showHiddenCards || (configuration.hidden || [])
    .indexOf(stimulus.id) === -1) && stimulus.id >= (configuration.showTotalCard ? -100 : 0))
    .forEach(s => {
      var title = dashboardService.getStimulusName(s, configuration, stimuli, sequences, t);
      arrayStimulus.push({name: title, id: s.id, r3mScore: Math.round(s.weightedScore/100), attributes: []})
      Object.values(s.groups).forEach(g => {
        g.filter(d => !d.missing)
        .filter(d => !dashboardService.isInRedZone(d.score, configuration) || d.diamond || d.relevant)
        .forEach(d => {
          arrayStimulus[arrayStimulus.length-1].attributes.push({name: capitalizeFirstLetter(d.title), score: Math.round(d.score), relevant: "no"});
        })
      })
    })
    arrayStimulus.forEach(s => {
      s.attributes.sort((a, b) => b.score - a.score);
    })

    getAnalysis(highlightSignificantDifferences(arrayStimulus))
    findTallestStimulusCard(arrayStimulus)
  }, [showAiAnalysis])

  useEffect(() => {
    if (analysis === undefined) {
      return
    }
    const analysisCard = document.getElementById('aiAnalysisCard');
    if (analysisCard) {
      setRealHeightOfAiAnalysisCard(analysisCard.clientHeight)
    }
  }, [analysis])

  const getAnalysis = (arrayStimulus) => {
    const data = `{"elements":${JSON.stringify(arrayStimulus)}}`
    const endpoint = showAiAnalysis === 1 ? 'dashboard/analyse' : 'dashboard/analyseRecommendation';

    promptsService.fetchAllPromptsByTypeAndLang(endpoint, preferredLanguage)
      .then(result => {
        if (result.data.total > 0) {
          const promptAndData = result.data.hits[0].content + data;
          return promptsService.askSimpleQuestion(preferredLanguage, promptAndData);
        } else {
          throw new Error("No prompts found");
        }
      })
      .then(resultContent => {
        setAnalysis(resultContent.data.answer);
      })
      .catch(err => {
        openSnackbar('error', t("react.error.fetch.message"));
        setAnalysis(t("react.project.chatgptcard.error.fetch"));
      })
      .finally(f => {
        setAiAnalysisLoading(false);
      });
  }

  const copyToClipboard = () => {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = analysis;
    const textToCopy = tempElement.innerText || tempElement.textContent;
    navigator.clipboard.writeText(textToCopy)
    .then(() => {
      openSnackbar('success', t("react.project.chatgptcard.snackbar.success"));
    })
    .catch(err => {
      openSnackbar('error', t("react.project.chatgptcard.snackbar.error"));
    });
  };

  const findTallestStimulusCard = (arrayStimulus) => {
    let tallestCard = 0;
    arrayStimulus.forEach(s => {
      const stimulusCard = document.getElementById(`stimulusCard${s.id}`);
      if (stimulusCard.clientHeight > tallestCard) {
        tallestCard = stimulusCard.clientHeight;
      }
    })
    setTallestStimulusCard(tallestCard)
  }

  const getHeightOfCardAiAnalysisCard = () => {
    if (realHeightOfAiAnalysisCard > tallestStimulusCard) {
      if (!displayArrowExtend) {
        return tallestStimulusCard-85;
      } else {
        return realHeightOfAiAnalysisCard-100;
      }
    } else {
      return realHeightOfAiAnalysisCard-100;
    }
  }

  const highlightSignificantDifferences = (data) => {
    const differenceRequired = configuration.comparisonTriggeringValue;
    
    data.forEach((element, index) => {
      element.attributes.forEach(attr => {
        const name = attr.name;
        const score = attr.score;
        let significant = true;

        if (Math.abs(score) > differenceRequired) {
          data.forEach((otherElement, otherIndex) => {
            if (index !== otherIndex) {
              const otherAttr = otherElement.attributes.find(g => g.name === name);
              if (otherAttr) {
                const otherScore = otherAttr.score;
                if (Math.abs(score - otherScore) < differenceRequired || 
                  (score > 0 && otherScore > score) || 
                  (score < 0 && otherScore < score)) {
                  significant = false;
                } 
              } 
            }
          });
        } else {
          significant = false;
        }
        if (significant) {
          attr.relevant = "yes";
        }
      });
    });

    return data;
  }

  return (
    <div style={{ width: '407px'}} >
      <Paper className={classes.paper} id={`aiAnalysisCard`}>
        <div style={{height: realHeightOfAiAnalysisCard === 0 ? '100%' : getHeightOfCardAiAnalysisCard(), overflow: 'hidden', paddingBottom: 10}}> 
          <div className={classes.divTypo}>
            <RiAiGenerate color="primary" size={24}/>&nbsp;<Typography className={classes.typo}>{showAiAnalysis === 1 ? t("react.project.chatgptcard.title.option1") : t("react.project.chatgptcard.title.option2")}</Typography>
          </div>
          <div className={classes.paddingComponent}>
            {analysis === undefined ? 
              <div className={classes.paddingBottomCenter}>
                <CircularProgress />
              </div> :
              <div className={classes.justify} dangerouslySetInnerHTML={{ __html: analysis }}></div>
            }
          </div>
        </div>
        {analysis !== undefined && 
          <div style={{height: 70}} className={classes.rowReverse}>
            <Tooltip title={t('react.project.chatgptcard.tooltip.clipboard')}>
              <IconButton onClick={() => copyToClipboard()}>
                <ContentCopyIcon/>
              </IconButton>
            </Tooltip>
            {realHeightOfAiAnalysisCard > tallestStimulusCard && (
              <IconButton onClick={() => setDisplayArrowExtend(!displayArrowExtend)}>
                {displayArrowExtend ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            )}
          </div>
        }
      </Paper>
    </div>
  )
}