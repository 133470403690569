import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from 'tss-react/mui';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { DropzoneArea } from "mui-file-dropzone";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import DeleteIcon from '@mui/icons-material/Delete';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import FolderIcon from '@mui/icons-material/Folder';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import LinearProgress from '@mui/material/LinearProgress';
import ProjectService from '../../services/ProjectService';
import { LoadData } from '../../Constants.js'
import GetAppIcon from '@mui/icons-material/GetApp';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import PopupTwoButtons from '../shared/PopupTwoButtons/PopupTwoButtons';
import EditIcon from '@mui/icons-material/Edit';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import DialogTitleWithCloseIcon from '../shared/DialogTitleWithCloseIcon/DialogTitleWithCloseIcon';
import InputAdornment from '@mui/material/InputAdornment';

const projectService = new ProjectService();

const useStyles = makeStyles()(theme => ({
  button: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(1),
    float: 'right'
  },
  buttonDownloadAll: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(1),
    float: 'right'
  },
  dropzoneArea: {
    backgroundColor: '#F0F0F0',
  },
  imgUpload: {
    position: 'absolute',
    left: '50%',
    top: '10vh',
    transform: 'translate(-50%)',
    zIndex: 1000,
    backgroundColor: 'white',
    padding: '40px',
    minHeight: '400px',
    minWidth: '400px'
  },
  closeIconImg: {
    position: 'absolute',
    right: '-5px',
    top: '-5px',
  }
}));

export default function PanelFiles(props) {

  const { t, project, openSnackbar, showSpinner } = props;

  const { classes } = useStyles();

  const [existingFiles, setExistingFiles] = useState([]);
  const [dropzoneFiles, setDropzoneFiles] = useState([]);
  const isUploadButtonDisabled = dropzoneFiles.length === 0;
  const [showImg, setShowImg] = useState({ show: false, src: '' });
  const [openConfirmDelete, setOpenConfirmDelete] = useState(0);

  const [openRenameFileId, setOpenRenameFileId] = useState(0);
  const [newNameFile, setNewNameFile] = useState('');
  const [extensionEditingFile, setExtensionEditingFile] = useState('');

  const [loadData, setLoadData] = useState(LoadData.Load);
  useEffect(() => {
    if (loadData !== LoadData.Load) return;

    setLoadData(LoadData.Loading);

    if (project.id > 0) {
      projectService.fetchFilesInDefaultSequence('miscellaneous', project.id)
        .then(result => {
          setExistingFiles(result.data.hits);
        })
        .catch(err => {
          openSnackbar('error', t("react.project.error.while.loading"));
        }).finally(() => {
          setLoadData(LoadData.Loaded);
        });
    } else {
      setLoadData(LoadData.Loaded);
    }
  }, [loadData, project]);

  const handleOnChangeInDropzoneArea = (files) => {
    setDropzoneFiles(files);
  };

  const handleClickUploadFilesInDropzoneArea = (event) => {
    event.preventDefault();
    showSpinner(true);

    var promises = [];
    for (var i = 0; i < dropzoneFiles.length; i++) {
      promises.push(projectService.uploadProjectFileInDefaultSequence(project.id, 'miscellaneous', dropzoneFiles[i], false));
    }

    Promise.all(promises)
      .then(results => {
        setLoadData(LoadData.Load);
      })
      .catch(err => {
        openSnackbar('error', t("react.project.error.upload.file"));
      })
      .finally(function () {
        showSpinner(false);
      });
  };

  const openImg = (event, projectFile) => {
    event.preventDefault();
    projectService.openProjectFile('miscellaneous', projectFile.id)
      .then(response => {
        setShowImg({ show: true, src: response });
      })
      .catch(err => {
        setShowImg({ show: false, src: '' });
        openSnackbar('error', t("react.project.error.download.file"));
      });
  }

  const handleDeleteProjectFile = () => {
    let projectFileId = openConfirmDelete;
    setOpenConfirmDelete(0);

    showSpinner(true);

    projectService.deleteProjectFile('miscellaneous', projectFileId)
      .then(result => {
        setLoadData(LoadData.Load);
      })
      .catch(err => {
        openSnackbar('error', t("react.project.error.delete.file"));
      })
      .finally(function () {
        showSpinner(false);
      });
  };

  const handleClickDownloadProjectFile = (event, fileId) => {
    event.preventDefault();
    projectService.downloadProjectFile('miscellaneous', fileId)
      .catch(err => {
        openSnackbar('error', t("react.project.error.download.file"));
      });
  };

  const handleClickDownloadAllProjectFile = (event) => {
    existingFiles.forEach(oneFile => handleClickDownloadProjectFile(event, oneFile.id));
  };

  const isAnImg = (contentType) => {
    return contentType.startsWith('image/');
  }

  const openRenamePopup = (f) => {
    setOpenRenameFileId(f.id);
    setExtensionEditingFile(f.name.split(".").pop());
    setNewNameFile(f.name.substring(0, f.name.lastIndexOf('.')));
  }

  const closeRenamePopup = () => {
    setOpenRenameFileId(0);
    setNewNameFile('');
    setExtensionEditingFile('');
  }

  const renameProjectFile = () => {
    // copy file id and hide the popup
    let fileId = openRenameFileId;
    setOpenRenameFileId(0);

    showSpinner(true);

    let filename = `${newNameFile}.${extensionEditingFile}`;

    projectService.renameProjectFile(filename, fileId)
      .then(res => {
        openSnackbar('success', t("react.project.success.rename.file"));
        const temporaryFiles = [...existingFiles]
        temporaryFiles.find(f => f.id === fileId).name = filename;
        setExistingFiles(temporaryFiles);
      })
      .catch(err => {
        if (err.response.status === 409 /* 409 = Conflict */) {
          openSnackbar('error', t("react.project.error.rename.file.exists"));
        } else {
          openSnackbar('error', t("react.project.error.rename.file"));
        }
      })
      .finally(() => {
        closeRenamePopup();
        showSpinner(false);
      })
  }

  if (loadData !== LoadData.Loaded) {
    return (
      <Grid item xs={12} container>
        <Grid item xs={12}>
          <LinearProgress />
        </Grid>
      </Grid>
    );
  }

  const existingFilesContent = existingFiles.map((f) =>
    <ListItem button key={f.id} >
      <ListItemAvatar onClick={(e) => handleClickDownloadProjectFile(e, f.id)}>
        <Avatar title={t("react.project.edit.filestab.button.download")} >
          <FolderIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={f.name}
        secondary={f.description}
        onClick={(e) => handleClickDownloadProjectFile(e, f.id)}
      />
      <ListItemSecondaryAction>
        {isAnImg(f.contentType) &&
          <IconButton
            title={t("react.project.edit.filestab.button.view")}
            edge="end"
            onClick={(e) => openImg(e, f)}
            size="large">
            <VisibilityIcon />
          </IconButton>
        }
        <IconButton
          title={t("react.project.edit.filestab.button.download")}
          edge="end"
          onClick={(e) => handleClickDownloadProjectFile(e, f.id)}
          size="large">
          <GetAppIcon />
        </IconButton>
        <IconButton
          title={t("react.project.edit.filestab.button.rename")}
          edge="end"
          onClick={(e) => openRenamePopup(f)}
          size="large">
          <EditIcon />
        </IconButton>
        <IconButton
          title={t("react.project.edit.filestab.button.delete")}
          edge="end"
          onClick={(e) => setOpenConfirmDelete(f.id)}
          size="large">
          <DeleteIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );

  return (
    <Grid item xs={12} container>
      <PopupTwoButtons
        variant='question'
        openState={openConfirmDelete !== 0}
        callbackOnclose={() => setOpenConfirmDelete(0)}
        callbackOnclickLeftButton={() => setOpenConfirmDelete(0)}
        callbackOnclickRightButton={handleDeleteProjectFile}
        title={t('react.confirm.delete.file.title')}
        content={t('react.confirm.delete.file.body')}
        leftButton={t('react.button.cancel')}
        rightButton={t('react.button.delete')}
      />
      <Dialog 
        maxWidth={"xs"}
        fullWidth={true}
        open={openRenameFileId > 0}
        onClose={() => closeRenamePopup()}
      >
        <DialogTitleWithCloseIcon
          title={t("react.project.edit.filestab.rename.title")}
          callbackOnclose={() => closeRenamePopup()}
        />
        <DialogContent>
          <TextField
            fullWidth
            value={newNameFile}
            onChange={(e) => setNewNameFile(e.target.value)}
            variant="outlined"
            size="small"
            InputProps={{
              endAdornment: <InputAdornment position="start">.{extensionEditingFile}</InputAdornment>,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => closeRenamePopup()} color="primary">{t('react.button.cancel')}</Button>
          <Button onClick={() => renameProjectFile()} color="primary">{t("react.button.apply")}</Button>
        </DialogActions>
      </Dialog>
      <Grid item xs={6}>
        <Typography variant="h6" >
          {t("react.project.edit.filestab.title")}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        {/* https://github.com/Yuvaleros/material-ui-dropzone */}
        <DropzoneArea
          dropzoneText={t("react.project.edit.filestab.selectfiles")}
          onChange={handleOnChangeInDropzoneArea}
          maxFileSize={10000000 /* up to 10 mo per file is allowed */}
          filesLimit={5}
          showFileNames={true}
          dropzoneClass={classes.dropzoneArea}
        />
        <Button
          id={project.id}
          title=""
          disabled={isUploadButtonDisabled}
          onClick={handleClickUploadFilesInDropzoneArea}
          variant="outlined"
          size="large"
          color="primary"
          startIcon={<CloudUploadIcon />}
          className={classes.button}
        >
          {t("react.project.edit.filestab.button.send")}
        </Button>
      </Grid>

      <Grid item xs={12}>
        {showImg.show &&
          <Paper className={classes.imgUpload}>
            <Tooltip title={t('react.project.edit.filestab.close.image')} className={classes.closeIconImg}>
              <IconButton onClick={() => setShowImg({ show: false, src: '' })} size="large">
                <CloseIcon />
              </IconButton>
            </Tooltip>
            <img src={showImg.src} style={{ maxWidth: '1024px' }} />
          </Paper>
        }
        <List dense={false}>
          {existingFilesContent}
        </List>
        {existingFiles.length > 1 &&
        <Button
          variant="outlined"
          size="large"
          color="primary"
          startIcon={<GetAppIcon />}
          className={classes.buttonDownloadAll}
          onClick={handleClickDownloadAllProjectFile}
        >
          {t("react.project.edit.filestab.button.downloadAll")}
        </Button>
      }
      </Grid>
    </Grid>
  );
}
