import React, { useState, useEffect } from 'react';
import { tss } from 'tss-react/mui';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitleWithCloseIcon from '../shared/DialogTitleWithCloseIcon/DialogTitleWithCloseIcon';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { FilterIcon } from '../../common';
import { findById, indexOf, appendSuffixIfGreaterThan, formatPValue, isBlank } from '../../utils/utils';
import { DashboardConstants, TOTAL_EXCEPT_STIMULUS_ID, TOTAL_STIMULUS_ID, getViewThemes } from '../../Constants';
import GroupChart from './GroupChart'
import ThemeChart from './ThemeChart'
import FilterDialog from './FilterDialog'
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import StimulusCardContextMenu from './StimulusCardContextMenu';
import InfoIcon from '@mui/icons-material/Info';
import TagCloud from './TagCloud';

const useStyles = tss
  .withParams()
  .create(({ theme, fontSize }) => ({
    paper: {
      padding: theme.spacing(0.25),
      color: theme.palette.text.secondary,
      marginLeft: theme.spacing(2),
      marginBottom: theme.spacing(2),
      fontSize: `${fontSize}px`, 
    },
    comparewithSelector: {
      marginLeft: theme.spacing(3),
      marginBottom: theme.spacing(1),
      minWidth: 200,
      width: '70%',
    },
    grouppanel: {
    },
    cardname: {
      marginLeft: '10px',
      marginBottom: '-17px'
    },
    nbanswers: {
      marginLeft: '10px',
      marginBottom: '50px'
    },
    addicts: {
      marginLeft: '10px',
      marginBottom: '0px',
      verticalAlign: 'bottom',
      fontSize: '25px',
    },
    thermometerpanel: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      position: 'relative',
      bottom: '70px',
      marginTop: '10px',
      height: '70px',
      zIndex: '2',
    },
    filterpanel: {
      display: 'flex',
      justifyContent: 'left',
      flexWrap: 'wrap',
      '& > *': {
        marginLeft: theme.spacing(0.5),
      },
      margin: theme.spacing(1)
    },
    comparewithpanel: {
      maxHeight: '70px',
      height: '70px',
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'nowrap',
      '& > *': {
        margin: theme.spacing(0.5),
      },
      marginLeft: theme.spacing(1),
      marginTop: theme.spacing(1)
    },
    toolbar: {
      zIndex: '100',
      position: 'relative',
      top: '20px',
      right: '20px',
      minHeight: '45px'
    },
    showhide: {
      position: 'relative',
      top: '8px',
      left: '-5px',
      zIndex: '100',
      width: '30px'
    },
    marginBottomChips: {
      marginBottom: '6px',
    },
    infoIconHelp: {
      top: '8px',
      left: '5px',
      opacity: '0.8',
      transform: 'scale(0.8)',
    },
    imageIcon: {
      float: 'left'
    },
    loversRejectorsBlock: {
      display: 'flex',
      marginTop: '10px'
    },
    loversRejectorsBox: {
      width: '80px',
      display: 'flex',
      flexDirection: 'column'
    },
    noSignificativityIcons: {
      minHeight: '0px', 
      paddingLeft: '0px'
    },
    hasSignificativityIcons: {
      minHeight: '29px', 
      paddingLeft: '10px'
    }
}));

const viewThemes = getViewThemes();

export default function StimulusCard(props) {

  const { t, stimulus, stimuli, sequences, configuration, compareWiths, 
    dashboardService, generateImage, stimulusCardToImage, collectImageCallback,
    setConfiguration, applyConfiguration, tags
  } = props;

  const dashboardFilters = props.filters;

  const { classes } = useStyles({ fontSize: configuration.dashboardFontSize });

  const [iconTheme, setIconTheme] = useState(viewThemes[viewThemes.default]);

  const [openRenameDialog, setOpenRenameDialog] = useState(false);
  const [openFilterDialog, setOpenFilterDialog] = useState(false);

  const [title, setTitle] = useState(stimulus.name);
  const [imageTitle, setImageTitle] = useState(stimulus.name);
  const [ignoreFiltersOnExport, setIgnoreFiltersOnExport] = useState(true);
  const [ignoreCompareWithOnExport, setIgnoreCompareWithOnExport] = useState(true);
  const [hideThermometerArrow, setHideThermometerArrow] = useState(true);
  const [hideGroupArrows, setHideGroupArrows] = useState(true);

  const [significativity, setSignificativity] = useState(0);
  const [compareWith, setCompareWith] = useState();
  const [comparationMap, setComparationMap] = useState();
  const [filters, setFilters] = useState([]);
  const [engagementFilters, setEngagementFilters] = useState([]);
  const [cardId] = useState([`card_${stimulus.id}`.replace('-', '_')]);
  const [filterPanelId] = useState([`filterpanel_${stimulus.id}`.replace('-', '_')]);
  const [compareWithPanelId] = useState([`comparewithpanel_${stimulus.id}`.replace('-', '_')]);
  const [tagCloudData, setTagCloudData] = useState([]);

  useEffect(() => {
    if (!generateImage) return;
    stimulusCardToImage(
      cardId,
      filterPanelId,
      compareWithPanelId,
      ignoreFiltersOnExport,
      ignoreCompareWithOnExport,
      imageTitle,
    ).then((result) => {
      collectImageCallback(result);
    })

  }, [
    generateImage,
    cardId,
    filterPanelId,
    compareWithPanelId,
    ignoreFiltersOnExport,
    ignoreCompareWithOnExport,
  ]);

  useEffect(() => {
    if (configuration.iconTheme && viewThemes[configuration.iconTheme]) {
      setIconTheme(viewThemes[configuration.iconTheme]);
    }
  }, [configuration.iconTheme]);

  useEffect(() => {
    if (configuration && stimulus) {
      let compareWithStimulus = dashboardService.getCompareWith(stimulus, stimuli, configuration);
      if (compareWithStimulus) {
        setCompareWith(compareWithStimulus);
      }

      setComparationMap(dashboardService.buildComparationMap(compareWithStimulus));

      let newTitle = dashboardService.getStimulusName(stimulus, configuration, stimuli, sequences, t);
      setTitle(newTitle);

      let titleParts = [newTitle];
      let filterlist = [];
      let filters = (configuration.filters ? configuration.filters[stimulus.id] : {}) || {};
      for (let f in filters) {
        for (let v in filters[f]) {
          filters[f][v].filterId = Number(f);
          filterlist.push(filters[f][v]);
          titleParts.push(filters[f][v].value);
        }
      }

      let engagementFiltersList = [];
      let engagementFilters = (configuration.engagementFilters ? configuration.engagementFilters[stimulus.id] : []) || [];
      engagementFilters.forEach((engagementFilter, i) => {
        var ef = { id: engagementFilter, value: 'undefined' };
        if (engagementFilter === -1) {
          ef.value = t('react.dashboard.filterdialog.select.engagementType.rejectors');
        } else if (engagementFilter === 0) {
          ef.value = t('react.dashboard.filterdialog.select.engagementType.neutrals');
        } else if (engagementFilter === 1) {
          ef.value = t('react.dashboard.filterdialog.select.engagementType.lovers');
        }
        engagementFiltersList.push(ef);
        titleParts.push(ef.value);
      });

      setFilters(filterlist);
      setEngagementFilters(engagementFiltersList);
      setImageTitle(titleParts.join('-'));

      setIgnoreFiltersOnExport(!configuration.exportFilters || !filters || filters.length === 0);
      setIgnoreCompareWithOnExport(!configuration.exportCompareWith || !compareWithStimulus || compareWithStimulus.id === stimulus.id);
      setHideGroupArrows(!compareWithStimulus || !compareWithStimulus.id || compareWithStimulus.id === stimulus.id || stimulus.id < 0);

      let newSignificativityValue = 0;
      let newHideThermometerArrow = true;
      if (compareWithStimulus && stimulus.significativity && stimulus.significativity[compareWithStimulus.id] !== undefined && stimulus.significativity[compareWithStimulus.id].value !== undefined) {
        let sy = stimulus.significativity[compareWithStimulus.id].value;
        newSignificativityValue = Math.round(sy * 1000) / 1000;
        newHideThermometerArrow = (sy >= configuration.comparisonSignificativityValue / 100);
      }
      setSignificativity(newSignificativityValue);
      setHideThermometerArrow(newHideThermometerArrow);

      let newTagCloudData = [];
      if(configuration.showAsTagcloud) {
        Object.values(stimulus.groups).forEach((group) => {
            group
              .filter(d => !d.missing)
              .filter(d => !dashboardService.isInRedZone(d.score, configuration) || d.diamond || d.relevant)
              .forEach((element) => {
                newTagCloudData.push({
                  name: element.title,
                  weight: Math.round(element.score),
                  colorIndex: element.tag.position,
                });
              });
        });
       setTagCloudData(newTagCloudData);
      }
    }
  }, [configuration, stimuli, stimulus, dashboardService, compareWith, compareWiths, configuration.exportCompareWith, configuration.exportFilters]);

  const handleDeleteFilter = (filterId, valueId) => {
    const result = { ...configuration };
    if (result.filters[stimulus.id] && result.filters[stimulus.id][filterId]) {
      const index = indexOf(result.filters[stimulus.id][filterId], 'id', valueId);
      result.filters[stimulus.id][filterId].splice(index, 1);
      result.updated = true;
    }
    applyConfiguration(result);
  };

  const handleDeleteEngagementFilter = (engagementValue) => {
    const result = { ...configuration };
    if (result.engagementFilters[stimulus.id]) {
      const index = result.engagementFilters[stimulus.id].indexOf(engagementValue);
      result.engagementFilters[stimulus.id].splice(index, 1);
      result.updated = true;
    }
    applyConfiguration(result);
  };

  const handleCompareWith = event => {
    const result = { ...configuration };
    result.compareWith[stimulus.id] = event.target.value;
    result.updated = true;
    return result;
  };

  const handleRenameExample = (newname) => {
    setOpenRenameDialog(false);
    const result = { ...configuration };
    result.names[stimulus.id] = newname;
    result.updated = true;
    setTitle(newname);
    return result;
  }

  const generateCharts = () => {
    const uicharts = [];

    if (configuration.showThemeMode !== undefined && configuration.showThemeMode === true) {
      // this is a special mode with only themes displayed
      const block = (<ThemeChart
        {...props}
        key={`stimulus-card-themechart-${stimulus.id}`}
        groups={stimulus.groups}
        compareWith={compareWith}
        hideArrows={hideGroupArrows}
      />);

      uicharts.push(block);
    } else if (configuration.groupByTheme !== undefined && configuration.groupByTheme === true) {
      const tagColors = [...new Set(tags.map(item => item.color))];
      tagColors.forEach(c => {
        // get groups of this tags
        let data = stimulus.groups[c] || [];
        let groupComponent = (<GroupChart
          {...props}
          key={`stimulus-card-groupbytheme-${c}`}
          color={c}
          data={dashboardService.getGroupsOrderedForDisplay(data, configuration)}
          comparationMap={comparationMap}
          hideArrows={hideGroupArrows}
        />);
        uicharts.push(groupComponent);
      });
    } else {
      let data = [];
      const c = 'full';

      // clone all groups of all tags into "data"
      Object.values(stimulus.groups).forEach(c => {
        c.forEach(d => {
          data.push(d);
        });
      });

      const groupComponent = (<GroupChart
        {...props}
        key={`stimulus-card-normal-${c}`}
        color={c}
        data={dashboardService.getGroupsOrderedForDisplay(data, configuration)}
        comparationMap={comparationMap}
        hideArrows={hideGroupArrows}
      />);

      uicharts.push(groupComponent);
    }

    return (
      <div>
        {
          uicharts.map(c => c)
        }
        <RenameDialog
          {...props}
          open={openRenameDialog}
          stimulusName={title}
          handleClose={e => setOpenRenameDialog(false)}
          handleApply={e => setConfiguration(handleRenameExample(e))}
          title={t('react.projectdashboard.rename.stimulus.title')}
          label={t('react.projectdashboard.rename.stimulus.label')}
        />
        <FilterDialog
          {...props}
          open={openFilterDialog}
          stimulusId={stimulus.id}
          filters={dashboardFilters}
          handleClose={e => setOpenFilterDialog(false)}
          handleApply={e => applyConfiguration(e)}
        />
      </div>
    )
  }

  const renderLoversRejectors = () => {
    if(!configuration.showAddicts || isNaN(stimulus.addictsPercentil) || isNaN(stimulus.hatersPercentil)) {
      return null;
    }

    var syLovers = 0;
    var syRejectors = 0;
    var loversSignificativityIcon = undefined;
    var rejectorsSignificativityIcon = undefined;
    if(compareWith && stimulus.significativity && stimulus.significativity[compareWith.id] !== undefined) {
      if(stimulus.significativity[compareWith.id].loversValue !== undefined) {
        syLovers = stimulus.significativity[compareWith.id].loversValue;
        syLovers = Math.round(syLovers * 1000) / 1000;
        if(syLovers < configuration.comparisonSignificativityValue / 100) {
          loversSignificativityIcon = stimulus.addictsPercentil > compareWith.addictsPercentil ? iconTheme['upIcon'] : iconTheme['downIcon'];
        }
      }
  
      if(stimulus.significativity[compareWith.id].rejectorsValue !== undefined) {
        syRejectors = stimulus.significativity[compareWith.id].rejectorsValue;
        syRejectors = Math.round(syRejectors * 1000) / 1000;
        if(syRejectors < configuration.comparisonSignificativityValue / 100) {
          rejectorsSignificativityIcon = stimulus.hatersPercentil < compareWith.hatersPercentil ? iconTheme['upIcon'] : iconTheme['downIcon'];
        }
      }
    }

    // if any of the stimuli has a significativity
    const anyHasSignificativityIcons = stimuli.findIndex(s => s.significativity) >= 0;

    return (
        <Box className={classes.loversRejectorsBlock}>
          <Box className={classes.loversRejectorsBox}>
              {renderSignificativityIcon(anyHasSignificativityIcons, rejectorsSignificativityIcon)}
              <Box><SentimentVeryDissatisfiedIcon className={classes.addicts} /> {Math.round(stimulus.hatersPercentil)}%</Box>
          </Box>
          <Box className={classes.loversRejectorsBox}>
              {renderSignificativityIcon(anyHasSignificativityIcons, loversSignificativityIcon)}
              <Box><SentimentVerySatisfiedIcon className={classes.addicts} /> {Math.round(stimulus.addictsPercentil)}%</Box>
          </Box>
        </Box>
      );
  }

  const renderSignificativityIcon = (anyHasSignificativityIcons, significativityIcon) => {
    if(!anyHasSignificativityIcons) return (<Box className={classes.noSignificativityIcons}></Box>);
    return (<Box className={classes.hasSignificativityIcons}>{significativityIcon}</Box>);
  }
  return (<div style={{ width: '407px' }} id={`stimulusCard${stimulus.id}`}>
    <Grid item key={stimulus.id}>
      <Paper className={classes.paper}>
        <div id={cardId}>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '95%', position: 'relative', top: '-8px' }} onDoubleClick={e => setOpenRenameDialog(true)}>
              <Tooltip title={t('react.dashboard.toolbar.rename.hint')}>
                <h3 className={classes.cardname}>{title}</h3>
              </Tooltip>
              <h4 className={classes.nbanswers}>{t('react.dashboard.card.base.count', { nbMatchedResponses: stimulus.nbMatchedResponses })}</h4>
            </div>
            <StimulusCardContextMenu
              {...props}
              classes={classes}
              setOpenRenameDialog={setOpenRenameDialog}
              handleImageToClipboardClick={() => stimulusCardToImage(
                cardId,
                filterPanelId,
                compareWithPanelId,
                ignoreFiltersOnExport,
                ignoreCompareWithOnExport,
                imageTitle,
                'clipboard'
              )}
              handleImageToDownloadClick={() => stimulusCardToImage(
                cardId,
                filterPanelId,
                compareWithPanelId,
                ignoreFiltersOnExport,
                ignoreCompareWithOnExport,
                imageTitle,
                'download'
              )}
            />
          </div>
          <div className={classes.thermometerpanel}>
            <Thermometer
              {...props}
              hideArrow={hideThermometerArrow}
              significativity={significativity}
              value={stimulus.weightedScore} compareWithValue={compareWith ? compareWith.weightedScore : null} />
          </div>
          {!configuration.showAsTagcloud && <><Grid item>
            {stimulus.groups && stimulus.groups.length !== 0 && generateCharts()}
          </Grid>
          <Grid item style={{ width: '100%' }} id={filterPanelId}>
            {stimulus.id < 0 && <div className={classes.filterpanel} />}
            {stimulus.id > 0 && <div className={classes.filterpanel} >
              {filters.map(f => {
                let tooltip = findById(dashboardFilters, f.filterId)?.name;
                if (stimulus.id >= 0)
                  return <Tooltip title={tooltip} key={f.id}><Chip size='small' className={classes.marginBottomChips} label={appendSuffixIfGreaterThan(f.value, '...', 45)} onDelete={e => handleDeleteFilter(f.filterId, f.id)} variant="outlined" color="primary" /></Tooltip>;
                else
                  return <Tooltip title={tooltip} key={f.id}><Chip size='small' className={classes.marginBottomChips} label={appendSuffixIfGreaterThan(f.value, '...', 45)} variant="outlined" color="primary" /></Tooltip>;
              })}
              {engagementFilters.map(ef => {
                return <Tooltip title={ef.value} key={`ef-tooltip-${ef.id}`}><Chip size='small' onDelete={e => handleDeleteEngagementFilter(ef.id)} label={appendSuffixIfGreaterThan(ef.value, '...', 45)} variant="outlined" color="primary" /></Tooltip>;
              })}
            </div>
            }
            {stimulus.id > 0 && (filters.length > 0 || engagementFilters.length > 0) && <Divider />}
          </Grid></>}
          {configuration.showAsTagcloud && <><TagCloud {...props} title={title} data={tagCloudData} /><Divider /></>}
          {renderLoversRejectors()}
          <Grid item id={compareWithPanelId}>
            <div className={classes.comparewithpanel}>
              {stimulus.id >= 0 && <FormControl className={classes.comparewithSelector}>
                <TextField
                  select
                  label={t('react.dashboard.compare.with')}
                  id={stimulus.id + '_compare'}
                  value={(!compareWith || compareWith.id === stimulus.id) ? "" : (findById(compareWiths, compareWith.id) || '')}
                  onChange={e => applyConfiguration(handleCompareWith(e))}
                >
                  <MenuItem value="">
                    <em>{t('react.generic.text.none')}</em>
                  </MenuItem>
                  {/* NOTE: XF20200420 The compare with full total is now disabled, only Total Except */}
                  <MenuItem value={findById(compareWiths, TOTAL_EXCEPT_STIMULUS_ID)} key={TOTAL_EXCEPT_STIMULUS_ID}>
                    {t('react.projectdashboard.totaloverallexcept.title')}
                  </MenuItem>
                  {compareWiths
                      // display only true stimulus & clones
                      .filter(s => s.id > 0 && s.id !== stimulus.id)
                      .map(s => {
                        let name = dashboardService.getStimulusName(s, configuration, stimuli, sequences, t);
                        return { embeddedStimulus: s, embeddedName: name };
                      })
                      .sort((it1, it2) => it1.embeddedName.localeCompare(it2.embeddedName))
                      .map(it => (
                        <MenuItem key={it.embeddedStimulus.id} value={it.embeddedStimulus}>{it.embeddedName}</MenuItem>
                      ))
                  }
                </TextField>
              </FormControl>}
              {stimulus.id === TOTAL_STIMULUS_ID && <div className={classes.comparewithSelector}>
                <Tooltip title={t("react.dashboard.card.total.help")} placement="right-start" data-html2canvas-ignore='true'>
                  <InfoIcon className={classes.infoIconHelp} />
                </Tooltip>
              </div>}
              <Toolbar
                {...props}
                className={classes.toolbar}
                showFilterButton={stimulus.id >= 0}
                showRenameButton={stimulus.id >= 0}
                handleFilterClick={e => setOpenFilterDialog(true)}
              />
            </div>
          </Grid>
        </div>
      </Paper>
    </Grid>
  </div>
  );
}

const Toolbar = (props) => {
  const { t, showFilterButton, className, handleFilterClick } = props;

  return <div className={className} data-html2canvas-ignore='true'>
    {showFilterButton && <Tooltip title={t('react.dashboard.card.button.filter')}>
      <IconButton size='small' onClick={handleFilterClick} style={{ width: '30px' }}>
        <FilterIcon fontSize='small' />
      </IconButton>
    </Tooltip>}
  </div>
}

const Thermometer = props => {

  const { t, configuration } = props;

  const { classes } = useStyles();

  const size = 300;
  const min = DashboardConstants.lowScore * 100;
  const max = DashboardConstants.highScore * 100;

  let valueLimitedToMarker = props.value < min ? min : props.value > max ? max : props.value;
  let valueToPixel = valueLimitedToMarker / Math.abs((valueLimitedToMarker < 0) ? min : max);

  // Compute position of the marker. WARNING: The thermometer bar has a smaller red zone, so there is 2 calculs.
  let markerPosition = 0;
  if (valueToPixel <= 0) {
    markerPosition = /* left margin of 15 */ 15 + (/* prorata of 115px of red, based 0 -> 1 */ (valueToPixel + 1) * 115) - /* center of the marker */ 32;
  } else {
    markerPosition = /* left margin of 15 */ 15 + /* 115px of red */ 115 + (/* prorata of 155px of yellow, based 0 -> 1 */ valueToPixel * 155) - /* center of the marker */ 32;
  }

  /*
    Score text must be well centered
    -100 -> 4 chars             --> +10px
    10 -> 99 => 2 chars         --> +20px
    0 -> 9 => 1 char            --> +25px

  */
  const numberOfChars = ('' + Math.round(props.value / 100)).length;
  const scoreTextMargin = 10 + (4 - numberOfChars) * 5;

  const color = props.compareWithValue && props.value > props.compareWithValue ? DashboardConstants.positiveColor : DashboardConstants.negativeColor;

  const ThermometerBar = () => {
    return <Icon style={{ height: '100%', width: '100%' }}>
      <img alt="" className={classes.imageIcon} src="/img/thermometerbar.svg" />
    </Icon>
  }

  const ScoreMarker = () => {
    return <Icon style={{ height: '100%', width: '100%' }}>
      <img alt="" className={classes.imageIcon} src="/img/scoremarker.svg" />
    </Icon>
  }

  const arrow = (props.compareWithValue && props.value !== props.compareWithValue && !props.hideArrow) ?
    ((props.value > props.compareWithValue) ? <img alt="" src="/img/ArrowY.png" width="30px" /> : <img alt="" src="/img/ArrowR.png" width="30px" />) : <div>&nbsp;</div>

  return <div style={{ display: 'block', height: '50px' }}>
    <div style={{ position: 'relative', left: `${markerPosition - 35}px`, bottom: '-30px', height: '15px', color: color, maxWidth: '30px' }}>
      {!props.hideArrow && <Tooltip title={t('react.dashboard.card.comparewith.significativity', { pvalue: formatPValue(props.significativity, configuration.pValueType) })}>
        {
          arrow
        }
      </Tooltip>
      }
    </div>
    <div style={{ position: 'relative', top: '58px', maxWidth: '300px' }}>
      <ThermometerBar />
    </div>
    <div style={{ position: 'relative', bottom: '95px', left: `${markerPosition}px`, maxWidth: '132px' }}>
      <ScoreMarker />
    </div>
    <div style={{
      position: 'relative', fontSize: 'large', alignContent: 'center',
      left: `${markerPosition + scoreTextMargin}px`, width: `${size}px`,
      bottom: '155px',
      maxWidth: '50px'
    }}>
      <b>{Math.round(props.value / 100)}</b>
    </div>
  </div>
}

function RenameDialog(props) {

  const { t, open, title, label, stimulusName, handleClose, handleApply } = props;

  const [newTitle, setNewTitle] = useState('');

  useEffect(() => {
    if(!open) return;
    setNewTitle(stimulusName ? stimulusName : '');
  }, [open]);

  const handleApplyNewTitle = (e) => {
      handleApply(newTitle);
  }

  return (
    <form noValidate autoComplete="off">
      <Dialog
        fullWidth={true}
        maxWidth='xs'
        open={open}
        onClose={handleClose}
      >
        <DialogTitleWithCloseIcon
          title={title}
          callbackOnclose={handleClose}
        />
        <DialogContent>
          <TextField value={newTitle} onChange={e => { setNewTitle(e.target.value) }} label={label} autoFocus/>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t('react.button.close')}
          </Button>
          <Button onClick={handleApplyNewTitle} color="primary" disabled={isBlank(newTitle)}>
            {t('react.button.rename')}
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  )
}
