import ApiService from './ApiService';
require('dotenv').config()

const apiService = new ApiService();
const restClient = apiService.getClient();

class PreferenceService {

  async setMyDefaultDashboardConfiguration(configurationToUseAsDefaultPreferences) {
      return restClient.post('/api/preferences/v1.0/dashboard', configurationToUseAsDefaultPreferences);
  }

  async getMyDefaultDashboardConfiguration() {
      return restClient.get('/api/preferences/v1.0/dashboard');
  }

  async setMenubarState(newState) {
      return restClient.post(`/api/preferences/v1.0/menubar/state/${newState}`);
  }

  async getMenubarState() {
      return restClient.get('/api/preferences/v1.0/menubar/state');
  }
}

export default PreferenceService;
