import React, { useState, useEffect, Fragment } from 'react';
import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitleWithCloseIcon from '../shared/DialogTitleWithCloseIcon/DialogTitleWithCloseIcon';
import Button from '@mui/material/Button';
import { LoadData, getDefaultConfiguration, getViewThemes, getTopicsThemes } from '../../Constants.js';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import ButtonGroup from '@mui/material/ButtonGroup';
import Divider from '@mui/material/Divider';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Chip from '@mui/material/Chip';
import { cloneDeep } from '../../utils/utils.js';
import { green } from '@mui/material/colors';
import Tooltip from '@mui/material/Tooltip';
import PreferenceService from '../../services/PreferenceService';
import { AuthService } from '../../services/AuthService';
import InfoIcon from '@mui/icons-material/Info';
import { TextField } from '@mui/material';

const preferenceService = new PreferenceService();

const viewThemes = getViewThemes();
const defaultIconTheme = viewThemes[viewThemes.default];

const topicsThemes = getTopicsThemes();
const defaultTopicsTheme = topicsThemes[topicsThemes.default];

const useStyles = makeStyles()(theme => ({
    paper: {
        minWidth: '800px',
        maxWidth: '800px',
    },
    configurationRoot: {
        margin: theme.spacing(2),
        height: '600px',
    },
    margin: {
        margin: theme.spacing(1),
    },
    takeAllSpace: {
        width: '100%',
    },
    tabSize: {
        width: '700px',
        marginTop: theme.spacing(4),
    },
    divTopSpace: {
        marginTop: theme.spacing(4),
    },
    iconThemePreview: {
        '& > *': {
            marginLeft: theme.spacing(1),
            verticalAlign: 'sub'
        },
    },
    iconThemeSelector: {
        marginBottom: theme.spacing(2),
        width: '100%',
    },
    topicsThemeSelector: {
        marginTop: theme.spacing(2),
        width: '100%',
    },
    scorelimits: {
        width: '100%',
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
    },
    configurationDialogFormGroupBorder: {
        width: '100%',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: '#EEE'
    },
    configurationDialogFormGroupTitle: {
        padding: theme.spacing(1),
    },
    configurationDialogFormGroup: {
        width: '100%',
        padding: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        '& > *': {
            margin: theme.spacing(1),
            width: '100%',
        },
    },
    separeSortSelectors: {
        marginLeft: '8px',
    },
    allInclusiveIcon: {
        verticalAlign: 'middle'
    },
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    buttonGroup: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    savePreferences: {
        marginRight: '30px',
    },
    selectPvalue: {
        minWidth: '200px',
    },
    compareHeadBottom: {
        marginBottom: '10px',
    },
    infoIconHelp: {
        top: '8px',
        left: '5px',
        opacity: '0.8',
        transform: 'scale(0.8)',
    },
}));

const addictsHatersRangeMarks = [
    {
        value: 0,
        label: '0',
    },
    {
        value: 100,
        label: '100',
    }
];

const comparisonTriggeringValueMarks = [
    {
        value: 50,
        label: '50',
    },
    {
        value: 100,
        label: '100',
    },
    {
        value: 200,
        label: '200',
    }
];

const comparisonSignificativityValueMarks = [
    {
        value: 50,
        label: '50',
    },
    {
        value: 100,
        label: '100',
    }
];

const scoreRangeMarks = [
    {
        value: -200,
    },
    {
        value: 0,
        label: '0',
    },
    {
        value: 100,
        label: '100',
    },
    {
        value: 200,
        label: '200',
    },
    {
        value: 300,
    },
];

const absMaxScoreLimitValueMarks = [
    {
        value: 0,
        label: '0',
    },
    {
        value: 500,
        label: '500',
    },
    {
        value: 1000,
        label: '1000',
    },
    {
        value: 1500,
        label: '1500',
    },
    {
        value: 2000,
        label: '2000',
    }
];

export default function ConfigurationDialog(props) {

    const { t, configuration, open, handleClose, handleApply, openSnackbar, showSpinner } = props;

    const [defaultConfiguration, setDefaultConfiguration] = useState(getDefaultConfiguration());

    const { classes } = useStyles();

    const [scoreRange, setScoreRange] = useState([defaultConfiguration.low, defaultConfiguration.high]);
    const [comparisonTriggeringValue, setComparisonTriggeringValue] = useState(defaultConfiguration.comparisonTriggeringValue);
    const [comparisonSignificativityValue, setComparisonSignificativityValue] = useState(defaultConfiguration.comparisonSignificativityValue);
    const [significativity, setSignificativity] = useState(defaultConfiguration.significativity);
    const [addictsHatersRange, setAddictsHatersRange] = useState([defaultConfiguration.hatersLimit, defaultConfiguration.addictsLimit]);
    const [exportFilters, setExportFilters] = useState(defaultConfiguration.exportFilters);
    const [exportCompareWith, setExportCompareWith] = useState(defaultConfiguration.exportCompareWith);
    const [showTotalCard, setShowTotalCard] = useState(defaultConfiguration.showTotalCard);
    const [showHiddenCards, setShowHiddenCards] = useState(defaultConfiguration.showHiddenCards);
    const [showSequenceName, setShowSequenceName] = useState(defaultConfiguration.showSequenceName);
    const [showAsTagcloud, setShowAsTagcloud] = useState(defaultConfiguration.showAsTagcloud);
    const [alignView, setAlignView] = useState(defaultConfiguration.alignView);
    const [groupByTheme, setGroupByTheme] = useState(defaultConfiguration.groupByTheme);
    const [showAddicts, setShowAddicts] = useState(defaultConfiguration.showAddicts);
    const [iconTheme, setIconTheme] = useState(defaultIconTheme.name);
    const [showMissingGroups, setShowMissingGroups] = useState(defaultConfiguration.showMissingGroups);
    const [displayEmotionalDriver, setDisplayEmotionalDriver] = useState(defaultConfiguration.displayEmotionalDriver);
    const [missingGroupsUseTheme, setMissingGroupsUseTheme] = useState(defaultConfiguration.missingGroupsUseTheme);
    const [missingGroupsShowDiamonds, setMissingGroupsShowDiamonds] = useState(defaultConfiguration.missingGroupsShowDiamonds);
    const [missingGroupsDiamondsThreshold, setMissingGroupsDiamondsThreshold] = useState(defaultConfiguration.missingGroupsDiamondsThreshold);
    const [sortStimulusBy, setSortStimulusBy] = useState(defaultConfiguration.sortStimulusBy);
    const [sortStimulusOrder, setSortStimulusOrder] = useState(defaultConfiguration.sortStimulusOrder);
    const [topicsTheme, setTopicsTheme] = useState(defaultTopicsTheme.name);
    const [dashboardFontSize, setDashboardFontSize] = useState(defaultConfiguration.dashboardFontSize);
    const [pValueType, setpValueType] = useState(defaultConfiguration.pValueType);
    const [absMaxScoreLimitValue, setAbsMaxScoreLimitValue] = useState(defaultConfiguration.absMaxScoreLimit);

    // -- Display modes are stored in 2 old parameters
    const [showReportingMode, setShowReportingMode] = useState(defaultConfiguration.showReportingMode);
    const [showThemeMode, setShowThemeMode] = useState(defaultConfiguration.showThemeMode);
    const [displayMode, setDisplayMode] = useState(
        defaultConfiguration.showReportingMode ? 'reporting' : defaultConfiguration.showThemeMode ? 'theme' : 'normal'
    );
    // ------------------------------------------------

    const handleSaveCloseAndApply = (e) => {
        const newConfiguration = { ...configuration };

        newConfiguration.updated = true;      // to indicate that this configuration can be saved
        newConfiguration.customized = true;   // to indicate that this configuration must overwrite the default one
        newConfiguration.low = scoreRange[0];
        newConfiguration.high = scoreRange[1];
        newConfiguration.comparisonTriggeringValue = comparisonTriggeringValue;
        newConfiguration.comparisonSignificativityValue = comparisonSignificativityValue;
        newConfiguration.significativity = significativity;
        newConfiguration.exportFilters = exportFilters;
        newConfiguration.exportCompareWith = exportCompareWith;
        newConfiguration.showTotalCard = showTotalCard;
        newConfiguration.showHiddenCards = showHiddenCards;
        newConfiguration.showSequenceName = showSequenceName;
        newConfiguration.showAsTagcloud = showAsTagcloud;
        newConfiguration.showAddicts = showAddicts;
        newConfiguration.iconTheme = iconTheme;
        newConfiguration.hatersLimit = addictsHatersRange[0];
        newConfiguration.addictsLimit = addictsHatersRange[1];
        newConfiguration.groupByTheme = groupByTheme;
        newConfiguration.alignView = groupByTheme ? alignView : false;
        newConfiguration.showMissingGroups = showMissingGroups;
        newConfiguration.displayEmotionalDriver = displayEmotionalDriver;
        newConfiguration.missingGroupsUseTheme = missingGroupsUseTheme;
        newConfiguration.missingGroupsShowDiamonds = missingGroupsShowDiamonds;
        newConfiguration.missingGroupsDiamondsThreshold = missingGroupsDiamondsThreshold;
        newConfiguration.sortStimulusBy = sortStimulusBy;
        newConfiguration.sortStimulusOrder = sortStimulusOrder;
        newConfiguration.topicsTheme = topicsTheme;
        newConfiguration.dashboardFontSize = dashboardFontSize;
        newConfiguration.pValueType = pValueType;
        newConfiguration.absMaxScoreLimit = absMaxScoreLimitValue;

        // -- Display modes are stored in 2 old parameters
        newConfiguration.showThemeMode = showThemeMode;
        newConfiguration.showReportingMode = showReportingMode;
        // ------------------------------------------------

        handleClose();
        handleApply(newConfiguration);
    }

    const handleChangeDisplayMode = (mode) => {
        setDisplayMode(mode);
        switch (mode) {
            case 'reporting':
                setGroupByTheme(false);
                setShowReportingMode(true);
                setShowThemeMode(false);
                setShowAsTagcloud(false);
                break;
            case 'theme':
                setGroupByTheme(false);
                setShowReportingMode(false);
                setShowThemeMode(true);
                setShowAsTagcloud(false);
                break;
            default:
                setShowReportingMode(false);
                setShowThemeMode(false);
                setShowAsTagcloud(false);
        }
    };

    const handleChangeScoreLimit = (e, newValue) => {
        if (newValue[0] > 0) {
            newValue[0] = 0
        } else if (newValue[1] < 0) {
            newValue[1] = 0;
        }
        setScoreRange(newValue);
    }

    const handleChangeAddictsHatersRange = (e, newValue) => {
        setAddictsHatersRange(newValue);
    }

    const handleChangeComparisonTriggeringValue = (e, newValue) => {
        setComparisonTriggeringValue(newValue);
    }

    const handleChangeComparationSignificativityValue = (e, newValue) => {
        setComparisonSignificativityValue(newValue);
    }

    const handleChangeMissingGroupsUseTheme = (e, newValue) => {
        setMissingGroupsUseTheme(newValue);
    }

    const handleChangeMissingGroupsDiamondsThreshold = (e, newValue) => {
        setMissingGroupsDiamondsThreshold(newValue);
    }

    const handleSetDashboardFontSize = (e, newValue) => {
        setDashboardFontSize(newValue);
    }

    const handleChangeAbsMaxScoreLimitValue = (e, newValue) => {
        setAbsMaxScoreLimitValue(newValue);
    }

    const handleReset = () => {
        setScoreRange([defaultConfiguration.low, defaultConfiguration.high]);
        setComparisonTriggeringValue(defaultConfiguration.comparisonTriggeringValue);
        setComparisonSignificativityValue(defaultConfiguration.comparisonSignificativityValue);
        setSignificativity(defaultConfiguration.significativity);
        setShowTotalCard(defaultConfiguration.showTotalCard);
        setExportCompareWith(defaultConfiguration.exportCompareWith);
        setExportFilters(defaultConfiguration.exportFilters);
        setShowHiddenCards(defaultConfiguration.showHiddenCards);
        setShowSequenceName(defaultConfiguration.showSequenceName);
        setShowAsTagcloud(defaultConfiguration.showAsTagcloud);
        setGroupByTheme(defaultConfiguration.groupByTheme);
        setAlignView(defaultConfiguration.alignView);
        setShowAddicts(defaultConfiguration.showAddicts);
        setIconTheme(defaultConfiguration.iconTheme ? defaultConfiguration.iconTheme : viewThemes.default);
        setAddictsHatersRange([defaultConfiguration.hatersLimit, defaultConfiguration.addictsLimit]);
        setShowMissingGroups(defaultConfiguration.showMissingGroups);
        setDisplayEmotionalDriver(defaultConfiguration.displayEmotionalDriver);
        setMissingGroupsUseTheme(defaultConfiguration.missingGroupsUseTheme);
        setMissingGroupsShowDiamonds(defaultConfiguration.missingGroupsShowDiamonds);
        setMissingGroupsDiamondsThreshold(defaultConfiguration.missingGroupsDiamondsThreshold);
        setSortStimulusBy(defaultConfiguration.sortStimulusBy);
        setSortStimulusOrder(defaultConfiguration.sortStimulusOrder);
        setTopicsTheme(defaultConfiguration.topicsTheme ? defaultConfiguration.topicsTheme : topicsThemes.default);
        setDashboardFontSize(defaultConfiguration.dashboardFontSize);
        setpValueType(defaultConfiguration.pValueType = pValueType);
        setAbsMaxScoreLimitValue(defaultConfiguration.absMaxScoreLimit);

        // -- Display modes are stored in 2 old parameters
        setShowReportingMode(defaultConfiguration.showReportingMode);
        setShowThemeMode(defaultConfiguration.showThemeMode);
        setDisplayMode(defaultConfiguration.showReportingMode ? 'reporting' : defaultConfiguration.showThemeMode ? 'theme' : 'normal');
        // ------------------------------------------------
    }

    const handleSavePreferences = (event) => {
        const newConfiguration = {};

        newConfiguration.low = scoreRange[0];
        newConfiguration.high = scoreRange[1];
        newConfiguration.comparisonTriggeringValue = comparisonTriggeringValue;
        newConfiguration.comparisonSignificativityValue = comparisonSignificativityValue;
        newConfiguration.significativity = significativity;
        newConfiguration.exportFilters = exportFilters;
        newConfiguration.exportCompareWith = exportCompareWith;
        newConfiguration.showTotalCard = showTotalCard;
        newConfiguration.showHiddenCards = showHiddenCards;
        newConfiguration.showSequenceName = showSequenceName;
        newConfiguration.showAsTagcloud = showAsTagcloud;
        newConfiguration.showAddicts = showAddicts;
        newConfiguration.iconTheme = iconTheme;
        newConfiguration.addictsLimit = addictsHatersRange[1];
        newConfiguration.hatersLimit = addictsHatersRange[0];
        newConfiguration.groupByTheme = groupByTheme;
        newConfiguration.alignView = groupByTheme ? alignView : false;
        newConfiguration.showMissingGroups = showMissingGroups;
        newConfiguration.displayEmotionalDriver = displayEmotionalDriver;
        newConfiguration.missingGroupsUseTheme = missingGroupsUseTheme;
        newConfiguration.missingGroupsShowDiamonds = missingGroupsShowDiamonds;
        newConfiguration.missingGroupsDiamondsThreshold = missingGroupsDiamondsThreshold;
        newConfiguration.sortStimulusBy = sortStimulusBy;
        newConfiguration.sortStimulusOrder = sortStimulusOrder;
        newConfiguration.topicsTheme = topicsTheme;
        newConfiguration.dashboardFontSize = dashboardFontSize;
        newConfiguration.pValueType = pValueType;
        newConfiguration.absMaxScoreLimit = absMaxScoreLimitValue;

        // -- Display modes are stored in 2 old parameters
        newConfiguration.showThemeMode = showThemeMode;
        newConfiguration.showReportingMode = showReportingMode;
        // ------------------------------------------------

        preferenceService.setMyDefaultDashboardConfiguration(newConfiguration)
            .then(result => {
                openSnackbar('success', t('react.dashboard.configurationsettingsdialog.save.preferences.success'))
            }).catch(() => {
                openSnackbar('error', t('react.generic.error.while.saving'))
            });
    };

    const handleIconThemeChange = (e) => {
        setIconTheme(e.target.value);
    }

    const handletopicsThemeChange = (e) => {
        setTopicsTheme(e.target.value);
    }

    const handleChangepValueType = (e) => {
        setpValueType(e.target.value);
    }

    function valuetext(value) {
        return `${value}`;
    }

    const [configTabIndex, setConfigTabIndex] = useState(0);
    const handleChangeConfigTab = (e, newValue) => {
        setConfigTabIndex(newValue);
    }

    const [loadData, setLoadData] = useState(LoadData.Load);
    useEffect(() => {
        if (!open) {
            setLoadData(LoadData.Load);
            return;
        }

        if (loadData !== LoadData.Load) return;

        setLoadData(LoadData.Loading);

        showSpinner(true);

        setConfigTabIndex(0);

        preferenceService.getMyDefaultDashboardConfiguration()
            .then(result => {
                // merge default config with user preferences
                let newDefaultConfiguration = cloneDeep(getDefaultConfiguration(), result.data);
                setDefaultConfiguration(newDefaultConfiguration);

                let loadedConfiguration = undefined;
                if (configuration !== undefined && (configuration.id > 0 || configuration.customized === true)) {
                    // initialize the given configuration over the default one
                    loadedConfiguration = cloneDeep(newDefaultConfiguration, configuration);
                } else {
                    // initialize from default one
                    loadedConfiguration = cloneDeep(newDefaultConfiguration);
                }

                setScoreRange([loadedConfiguration.low, loadedConfiguration.high]);
                setComparisonTriggeringValue(loadedConfiguration.comparisonTriggeringValue);
                setComparisonSignificativityValue(loadedConfiguration.comparisonSignificativityValue);
                setSignificativity(loadedConfiguration.significativity);
                setShowTotalCard(loadedConfiguration.showTotalCard);
                setShowHiddenCards(loadedConfiguration.showHiddenCards);
                setShowSequenceName(loadedConfiguration.showSequenceName);
                setShowAsTagcloud(loadedConfiguration.showAsTagcloud);
                setGroupByTheme(loadedConfiguration.groupByTheme);
                setAlignView(loadedConfiguration.alignView);
                setShowAddicts(loadedConfiguration.showAddicts);
                setExportCompareWith(loadedConfiguration.exportCompareWith);
                setExportFilters(loadedConfiguration.exportFilters);
                setAddictsHatersRange([loadedConfiguration.hatersLimit, loadedConfiguration.addictsLimit]);
                setShowMissingGroups(loadedConfiguration.showMissingGroups);
                setDisplayEmotionalDriver(loadedConfiguration.displayEmotionalDriver);
                setMissingGroupsUseTheme(loadedConfiguration.missingGroupsUseTheme);
                setMissingGroupsShowDiamonds(loadedConfiguration.missingGroupsShowDiamonds);
                setMissingGroupsDiamondsThreshold(loadedConfiguration.missingGroupsDiamondsThreshold);
                setSortStimulusBy(loadedConfiguration.sortStimulusBy);
                setSortStimulusOrder(loadedConfiguration.sortStimulusOrder);
                setDashboardFontSize(loadedConfiguration.dashboardFontSize);
                setIconTheme(loadedConfiguration.iconTheme ? loadedConfiguration.iconTheme : viewThemes.default);
                setTopicsTheme(loadedConfiguration.topicsTheme ? loadedConfiguration.topicsTheme : topicsThemes.default);
                setpValueType(loadedConfiguration.pValueType);
                setAbsMaxScoreLimitValue(loadedConfiguration.absMaxScoreLimit);

                // -- Display modes are stored in 2 old parameters
                setShowReportingMode(loadedConfiguration.showReportingMode);
                setShowThemeMode(loadedConfiguration.showThemeMode);
                setDisplayMode(loadedConfiguration.showReportingMode ? 'reporting' : loadedConfiguration.showThemeMode ? 'theme' : 'normal');
                // ------------------------------------------------
            }).catch((err) => {
                openSnackbar('error', t('react.generic.error.while.loading'))
            }).finally(() => {
                setLoadData(LoadData.Loaded);
                showSpinner(false);
            });
    }, [configuration, open]);

    if (loadData !== LoadData.Loaded) {
        return null;
    }

    return (
        <form noValidate autoComplete="off">
            <Dialog
                classes={{ paper: classes.paper }}
                open={open}
                onClose={handleClose}
                maxWidth={"sm"}
                fullWidth={true}
            >
                <DialogTitleWithCloseIcon
                    title={t('react.dashboard.configurationsettingsdialog.title')}
                    callbackOnclose={handleClose}
                />
                <DialogContent>
                    <div className={classes.configurationRoot}>
                        <AppBar position="static" color="default">
                            <Tabs value={configTabIndex} onChange={handleChangeConfigTab}>
                                <Tab label={t('react.dashboard.configurationsettingsdialog.general')} />
                                <Tab label={t('react.dashboard.configurationsettingsdialog.comparison')} />
                                <Tab label={t('react.dashboard.configurationsettingsdialog.export')} />
                            </Tabs>
                        </AppBar>
                        <div id="simple-tabpanel-0" hidden={0 !== configTabIndex} className={classes.tabSize}>
                            <Grid container spacing={5}>
                                <Grid item xs={6}>
                                    <div>
                                        <Typography id="high-range-slider" gutterBottom>
                                            {t('react.dashboard.configurationsettingsdialog.scorelimits.title')}<br />
                                            {t('react.dashboard.configurationsettingsdialog.scorelimits', { 'negative': scoreRange[0], 'positive': scoreRange[1] })}
                                        </Typography>
                                        <Grid container spacing={2}>
                                            <Grid item>
                                                -<AllInclusiveIcon className={classes.allInclusiveIcon} />
                                            </Grid>
                                            <Grid item xs>
                                                <Slider
                                                    value={scoreRange}
                                                    onChange={handleChangeScoreLimit}
                                                    valueLabelDisplay="auto"
                                                    getAriaValueText={valuetext}
                                                    min={-200}
                                                    max={300}
                                                    step={5}
                                                    marks={scoreRangeMarks}
                                                    track="inverted"
                                                />
                                            </Grid>
                                            <Grid item>
                                                +<AllInclusiveIcon className={classes.allInclusiveIcon} />
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <div className={classes.divTopSpace}>
                                        <FormControlLabel className={classes.takeAllSpace}
                                            control={
                                                <Switch checked={showAddicts} onChange={e => setShowAddicts(e.target.checked)} />
                                            }
                                            label={t('react.dashboard.configurationsettingsdialog.showAddicts')}
                                        />
                                        <Typography id="addictshaters-slider" gutterBottom >
                                            {t('react.dashboard.configurationsettingsdialog.addicthaterslimit', { haters: addictsHatersRange[0], addicts: addictsHatersRange[1] })}
                                        </Typography>
                                        <Slider
                                            value={addictsHatersRange}
                                            onChange={handleChangeAddictsHatersRange}
                                            valueLabelDisplay="auto"
                                            getAriaValueText={valuetext}
                                            min={0}
                                            max={100}
                                            step={5}
                                            marks={addictsHatersRangeMarks}
                                            disabled={!showAddicts}
                                            track="inverted"
                                        />
                                    </div>
                                    <div className={classes.divTopSpace}>
                                        <Typography id="sortStimulusBy-label" gutterBottom >
                                            {t('react.dashboard.configurationsettingsdialog.sortStimulusBy')}
                                        </Typography>
                                        <Select
                                            labelId={'sortStimulusBy-label'}
                                            id={'sortStimulusBy'}
                                            value={!sortStimulusBy ? 'name' : sortStimulusBy}
                                            onChange={e => setSortStimulusBy(e.target.value)}
                                        >
                                            <MenuItem value="name">
                                                <em>{t('react.dashboard.configurationsettingsdialog.sortStimulusBy.name')}</em>
                                            </MenuItem>
                                            <MenuItem value="score">
                                                <em>{t('react.dashboard.configurationsettingsdialog.sortStimulusBy.score')}</em>
                                            </MenuItem>
                                            <MenuItem value="base">
                                                <em>{t('react.dashboard.configurationsettingsdialog.sortStimulusBy.base')}</em>
                                            </MenuItem>
                                            <MenuItem value="addicts">
                                                <em>{t('react.dashboard.configurationsettingsdialog.sortStimulusBy.addicts')}</em>
                                            </MenuItem>
                                            <MenuItem value="haters">
                                                <em>{t('react.dashboard.configurationsettingsdialog.sortStimulusBy.haters')}</em>
                                            </MenuItem>
                                        </Select>
                                        <Select
                                            labelId={'sortStimulusOrder-label'}
                                            id={'sortStimulusOrder'}
                                            value={!sortStimulusOrder ? 'desc' : sortStimulusOrder}
                                            onChange={e => setSortStimulusOrder(e.target.value)}
                                            className={classes.separeSortSelectors}
                                        >
                                            <MenuItem value="asc">
                                                <em>{t('react.dashboard.configurationsettingsdialog.sortStimulusOrder.asc')}</em>
                                            </MenuItem>
                                            <MenuItem value="desc">
                                                <em>{t('react.dashboard.configurationsettingsdialog.sortStimulusOrder.desc')}</em>
                                            </MenuItem>
                                        </Select>
                                    </div>
                                    <div className={classes.divTopSpace}>
                                        <Typography id="dashboardFontSize-slider" gutterBottom>
                                            {t('react.dashboard.configurationsettingsdialog.fontSize')}
                                        </Typography>
                                        <Slider
                                            value={dashboardFontSize}
                                            onChange={handleSetDashboardFontSize}
                                            valueLabelDisplay="auto"
                                            getAriaValueText={valuetext}
                                            min={10}
                                            max={14}
                                            step={2}
                                            marks={[
                                                {
                                                    value: 10,
                                                    label: t('react.dashboard.configurationsettingsdialog.fontSize.verysmall'),
                                                },
                                                {
                                                    value: 12,
                                                    label: t('react.dashboard.configurationsettingsdialog.fontSize.small'),
                                                },
                                                {
                                                    value: 14,
                                                    label: t('react.dashboard.configurationsettingsdialog.fontSize.normal'),
                                                }
                                            ]}
                                            track={false}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={6}>
                                    <div className={classes.margin}>
                                        <FormControlLabel className={classes.takeAllSpace}
                                            control={
                                                <Switch checked={showTotalCard} onChange={e => setShowTotalCard(e.target.checked)} />
                                            }
                                            label={t('react.dashboard.configurationsettingsdialog.showTotalCard')}
                                        />
                                        <FormControlLabel className={classes.takeAllSpace}
                                            control={
                                                <Switch checked={showHiddenCards} onChange={e => setShowHiddenCards(e.target.checked)} />
                                            }
                                            label={t('react.dashboard.configurationsettingsdialog.showHiddenCards')}
                                        />
                                        <FormControlLabel className={classes.takeAllSpace}
                                            control={
                                                <Switch checked={showSequenceName} onChange={e => setShowSequenceName(e.target.checked)} />
                                            }
                                            label={t('react.dashboard.configurationsettingsdialog.showSequenceName')}
                                        />
                                        <Divider className={classes.divider} />
                                        <Typography>{t('react.dashboard.configurationsettingsdialog.displayMode')}</Typography>
                                        <ButtonGroup color="primary" className={classes.buttonGroup}>
                                            <Button variant={displayMode === 'normal' ? 'contained' : 'outlined'} onClick={e => handleChangeDisplayMode('normal')}>{t('react.dashboard.configurationsettingsdialog.displayMode.normal')}</Button>
                                            <Button variant={displayMode === 'theme' ? 'contained' : 'outlined'} onClick={e => handleChangeDisplayMode('theme')}>{t('react.dashboard.configurationsettingsdialog.displayMode.theme')}</Button>
                                            <Button variant={displayMode === 'reporting' ? 'contained' : 'outlined'} onClick={e => handleChangeDisplayMode('reporting')}>{t('react.dashboard.configurationsettingsdialog.displayMode.reporting')}</Button>
                                        </ButtonGroup>
                                        <FormControlLabel className={classes.takeAllSpace}
                                            control={
                                                <Switch disabled={displayMode !== 'normal'} checked={groupByTheme} onChange={e => setGroupByTheme(e.target.checked)} />
                                            }
                                            label={t('react.dashboard.configurationsettingsdialog.groupByTheme')}
                                        />
                                        <FormControlLabel className={classes.takeAllSpace}
                                            control={
                                                <Switch disabled={!groupByTheme} checked={alignView} onChange={e => setAlignView(e.target.checked)} />
                                            }
                                            label={t('react.dashboard.configurationsettingsdialog.alignView')}
                                        />
                                        <FormControlLabel className={classes.takeAllSpace}
                                            control={
                                                <Switch disabled={displayMode !== 'normal'} checked={showAsTagcloud} onChange={e => setShowAsTagcloud(e.target.checked)} />
                                            }
                                            label={t('react.dashboard.configurationsettingsdialog.showtagcloud')}
                                        />
                                        <FormControl className={classes.topicsThemeSelector}>
                                            <TextField
                                                select
                                                label={t('react.dashboard.configurationsettingsdialog.topicstheme')}
                                                id={'topicstheme'}
                                                value={topicsTheme}
                                                onChange={handletopicsThemeChange}
                                                disabled={displayMode !== 'reporting'}
                                            >
                                                {
                                                    Object.values(topicsThemes).filter(s => s.name).map(s => {
                                                        return <MenuItem key={s.name} value={s.name}>
                                                            <div className={classes.topicsThemePreview}>
                                                                {s.label}
                                                            </div>
                                                        </MenuItem>
                                                    })
                                                }
                                            </TextField>
                                        </FormControl>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                        <div id="simple-tabpanel-1" hidden={1 !== configTabIndex} className={classes.tabSize}>
                            <Grid container spacing={5}>
                                <Grid item xs={6}>
                                    <div>
                                        <Typography className={classes.compareHeadBottom}>
                                            {t('react.dashboard.configurationsettingsdialog.significativity')}
                                        </Typography>
                                    </div>
                                    <div>
                                        <FormControl component="fieldset">
                                            <RadioGroup row name="significativity" value={significativity} onChange={e => setSignificativity(e.target.value)}>
                                                <FormControlLabel value="T" control={<Radio />} label={t('react.dashboard.configurationsettingsdialog.ttest')} />
                                                <FormControlLabel value="A" control={<Radio />} label={t('react.dashboard.configurationsettingsdialog.anova')} />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                    <div>
                                        <Typography id="high-range-slider" gutterBottom>
                                            {t('react.dashboard.configurationsettingsdialog.comparisonsignificativityvalue')} <b>[{comparisonSignificativityValue}]</b>
                                        </Typography>
                                        <Slider
                                            value={comparisonSignificativityValue}
                                            onChange={handleChangeComparationSignificativityValue}
                                            valueLabelDisplay="auto"
                                            getAriaValueText={valuetext}
                                            min={5}
                                            max={100}
                                            step={5}
                                            marks={comparisonSignificativityValueMarks}
                                        />
                                    </div>
                                    <div>
                                        <FormControl className={classes.selectPvalue}>
                                            <TextField
                                                select
                                                id={'pValueType'}
                                                label={t('react.dashboard.configurationsettingsdialog.comparison.typePvalue')}
                                                value={pValueType}
                                                onChange={handleChangepValueType}
                                            >
                                                <MenuItem value={'percent'}>{t('react.dashboard.configurationsettingsdialog.comparison.percentPvalue')}</MenuItem>
                                                <MenuItem value={'numbers'}>{t('react.dashboard.configurationsettingsdialog.comparison.numbersPvalue')}</MenuItem>
                                            </TextField>
                                        </FormControl>
                                        <Typography variant="body2" gutterBottom className={classes.divTopSpace}>
                                            {t('react.dashboard.configurationsettingsdialog.comparison.descriptionPvalue')}
                                        </Typography>
                                        <div className={classes.divTopSpace}>
                                            <FormControlLabel className={classes.takeAllSpace}
                                                control={
                                                    <Switch checked={displayEmotionalDriver} onChange={e => setDisplayEmotionalDriver(e.target.checked)} />
                                                }
                                                label={
                                                    <Fragment>
                                                        {t('react.dashboard.configurationsettingsdialog.showemotionaldriver')}
                                                        <Tooltip title={t("react.dashboard.configurationsettingsdialog.displayemotional.help")} placement="right-start" >
                                                            <InfoIcon className={classes.infoIconHelp} />
                                                        </Tooltip>
                                                    </Fragment>
                                                }
                                            />
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={6}>
                                    <div>
                                        <Typography className={classes.compareHeadBottom}>
                                            {t('react.dashboard.configurationsettingsdialog.comparison.groups.head')}
                                        </Typography>
                                    </div>
                                    <div>
                                        <Typography id="high-range-slider" gutterBottom>
                                            {t('react.dashboard.configurationsettingsdialog.comparisonactivationvalue')} <b>[{comparisonTriggeringValue}]</b>
                                        </Typography>
                                        <Slider
                                            value={comparisonTriggeringValue}
                                            onChange={handleChangeComparisonTriggeringValue}
                                            valueLabelDisplay="auto"
                                            getAriaValueText={valuetext}
                                            min={50}
                                            max={200}
                                            step={5}
                                            marks={comparisonTriggeringValueMarks}
                                        />
                                    </div>
                                    <div className={classes.divTopSpace}>
                                        <FormControlLabel className={classes.takeAllSpace}
                                            control={
                                                <Switch checked={showMissingGroups} onChange={e => setShowMissingGroups(e.target.checked)} />
                                            }
                                            label={
                                                <Fragment>
                                                    {t('react.dashboard.configurationsettingsdialog.showmissinggroups')}
                                                    <Tooltip title={t("react.dashboard.configurationsettingsdialog.showmissinggroups.help")} placement="right-start" >
                                                        <InfoIcon className={classes.infoIconHelp} />
                                                    </Tooltip>
                                                </Fragment>
                                            }
                                        />
                                    </div>
                                    <div>
                                        <FormControlLabel className={classes.takeAllSpace}
                                            disabled={!showMissingGroups}
                                            control={
                                                <Switch
                                                    checked={missingGroupsUseTheme}
                                                    onChange={e => setMissingGroupsUseTheme(e.target.checked)} />
                                            }
                                            label={t('react.dashboard.configurationsettingsdialog.missinggroupsusetheme')}
                                        />
                                    </div>
                                    <div className={classes.divTopSpace}>
                                        <FormControlLabel className={classes.takeAllSpace}
                                            control={
                                                <Switch
                                                    checked={missingGroupsShowDiamonds}
                                                    onChange={e => setMissingGroupsShowDiamonds(e.target.checked)} />
                                            }
                                            label={
                                                <Fragment>
                                                    {t('react.dashboard.configurationsettingsdialog.missinggroupsshowdiamonds')}
                                                    <Tooltip title={t("react.dashboard.configurationsettingsdialog.missinggroupsshowdiamonds.help")} placement="right-start" >
                                                        <InfoIcon className={classes.infoIconHelp} />
                                                    </Tooltip>
                                                </Fragment>
                                            }
                                        />
                                    </div>
                                    <div>
                                        <Typography id="high-range-slider" gutterBottom>
                                            {t('react.dashboard.configurationsettingsdialog.missinggroupsdiamondsthreshold')} <b>[{missingGroupsDiamondsThreshold}]</b>
                                        </Typography>
                                        <Slider
                                            value={missingGroupsDiamondsThreshold}
                                            onChange={handleChangeMissingGroupsDiamondsThreshold}
                                            valueLabelDisplay="auto"
                                            getAriaValueText={valuetext}
                                            min={0}
                                            max={100}
                                            step={5}
                                            disabled={!missingGroupsShowDiamonds}
                                        />
                                    </div>
                                    <div className={classes.divTopSpace}>
                                        <FormControl className={classes.iconThemeSelector}>
                                            <TextField
                                                select
                                                id={'icontheme'}
                                                label={t('react.dashboard.configurationsettingsdialog.icontheme')}
                                                value={iconTheme}
                                                onChange={handleIconThemeChange}
                                            >
                                                {
                                                    Object.values(viewThemes).filter(s => s.name).map(s => {
                                                        return <MenuItem key={s.name} value={s.name}>
                                                            <div className={classes.iconThemePreview}>
                                                                {s.label}: {s.upIcon}{s.downIcon}{s.diamondIcon}{s.missingIcon}{s.upMissingIcon}{s.downMissingIcon}
                                                            </div>
                                                        </MenuItem>
                                                    })
                                                }
                                            </TextField>
                                        </FormControl>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                        <div id="simple-tabpanel-2" hidden={2 !== configTabIndex} className={classes.tabSize}>
                            <Grid container spacing={5}>
                                <Grid item xs={6}>
                                    <FormControlLabel
                                        control={
                                            <Switch checked={exportFilters} onChange={e => setExportFilters(e.target.checked)} />
                                        }
                                        label={t('react.dashboard.configurationsettingsdialog.exportFilters')}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Switch checked={exportCompareWith} onChange={e => setExportCompareWith(e.target.checked)} />
                                        }
                                        label={t('react.dashboard.configurationsettingsdialog.exportCompareWith')}
                                    />
                                    {AuthService.isAdmin() && <>
                                        <Typography id="addictshaters-slider" gutterBottom className={classes.divTopSpace}>
                                            {t('react.dashboard.configurationsettingsdialog.absMaxScoreLimit')}
                                        </Typography>
                                        <Slider
                                            value={absMaxScoreLimitValue}
                                            onChange={handleChangeAbsMaxScoreLimitValue}
                                            valueLabelDisplay="auto"
                                            getAriaValueText={valuetext}
                                            min={0}
                                            max={2000}
                                            step={20}
                                            marks={absMaxScoreLimitValueMarks}
                                        />
                                    </>}
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Tooltip className={classes.savePreferences} title={t('react.dashboard.configurationsettingsdialog.save.preferences.tooltip')}>
                        <Chip
                            icon={<FavoriteIcon style={{ color: green[200] }} />}
                            label={t('react.dashboard.configurationsettingsdialog.save.preferences')}
                            clickable
                            onClick={handleSavePreferences}
                        />
                    </Tooltip>
                    <Button onClick={handleReset}>
                        {t('react.button.reset')}
                    </Button>
                    <Button onClick={handleClose} color="primary">
                        {t('react.button.cancel')}
                    </Button>
                    <Button onClick={(e) => handleSaveCloseAndApply(configuration)} autoFocus>
                        {t('react.button.apply')}
                    </Button>
                </DialogActions>
            </Dialog>
        </form>
    );
}
