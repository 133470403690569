import React, { useState, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';

const useStyles = makeStyles()(theme => ({
    spanPassword: {
        width: '24%',
        height: 8,
        borderRadius: 8,
      },
      dotOrangeGreen: {
        width: 10,
        height: 10,
        borderRadius: 10,
        backgroundColor: 'orange',
        marginRight: 10
      },
      textHelpPassword: {
        color: 'rgb(100,100,100)'
      },
      divTextHelp: {
        display: 'flex', 
        alignItems: 'baseline', 
        marginTop: 10
      },
      msgErr: {
        color: '#d32f2f',
        fontSize: 12
      }
}));

const PasswordConfirmation = props => {

    const { classes } = useStyles();

    const {
        password,
        setPassword,
        notSamePassword,
        passwordConfirm,
        setPasswordConfirm,
        t,
        setPasswordCorrect 
    } = props;

    const [visible, setVisible] = useState(false);
    const [confirmVisible, setConfirmVisible] = useState(false);
    const [conditions, setConditions] = useState({
        minLength: false,
        letter: false,
        digit: false,
        noSpace: false,
    });

    useEffect(() => {
        const minLengthConst = /^(.{8,})$/.test(password);
        const letterConst = /^(?=.*[A-Za-z])/.test(password);
        const digitConst = /^(?=.*\d)/.test(password);
        const noSpaceConst = /^\S*$/.test(password) && password.length > 0;

        setConditions({
        minLength: minLengthConst,
        letter: letterConst,
        digit: digitConst,
        noSpace: noSpaceConst,
        });
        setPasswordCorrect(validConditionsCount === 4 && (password === passwordConfirm));
    }, [password]);

      const getBackgroundColor = (i) => {
        switch (i) {
          case 0:
            return validConditionsCount === 0 ? 'rgb(240,240,240)' : validConditionsCount === 4 ? 'rgb(54, 196, 26)' : 'rgb(247, 203, 94)';
          case 1:
            return validConditionsCount <= 1 ? 'rgb(240,240,240)' : validConditionsCount === 4 ? 'rgb(54, 196, 26)' : 'rgb(247, 203, 94)';
          case 3:
            return validConditionsCount <= 2 ? 'rgb(240,240,240)' : validConditionsCount === 4 ? 'rgb(54, 196, 26)' : 'rgb(247, 203, 94)' ;
          case 4:
            return validConditionsCount === 4 ? 'rgb(54, 196, 26)' : 'rgb(240,240,240)';
        }
      };

    const validConditionsCount = Object.values(conditions).filter((condition) => condition === true).length;

    const handleChangePasswordConfirm = (value) => {
      setPasswordConfirm(value);
      setPasswordCorrect(validConditionsCount === 4 && (password === value));
    }

    return (
        <>
            <FormControl style={{ margin: "15px 0" }} variant="outlined" size="small">
                <InputLabel>{t('react.users.changepassword.newpassword')}</InputLabel>
                <OutlinedInput
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    type={visible ? 'text' : 'password'}
                    endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            onClick={() => setVisible(!visible)}
                            edge="end"
                        >
                        {visible ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                    }
                    label={t('react.users.changepassword.newpassword')}
                />
            </FormControl>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
            {[0, 1, 3, 4].map((index) => (
                <div key={index} className={classes.spanPassword} style={{ backgroundColor: getBackgroundColor(index) }}></div>
            ))}
            </div>
            <div className={classes.divTextHelp}>
                <div className={classes.dotOrangeGreen} style={{backgroundColor: password.length === 0 ? 'rgb(240,240,240)' : conditions.minLength === true ? 'rgb(54, 196, 26)' : 'rgb(242, 70, 73)'}}></div>
                <div className={classes.textHelpPassword}>{t('react.users.changepassword.minimumlength')}</div>
            </div>
            <div className={classes.divTextHelp}>
                <div className={classes.dotOrangeGreen} style={{backgroundColor: password.length === 0 ? 'rgb(240,240,240)' : conditions.noSpace === true ? 'rgb(54, 196, 26)' : 'rgb(242, 70, 73)'}}></div>
                <div className={classes.textHelpPassword}>{t('react.users.changepassword.nospace')}</div>
            </div>
            <div className={classes.divTextHelp}>
                <div className={classes.dotOrangeGreen} style={{backgroundColor: password.length === 0 ? 'rgb(240,240,240)' : conditions.letter === true ? 'rgb(54, 196, 26)' : 'rgb(242, 70, 73)'}}></div>
                <div className={classes.textHelpPassword}>{t('react.users.changepassword.oneletterminimum')}</div>
            </div>
            <div className={classes.divTextHelp}>
                <div className={classes.dotOrangeGreen} style={{backgroundColor: password.length === 0 ? 'rgb(240,240,240)' : conditions.digit === true ? 'rgb(54, 196, 26)' : 'rgb(242, 70, 73)'}}></div>
                <div className={classes.textHelpPassword}>{t('react.users.changepassword.onenumberminimum')}</div>
            </div>
            <FormControl style={{ margin: "15px 0" }} variant="outlined" size="small" error={notSamePassword.length > 0}>
                <InputLabel>{t('react.users.changepassword.confirmnewpassword')}</InputLabel>
                <OutlinedInput
                    value={passwordConfirm}
                    onChange={e => handleChangePasswordConfirm(e.target.value)}
                    type={confirmVisible ? 'text' : 'password'}
                    endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            onClick={() => setConfirmVisible(!confirmVisible)}
                            edge="end"
                        >
                        {confirmVisible ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                    }
                    label={t('react.users.changepassword.confirmnewpassword')}
                />
                <div className={classes.msgErr}>{notSamePassword.length > 0 && notSamePassword}</div>
            </FormControl>
        </>
    );
}

export default PasswordConfirmation;