import React, { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import MuiStyledListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import VisibilityIcon from '@mui/icons-material/Visibility';
import IconButton from '@mui/material/IconButton';
import GetAppIcon from '@mui/icons-material/GetApp';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { ImgIcon } from '../../common';
import MenuItem from '@mui/material/MenuItem';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { withStyles } from 'tss-react/mui';
import Badge from '@mui/material/Badge';
import EditIcon from '@mui/icons-material/Edit';
import ProjectService from '../../services/ProjectService';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { AuthService } from '../../services/AuthService';
import Divider from '@mui/material/Divider';
import { findById } from '../../utils/utils';

const projectService = new ProjectService();

const StyledListItemIcon = withStyles(MuiStyledListItemIcon, {
    root: {
        minWidth: '30px',
    },
});

export default function StimulusCardContextMenu(props) {

    const {
        t, openSnackbar, configuration, project, stimuli, stimulus, handleClone, handleRemoveCard, handleShowCard, 
        handleHideCard, handleImageToClipboardClick, handleImageToDownloadClick, classes, setOpenRenameDialog,
        stimulusVisuals, sequences, setShowImg, setOpenImportVisual, setOpenConfirmDeleteStimulusVisual
    } = props;

    const isHidden = (configuration.hidden || []).indexOf(stimulus.id) >= 0;

    const [openContextMenu, setOpenContextMenu] = useState(false);
    const [anchorElContextMenu, setAnchorElContextMenu] = useState(null);
    const [stimulusVisual, setStimulusVisual] = useState(undefined);

    const handleClickOpenContextMenu = (event) => {
        let projectFile = undefined;
        if(stimulus.id > 0 /* not available for "Total Overall" and "Total Except" cards */) {
            if(stimulus.imageId > 0) {
                projectFile = stimulusVisuals.find(d => d.id === stimulus.imageId);
            } 

            if(!projectFile) {
                // try to find if there is an image with the same name than the stimulus
                let expectedNameOfTheVisual = stimulus.name;
                if(project.sequenceMode === 'multi') {
                    expectedNameOfTheVisual = `${stimulus.name} - ${findById(sequences, stimulus.sequenceId).name}.`;
                }
                projectFile = stimulusVisuals.find(d => d.name.toLowerCase().startsWith(expectedNameOfTheVisual.toLowerCase()));
            }
        }
        setStimulusVisual(projectFile);

        setAnchorElContextMenu(event.currentTarget);
        setOpenContextMenu(true);
    };

    const handleCloseContextMenu = () => {
        setOpenContextMenu(false);
        setAnchorElContextMenu(null);
    };

    const openImg = () => {
        projectService.openProjectFile('miscellaneous', stimulusVisual.id)
          .then(response => {
            setShowImg({ show: true, src: response });
          })
          .catch(err => {
            setShowImg({ show: false, src: '' });
            openSnackbar('error', t("react.project.error.download.file"));
          });
    }

    const handleAction = (action) => {
        handleCloseContextMenu();

        switch(action) {
            case 'showCard':
                handleShowCard(stimulus);
                break;
            case 'hideCard':
                handleHideCard(stimulus);
                break;
            case 'removeCard':
                handleRemoveCard(stimulus);
                break;
            case 'cloneCard':
                handleClone(stimulus);
                break;
            case 'renameCard':
                setOpenRenameDialog(true);
                break;
            case 'toClipboard':
                handleImageToClipboardClick();
                new Promise(resolve => setTimeout(resolve, 2000)).then(() => {
                    openSnackbar('success', t('react.dashboard.card.button.copied'));
                });
                break;
            case 'downloadImage':
                handleImageToDownloadClick();
                break;
            case 'viewImage':
                openImg();
                break;
            case 'importImage':
                setOpenImportVisual({ open: true, stimulus: stimulus });
                break;
            case 'deleteVisual':
                setOpenConfirmDeleteStimulusVisual(stimulus.id);
                break;
        }
    }

    return (
        <div className={classes.showhide}>
            <Tooltip title={t('react.dashboard.card.show.menu')} data-html2canvas-ignore='true' /* indicate that this menu will not be exported as image */>
                <IconButton size='small' onClick={e => handleClickOpenContextMenu(e)}>
                    <Badge badgeContent={isHidden ? t('react.dashboard.card.status.hidden') : 0} color="error" overlap="rectangular">
                        <MenuIcon/>
                    </Badge>
                </IconButton>
            </Tooltip>
            <Menu
                id={`context-menu-card-${stimulus.id}`}
                elevation={1}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                anchorEl={anchorElContextMenu}
                keepMounted
                open={openContextMenu}
                onClose={handleCloseContextMenu}
                data-html2canvas-ignore='true'
            >
                {isHidden && <MenuItem onClick={e => handleAction('showCard')} disabled={stimulus.totalOverall}>
                    <StyledListItemIcon>
                        <VisibilityIcon fontSize="small"/>
                    </StyledListItemIcon>
                    <ListItemText primary={t('react.dashboard.card.button.show')}/>
                </MenuItem>
                }
                {!isHidden && <MenuItem onClick={e => handleAction('hideCard')} disabled={stimulus.totalOverall}>
                    <StyledListItemIcon>
                        <VisibilityOffIcon fontSize="small"/>
                    </StyledListItemIcon>
                    <ListItemText primary={t('react.dashboard.card.button.hide')}/>
                </MenuItem>
                }
                <MenuItem onClick={e => handleAction('removeCard')}>
                    <StyledListItemIcon>
                        <DeleteIcon fontSize="small"/>
                    </StyledListItemIcon>
                    <ListItemText primary={t('react.dashboard.card.button.delete.clone')}/>
                </MenuItem>
                <MenuItem onClick={e => handleAction('cloneCard')} disabled={stimulus.virtual /* not available for "Total Overall" and "Total Except" cards */}>
                    <StyledListItemIcon>
                        <FileCopyIcon fontSize="small"/>
                    </StyledListItemIcon>
                    <ListItemText primary={t('react.dashboard.card.button.clone')}/>
                </MenuItem>
                <MenuItem onClick={e => handleAction('renameCard')}>
                    <StyledListItemIcon>
                        <EditIcon fontSize="small"/>
                    </StyledListItemIcon>
                    <ListItemText primary={t('react.button.rename')}/>
                </MenuItem>
                <Divider />
                <MenuItem onClick={e => handleAction('toClipboard')}>
                    <StyledListItemIcon>
                        <ImgIcon fontSize="small"/>
                    </StyledListItemIcon>
                    <ListItemText primary={t('react.dashboard.card.button.copy')}/>
                </MenuItem>
                <MenuItem onClick={e => handleAction('downloadImage')}>
                    <StyledListItemIcon>
                        <GetAppIcon fontSize="small"/>
                    </StyledListItemIcon>
                    <ListItemText primary={t('react.dashboard.card.button.download')}/>
                </MenuItem>
                <Divider />
                <MenuItem onClick={e => handleAction(stimulusVisual !== undefined ? 'viewImage' : 'importImage')} disabled={AuthService.isVisitor() || stimulus.virtual /* not available for "Total Overall" and "Total Except" cards */}>
                    <StyledListItemIcon>
                        {stimulusVisual !== undefined ? <PhotoCameraIcon fontSize="small"/> : <AddAPhotoIcon fontSize="small"/>}
                    </StyledListItemIcon>
                    <ListItemText primary={t(stimulusVisual !== undefined ? 'react.dashboard.card.button.view.visual' : 'react.dashboard.card.button.import.visual')}/>
                </MenuItem>
                {stimulusVisual !== undefined &&
                <MenuItem onClick={e => handleAction('deleteVisual')}>
                <StyledListItemIcon>
                    <DeleteForeverIcon fontSize="small"/>
                    </StyledListItemIcon>
                    <ListItemText primary={t('react.dashboard.card.button.delete.visual')}/>
                </MenuItem>
                }
            </Menu>
        </div>
    );
}