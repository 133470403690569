import { Fragment } from 'react';
import {hasFlag} from 'country-flag-icons'
import * as Flags from 'country-flag-icons/react/3x2';

function languageToFlagCode(language = 'EN', height = 16) {
    var langToCountry = language.toUpperCase();

    // There are some convertions to do
    if(langToCountry === 'EN') {
        langToCountry = "GB";
    } else if(langToCountry === 'JA') {
        langToCountry = "JP";
    } else if(langToCountry === 'ZH') {
        langToCountry = "CN";
    }

    if(hasFlag(langToCountry)) {
        const MyComponent = Flags[langToCountry];
        return <MyComponent style={{height: height, verticalAlign: 'middle'}}/>;
    }

    return <Fragment/>;
};

/**
 * This component maps from language to Country flag.
 * 
 * @param {
 * } param0 
 * @returns 
 */
export default function Flag ({language, height}) {
    return languageToFlagCode(language, height);
}
