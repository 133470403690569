import React, { useState, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import Paper from '@mui/material/Paper';
import { Helmet } from "react-helmet";
import Highcharts from 'highcharts';
import drilldown from 'highcharts/modules/drilldown';
import { LoadData } from '../../Constants';
import HighchartsReact from 'highcharts-react-official';
import { Input, InputLabel, Typography, Tooltip, IconButton } from '@mui/material';
import Grid from '@mui/material/Grid';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import SubscriptionService from '../../services/SubscriptionService';
import { orderBy } from '../../utils/utils';
import { numericFormatter } from 'react-number-format';

drilldown(Highcharts);
const subscriptionsService = new SubscriptionService();

const useStyles = makeStyles()(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(5)
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  textField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(5),
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  }
}));

export default function SubscriptionsReporting(props) {
  const { classes } = useStyles();
  const numericFormatterProps = { thousandSeparator: ' ', decimalSeparator: '.', decimalScale: 2, thousandsGroupStyle: 'thousand' };
  const { t, showSpinner, openSnackbar } = props;
  const [loadData, setLoadData] = useState(LoadData.Load);
  const [startDate, setStartDate] = useState(new Date().toISOString().slice(0, 4) + '-01-01');
  const [endDate, setEndDate] = useState(new Date().toISOString().slice(0, 10));
  const [analyticsData, setAnalyticsData] = useState([]);
  const [projectChartData, setProjectChartData] = useState(null);

  const [nbTotalProjects, setNbTotalProjects] = useState(0);
  const [nbTotalStimulis, setNbTotalStimulis] = useState(0);
  const [nbTotalObservations, setNbTotalObservations] = useState(0);
  const [nbTotalSequences, setNbTotalSequences] = useState(0);
  const [nbTotalConsumedProjects, setNbTotalConsumedProjects] = useState(0);
  const [nbTotalConsumedObservations, setNbTotalConsumedObservations] = useState(0);

  useEffect(() => {
    if (loadData !== LoadData.Load) return;
    setLoadData(LoadData.Loading);
    showSpinner(true);

    subscriptionsService.getSubscriptionsReporting(startDate, endDate)
      .then(result => {
        setAnalyticsData(result.data);
      })
      .catch(err => {
        setAnalyticsData([]);
        openSnackbar('error', t('react.snackbar.subscriptions.analytics.fetchdata', { error: err.message }));
      }).finally(() => {
        setLoadData(LoadData.Loaded);
        showSpinner(false);
      });
  }, [loadData, endDate, startDate]);

  useEffect(() => {
    if (analyticsData.length > 0) {
      const projectDataChart = () => {
        const seriesData = orderBy(analyticsData.map(customer => ({
          name: customer.name,
          y: customer.projects.length,
          drilldown: customer.id,
          totalStimulis: customer.projects.reduce((acc, project) => acc + project.nbStimuli, 0),
          totalObservations: customer.projects.reduce((acc, project) => acc + project.nbObservations, 0),
          totalSequences: customer.projects.reduce((acc, project) => acc + project.nbSequences, 0),
          totalConsumedProjects: customer.projects.reduce((acc, project) => acc + project.nbConsumedProjects, 0),
          totalConsumedObservations: customer.projects.reduce((acc, project) => acc + project.nbConsumedObservations, 0),
        })), 'y');

        const drilldownSeries = analyticsData.map(customer => ({
          id: customer.id,
          name: customer.name,
          data: orderBy(customer.projects, 'nbConsumedObservations').map(project => ({
            name: project.name,
            z: project.nbStimuli,
            y: project.nbObservations,
            sequences: project.nbSequences,
            nbConsumedObservations: project.nbConsumedObservations,
            nbConsumedProjects: project.nbConsumedProjects,
            nbNewWords: project.nbNewWords,
            username: project.username,
          })),
        }));

        return {
          chart: {
            type: 'column',
            events: {
              drilldown: function (e) {
                this.setTitle({ text: t('react.subscriptions.analytics.chart.projects.details', {customerName: e.point.name})});
                this.yAxis[0].update({ title: { text: t('react.subscriptions.analytics.chart.observationsnb') } }, false);
              },
              drillup: function (e) {
                this.setTitle({ text: t('react.subscriptions.analytics.chart.projectsnb.customer') });
                this.yAxis[0].update({ title: { text: t('react.subscriptions.analytics.chart.projectsnb') } }, false);
              }
            }
          },
          title: {
            text: t('react.subscriptions.analytics.chart.projectsnb.customer')
          },
          xAxis: {
            type: 'category'
          },
          yAxis: {
            title: {
              text: t('react.subscriptions.analytics.chart.projectsnb')
            }
          },
          legend: {
            enabled: false
          },
          plotOptions: {
            series: {
              borderWidth: 0,
              dataLabels: {
                enabled: true,
                format: '{point.y}'
              }
            }
          },
          tooltip: {
            formatter: function () {
              const point = this.point;
              if (point.drilldown) {
                return `<b>${point.name}</b><br/>` +
                  `${t('react.subscriptions.analytics.infos.projects')}: ${numericFormatter(point.y.toString(), numericFormatterProps)}<br/>` +
                  `${t('react.subscriptions.analytics.infos.sequences')}: ${numericFormatter(point.totalSequences.toString(), numericFormatterProps)}<br/>` + 
                  `${t('react.subscriptions.analytics.infos.stimuli')}: ${numericFormatter(point.totalStimulis.toString(), numericFormatterProps)}<br/>` +
                  `${t('react.subscriptions.analytics.infos.observations')}: ${numericFormatter(point.totalObservations.toString(), numericFormatterProps)}<br/>` +
                  `<br/><b>${t('react.subscriptions.analytics.infos.billed')}</b><br/>` +
                  `${t('react.subscriptions.analytics.infos.projects')}: ${numericFormatter(point.totalConsumedProjects.toString(), numericFormatterProps)}<br/>` +
                  `${t('react.subscriptions.analytics.infos.observations')}: ${numericFormatter(point.totalConsumedObservations.toString(), numericFormatterProps)}`;
              } else {
                return `<b>${point.name}</b><br/>` +
                  `${t('react.subscriptions.analytics.infos.user')}: ${point.username}<br/>` +
                  `${t('react.subscriptions.analytics.infos.sequences')}: ${numericFormatter(point.sequences.toString(), numericFormatterProps)}<br/>` +
                  `${t('react.subscriptions.analytics.infos.stimuli')}: ${numericFormatter(point.z.toString(), numericFormatterProps)}<br/>` +
                  `${t('react.subscriptions.analytics.infos.observations')}: ${numericFormatter(point.y.toString(), numericFormatterProps)}<br/>` +
                  `<br/><b>${t('react.subscriptions.analytics.infos.billed')}</b><br/>` +
                  `${t('react.subscriptions.analytics.infos.projects')}: ${numericFormatter(point.nbConsumedProjects.toString(), numericFormatterProps)}<br/>` +
                  `${t('react.subscriptions.analytics.infos.observations')}: ${numericFormatter(point.nbConsumedObservations.toString(), numericFormatterProps)}`;
              }
            }
          },
          series: [{
            name: t('react.dashboard.menu.customers'),
            colorByPoint: true,
            data: seriesData
          }],
          drilldown: {
            colorByPoint: true,
            series: drilldownSeries
          },
          credits: {
            enabled: false
          },
        };
      };

      setProjectChartData(projectDataChart());
      setNbTotalProjects(analyticsData.reduce((total, customer) => total + customer.projects.length, 0));
      setNbTotalStimulis(analyticsData.reduce((total, customer) => total + customer.projects.reduce((acc, project) => acc + project.nbStimuli, 0), 0));
      setNbTotalObservations(analyticsData.reduce((total, customer) => total + customer.projects.reduce((acc, project) => acc + project.nbObservations, 0), 0));
      setNbTotalSequences(analyticsData.reduce((total, customer) => total + customer.projects.reduce((acc, project) => acc + project.nbSequences, 0), 0))

      setNbTotalConsumedProjects(analyticsData.map(c => c.projects).flat().reduce((total, project) => total + project.nbConsumedProjects, 0));
      setNbTotalConsumedObservations(analyticsData.map(c => c.projects).flat().reduce((total, project) => total + project.nbConsumedObservations, 0));
    }
  }, [analyticsData]);

  const handleChangeStartDate = (event) => {
    event.preventDefault();
    setStartDate(event.target.value);
    setLoadData(LoadData.Load)
  };

  const handleChangeEndDate = (event) => {
    event.preventDefault();
    setEndDate(event.target.value);
    setLoadData(LoadData.Load)
  };

  const handleExportToXLSX = () => {
    // FIXME: Create EndPoint to export Excel file
    return;
  };

  return (
    <Paper className={classes.paper}>
      <Helmet title={t('react.helmet.analytics')} />
      <Grid container style={{display: 'flex', justifyContent: 'space-between'}}>
        <Grid item xs={2}>
          <Tooltip title={t('react.snackbar.subscription.analytics.back')}>
            <IconButton
              onClick={() => props.navigate('/subscriptions')}
              size="large">
              <ArrowBackIosNewIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={10} className={classes.flexEnd}>
          <Grid item xs={2}>
            <InputLabel htmlFor='startDate' >{t('react.subscriptions.analytics.startDate')}</InputLabel>
            <Input id='startDate' type='date' value={startDate} onChange={e => handleChangeStartDate(e)} />
          </Grid>
          <Grid item xs={2}>
            <InputLabel htmlFor='endDate' >{t('react.subscriptions.analytics.endDate')}</InputLabel>
            <Input id='endDate' type='date' value={endDate} onChange={e => handleChangeEndDate(e)} />
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} className={classes.flexCenter} style={{marginBottom: '30px'}}>
          <Typography variant='h6'>{t('react.subscriptions.analytics.infos')}</Typography>
        </Grid>
        <Grid item xs={12/4} className={classes.flexCenter}>
          <Typography variant='h3'>{numericFormatter(nbTotalProjects.toString(), numericFormatterProps)}</Typography>
          <Typography>{t('react.subscriptions.analytics.infos.projects')}</Typography>
        </Grid>
        <Grid item xs={12/4} className={classes.flexCenter}>
          <Typography variant='h3'>{numericFormatter(nbTotalSequences.toString(), numericFormatterProps)}</Typography>
          <Typography>{t('react.subscriptions.analytics.infos.sequences')}</Typography>
        </Grid>
        <Grid item xs={12/4} className={classes.flexCenter}>
          <Typography variant='h3'>{numericFormatter(nbTotalStimulis.toString(), numericFormatterProps)}</Typography>
          <Typography>{t('react.subscriptions.analytics.infos.stimuli')}</Typography>
        </Grid>
        <Grid item xs={12/4} className={classes.flexCenter}>
          <Typography variant='h3'>{numericFormatter(nbTotalObservations.toString(), numericFormatterProps)}</Typography>
          <Typography>{t('react.subscriptions.analytics.infos.observations')}</Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} className={classes.flexCenter} style={{marginBottom: '30px'}}>
          <Typography variant='h6'>{t('react.subscriptions.analytics.infos.consumed')}</Typography>
        </Grid>
        <Grid item xs={6} className={classes.flexCenter}>
          <Typography variant='h3'>{numericFormatter(nbTotalConsumedProjects.toString(), numericFormatterProps)}</Typography>
          <Typography>{t('react.subscriptions.analytics.infos.projects.consumed')}</Typography>
        </Grid>
        <Grid item xs={6} className={classes.flexCenter}>
          <Typography variant='h3'>{numericFormatter(nbTotalConsumedObservations.toString(), numericFormatterProps)}</Typography>
          <Typography>{t('react.subscriptions.analytics.infos.observations.consumed')}</Typography>
        </Grid>
      </Grid>
      {projectChartData && (
        // Random key to force component re-render to avoid bugs when changing date in a drilldown
        <HighchartsReact key={Math.random()*10000} highcharts={Highcharts} options={projectChartData} />
      )}
      {/* <Grid>
        <Button
          variant="outlined"
          size="large"
          color="primary"
          startIcon={<ExportIcon />}
          className={classes.button}
          onClick={handleExportToXLSX}
          >
            {t("react.button.export")}
        </Button>
      </Grid> */}
    </Paper>
  );
};
