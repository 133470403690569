import React, { useState, useEffect } from 'react';
import { tss } from 'tss-react/mui';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import { formatPValue } from '../../utils/utils.js';
import { getViewThemes } from '../../Constants.js';

const useStyles = tss
  .withParams()
  .create(({ theme, fontSize }) => ({
    chart: {
      width: '50px',
      height: '350px',
      margin: '0px auto',
      position: 'relative',
      marginTop: '10px',
      marginBottom: '10px',
    },
    chartAddict: {
      width: '100%',
      background: '#ffcc3c',
      position: 'absolute',
      bottom: '50%',
    },
    chartHater: {
      width: '100%',
      background: '#ee3330',
      position: 'absolute',
      top: '50%',
    },
    chartLoversSignificativity: {
      bottom: '50%',
      position: 'absolute',
      paddingLeft: '15px',
    },
    chartRejectorsSignificativity: {
      top: '50%',
      position: 'absolute',
      paddingLeft: '15px',
    },
    percentageDark: {
      width: '100%',
      textAlign: 'center',
      margin: '0',
      color: 'rgba(0, 0, 0, 0.54)',
      fontSize: `${fontSize}px`,
    },
    percentageLight: {
      width: '100%',
      textAlign: 'center',
      margin: '0',
      color: '#eee',
      fontSize: `${fontSize}px`,
    },
    tooltip: {
      '&:hover':{
        cursor: 'default',
      }
    },
    title: {
      textAlign: 'center',
      margin: '0',
    },
    chartTitle: {
      margin: '0',
    },
    paper: {
      boxShadow: 'none',
      color: 'rgba(0, 0, 0, 0.54)',
      fontSize: `${fontSize}px`,
    },
    stimulusName: {
      textAlign: 'center',
      height: '40px',
      width:'80px',
      overflow: 'hidden',
    },
    box: {
      width:'80px',
      margin: '20px 10px 10px 10px',
    }
}));

const viewThemes = getViewThemes();

export default function AddictsReportingChart(props) {
  const {stimulus, t, minMaxAddictsHaters, configuration, stimuli, sequences, dashboardService} = props;

  // circuit breaker when data are wrong
  if(isNaN(stimulus.addictsPercentil) || isNaN(stimulus.hatersPercentil)) return '';

  const { classes } = useStyles({fontSize: configuration.dashboardFontSize});

  const [iconTheme, setIconTheme] = useState(viewThemes[viewThemes.default]);

  useEffect(() => {
    if (configuration.iconTheme && viewThemes[configuration.iconTheme]) {
        setIconTheme(viewThemes[configuration.iconTheme]);
    }
  }, [configuration.iconTheme]);

  // compute title
  var title = dashboardService.getStimulusName(stimulus, configuration, stimuli, sequences, t);

  // compute block sizes
  var addictsHeight = 0;
  var hatersHeight = 0;

  if(minMaxAddictsHaters[0] >= minMaxAddictsHaters[1]) {
    addictsHeight = Math.round((stimulus.addictsPercentil * 150) / minMaxAddictsHaters[0]);
    hatersHeight = Math.round((stimulus.hatersPercentil * 150) / minMaxAddictsHaters[0]);
  }

  if(minMaxAddictsHaters[1] >= minMaxAddictsHaters[0]) {
    addictsHeight = Math.round((stimulus.addictsPercentil * 150) / minMaxAddictsHaters[1]);
    hatersHeight = Math.round((stimulus.hatersPercentil * 150) / minMaxAddictsHaters[1]);
  }

  // safety display
  if(isNaN(addictsHeight) || addictsHeight < 0) addictsHeight = 0;
  if(isNaN(hatersHeight) || hatersHeight < 0) hatersHeight = 0;

  // compute significativities
  let compareWithStimulus = dashboardService.getCompareWith(stimulus, stimuli, configuration);

  var syLovers = 0;
  var syRejectors = 0;
  var loversSignificativityIcon = undefined;
  var rejectorsSignificativityIcon = undefined;
  if(compareWithStimulus && stimulus.significativity && stimulus.significativity[compareWithStimulus.id] !== undefined) {
    if(stimulus.significativity[compareWithStimulus.id].loversValue !== undefined) {
      syLovers = stimulus.significativity[compareWithStimulus.id].loversValue;
      syLovers = Math.round(syLovers * 1000) / 1000;
      if(syLovers < configuration.comparisonSignificativityValue / 100) {
        loversSignificativityIcon = stimulus.addictsPercentil > compareWithStimulus.addictsPercentil ? iconTheme['upIcon'] : iconTheme['downIcon'];
      }
    }

    if(stimulus.significativity[compareWithStimulus.id].rejectorsValue !== undefined) {
      syRejectors = stimulus.significativity[compareWithStimulus.id].rejectorsValue;
      syRejectors = Math.round(syRejectors * 1000) / 1000;
      if(syRejectors < configuration.comparisonSignificativityValue / 100) {
        rejectorsSignificativityIcon = stimulus.hatersPercentil < compareWithStimulus.hatersPercentil ? iconTheme['upIcon'] : iconTheme['downIcon'];
      }
    }
  }

  return(<Box key={stimulus.id} className={classes.box}>
    <Paper className={classes.paper}>
      <div className={classes.stimulusName}>
        <Tooltip
          title={title}
          placement="top" className={classes.tooltip}
          disableHoverListener={title.length <= 8}
          arrow
        >
          <h4 className={classes.chartTitle}>{title}</h4>
        </Tooltip>
      </div>
      <div className={classes.chart}>
        {loversSignificativityIcon &&
          <div className={classes.chartLoversSignificativity} style={{height: `${addictsHeight + 30}px`}}>
            <Tooltip title={t('react.dashboard.card.comparewith.significativity', {pvalue: formatPValue(syLovers, configuration.pValueType)})} placement="bottom" >
              {loversSignificativityIcon}
            </Tooltip>
          </div>
        }
        <div className={classes.chartAddict} style={{height: `${addictsHeight}px`}}>
          <h3 className={classes.percentageDark} style={{lineHeight: `${addictsHeight}px`}}>
            <DisplayRoundedPercentil percentil={stimulus.addictsPercentil} />
          </h3>
        </div>
        <div className={classes.chartHater} style={{height: `${hatersHeight}px`}}>
          <h3 className={classes.percentageLight} style={{lineHeight: `${hatersHeight}px`}}>
            <DisplayRoundedPercentil percentil={stimulus.hatersPercentil} />
          </h3>
        </div>
        {rejectorsSignificativityIcon &&
          <div className={classes.chartRejectorsSignificativity} style={{paddingTop: `${hatersHeight+5}px`}}>
            <Tooltip title={t('react.dashboard.card.comparewith.significativity', {pvalue: formatPValue(syRejectors, configuration.pValueType)})} placement="bottom" >
              {rejectorsSignificativityIcon}
            </Tooltip>
          </div>
        }
      </div>
      <h4 className={classes.title}>{t('react.dashboard.card.base.count', {nbMatchedResponses: stimulus.nbMatchedResponses})}</h4>
    </Paper>
  </Box>);
}

const DisplayRoundedPercentil = (props) => {
  if(isNaN(props.percentil) || props.percentil <= 0) return '';
  return Math.round(props.percentil) + '%';
}
