import ApiService from './ApiService';
require('dotenv').config()

const apiService = new ApiService();
const restClient = apiService.getClient();

class SubscriptionService {

    async getSubscriptionById(id) {
        return restClient.get(`/api/subscriptions/v1.0/${id}`);
    }

    async create(payload) {
        return restClient.post('/api/subscriptions/v1.0/', payload);
    }

    async getAllCustomerSubscriptions(customerId) {
        return restClient.get(`/api/subscriptions/v1.0/customer/${customerId}`);
    }
  
    async getCurrentCustomerSubscription(customerId) {
        return restClient.get(`/api/subscriptions/v1.0/customer/${customerId}/current`);
    }
  
    async updateSubscription(subscriptionId, payload) {
        return restClient.put(`/api/subscriptions/v1.0/${subscriptionId}`, payload);
    }

    async deleteSubscription(subscriptionId) {
        return restClient.delete(`/api/subscriptions/v1.0/${subscriptionId}`);
    }

    async getSubscriptionTypes() {
        return restClient.get('/api/subscriptions/v1.0/types');
    }

    async getAllSubscriptions(from = 0, size = 25, sort = "id", order = "asc", onlyActive = false, onlyExpired = false) {
        let apiUrl = `/api/subscriptions/v1.0/?includes=customers&from=${from}&size=${size}&sort=${sort}&order=${order}`;
        if(onlyActive) apiUrl+=`&onlyActive=${onlyActive}`;
        if(onlyExpired) apiUrl+=`&onlyExpired=${onlyExpired}`;
        return restClient.get(apiUrl);
    }
  
    async applySubscriptionExpirationToAllCustomerUsers(subscriptionId) {
        return restClient.put(`/api/subscriptions/v1.0/${subscriptionId}/apply-expiration-date-to-users`);
    }

    async getSubscriptionsReporting(startDate, endDate) {
        return restClient.get(`/api/subscriptions/v1.0/reporting?startDate=${startDate}&endDate=${endDate}`);
    }
}
  
  export default SubscriptionService;