import React, { useEffect, useState, Fragment } from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official';
import { stripHtmlTags } from '../../utils/utils';

export default function SimplePieChart(props) {

    const {
        t,
        index,
        thisQuestion,
    } = props;

    const simplePieChart = (index) => {
        let values = []

        thisQuestion.answers.forEach((element, subindex) => (
            values.push({
                name: element.answer,
                y: element.nbAnswers
            })
        ));

        return {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
            },
            title: {
                text: stripHtmlTags(thisQuestion.question)
            },
            tooltip: {
                formatter: function () {
                    return this.point.name + ': <b>' + Math.round(this.y / thisQuestion.base * 100) + '% </b>'
                }
            },
            accessibility: {
                point: {
                    valueSuffix: '%'
                }
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        format: '{point.name}: {point.y}',
                        distance: 30,
                        style: {
                            fontWeight: 300
                        }
                    },
                    size: 240
                }
            },
            series: [{
                name: 'Brands',
                colorByPoint: true,
                data: values
            }],
            credits: {
                enabled: false
            },

        }
    }

    return (
        <HighchartsReact highcharts={Highcharts} options={simplePieChart(index)} />
    );
};