import ApiService from './ApiService';
require('dotenv').config()


const apiService = new ApiService();
const restClient = apiService.getClient();

class TranslationService {

    /**
     * For internationalisation ONLY
     * Returns the list of languages supported by the frontend application
     **/
    async getSupportedLanguages() {
        return restClient.get('/api/translations/v1.0/languages');
    }

    async export(locale){
        return restClient.get("/api/translations/v1.0/export/"+locale,{responseType: 'blob'}).then(response => {
            apiService.processBinaryResponse(response);
        });
    }

    async import(formData){
        return restClient.post('/api/translations/v1.0/import', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    }

    async fetch(keywords, page, rowsPerPage, sortBy, sortDirection) {
        return restClient.get(`/api/translations/v1.0/?sort=${sortBy}&order=${sortDirection}&from=${page*rowsPerPage}&size=${rowsPerPage}&keywords=${keywords}`);
    }

    async delete(id){
        return restClient.delete('/api/translations/v1.0/' + id);
    }

    async findOne(id){
        return restClient.get('/api/translations/v1.0/' + id);
    }

    async create(translationBody){
        restClient.post('/api/translations/v1.0/', translationBody);
    }

    async update(translationBody){
        restClient.put('/api/translations/v1.0/'+translationBody.id, translationBody);
    }
}

export default TranslationService;
