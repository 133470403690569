import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { withStyles, makeStyles } from 'tss-react/mui';
import TableRow from '@mui/material/TableRow';
import AnalyticsService from '../../services/AnalyticsService';
import ProjectService from '../../services/ProjectService';
import { AuthService } from '../../services/AuthService';
import SequenceService from '../../services/SequenceService';
import { useParams } from "react-router-dom";
import { getDefaultConfiguration, LoadData } from '../../Constants';
import LinearProgress from '@mui/material/LinearProgress';
import InboxIcon from '@mui/icons-material/Inbox';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import AnalyticModeOne from './AnalyticModeOne';
import AnalyticModeTwo from './AnalyticModeTwo';
import TimerIcon from '@mui/icons-material/Timer';
import { cloneDeep, orderBy } from '../../utils/utils';
import PreferenceService from '../../services/PreferenceService';
import { ExportIcon } from '../../common';
import BarChartIcon from '@mui/icons-material/BarChart';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CachedIcon from '@mui/icons-material/Cached';
import {Helmet} from "react-helmet";

const analyticsService = new AnalyticsService();
const projectService = new ProjectService();
const sequenceService = new SequenceService();
const preferenceService = new PreferenceService();

const useStyles = makeStyles()(theme => ({
  miniToolBar: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px'
  },
  flex: {
    display: 'flex',
  },
  timerIcon: {
    marginRight: '6px'
  },
  typographyStyle: {
    position: 'relative',
    top: '-4px',
    left: '10px',
    minWidth: '400px',
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  }
}));

const StyledTableRow = withStyles(TableRow, (theme) => ({
  root: {
    backgroundColor: theme.palette.action.hover,
  },
}));

const dataTemplate = { stimulus: [], filters: [], analytics: [], totalAnalytics: [] };

export default function ProjectAnalytics(props) {

  const [analyticsId, setAnalyticsId] = useState(0);
  const [data, setData] = useState(cloneDeep(dataTemplate));
  const [project, setProject] = useState('');
  const [analyticsType, setAnalyticsType] = useState(2);
  const [loadData, setLoadData] = useState(LoadData.Load);
  const { projectIdParameter } = useParams();
  const [noData, setNoData] = useState(false);
  const [configuration, setConfiguration] = useState(getDefaultConfiguration());
  const [sequences, setSequences] = useState([]);
  const [indexSequence, setIndexSequence] = useState(0);

  const { t, openSnackbar } = props;

  const { classes } = useStyles();

  useEffect(() => {
    if (loadData !== LoadData.Load) return;

    setLoadData(LoadData.Loading);

    loadDataHandler()
      .then(results => {
        setNoData(false);
        setProject(results[0].data);
        setSequences(results[4]);

        let newConfiguration = cloneDeep(getDefaultConfiguration(), results[1].data);
        setConfiguration(newConfiguration);

        setAnalyticsId(results[2]);

        let analyticsContent = results[3].data;
        setNoData(analyticsContent.length === 0);
        setData(cloneDeep(dataTemplate, analyticsContent));
        setLoadData(LoadData.Loaded);
      })
      .catch(result => {
        setAnalyticsId(0);
        setData(cloneDeep(dataTemplate));
        if (result.response.status === 404) {
          setNoData(true);
        }
        setLoadData(LoadData.Loaded);
      });
  }, [loadData])

  const loadDataHandler = async () => {
    let analyticsId = 0;
    let analyticsContent = { data: [] };

    // fetch all elements
    let project = await projectService.fetchProject(projectIdParameter);
    let sequences = await sequenceService.fetchProjectSequences(projectIdParameter, false, true);
    let preferences = await preferenceService.getMyDefaultDashboardConfiguration();
    let analytics = await analyticsService.fetchProjectsAnalytics(projectIdParameter);

    let analyticsSequences = sequences.data.hits;

    if(analytics.data.hits.length > 0 && analyticsSequences.length >= indexSequence) {
      analyticsSequences = orderBy(analyticsSequences, 'id', true);
      const sequenceId = analyticsSequences[indexSequence].id;

      const analyticsHits = analytics.data.hits
        .filter(a => a.sequenceId === sequenceId)
        .filter(a => a.type === analyticsType);

      if (analyticsHits.length == 1) {
        analyticsId = analyticsHits[0].id;
        analyticsContent = await analyticsService.fetchAnalyticsContent(analyticsId);
      }
    }

    return [project, preferences, analyticsId, analyticsContent, analyticsSequences];
  }

  const handleChangeAnalytics = (event) => {
    setLoadData(LoadData.Load);
    setAnalyticsType(event.target.value);

    setAnalyticsId(0);
    setData(cloneDeep(dataTemplate));
    setNoData(false);
  };

  const handleChangeSequence = (event) => {
    setIndexSequence(event.target.value);
    setLoadData(LoadData.Load);
  };

  const returnToProject = () => {
    props.navigate(`/projects/${projectIdParameter}`);
  }

  const returnToDashboard = () => {
    props.navigate(`/projects/${projectIdParameter}/dashboard`);
  }

  const fireAnalyticsComputations = () => {
    // fire a computation
    analyticsService.fireAnalyticsComputations(projectIdParameter, sequences[indexSequence].id, analyticsType)
      .then(results => {
        openSnackbar('success', t("react.dashboard.analytics.fire.success"));
      })
      .catch(err => {
        openSnackbar('error', t("react.dashboard.analytics.fire.error"));
      });
  }

  const exportAsExcel = () => {
    openSnackbar('info', t("react.dashboard.toolbar.button.export.starting"));
    analyticsService.fetchAnalyticsAsExcelFileMode(analyticsId)
      .then(response => {
        var regExp = new RegExp('filename="([^"]+)"', 'i');
        let filename = regExp.exec(response.headers['content-disposition'])[1];
        let url = window.URL.createObjectURL(new Blob([response.data]));
        let link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        openSnackbar('success', t("react.dashboard.toolbar.button.export.finished"));
      })
      .catch(err => {
        console.error(err)
        openSnackbar('error', t("react.dashboard.toolbar.button.export.error"));
      });
  };

  const refresh = () => {
    setLoadData(LoadData.Load);
  };

  if (loadData !== LoadData.Loaded) {
    return <LinearProgress />
  } else {
    return (
      <>
        <Helmet title={project.name} />
        <Box className={classes.miniToolBar}>
          <Box className={classes.flex}>
            <InboxIcon />
            <Typography
              variant="h6"
              id="projectTitle"
              className={classes.typographyStyle}
              onClick={() => returnToProject()}
            >
              {project.name}
            </Typography>
          </Box>
          <Box className={classes.flex}>
            <Tooltip title={t('react.dashboard.analytics.button.back')}><span>
              <IconButton size='small' onClick={() => returnToDashboard()}>
                <BarChartIcon />
              </IconButton></span>
            </Tooltip>
            <Tooltip title={t('react.button.refresh')}><span>
              <IconButton size='small' onClick={() => refresh()}>
                <CachedIcon />
              </IconButton></span>
            </Tooltip>
            <Tooltip title={t('react.dashboard.analytics.button.exportAsExcel')}><span>
              <IconButton size='small' onClick={() => exportAsExcel()} disabled={analyticsId <= 0}>
                <ExportIcon />
              </IconButton></span>
            </Tooltip>
            {AuthService.isAdmin() &&
            <Tooltip title={t('react.dashboard.analytics.button.fire.analytics.computations')}><span>
              <IconButton size='small' onClick={() => fireAnalyticsComputations()} >
                <AutoAwesomeIcon style={{ color: '#F00' }}/>
              </IconButton></span>
            </Tooltip>
            }
            {sequences.length > 1 &&
              <FormControl style={{ m: 1, minWidth: 110, marginLeft: '10px' }} size="small">
                <Select
                  value={indexSequence}
                  onChange={(e) => handleChangeSequence(e)}
                >
                  {sequences.map((sequence, index) => (
                    <MenuItem key={sequence.id} value={index}>{sequence.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            }
            <FormControl style={{ m: 1, minWidth: 220, marginLeft: '10px' }} size="small">
              <Select
                value={analyticsType}
                onChange={handleChangeAnalytics}
              >
                <MenuItem value={1}>{t('react.dashboard.analytics.button.mode1')}</MenuItem>
                <MenuItem value={2}>{t('react.dashboard.analytics.button.mode2')}</MenuItem>
              </Select>
            </FormControl>

          </Box>
        </Box>
        {noData ?
          <Box sx={{
            textAlign: 'center',
            m: 10,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
            fontSize: '1.1em'
          }}>
            <TimerIcon className={classes.timerIcon} />
            {t('react.dashboard.analytics.nodata.message', { 'projectName': project.name })}
          </Box>
          :
          analyticsType === 1 ?
            <AnalyticModeOne
              {...props}
              data={data}
              StyledTableRow={StyledTableRow}
              configuration={configuration}
              projectId={projectIdParameter}
              sequences={sequences}
              indexSequence={indexSequence}
            /> :
            <AnalyticModeTwo
              {...props}
              data={data}
              StyledTableRow={StyledTableRow}
              configuration={configuration}
              projectId={projectIdParameter}
              sequences={sequences}
              indexSequence={indexSequence}
            />
        }
      </>
    );
  }
}
