import ApiService from './ApiService';
require('dotenv').config()

const apiService = new ApiService();
const restClient = apiService.getClient();

class ReferenceService {

  async fetchCoefficients() {
      return restClient.get('/api/references/v1.0/coefficients');
  }
}

export default ReferenceService;
