import React, { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitleWithCloseIcon from '../shared/DialogTitleWithCloseIcon/DialogTitleWithCloseIcon';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import { isBlank } from '../../utils/utils.js';
import { Select, Input } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    maxHeight: '400px',
    backgroundColor: theme.palette.background.paper,
  },
  SomeSpaceUnder: {
    marginBottom: theme.spacing(2),
  },
  formControl: {
    minWidth: 120,
    width: '100%',
    marginTop: '15px',
  },
  textField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}));

const ImportLibraryDialog = (props) =>  {
  // expected parameters
  const {
    t,
    openState,
    supportedLanguages,
    callbackAfterConfirm,
    callbackAfterCancel,
    openSnackbar,
    closeSnackbar
  } = props;

  const { classes } = useStyles();

  const [language, setLanguage] = useState('');
  const [selectedCodificationFile, setSelectedCodificationFile] = useState({ name: "" });

  const handleSelectedCodificationFile = (event) => {
    event.preventDefault();
    if (event.target.files.length === 1) {
      let fnlc = event.target.files[0].name.toLowerCase();
      if (fnlc.endsWith('.xlsx')) {
        setSelectedCodificationFile(event.target.files[0]);
      } else {
        openSnackbar('error', t('react.codification.import.library.error.unsupported'));
      }
    }
  };

  const handleClickConfirm = (event) => {
    event.preventDefault();

    if(isBlank(selectedCodificationFile.name) || isBlank(language)) {
      openSnackbar('error', t('react.codification.import.library.error.empty'));
      return;
    }

    const formData = new FormData();
    formData.append("language", language);
    formData.append("codificationFile", selectedCodificationFile);

    callbackAfterConfirm(formData);
  };

  const handleClickCancel = (event) => {
    event.preventDefault();

    setLanguage('');
    setSelectedCodificationFile({ name: "" });

    closeSnackbar();
    callbackAfterCancel();
  };

  if(!openState) {
    return null;
  }

  return (
      <Dialog open={openState} onClose={handleClickCancel}>
        <DialogTitleWithCloseIcon
          title={t("react.codification.import.library.title")}
          callbackOnclose={handleClickCancel}
        />
        <DialogContent>
          <DialogContentText>
            {t("react.codification.import.library.content")}
          </DialogContentText>
          <Box className={classes.SomeSpaceUnder}>
            <FormControl className={classes.formControl}>
              <InputLabel id="language-label" variant='standard'>{t("react.codification.import.field.lang")}</InputLabel>
              <Select
                labelId="language-label"
                id="language"
                value={language}
                onChange={e => setLanguage(e.target.value)}
              >
                {supportedLanguages.map(language => {
                  return (
                    <MenuItem key={language} value={language}>{language}</MenuItem>
                )})}
              </Select>
            </FormControl>
          </Box>
          <Box className={classes.SomeSpaceUnder}>
            <FormControl className={classes.formControl}>
              <Input
                  disabled
                  id="selectedCodificationFilename"
                  value={selectedCodificationFile.name}
                  className={classes.textField}
                  placeholder={t("react.codification.import.field.codificationFile")}
                  endAdornment={
                    <InputAdornment position="end">
                      <label htmlFor="contained-button-file_3">
                        <Button variant="contained" color="primary" component="span">
                          {t('react.generic.select')}
                        </Button>
                      </label>
                    </InputAdornment>
                  }
                />
                <input
                  onChange={handleSelectedCodificationFile}
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  style={{ display: 'none' }}
                  id="contained-button-file_3"
                  type="file"
                />
            </FormControl>
           </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickCancel} color="primary">
            {t("react.button.cancel")}
          </Button>
          <Button onClick={handleClickConfirm} color="primary" disabled={isBlank(selectedCodificationFile.name) || isBlank(language)}>
            {t("react.button.apply")}
          </Button>
        </DialogActions>
      </Dialog>
  );
}

export default ImportLibraryDialog;
