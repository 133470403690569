import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import MenuItem from '@mui/material/MenuItem';
import Input from '@mui/material/Input';
import FormControl from '@mui/material/FormControl';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitleWithCloseIcon from '../shared/DialogTitleWithCloseIcon/DialogTitleWithCloseIcon';
import Button from '@mui/material/Button';
import { indexOf, capitalizeFirstLetter } from '../../utils/utils.js';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const useStyles = makeStyles()(theme => ({
    content: {
        fontSize: '0.875em'
    },
    formControl: {
        margin: theme.spacing(1),
        width: 250
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    warnHasNonGlobalFilters: {
        color: 'red'
    },
}));

function getStyles(name, arr, theme) {
    return {
        fontWeight:
            arr.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const engagementValues = [
    { 'id': 1, 'value': 1, 'label': 'react.dashboard.filterdialog.select.engagementType.lovers' },
    { 'id': 0, 'value': 0, 'label': 'react.dashboard.filterdialog.select.engagementType.neutrals' },
    { 'id': -1, 'value': -1, 'label': 'react.dashboard.filterdialog.select.engagementType.rejectors' },
];

export default function FilterDialog(props) {

    const { open, handleClose, handleApply, configuration, stimulusId, stimuli, filters, t } = props;

    const theme = useTheme();
    const { classes } = useStyles();

    const [filterData, setFilterData] = useState({});
    const [filterDataOriginal, setFilterDataOriginal] = useState({});

    const [engagementFilter, setEngagementFilter] = useState([]);
    const [engagementFilterOriginal, setEngagementFilterOriginal] = useState([]);

    const [warnHasNonGlobalFilters, setWarnHasNonGlobalFilters] = useState(false);

    const confirmApply = (configuration) => {
        handleClose(configuration);

        const newconfiguration = { ...configuration };

        newconfiguration.updated = true;
        // ensure initialized data
        if (!newconfiguration.filters) newconfiguration.filters = {};
        if (!newconfiguration.engagementFilters) newconfiguration.engagementFilters = {};

        if (stimulusId) {
            // MODE SINGLE STIMULUS
            for (let f in filterData) {
                if (!newconfiguration.filters[stimulusId]) newconfiguration.filters[stimulusId] = {};
                newconfiguration.filters[stimulusId][f] = [...filterData[f]];
            }

            newconfiguration.engagementFilters[stimulusId] = [];
            for (let f in engagementFilter) {
                newconfiguration.engagementFilters[stimulusId].push(engagementFilter[f].value);
            }
        } else if (stimuli) {
            // MODE GLOBAL

            // we have to check what has been globally deleted
            let deletedFilterValueId = [];
            for (let filterId in filterDataOriginal) {
                filterDataOriginal[filterId].forEach(selectedValOriginal => {
                    let exists = indexOf(filterData[filterId], 'id', selectedValOriginal.id) >= 0;
                    if (!exists) deletedFilterValueId.push(selectedValOriginal.id);
                });
            }
            for (let idx in engagementFilterOriginal) {
                let exists = indexOf(engagementFilter, 'id', engagementFilterOriginal[idx].id) >= 0;
                if (!exists) deletedFilterValueId.push(engagementFilterOriginal[idx].id);
            }

            stimuli.forEach(s => {
                // ensure it's initialized for this stimulus
                if (!newconfiguration.filters[s.id]) newconfiguration.filters[s.id] = {};
                if (!newconfiguration.engagementFilters[s.id]) newconfiguration.engagementFilters[s.id] = [];

                // remove deselected elements to this stimulus
                deletedFilterValueId.forEach((removedFilterValueId, i) => {
                    Object.entries(newconfiguration.filters[s.id]).forEach(([filter, filterValues]) => {
                        // loop from end to begin
                        for (let i = filterValues.length - 1; i >= 0; i--) {
                            if (filterValues[i].id === removedFilterValueId) {
                                newconfiguration.filters[s.id][filter].splice(i, 1);
                            }
                        }
                    });

                    let idx = newconfiguration.engagementFilters[s.id].indexOf(removedFilterValueId);
                    if (idx > -1) newconfiguration.engagementFilters[s.id].splice(idx, 1);
                });

                // loop over the new selections
                for (let f in filterData) {
                    // if there is not previous selections
                    if (!newconfiguration.filters[s.id][f] || newconfiguration.filters[s.id][f].length === 0) {
                        // just set the new selections
                        newconfiguration.filters[s.id][f] = [...filterData[f]];
                        continue;
                    }

                    // append selected values of this stimulus / filter
                    filterData[f].forEach(selectedVal => {
                        let exists = indexOf(newconfiguration.filters[s.id][f], 'id', selectedVal.id) >= 0;
                        if (!exists) {
                            // avoid duplicates
                            newconfiguration.filters[s.id][f].push(selectedVal);
                        }
                    });
                }

                for (let idx in engagementFilter) {
                    let exists = newconfiguration.engagementFilters[s.id].indexOf(engagementFilter[idx].id) >= 0;
                    if (!exists) {
                        // avoid duplicates
                        newconfiguration.engagementFilters[s.id].push(engagementFilter[idx].value);
                    }
                }
            });
        }

        return newconfiguration;
    }

    const handleChangeMultipleEngagementFilter = (event) => {
        setEngagementFilter(event.target.value);
    };

    const handleChangeMultiple = (event, filter) => {
        let newFilterData = { ...filterData };
        newFilterData[filter.id] = event.target.value;
        setFilterData(newFilterData);
    };

    useEffect(() => {
        if (open === false || configuration === undefined) return;

        var hasNonGlobalSelections = false;

        if (configuration.filters) {
            let newFilterData = {};

            if (stimulusId && configuration.filters[stimulusId]) {
                filters.forEach(v => {
                    if (configuration.filters[stimulusId][v.id]) {
                        let configured = configuration.filters[stimulusId][v.id].map(f => f.id);
                        newFilterData[v.id] = v.values.filter(filter => configured.indexOf(filter.id) >= 0);
                    }
                });
            } else if (stimuli) {
                let nbStimulusPerFilterValueId = {};
                filters.forEach(f => {
                    f.values.forEach(v => {
                        // count how many stimulus have this value checked
                        nbStimulusPerFilterValueId[v.id] = stimuli
                            .filter(s => configuration.filters[s.id] && indexOf(configuration.filters[s.id][f.id], 'id', v.id) > -1)
                            .length;
                    })
                });
                filters.forEach(f => {
                    // set globally selected values
                    newFilterData[f.id] = f.values.filter(v => nbStimulusPerFilterValueId[v.id] === stimuli.length);

                    // check if has partially selected values
                    hasNonGlobalSelections = hasNonGlobalSelections || f.values
                        .filter(v => nbStimulusPerFilterValueId[v.id] > 0 && nbStimulusPerFilterValueId[v.id] !== stimuli.length)
                        .length > 0;
                });
            }

            setFilterData(newFilterData);
            setFilterDataOriginal(newFilterData);
        }

        if (configuration.engagementFilters) {
            let newEngagementFilters = [];

            if (stimulusId && configuration.engagementFilters[stimulusId]) {
                let selected = configuration.engagementFilters[stimulusId];
                newEngagementFilters = engagementValues.filter(filter => selected.indexOf(filter.id) >= 0);
            } else if (stimuli) {
                let nbStimulusPerEngagementValueId = {};
                engagementValues.forEach(v => {
                    // count how many stimulus have this value checked
                    nbStimulusPerEngagementValueId[v.id] = stimuli
                        .filter(s => configuration.engagementFilters[s.id] && configuration.engagementFilters[s.id].indexOf(v.id) > -1)
                        .length;
                });

                newEngagementFilters = engagementValues.filter(filter => nbStimulusPerEngagementValueId[filter.id] === stimuli.length);
                hasNonGlobalSelections = hasNonGlobalSelections || engagementValues
                    .filter(filter => nbStimulusPerEngagementValueId[filter.id] > 0 && nbStimulusPerEngagementValueId[filter.id] !== stimuli.length)
                    .length > 0;
            }

            setEngagementFilter(newEngagementFilters);
            setEngagementFilterOriginal(newEngagementFilters);
        }

        setWarnHasNonGlobalFilters(hasNonGlobalSelections);
    }, [open, configuration, stimulusId, filters, stimuli]);

    if(!open) return null;

    return (
        <form noValidate autoComplete="off">
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitleWithCloseIcon
                    title={t('react.dashboard.filterdialog.title')}
                    callbackOnclose={handleClose}
                />
                <DialogContent>
                    <Box className={classes.content}>{t('react.dashboard.filterdialog.content')}</Box>
                    {warnHasNonGlobalFilters &&
                        <Typography variant='body2' className={classes.warnHasNonGlobalFilters}>{t('react.dashboard.filterdialog.warnHasNonGlobalFilters')}</Typography>
                    }
                    <FormControl key='FormControl-001' className={classes.formControl}>
                        <InputLabel id="select.engagementType-label" variant='standard'>{t('react.dashboard.filterdialog.select.engagementType')}</InputLabel>
                        <Select
                            id="select.engagementType"
                            labelId="select.engagementType-label"
                            multiple
                            value={engagementFilter}
                            input={<Input />}
                            onChange={e => { handleChangeMultipleEngagementFilter(e) }}
                            renderValue={selected => selected.map(v => t(v.label)).join(', ')}
                            MenuProps={{
                                maxheight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                width: 250,
                            }}
                        >
                            {engagementValues.map(value => {
                                return (
                                    <MenuItem key={value.id} value={value}>
                                        <Checkbox checked={indexOf(engagementFilter, 'id', value.id) > -1} />
                                        <ListItemText primary={t(value.label)} />
                                    </MenuItem>
                                )})}
                        </Select>
                    </FormControl>
                    {
                        filters.filter(filter => filter.hidden === false).map(filter => {
                            const filterValue = filterData[filter.id] || [];
                            return <FormControl key={filter.id} className={classes.formControl}>
                                <InputLabel id={`select-filter-${filter.id}`} variant='standard'>{capitalizeFirstLetter(filter.name)}</InputLabel>
                                <Select
                                    id={`${filter.id}`}
                                    labelId={`select-filter-${filter.id}`}
                                    multiple
                                    value={filterValue}
                                    input={<Input />}
                                    MenuProps={{
                                        maxheight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                        width: 250,
                                    }}
                                    onChange={e => { handleChangeMultiple(e, filter) }}
                                    renderValue={selected => selected.map(v => v.value).join(', ')}
                                >
                                    {
                                        filter.values.filter(filter => filter.hidden === false).map(value => {
                                            return <MenuItem key={value.id} value={value} style={getStyles(value.value, filterValue, theme)}>
                                                <Checkbox checked={indexOf(filterValue, 'id', value.id) > -1} />
                                                <ListItemText primary={value.value} />
                                            </MenuItem>
                                        })
                                    }
                                </Select>
                            </FormControl>
                        })
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        {t('react.button.cancel')}
                    </Button>
                    <Button onClick={(e) => handleApply(confirmApply(configuration))} autoFocus>
                        {t('react.button.apply')}
                    </Button>
                </DialogActions>
            </Dialog>
        </form >
    )
}
