import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitleWithCloseIcon from '../shared/DialogTitleWithCloseIcon/DialogTitleWithCloseIcon';
import Button from '@mui/material/Button';
import {TextField, Select, InputLabel} from '@mui/material';
import TranslationService from '../../services/TranslationService'
import { AuthService } from '../../services/AuthService';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { LoadData } from '../../Constants.js';
import MenuItem from '@mui/material/MenuItem';
import { cloneDeep, isBlank } from '../../utils/utils.js';
import FormControl from '@mui/material/FormControl';
import ISO6391 from 'iso-639-1';

const translationService = new TranslationService();

const useStyles = makeStyles()(theme => ({
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  textField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}));

export default function TranslationEdit(props) {

  const { t, openSnackbar,
    openState,
    id,
    callbackAfterConfirm,
    callbackAfterCancel,
    showSpinner,
    supportedLanguages
  } = props;

  const { classes } = useStyles();

  const emptyTranslation = { id: 0, key: '', locale: 'none', content: '' };

  const [translation, setTranslation] = useState(cloneDeep(emptyTranslation));
  const changeField = (name, value) => {
    let newTranslation = cloneDeep(translation, { [name]: value });
    setTranslation(newTranslation);
  };

  const handleClickEditDialogCancel = (event) => {
    event.preventDefault();
    callbackAfterCancel();
  };

  const handleClickEditDialogConfirm = () => {
    let result;
    if (id === 0) {
      result = translationService.create(translation);
    } else {
      result = translationService.update(translation);
    }
    result.then(result => {
      callbackAfterConfirm();
    })
      .catch(err => {
        openSnackbar('error', t('react.generic.error.while.saving', { error: err.message }));
      });
  };

  const [loadData, setLoadData] = useState(LoadData.Load);
  useEffect(() => {
    if (!openState) {
      setLoadData(LoadData.Load);
      return;
    }

    if (loadData !== LoadData.Load) return;

    setLoadData(LoadData.Loading);

    showSpinner(true);

    if (id > 0) {
      translationService.findOne(id)
        .then(result => {
          let tn = cloneDeep(emptyTranslation, result.data);
          setTranslation(tn);
        })
        .catch(err => {
          openSnackbar('error', t('react.generic.error.while.loading'));
        }).finally(() => {
          showSpinner(false);
          setLoadData(LoadData.Loaded);
        });
    } else {
      setTranslation(cloneDeep(emptyTranslation));
      showSpinner(false);
      setLoadData(LoadData.Loaded);
    }
  }, [loadData, id, openState]);

  if (loadData !== LoadData.Loaded) {
    return null;
  }

  return (
    <Dialog fullWidth={true} maxWidth={'md'} open={openState} onClose={handleClickEditDialogCancel}>
      {id > 0 ?
        <DialogTitleWithCloseIcon
          title={t("react.translationtool.edittranslation", { 'locale': translation.locale, 'key': translation.key })}
          callbackOnclose={handleClickEditDialogCancel}
        /> :
        <DialogTitleWithCloseIcon
          title={t("react.translationtool.createnewtranslation")}
          callbackOnclose={handleClickEditDialogCancel}
        />
      }
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={3}>
          <FormControl fullWidth>
            <InputLabel id="reviewsInputFileLang-label" variant='standard'>{t('react.translationtool.edit.locale')}</InputLabel>
            <Select 
              labelId="translation-label"
              id="translation"
              autoFocus
              fullWidth
              value={translation.locale}
              onChange={e => changeField('locale', e.target.value.toLowerCase())}
            >
              <MenuItem key='-1' value='none' disabled>{t('react.translationtool.edit.select.value')}</MenuItem>
              {supportedLanguages.map((language, idx) => (
                <MenuItem key={idx} value={language}>{ISO6391.getNativeName(language)}</MenuItem>
              ))}
            </Select>
            </FormControl>
          </Grid>
          <Grid item xs={9}>
          <FormControl fullWidth>
            <TextField
              label={t('react.translationtool.edit.key')}
              type="text"
              value={translation.key}
              fullWidth
              onChange={e => changeField('key', e.target.value)}
            />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant='outlined'
              multiline={true}
              minRows={10}
              maxRows={50}
              margin="dense"
              label={t('react.translationtool.edit.content')}
              type="text"
              value={translation.content}
              fullWidth
              className={classes.textField}
              onChange={e => changeField('content', e.target.value)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          size="large"
          onClick={handleClickEditDialogCancel}
          color="primary"
          startIcon={<CloseIcon />}
          className={classes.button}
        >
          {t("react.button.cancel")}
        </Button>
        {AuthService.isManager() &&
          <Button
            variant="outlined"
            size="large"
            onClick={handleClickEditDialogConfirm}
            color="primary"
            startIcon={<SaveIcon />}
            className={classes.button}
            disabled={isBlank(translation.locale) || translation.locale === 'none' || isBlank(translation.key) || isBlank(translation.content)}
          >
            {t("react.button.save")}
          </Button>
        }
      </DialogActions>
    </Dialog>
  );
};
