import ApiService from './ApiService';
require('dotenv').config()


const apiService = new ApiService();
const restClient = apiService.getClient();

class NotificationService {

    async getMyNotifications(from, size) {
        return restClient.get('/api/notifications/v1.0/?from=' + from + '&size=' + size);
    }

    async markAsRead(notificationId) {
        return restClient.put('/api/notifications/v1.0/'+notificationId+'/read');
    }

    async markAllAsRead() {
        return restClient.put('/api/notifications/v1.0/all/read');
    }
}

export default NotificationService;
