import React, { useEffect, useState, Fragment } from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official';

export default function SimplePieChart(props) {

    const {
        t,
        subindex,
        zone,
        base,
        formBlock
    } = props;

    const simplePieChart = (subindex) => {

        let values = [];
        let countTotalAnswers = 0;

        zone.answers.forEach(a => {
            countTotalAnswers += a.nbAnswers
            values.push({name: a.text, y: a.nbAnswers});
        })
        values.push({name: t('react.reporting.survey.neutral'), y: base-countTotalAnswers});

        const formBlockZone = formBlock.hotspot.zones.find(z => z.id === zone.id);
        const title = `${formBlock.ref}.${formBlockZone.ref}: ${formBlockZone.name}`

        return {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
            },
            title: {
                text: title
            },
            tooltip: {
                formatter: function () {
                    return this.point.name + ': <b>' + Math.round(this.y / this.total * 100) + '% </b>'
                }
            },
            accessibility: {
                point: {
                    valueSuffix: '%'
                }
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        format: '{point.name}: {point.y}',
                        distance: 30,
                        style: {
                            fontWeight: 300
                        }
                    },
                    size: 240
                }
            },
            series: [{
                name: 'Brands',
                colorByPoint: true,
                data: values
            }],
            credits: {
                enabled: false
            },

        }
    }

    return (
        <HighchartsReact highcharts={Highcharts} options={simplePieChart(subindex)} />
    );
};