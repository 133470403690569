import React, { useState, useEffect, Fragment } from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { makeStyles } from 'tss-react/mui';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import { formatPValue, appendSuffixIfGreaterThan } from '../../utils/utils.js';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DashboardService from '../../services/DashboardService';
import PopupTwoButtons from '../shared/PopupTwoButtons/PopupTwoButtons';
import { configIdParamAtom } from "../../recoil/ProjectDashboard/atom";
import { useSetRecoilState } from "recoil";

const dashboardService = new DashboardService();

const useStyles = makeStyles()(theme => ({
    table: {
        minWidth: 650,
    },
    minWidthCells: {
        minWidth: '130px',
    },
    boxRed: {
        marginRight: '20px',
        fontWeight: '700',
        color: 'rgb(255 62 60)',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center'
    },
    boxOrange: {
        marginRight: '20px',
        fontWeight: '700',
        color: 'rgb(248 199 57)',
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center'
    },
    imgArrow: {
        marginRight: '8px',
        width: "20px"
    },
    marginRightBox: {
        marginRight: '20px'
    },
    emptyImg: {
        width: '20px',
        opacity: '0',
        marginRight: '8px'
    },
    tableCellStickyLeftFilterTitle: {
        top: '0',
        left: '0',
        zIndex: '1',
        position: 'sticky',
        borderCollapse: 'separate',
        fontWeight: '500',
        backgroundColor: '#f6f6f6',
        minWidth: '130px',
        borderRight: '1px solid #d2d2d2',
    },
    tableCellStickyLeftFilterValue: {
        top: '0',
        left: '0',
        zIndex: '1',
        position: 'sticky',
        borderCollapse: 'separate',
        backgroundColor: 'white',
        borderRight: '1px solid #d2d2d2',
    },
    stickyTotalTableCell: {
        top: '0',
        left: '130px',
        zIndex: '4',
        position: 'sticky',
        borderCollapse: 'separate',
        backgroundColor: 'white',
        minWidth: '130px',
        borderRight: '1px solid #d2d2d2',
    },
    emptyCellBorderRight: {
        top: '0',
        left: '130px',
        zIndex: '2',
        position: 'sticky',
        borderCollapse: 'separate',
        backgroundColor: '#f6f6f6',
        minWidth: '130px',
        borderRight: '1px solid #d2d2d2',
        fontWeight: 500,
    },
    tableCell1: {
        top: '0',
        left: '0',
        zIndex: '3',
        position: 'sticky',
        borderCollapse: 'separate',
        backgroundColor: '#fafafa',
        minWidth: '130px',
        height: '120px',
        borderRight: '1px solid #d2d2d2',
    },
    tableCell2: {
        top: '0',
        left: '130px',
        zIndex: '5',
        position: 'sticky',
        borderCollapse: 'separate',
        backgroundColor: '#fafafa',
        minWidth: '130px',
        borderRight: '1px solid #d2d2d2',
    },
    tableCell3: {
        backgroundColor: 'white',
        top: '120px',
        left: '0',
        zIndex: '6',
        position: 'sticky',
        borderCollapse: 'separate',
        fontWeight: '500',
        borderRight: '1px solid #d2d2d2',
    },
    tableCell4: {
        top: '120px',
        left: '130px',
        zIndex: '6',
        position: 'sticky',
        borderCollapse: 'separate',
        backgroundColor: 'white',
        minWidth: '130px',
        borderRight: '1px solid #d2d2d2',
        fontWeight: 500,
    },
    tableCell5: {
        minWidth: '130px',
        backgroundColor: 'white',
        position: 'sticky',
        top: '120px',
        zIndex: '5',
    },
    buttonNewConfig: {
        position: 'absolute',
        transform: 'scale(0.9)',
        top: -10,
        right: 0,
        opacity: '0.9',
    },
}));

const emotionalFilters = [
    { name: 'Lovers', value: 'LOVER', number: 1 },
    { name: 'In between', value: 'NEUTRAL', number: 0 },
    { name: 'Rejectors', value: 'REJECTOR', number: -1 }
];

export default function AnalyticModeOne(props) {

    const { t, data, StyledTableRow, configuration, openSnackbar, projectId, sequences, indexSequence } = props;

    const { classes } = useStyles();

    const hasEmotionalFilters = data.analytics.find(analytics => analytics.emotional_filter_value !== undefined) !== undefined;

    const [filters, setFilters] = useState([]);
    const [popupTwoButtonState, setPopupTwoButtonState] = useState(false);
    const [filterValueParam, setFilterValueParam] = useState('');
    const [filterParam, setFilterParam] = useState('');
    const [msgEmotion, setMsgEmotion] = useState(false);

    const setConfigIdParam = useSetRecoilState(configIdParamAtom);

    useEffect(() => {
        // keep visible filters
        let temporaryFilters = data.filters.filter(filter => filter.hidden === undefined || filter.hidden === false);
        for (let filterIdx in temporaryFilters) {
            // keep visible filter values
            temporaryFilters[filterIdx].values = temporaryFilters[filterIdx].values.filter(value => value.hidden === undefined || value.hidden === false);
        }
        setFilters(temporaryFilters);
    }, [data])

    const openPopupNewConfig = (event, filterValue, filter) => {
        event.preventDefault();
        setFilterParam(filter)
        setFilterValueParam(filterValue);
        if (filter === "emotion") {
            setMsgEmotion(true);
        } else {
            setMsgEmotion(false);
        }
        setPopupTwoButtonState(true);
    }

    const displayButtonAutoConfig = (filterId, show) => {
        if (filterId !== undefined) {
            document.getElementById(`button${filterId}`).style.display = show ? 'block' : 'none';
        }
    }

    const handleDeleteDialogCancel = () => {
        setPopupTwoButtonState(false);
    }

    const getAutoconfigName = (stimulusSequenceId, stimulusName) => {
        if (sequences.length > 1) {
            let sequenceName = sequences.find(s => s.id === stimulusSequenceId).name;
            return `${stimulusName} - ${sequenceName}`;
        } else {
            return `${stimulusName}`;
        }
    }

    const generateNewConfig = () => {
        let stimuliConfig = [];
        let automaticConfig = { ...configuration };

        if (filterParam !== 'emotion') {
            automaticConfig.name = `${filterParam.name} - ${filterValueParam.value}`;
            data.stimulus.forEach((stimuli) => {
                automaticConfig.names[stimuli.id] = getAutoconfigName(stimuli.sequenceId, stimuli.name);
                automaticConfig.compareWith[stimuli.id] = { id: -200 };
                automaticConfig.filters[stimuli.id] = {
                    [filterParam.id]: [{
                        id: filterValueParam.id,
                        value: filterValueParam.value
                    }]
                };
                automaticConfig.stimulusIds.push(stimuli.id);
            })
        } else {
            automaticConfig.name = `${t('react.projectanalytics.emotional.activation.title')} - ${filterValueParam}`;
            data.stimulus.forEach((stimuli) => {
                automaticConfig.names[stimuli.id] = getAutoconfigName(stimuli.sequenceId, stimuli.name);
                automaticConfig.compareWith[stimuli.id] = { id: -200 };
                automaticConfig.engagementFilters[stimuli.id] = [emotionalFilters.find(emotionFilter => emotionFilter.name === filterValueParam).number];
                automaticConfig.stimulusIds.push(stimuli.id);
            })
        }

        dashboardService.saveConfiguration(projectId, null, stimuliConfig, automaticConfig)
            .then(res => {
                setConfigIdParam(res.id);
                props.navigate(`/projects/${projectId}/dashboard`);
            })
            .catch(err => {
                openSnackbar('error', t('react.snackbar.analytics.autoconfig'));
            })
    }

    const getTotalCell = (stimuliId) => {
        let analytics = data.totalAnalytics.find(entry => entry.of === stimuliId);
        let stimulusName = stimuliId === -100 ? 'Total' : data.stimulus.find(entry => entry.id === stimuliId).name;

        let valueTotal = analytics.of_score / 100;
        let tooltip = `${stimulusName}: Score = ${Math.round(valueTotal)} / Base = ${analytics.of_base}`;

        if (analytics.of_base < 5) {
            if (analytics.of_base === 0) {
                tooltip = `${stimulusName}: Base = 0`;
            }

            return (
                <Tooltip title={tooltip}>
                    <span>
                        {t("react.projectdashboard.no.data")}
                    </span>
                </Tooltip>
            );
        } else {
            let pValue = undefined;
            let significativitySign = undefined;

            if (stimuliId > 0) {
                let analyticsOfTotal = data.totalAnalytics.find(entry => entry.of === -100);
                pValue = analytics.significativity;
                if (pValue < configuration.comparisonSignificativityValue / 100) {
                    significativitySign = analytics.with_score > analytics.of_score ? '-' : '+';
                }
                tooltip = `${stimulusName}: Score = ${Math.round(valueTotal)} / Total = ${Math.round(analyticsOfTotal.of_score / 100)} / pValue = ${formatPValue(pValue, configuration.pValueType)} / Base = ${analytics.of_base}`;
            }

            return (
                <Fragment>
                    {significativitySign === '+' && pValue !== undefined && pValue >= 0 ?
                        <Tooltip title={tooltip}>
                            <Box className={classes.boxOrange}>
                                <img alt="" src="/img/ArrowY.png" className={classes.imgArrow} />
                                {Math.round(valueTotal)}
                            </Box>
                        </Tooltip>
                        : significativitySign === '-' && pValue !== undefined && pValue >= 0 ?
                            <Tooltip title={tooltip}>
                                <Box className={classes.boxRed}>
                                    <img alt="" src="/img/ArrowR.png" className={classes.imgArrow} />
                                    {Math.round(valueTotal)}
                                </Box>
                            </Tooltip> :
                            <Tooltip title={tooltip}>
                                <Box className={classes.marginRightBox}>
                                    <img alt="" src="/img/ArrowY.png" className={classes.emptyImg} />
                                    {Math.round(valueTotal)}
                                </Box>
                            </Tooltip>
                    }
                </Fragment>
            );
        }
    };

    const getStimulusFilterCell = (stimuliId, filterValueId = 0, numberOfStimuli = undefined, indexInList = undefined, filterValue = undefined, filter = undefined, emotionalValue = undefined) => {
        let stimulusName = data.stimulus.find(stimulus => stimulus.id === stimuliId).name;
        let lastCell = indexInList === numberOfStimuli - 1 ? true : false;

        let analytics = undefined;
        let analyticsOfTotal = undefined;

        if (filterValueId > 0) {
            analytics = data.analytics.find(analytics => analytics.of === stimuliId && analytics.filter_value_id === filterValueId);
            analyticsOfTotal = data.analytics.find(analytics => analytics.of === -100 && analytics.filter_value_id === filterValueId);
        } else if (emotionalValue !== undefined) {
            analytics = data.analytics.find(analytics => analytics.of === stimuliId && analytics.emotional_filter_value === emotionalValue);
            analyticsOfTotal = data.analytics.find(analytics => analytics.of === -100 && analytics.emotional_filter_value === emotionalValue);
        } else {
            return;
        }

        let valueTotal = analyticsOfTotal.of_score / 100;
        let pValue = analytics.significativity;
        let valueCurrentStimulus = analytics.of_score / 100;
        let significativitySign = undefined;

        if (pValue < configuration.comparisonSignificativityValue / 100) {
            significativitySign = valueTotal > valueCurrentStimulus ? '-' : '+';
        }

        let tooltip = `${stimulusName}: Score = ${Math.round(valueCurrentStimulus)} / Total = ${Math.round(valueTotal)} / pValue = ${formatPValue(pValue, configuration.pValueType)} / Base = ${analytics.of_base}`;

        if (analytics.of_base < 5) {
            if (analytics.of_base === 0) {
                tooltip = `${stimulusName}: Base = 0`;
            }

            return (
                <TableCell style={{ color: 'rgba(0,0,0,0.4)' }} align="center">
                    <Tooltip title={tooltip}>
                        <Box>
                            {t("react.projectdashboard.no.data")}
                        </Box>
                    </Tooltip>
                    {lastCell &&
                        <Tooltip
                            title={t("react.analytics.tooltip.autoconfig")}
                            placement="top"
                        >
                            <IconButton
                                style={{ display: 'none' }}
                                id={`button${(emotionalValue !== undefined ? filterValue : filterValue.id)}`}
                                className={classes.buttonNewConfig}
                                onClick={(event) => openPopupNewConfig(event, filterValue, emotionalValue !== undefined ? 'emotion' : filter)}
                                size="large">
                                <AddCircleIcon />
                            </IconButton>
                        </Tooltip>
                    }
                </TableCell>
            );
        } else if (analytics.with_base < 5) {
            /* R3MSCORE-503: do not display arrow if the 'compare with' base is less than 5 */
            return (
                <TableCell component="th" scope="row" align="center">
                    <Tooltip title={tooltip}>
                        <Box className={classes.marginRightBox}>
                            <img alt="" src="/img/ArrowY.png" className={classes.emptyImg} />
                            {Math.round(valueCurrentStimulus)}
                        </Box>
                    </Tooltip>
                    {lastCell &&
                        <Tooltip
                            title={t("react.analytics.tooltip.autoconfig")}
                            placement="top"
                        >
                            <IconButton
                                style={{ display: 'none' }}
                                id={`button${(emotionalValue !== undefined ? filterValue : filterValue.id)}`}
                                className={classes.buttonNewConfig}
                                onClick={(event) => openPopupNewConfig(event, filterValue, emotionalValue !== undefined ? 'emotion' : filter)}
                                size="large">
                                <AddCircleIcon />
                            </IconButton>
                        </Tooltip>
                    }
                </TableCell>
            );
        } else {
            return (
                <TableCell
                    component="th"
                    scope="row"
                    align="center"
                >
                    {significativitySign === '+' && pValue !== undefined && pValue >= 0 ?
                        <Tooltip title={tooltip}>
                            <Box className={classes.boxOrange}>
                                <img alt="" src="/img/ArrowY.png" className={classes.imgArrow} />
                                {Math.round(valueCurrentStimulus)}
                            </Box>
                        </Tooltip>
                        : significativitySign === '-' && pValue !== undefined && pValue >= 0 ?
                            <Tooltip title={tooltip}>
                                <Box className={classes.boxRed}>
                                    <img alt="" src="/img/ArrowR.png" className={classes.imgArrow} />
                                    {Math.round(valueCurrentStimulus)}
                                </Box>
                            </Tooltip> :
                            <Tooltip title={tooltip}>
                                <Box className={classes.marginRightBox}>
                                    <img alt="" src="/img/ArrowY.png" className={classes.emptyImg} />
                                    {Math.round(valueCurrentStimulus)}
                                </Box>
                            </Tooltip>
                    }
                    {lastCell &&
                        <Tooltip
                            title={t("react.analytics.tooltip.autoconfig")}
                            placement="top"
                        >
                            <IconButton
                                style={{ display: 'none' }}
                                id={`button${(emotionalValue !== undefined ? filterValue : filterValue.id)}`}
                                className={classes.buttonNewConfig}
                                onClick={(event) => openPopupNewConfig(event, filterValue, emotionalValue !== undefined ? 'emotion' : filter)}
                                size="large">
                                <AddCircleIcon />
                            </IconButton>
                        </Tooltip>
                    }
                </TableCell>
            );
        }
    }

    const getTotalFilterCell = (filterValueId = 0, emotionalValue = undefined) => {
        let analytics = undefined;

        if (filterValueId > 0) {
            analytics = data.analytics.find(analytics => analytics.of === -100 && analytics.filter_value_id === filterValueId);
        } else if (emotionalValue !== undefined) {
            analytics = data.analytics.find(analytics => analytics.of === -100 && analytics.emotional_filter_value === emotionalValue);
        } else {
            return;
        }

        let valueTotal = analytics.of_score / 100;

        let tooltip = `Total: Score = ${Math.round(valueTotal)} / Base = ${analytics.of_base}`;

        if (analytics.of_base < 5) {
            if (analytics.of_base === 0) {
                tooltip = `Total: Base = 0`;
            }

            return (
                <TableCell align="center" className={classes.stickyTotalTableCell}>
                    <Tooltip title={tooltip}>
                        <Box>
                            {t("react.projectdashboard.no.data")}
                        </Box>
                    </Tooltip>
                </TableCell>
            );
        } else {
            return (
                <TableCell component="th" scope="row" align="center" className={classes.stickyTotalTableCell}>
                    {analytics.of_base > 0 ?
                        <Tooltip title={tooltip}>
                            <Box className={classes.marginRightBox} style={{ fontWeight: '500' }}>
                                <img alt="" src="/img/ArrowY.png" className={classes.emptyImg} />
                                {Math.round(valueTotal)}
                            </Box>
                        </Tooltip> :
                        <Box className={classes.marginRightBox} style={{ fontWeight: '500' }}>
                            <img alt="" src="/img/ArrowY.png" className={classes.emptyImg} />
                            <span style={{ color: 'rgba(0,0,0,0.4)' }}>{t("react.projectdashboard.no.data")}</span>
                        </Box>
                    }
                </TableCell>
            );
        }
    }

    if (data.stimulus.length <= 1) {
        return (
            <Box>{t("react.dashboard.analytics.nodata.mode1")}</Box>
        );
    }

    if (sequences[indexSequence].storage) {
        return (
            <Box>{t("react.dashboard.analytics.nodata.storage")}</Box>
        );
    }

    return (
        <TableContainer component={Paper} style={{ height: 'calc(100vh - 190px)' }}>

            <PopupTwoButtons
                variant='info'
                openState={popupTwoButtonState}
                callbackOnclose={handleDeleteDialogCancel}
                callbackOnclickLeftButton={handleDeleteDialogCancel}
                callbackOnclickRightButton={generateNewConfig}
                title={t("react.projectanalytics.popup.title")}
                content={t("react.projectanalytics.mode1.popup.message", { filterNameTrad: msgEmotion ? t('react.projectanalytics.emotional.activation.title') : filterParam.name, filterValueTrad: msgEmotion ? filterValueParam : filterValueParam.value })}
                leftButton={t('react.button.cancel')}
                rightButton={t('react.button.confirm')}
            />
            <Table className={classes.table} size="small" stickyHeader >
                <TableHead>
                    {/* first line with stimulus names */}
                    <TableRow>
                        <TableCell className={classes.tableCell1}></TableCell>
                        <TableCell className={classes.tableCell2} align="center">{t("react.projectanalytics.mode1.total.title")}</TableCell>
                        {data.stimulus.filter(stimuli => stimuli.id > 0).map((stimuli, stimuliIndex) => (
                            <TableCell style={{ minWidth: '130px' }} key={stimuliIndex} align="center">{appendSuffixIfGreaterThan(stimuli.name, '...', 20)}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {/* line corresponding to score of TOTAL observations */}
                    <TableRow>
                        <TableCell className={classes.tableCell3}>{t("react.projectanalytics.mode1.total.observations.title")}</TableCell>
                        <TableCell className={classes.tableCell4} align="center">
                            {getTotalCell(-100)}
                        </TableCell>
                        {data.stimulus.filter(stimuli => stimuli.id > 0).map((stimuli, stimuliIndex) => {
                            return (
                                <TableCell
                                    className={classes.tableCell5}
                                    key={stimuliIndex}
                                    align="center"
                                >
                                    {getTotalCell(stimuli.id)}
                                </TableCell>
                            )
                        })}
                    </TableRow>
                    {/* lines (loop) over all filters and values */}
                    {filters.map((filter, filterIndex) => (
                        <Fragment key={filterIndex}>
                            <StyledTableRow>
                                <TableCell component="th" scope="row" className={classes.tableCellStickyLeftFilterTitle}>
                                    {filter.name}
                                </TableCell>
                                <TableCell component="th" scope="row" className={classes.emptyCellBorderRight}></TableCell>
                                {data.stimulus.filter(stimuli => stimuli.id !== -200).map((stimuli, index) => (
                                    <TableCell component="th" scope="row" key={`TableCell-${stimuli.id}-${filter.id}`}>
                                    </TableCell>
                                ))}
                            </StyledTableRow>
                            {filter.values.map((filterValue, index) => (
                                <TableRow
                                    key={`TableRow-${filterValue.id}`}
                                    style={{ position: 'relative' }}
                                    onMouseEnter={() => displayButtonAutoConfig(filterValue.id, true)}
                                    onMouseLeave={() => displayButtonAutoConfig(filterValue.id, false)}
                                >
                                    <TableCell component="th" scope="row" className={classes.tableCellStickyLeftFilterValue}>
                                        {filterValue.value}
                                    </TableCell>
                                    {getTotalFilterCell(filterValue.id)}
                                    {data.stimulus.filter(stimuli => stimuli.id !== -200).map((stimuli, stimuliIndex) => (
                                        <Fragment key={stimuliIndex}>
                                            {getStimulusFilterCell(stimuli.id, filterValue.id, data.stimulus.filter(stimuli => stimuli.id !== -200).length, stimuliIndex, filterValue, filter, undefined)}
                                        </Fragment>
                                    ))}
                                </TableRow>
                            ))}
                        </Fragment>
                    ))}
                    {hasEmotionalFilters &&
                        <>
                            {/* lines (loop) over all Emotional values */}
                            <StyledTableRow>
                                <TableCell component="th" scope="row" className={classes.tableCellStickyLeftFilterTitle}>
                                    {t('react.projectanalytics.emotional.activation.title')}
                                </TableCell>
                                <TableCell component="th" scope="row" className={classes.emptyCellBorderRight}></TableCell>
                                {data.stimulus.filter(stimuli => stimuli.id !== -200).map((stimuli, index) => (
                                    <TableCell component="th" scope="row" key={`TableCell-${stimuli.id}-${index}`}>
                                    </TableCell>
                                ))}
                            </StyledTableRow>
                            {emotionalFilters.map((emotion, emotionalIndex) => (
                                <TableRow key={`TableRow-emotion-${emotionalIndex}`}
                                    style={{ position: 'relative' }}
                                    onMouseEnter={() => displayButtonAutoConfig(emotion.name, true)}
                                    onMouseLeave={() => displayButtonAutoConfig(emotion.name, false)}
                                >
                                    <TableCell component="th" scope="row" className={classes.tableCellStickyLeftFilterValue}>
                                        {emotion.name}
                                    </TableCell>
                                    {getTotalFilterCell(0, emotion.value)}
                                    {data.stimulus.filter(stimuli => stimuli.id !== -200).map((stimuli, stimuliIndex) => (
                                        <Fragment key={stimuliIndex}>
                                            {getStimulusFilterCell(stimuli.id, 0, data.stimulus.filter(stimuli => stimuli.id !== -200).length, stimuliIndex, emotion.name, undefined, emotion.value)}
                                        </Fragment>
                                    ))}
                                </TableRow>
                            ))}
                        </>
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}
