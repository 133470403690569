import ApiService from './ApiService';
require('dotenv').config()


const apiService = new ApiService();
const restClient = apiService.getClient();

class UserService {

    async searchUsers(page, rowsPerPage, sortBy, sortDirection, keywords, onlyActive, onlyExpired) {
        let apiUrl = `/api/users/v1.0/?include=customers&from=${page*rowsPerPage}&size=${rowsPerPage}&sort=${sortBy}&order=${sortDirection}&keywords=${encodeURIComponent(keywords)}`;
        if(onlyActive) apiUrl+=`&onlyActive=${onlyActive}`;
        if(onlyExpired) apiUrl+=`&onlyExpired=${onlyExpired}`;
        return restClient.get(apiUrl);
    }

    async fetchAllUsersWithCustomerId(customerId) {
        return restClient.get(`/api/users/v1.0/?customerId=${customerId}&from=0&size=500&sort=lastname`);
    }

    async getUser(userId) {
        return restClient.get('/api/users/v1.0/' + userId);
    }

    async getUserRights(userId) {
        return restClient.get('/api/rights/v1.0/user/' + userId);
    }

    async getRights() {
        return restClient.get('/api/rights/v1.0/')
    }

    async getUsers() {
        return restClient.get('/api/rights/v1.0/');
    }

    async saveUser(user, rights) {
        let userResult;
        let rightsResult;
        if(user.id === 0) {
            userResult = await restClient.post('/api/users/v1.0/', user);
            rightsResult = await restClient.put('/api/rights/v1.0/user/' + userResult.data.id, JSON.stringify(rights))
        } else {
            userResult = await restClient.put('/api/users/v1.0/' + user.id, user);
            rightsResult = await restClient.put('/api/rights/v1.0/user/' + user.id, JSON.stringify(rights))
        }
        return [userResult.data, rightsResult.data];
    }

    async deleteUser(id) {
        return restClient.delete('/api/users/v1.0/' + id);
    }

    async updateExpirationDate(id, expirationDate) {
        return restClient.put(`/api/users/v1.0/${id}/expiration-date/${expirationDate}`);
    }

    async setPassword(userId, password) {
        return restClient.put('/api/users/v1.0/' + userId + '/password', {'password' : password});
    }

    async sendFirstConnectionMail(id) {
        return restClient.post('/api/users/v1.0/send-first-connection-mail?id=' + id);
    }

    async changeLanguage(userId, language) {
        return restClient.put(`/api/users/v1.0/${userId}/language/${language}`)
    }
}

export default UserService;
