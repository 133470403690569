import React from "react";
import { Navigate } from "react-router-dom";

import { AuthService } from '../../services/AuthService';

export default function LogoutLayout(props) {
  AuthService.signOut();
  return (
    <Navigate to="/login" replace/>
  );
}
