import { sleep } from '../../utils/utils.js';
import LinearProgress from '@mui/material/LinearProgress';
import { lighten } from '@mui/material/styles';
import { withStyles } from 'tss-react/mui';
import { useEffect, useState } from 'react';

const BorderLinearProgress = withStyles(LinearProgress, {
    root: {
        height: 10,
        backgroundColor: lighten('#ff6c5c', 0.5),
    },
    bar: {
        borderRadius: 20,
        backgroundColor: '#ff6c5c',
        transition: 'none',
    },
});

export default function BarTimeout(props) {

    const { block, handleBarTimeoutExpired } = props;

    const [counter, setCounter] = useState(undefined)
    const [expired, setExpired] = useState(false);

    useEffect(() => {
        if (!block) return;

        // initial load will fire a new useEffect, that's why we can return
        if (block.timeout && block.timeout > 0) {
            setCounter(block.timeout);
            setExpired(false);
        } else {
            setExpired(true);
            setCounter(undefined);
        }        
    }, [block])

    useEffect(() => {
        if(!counter) return;

        sleep(20).then(() => {
            let newCounter = counter - 0.02;
            if (newCounter < 0.02) {
                setExpired(true);
            } else {
                setCounter(newCounter);
            }
        });
    }, [counter]);

    useEffect(() => {
        if (!expired) return;

        handleBarTimeoutExpired();
    }, [expired]);

    if (!block) return null;

    return (
        <BorderLinearProgress
            variant="determinate"
            color="secondary"
            value={(counter * 100 / block.timeout)-2}
        />
    )
}
