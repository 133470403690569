import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import HelpIcon from '@mui/icons-material/Help';
import { makeStyles } from 'tss-react/mui';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Trans } from 'react-i18next';
import Checkbox from '@mui/material/Checkbox';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
  question: HelpIcon,
};

const popupTwoButtonsDialogStyles = makeStyles()(theme => ({
  titleIcon: {
    marginRight: '12px',
    verticalAlign: 'sub'
  },
  colorCheckbox: {
    color: '#ba000d'
  },
  formControlCheckbox: {
    marginTop: 5,
    color: '#ba000d',
    marginBottom: '-20px'
  }
}));

const PopupTwoButtons = props => {
  const { classes } = popupTwoButtonsDialogStyles();
  const [checkboxConfirmDelete, setcheckboxConfirmDelete] = useState(false);

  const {
    variant,
    openState,
    callbackOnclose,
    callbackOnclickLeftButton,
    callbackOnclickRightButton,
    title,
    content,
    unlockCheckboxLabel,
    leftButton,
    rightButton
  } = props;
  const Icon = variantIcon[variant];

  const handleOnclickLeftButton = (event) => {
    event.preventDefault();
    callbackOnclickLeftButton();
  };

  const handleOnclickRightButton = (event) => {
    event.preventDefault();
    callbackOnclickRightButton();
  };

  const handleOnclose = (event) => {
    event.preventDefault();
    callbackOnclose();
  };

  if(!openState) return null;

  return (
      <Dialog
        open={openState}
        onClose={handleOnclose}
      >
        <DialogTitle>
          <Icon color='secondary' className={classes.titleIcon} />
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText component={'div'}>
            <Trans
            i18nKey={content}
          />
          </DialogContentText>
          {unlockCheckboxLabel !== undefined && 
            <FormControlLabel 
              className={classes.formControlCheckbox} 
              control={<Checkbox 
                color="error" 
                className={classes.colorCheckbox} 
                checked={checkboxConfirmDelete} 
                onChange={() => setcheckboxConfirmDelete(!checkboxConfirmDelete)} />
              } 
              label={unlockCheckboxLabel}
            />
          }
        </DialogContent>
        <DialogActions>
          {callbackOnclickLeftButton !== undefined && 
          <Button onClick={handleOnclickLeftButton} color="primary">
            {leftButton}
          </Button>
          }
          {callbackOnclickRightButton !== undefined && 
          <Button onClick={handleOnclickRightButton} disabled={unlockCheckboxLabel !== undefined && !checkboxConfirmDelete} color="primary" autoFocus>
            {rightButton}
          </Button>
          }
        </DialogActions>
      </Dialog>
  );
}

export default PopupTwoButtons;
