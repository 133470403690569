import React, { useState, useEffect  } from 'react';
import CodificationService from '../../services/CodificationService'
import { makeStyles } from 'tss-react/mui';
import { toolbarStyles } from '../../common.js';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import CachedIcon from '@mui/icons-material/Cached';
import FormatIndentIncreaseIcon from '@mui/icons-material/FormatIndentIncrease';
import Input from '@mui/material/Input';
import ClearIcon from '@mui/icons-material/Clear';
import InputAdornment from '@mui/material/InputAdornment';
import PopupTwoButtons from '../shared/PopupTwoButtons/PopupTwoButtons';
import MoreIcon from '@mui/icons-material/More';
import MoveLibraryDialog from './MoveLibraryDialog';
import EditCodifDialog from './EditCodifDialog';
import MergeEntriesDialog from './MergeEntriesDialog';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import LayersIcon from '@mui/icons-material/Layers';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import CopyEntriesDialog from './CopyEntriesDialog';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {getItemAriaLabel} from '../../utils/pagination.js';
import clsx from 'clsx';

const codificationService = new CodificationService();

const useStyles = makeStyles()(theme => ({
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableRowCursorPointer: {
    cursor: 'pointer',
  },
  containerSticky: {
    position: 'sticky',
    top: '64px',
    background: 'white',
    zIndex: '99',
    borderBottom: '1px solid rgba(0,0,0,0.2)',
  },
  divPaginationTop: {
    display: 'flex',
    flexDirection: 'row-reverse'
  },
  formControl: {
    marginTop: 13
  },
  fastPagination: {
    marginTop: 17,
    marginRight: 11,
    fontSize: '0.875em'
  },
}));

const headCells = [
  { id: 'word', label: 'react.codification.table.head.name', minWidth: 170 },
  { id: 'id', label: 'react.codification.table.head.code', minWidth: 200 },
  { id: 'nature', label: 'react.codification.table.head.nature', minWidth: 200 },
  { id: 'logic', label: 'react.codification.table.head.logic', minWidth: 200 },
  { id: 'valence', label: 'react.codification.table.head.valence', minWidth: 200 },
];

function createData(id, word, code, nature, logic, valence) {
  // const creationDate = new Date()
  // creationDate.setTime(creationDateValue)
  return { id, word, code, nature, logic, valence };
}

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, numSelected, rowCount, sortBy, sortDirection, handleChangeSort, t } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            id='checkbox-all'
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={sortBy === headCell.id ? sortDirection : false}
          >
            <TableSortLabel
              active={sortBy === headCell.id}
              direction={sortDirection}
              onClick={e => handleChangeSort(e, headCell.id)}
            >
              {t(headCell.label)}
              {sortBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {sortDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function EnhancedTableToolbar(props) {

  const { classes } = toolbarStyles();

  const {
    t, numSelected, countForPagination, setLoadData,
    handleDeleteBiblioEntries, handleMoveBiblioEntries, handleEditCodifDialog, handleMergeBiblioEntries,
    keywords, logic, nature, valence, handleFilterChange, handleCopyBiblioEntries
  } = props;

  const handleClickRefresh = (event) => {
    event.preventDefault();
    setLoadData(true);
  };

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <FormatIndentIncreaseIcon className={classes.mainIcon} />
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1">
          {t("react.codification.list.number.of.selected", { 'numSelected': numSelected })}
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle">
          {t("react.codification.list.number.title", { 'size': countForPagination })}
        </Typography>
      )}

      <FormControl className={classes.formControl}>
        <Select labelId="select-nature" id="select-nature"
          displayEmpty
          value={nature}
          onChange={e => handleFilterChange(keywords, logic, e.target.value, valence)}
        >
          <MenuItem value=""><em>Nature</em></MenuItem>
          <MenuItem value={'adjectivale'}>Adjectivale</MenuItem>
          <MenuItem value={'nominale'}>Nominale</MenuItem>
          <MenuItem value={'puissance'}>Puissance</MenuItem>
          <MenuItem value={'signe-iconique'}>SigneIconique</MenuItem>
          <MenuItem value={'verbale'}>Verbale</MenuItem>
        </Select>
      </FormControl>
      <FormControl className={classes.formControl}>
        <Select labelId="select-logic" id="select-logic"
          displayEmpty
          value={logic}
          onChange={e => handleFilterChange(keywords, e.target.value, nature, valence)}
        >
          <MenuItem value=""><em>Logic</em></MenuItem>
          <MenuItem value={'caracterisation'}>Caracterisation</MenuItem>
          <MenuItem value={'designation'}>Designation</MenuItem>
          <MenuItem value={'effet'}>Effet</MenuItem>
          <MenuItem value={'jugement'}>Jugement</MenuItem>
          <MenuItem value={'metaphore'}>Metaphore</MenuItem>
        </Select>
      </FormControl>
      <FormControl className={classes.formControl}>
        <Select labelId="select-valence" id="select-valence"
          displayEmpty
          value={valence}
          onChange={e => handleFilterChange(keywords, logic, nature, e.target.value)}
        >
          <MenuItem value=""><em>Valence</em></MenuItem>
          <MenuItem value={2}>2</MenuItem>
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={0}>0</MenuItem>
          <MenuItem value={-3}>-3</MenuItem>
        </Select>
      </FormControl>
      <Input
        value={keywords}
        id="search-filter-basic"
        className={classes.searchfield}
        placeholder={t('react.codification.search.title')}
        onChange={e => handleFilterChange(e.target.value, logic, nature, valence)}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              onClick={e => handleFilterChange('', logic, nature, valence)}
              edge="end"
              size="large">
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        }
      />
      <Tooltip title={t("react.codification.button.merge.hint")}>
        <span>
          <IconButton
            onClick={handleMergeBiblioEntries}
            disabled={numSelected < 2}
            size="large">
            <LayersIcon />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title={t("react.codification.button.move.hint")}>
        <span>
          <IconButton
            onClick={handleMoveBiblioEntries}
            disabled={numSelected < 1}
            size="large">
            <MoreIcon style={{ transform: 'scaleX(-1)' }} />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title={t("react.codification.button.move.copyto")}>
        <span>
          <IconButton
            onClick={handleCopyBiblioEntries}
            disabled={numSelected < 1}
            size="large">
            <FileCopyIcon />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title={t("react.button.delete")}>
        <span>
          <IconButton
            onClick={handleDeleteBiblioEntries}
            disabled={numSelected < 1}
            size="large">
            <DeleteIcon />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title={t("react.button.add")}>
        <span>
          <IconButton
            onClick={(e) => handleEditCodifDialog(e, 0)}
            size="large">
            <ControlPointIcon />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title={t("react.button.refresh")}>
        <span>
          <IconButton onClick={handleClickRefresh} size="large">
            <CachedIcon />
          </IconButton>
        </span>
      </Tooltip>
    </Toolbar>
  );
}

export default function CodificationTable(props) {
  const { library, t, openSnackbar, closeSnackbar, showSpinner, loadData, setLoadData, paths, libraries } = props;

  const [countForPagination, setCountForPagination] = useState(0);
  const [rows, setRows] = useState([]);
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(250);
  const [sortBy, setSortBy] = useState('word');
  const [sortDirection, setSortDirection] = useState('asc');
  const [previousLibrary, setPreviousLibrary] = useState({});
  const [pageSelectionArray, setpageSelectionArray] = useState([]);

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length);
  const isSelected = id => selected.indexOf(id) !== -1;

  const { classes } = useStyles();

  useEffect(() => {
    let emptyArray = [];
    for (var i = 0; i < Math.ceil(countForPagination / rowsPerPage); i++) {
      emptyArray.push(`${(i * rowsPerPage) + 1}-${(i + 1) * rowsPerPage}`);
    }
    setpageSelectionArray(emptyArray);
  }, [countForPagination, rowsPerPage])

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    event.preventDefault();
    setPage(newPage);
    setLoadData(true);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setLoadData(true);
  };

  const handleChangeSort = (event, headCellId) => {
    event.preventDefault();
    if (sortBy !== headCellId) {
      setSortBy(headCellId);
      setSortDirection('asc')
    } else {
      setSortDirection(sortDirection === 'desc' ? 'asc' : 'desc');
    }
    setLoadData(true);
  };

  const [keywords, setKeywords] = useState('');
  const [logic, setLogic] = useState('');
  const [nature, setNature] = useState('');
  const [valence, setValence] = useState('');
  const handleFilterChange = (k, l, n, v) => {
    if (k !== keywords) {
      setPage(0);
    }
    setKeywords(k);
    setLogic(l);
    setNature(n);
    setValence(v);
    setLoadData(true);
  };

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const handleDeleteBiblioEntries = (event, row) => {
    event.preventDefault();
    setDeleteDialogOpen(true)
  };
  const handleDeleteDialogConfirm = () => {
    var promises = [];
    for (var i = 0; i < selected.length; i++) {
      promises.push(codificationService.deleteBiblioEntry(library.id, selected[i]));
    }

    Promise.all(promises)
      .then(function (values) {
        setDeleteDialogOpen(false);
        closeSnackbar();
        setSelected([]);
        setLoadData(true);
      })
      .catch(err => {
        openSnackbar('error', t("react.codification.entries.error.while.deleting"));
      });
  };
  const handleDeleteDialogCancel = () => {
    setDeleteDialogOpen(false);
  }

  useEffect(() => {
    if (!loadData) return;

    if (library === undefined || previousLibrary === undefined || previousLibrary.id !== library.id) {
      // reset fields
      setRows([]);
      setSelected([]);
      setCountForPagination(0);
      setPage(0);
      setRowsPerPage(250);
      setSortBy('word');
      setSortDirection('asc');
      setPreviousLibrary(library);

      if (library === undefined || library.id <= 0) {
        setLoadData(false);
      }

      // force return to recall userEffect with changes for valid library
      return;
    }

    showSpinner(true);
    codificationService.searchBiblioEntries((page * rowsPerPage), rowsPerPage, sortBy, sortDirection, library.id, keywords, logic, nature, valence, false)
      .then(result => {
        setCountForPagination(result.data.total);
        const newRows = [];
        for (let i = 0; i < result.data.hits.length; i++) {
          let hit = result.data.hits[i];
          newRows.push(createData(hit.id, hit.biblioWord.word, hit.id, hit.nature, hit.logic, hit.valence));
        }
        setRows(newRows);
      })
      .catch(err => {
        openSnackbar('error', t("react.codification.entries.error.while.loading"));
      }).finally(() => {
        setLoadData(false);
        showSpinner(false);
      });
  }, [loadData, openSnackbar, showSpinner, library, previousLibrary, page, rowsPerPage, sortBy, sortDirection, keywords, logic, nature, valence, setLoadData, t]);

  const [openMoveBiblioEntriesDialog, setOpenMoveBiblioEntriesDialog] = useState(false);
  const handleMoveBiblioEntries = (event) => {
    event.preventDefault();
    setOpenMoveBiblioEntriesDialog(true);
  };
  const callbackAfterConfirmMoveBiblioEntries = (moveToLibraryId) => {
    codificationService.fetchLibrary(moveToLibraryId)
      .then(result => {
        let payload = {
          'from': library.id,
          'to': moveToLibraryId,
          'biblioEntries': selected
        };

        codificationService.moveBiblioEntries(payload)
          .then(result => {
            setOpenMoveBiblioEntriesDialog(false);
            closeSnackbar();
            setSelected([]);
            setLoadData(true);
          })
          .catch(err => {
            if (err.response.status === 409 /* 409 = Conflict */ && err.response.data.message !== undefined) {
              openSnackbar('error', t('react.generic.error.while.saving.with.message', { message: err.response.data.message }));
            } else {
              openSnackbar('error', t('react.generic.error.while.saving'));
            }

            setOpenMoveBiblioEntriesDialog(false);
          });
      })
      .catch(err => {
        openSnackbar('error', t("react.codification.entries.error.while.loading"));
        setOpenMoveBiblioEntriesDialog(false);
      });
  };
  const callbackAfterCancelMoveBiblioEntries = () => {
    setOpenMoveBiblioEntriesDialog(false);
  }

  const [openCopyBiblioEntriesDialog, setOpenCopyBiblioEntriesDialog] = useState(false);
  const handleCopyBiblioEntries = (event) => {
    event.preventDefault();
    setOpenCopyBiblioEntriesDialog(true);
  };
  const callbackAfterConfirmCopyBiblioEntries = (copyToLibraryId) => {
    codificationService.fetchLibrary(copyToLibraryId)
      .then(result => {
        let payload = {
          'from': library.id,
          'to': copyToLibraryId,
          'biblioEntries': selected
        };

        codificationService.copyBiblioEntries(payload)
          .then(result => {
            setOpenCopyBiblioEntriesDialog(false);
            closeSnackbar();
            setSelected([]);
            setLoadData(true);
          })
          .catch(err => {
            if (err.response.status === 409 /* 409 = Conflict */ && err.response.data.message !== undefined) {
              openSnackbar('error', t('react.generic.error.while.saving.with.message', { message: err.response.data.message }));
            } else {
              openSnackbar('error', t('react.generic.error.while.saving'));
            }

            setOpenCopyBiblioEntriesDialog(false);
          });
      })
      .catch(err => {
        openSnackbar('error', t("react.codification.entries.error.while.loading"));
        setOpenCopyBiblioEntriesDialog(false);
      });
  };
  const callbackAfterCancelCopyBiblioEntries = () => {
    setOpenCopyBiblioEntriesDialog(false);
  }

  const [openMergeBiblioEntriesDialog, setOpenMergeBiblioEntriesDialog] = useState(false);
  const handleMergeBiblioEntries = (event) => {
    event.preventDefault();
    setOpenMergeBiblioEntriesDialog(true);
  };
  const callbackAfterConfirmMergeBiblioEntries = (entriesId, destinationId) => {
    let payload = {
      'biblioEntries': entriesId,
      'destination': destinationId
    };
    codificationService.mergeBiblioEntries(payload)
      .then(result => {
        setOpenMergeBiblioEntriesDialog(false);
        closeSnackbar();
        setSelected([]);
        setLoadData(true);
      })
      .catch(err => {
        if (err.response.status === 409 /* 409 = Conflict */ && err.response.data.message !== undefined) {
          openSnackbar('error', t('react.generic.error.while.saving.with.message', { message: err.response.data.message }));
        } else {
          openSnackbar('error', t('react.generic.error.while.saving'));
        }

        setOpenMergeBiblioEntriesDialog(false);
      });
  };
  const callbackAfterCancelMergeBiblioEntries = () => {
    setOpenMergeBiblioEntriesDialog(false);
  }

  const [biblioEntryId, setBiblioEntryId] = useState(0);
  const [openEditCodifDialog, setOpenEditCodifDialog] = useState(false);
  const handleEditCodifDialog = (event, biblioEntryId) => {
    event.preventDefault();
    setBiblioEntryId(biblioEntryId);
    setOpenEditCodifDialog(true);
  };
  const callbackAfterConfirmEditCodifDialog = (moveToLibraryId) => {
    setOpenEditCodifDialog(false);
    setBiblioEntryId(0);
    setLoadData(true);
  };
  const callbackAfterCancelEditCodifDialog = () => {
    setOpenEditCodifDialog(false);
    setBiblioEntryId(0);
  }

  const handleChangeSelectPage = (event) => {
    setPage(event.target.value);
    setLoadData(true);
  };

  return (
    <div >
      <PopupTwoButtons
        variant='warning'
        openState={deleteDialogOpen}
        callbackOnclose={handleDeleteDialogCancel}
        callbackOnclickLeftButton={handleDeleteDialogCancel}
        callbackOnclickRightButton={handleDeleteDialogConfirm}
        title={t('react.codification.entries.deletedialog.title')}
        content={t('react.codification.entries.deletedialog.body')}
        leftButton={t('react.button.cancel')}
        rightButton={t('react.button.delete')}
      />
      <MoveLibraryDialog
        {...props}
        paths={paths}
        libraryId={library ? library.id : 0}
        openState={openMoveBiblioEntriesDialog}
        callbackAfterConfirm={callbackAfterConfirmMoveBiblioEntries}
        callbackAfterCancel={callbackAfterCancelMoveBiblioEntries}
      />
      <EditCodifDialog
        {...props}
        openState={openEditCodifDialog}
        callbackAfterConfirm={callbackAfterConfirmEditCodifDialog}
        callbackAfterCancel={callbackAfterCancelEditCodifDialog}
        biblioEntryId={biblioEntryId}
        appendToLibraryId={library ? library.id : 0}
        languagePlaceHolder={codificationService.getParentLanguage(libraries, library)}
      />
      <CopyEntriesDialog
        {...props}
        paths={paths}
        libraryId={library ? library.id : 0}
        openState={openCopyBiblioEntriesDialog}
        callbackAfterConfirm={callbackAfterConfirmCopyBiblioEntries}
        callbackAfterCancel={callbackAfterCancelCopyBiblioEntries}
      />
      <MergeEntriesDialog
        {...props}
        openState={openMergeBiblioEntriesDialog}
        callbackAfterConfirm={callbackAfterConfirmMergeBiblioEntries}
        callbackAfterCancel={callbackAfterCancelMergeBiblioEntries}
        entriesId={selected}
        libraryId={library ? library.id : 0}
      />
      <div className={classes.containerSticky}>
        {library &&
          <Typography variant="h6" style={{ marginLeft: '10px' }}>{t('react.codification.entries.current.display', { 'libraryName': paths[library.id] })}</Typography>
        }
        <EnhancedTableToolbar
          {...props}
          library={library}
          numSelected={selected.length}
          countForPagination={countForPagination}
          handleDeleteBiblioEntries={handleDeleteBiblioEntries}
          handleMoveBiblioEntries={handleMoveBiblioEntries}
          handleEditCodifDialog={handleEditCodifDialog}
          handleMergeBiblioEntries={handleMergeBiblioEntries}
          setLoadData={setLoadData}
          keywords={keywords}
          logic={logic}
          nature={nature}
          valence={valence}
          handleFilterChange={handleFilterChange}
          handleCopyBiblioEntries={handleCopyBiblioEntries}
        />
        <div className={classes.divPaginationTop}>
          <TablePagination
            rowsPerPageOptions={[50, 100, 250]}
            component="div"
            count={countForPagination}
            rowsPerPage={rowsPerPage}
            page={page}
            labelRowsPerPage={t('react.list.number.lignes.per.page')}
            labelDisplayedRows={({ from, to, count }) => t('react.list.number.from.to.count', { 'from': from, 'to': to, 'count': count })}
            getItemAriaLabel={(type) => getItemAriaLabel(t, type)}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <FormControl size="small" className={classes.formControl} >
            <Select
              id="formControlTop"
              value={page}
              onChange={(e) => handleChangeSelectPage(e)}
              disableUnderline
              style={{ transform: 'scale(0.85)' }}
            >
              {pageSelectionArray.map((value, index) => (
                <MenuItem key={index} value={index}>{value}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <div className={classes.fastPagination}>
            {t('react.dictionary.fast.pagination')}
          </div>
          <Tooltip title={t('react.unselect.all.list.button')}><span>
            <IconButton
              disabled={selected.length === 0}
              onClick={() => setSelected([])}
              size="large"><RemoveCircleOutlineIcon /></IconButton></span>
          </Tooltip>
        </div>
      </div>
      <div className={classes.tableWrapper}>
        <Table
          className={classes.table}
          size="small"
        >
          <EnhancedTableHead
            {...props}
            classes={classes}
            numSelected={selected.length}
            onSelectAllClick={handleSelectAllClick}
            rowCount={rows.length}
            sortBy={sortBy}
            sortDirection={sortDirection}
            handleChangeSort={handleChangeSort}
          />
          <TableBody>
            {rows.map((row, index) => {
              const isItemSelected = isSelected(row.id);

              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.id}
                  selected={isItemSelected}
                  className={classes.tableRowCursorPointer}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      id={`checkbox-${row.id}`}
                      onClick={event => handleClick(event, row.id)}
                      checked={isItemSelected}
                    />
                  </TableCell>
                  {headCells.map(column => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align}
                        onClick={event => handleEditCodifDialog(event, row.id)}
                      >
                        {column.format ? column.format(value) : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow style={{ height: (33) * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table></div>
    </div>
  );
}
