import React from 'react';
import { Link } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonIcon from '@mui/icons-material/Person';
import AllInboxIcon from '@mui/icons-material/AllInbox';
import StoreIcon from '@mui/icons-material/Store';
import GroupIcon from '@mui/icons-material/Group';
import DvrIcon from '@mui/icons-material/Dvr';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import BuildIcon from '@mui/icons-material/Build';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import LanguageIcon from '@mui/icons-material/Language';
import TryIcon from '@mui/icons-material/Try';
import { AuthService } from '../../services/AuthService';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';

const DashboardMenu = (props) => {
  const { t, open } = props;

  return (
    <List>
      <Tooltip title={open ? '' : t('react.dashboard.menu.welcome')} arrow placement="right">
        <ListItem button component={Link} to="/dashboard">
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary={t('react.dashboard.menu.welcome')} />
        </ListItem>
      </Tooltip>

      <Tooltip title={open ? '' : t('react.dashboard.menu.myproject')} arrow placement="right">
        <ListItem button component={Link} to="/projects">
          <ListItemIcon>
            <AllInboxIcon />
          </ListItemIcon>
          <ListItemText primary={t('react.dashboard.menu.myproject')} />
        </ListItem>
      </Tooltip>

      <Tooltip title={open ? '' : t('react.dashboard.menu.collectors')} arrow placement="right">
        <ListItem button component={Link} to="/surveys">
          <ListItemIcon>
            <QuestionAnswerIcon />
          </ListItemIcon>
          <ListItemText primary={t('react.dashboard.menu.collectors')} />
        </ListItem>
      </Tooltip>

      {AuthService.isManager() &&
        <Tooltip title={open ? '' : t('react.dashboard.menu.users')} arrow placement="right">
          <ListItem button component={Link} to="/users">
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary={t('react.dashboard.menu.users')} />
          </ListItem>
        </Tooltip>
      }

      {AuthService.isAdmin() &&
        <Tooltip title={open ? '' : t('react.dashboard.menu.customers')} arrow placement="right">
          <ListItem button component={Link} to="/customers">
            <ListItemIcon>
              <GroupIcon />
            </ListItemIcon>
            <ListItemText primary={t('react.dashboard.menu.customers')} />
          </ListItem>
        </Tooltip>
      }

      {AuthService.isAdmin() &&
        <Tooltip title={open ? '' : t('react.dashboard.menu.resellers')} arrow placement="right">
          <ListItem button component={Link} to="/resellers">
            <ListItemIcon>
              <StoreIcon />
            </ListItemIcon>
            <ListItemText primary={t('react.dashboard.menu.resellers')} />
          </ListItem>
        </Tooltip>
      }

      {AuthService.isSuperAdmin() &&
        <Tooltip title={open ? '' : t('react.dashboard.menu.subscriptions')} arrow placement="right">
          <ListItem button component={Link} to="/subscriptions">
            <ListItemIcon>
              <AccountBalanceWalletIcon />
            </ListItemIcon>
            <ListItemText primary={t('react.dashboard.menu.subscriptions')} />
          </ListItem>
        </Tooltip>
      }

      {AuthService.isSuperAdmin() &&
        <Tooltip title={open ? '' : t('react.dashboard.menu.codification')} arrow placement="right">
          <ListItem button component={Link} to="/codification">
            <ListItemIcon>
              <AccountTreeIcon />
            </ListItemIcon>
            <ListItemText primary={t('react.dashboard.menu.codification')} />
          </ListItem>
        </Tooltip>
      }

      {AuthService.isSuperAdmin() &&
        <Divider />
      }

      {AuthService.isSuperAdmin() &&
        <Tooltip title={open ? '' : t('react.dashboard.menu.translations')} arrow placement="right">
          <ListItem button component={Link} to="/translations">
            <ListItemIcon>
              <LanguageIcon />
            </ListItemIcon>
            <ListItemText primary={t('react.dashboard.menu.translations')} />
          </ListItem>
        </Tooltip>
      }

      {AuthService.isSuperAdmin() &&
        <Tooltip title={open ? '' : t('react.dashboard.menu.ai')} arrow placement="right">
          <ListItem button component={Link} to="/aitools">
            <ListItemIcon>
              <TryIcon/>
            </ListItemIcon>
            <ListItemText primary={t('react.dashboard.menu.ai')} />
          </ListItem>
        </Tooltip>
      }

      {AuthService.isSuperAdmin() &&
        <Tooltip title={open ? '' : t('react.dashboard.menu.admintools')} arrow placement="right">
          <ListItem button component={Link} to="/admintools">
            <ListItemIcon>
              <BuildIcon />
            </ListItemIcon>
            <ListItemText primary={t('react.dashboard.menu.admintools')} />
          </ListItem>
        </Tooltip>
      }

      {AuthService.isSuperAdmin() &&
        <Tooltip title={open ? '' : t('react.dashboard.menu.status')} arrow placement="right">
          <ListItem button component={Link} to="/status">
            <ListItemIcon>
              <DvrIcon />
            </ListItemIcon>
            <ListItemText primary={t('react.dashboard.menu.status')} />
          </ListItem>
        </Tooltip>
      }
    </List>
  );
}

export default DashboardMenu;
