import ApiService from './ApiService';

require('dotenv').config()

const apiService = new ApiService();
const restClient = apiService.getClient();

class AnalyticsService {

  async fetchProjectsAnalytics(projectId) {
    return restClient.get(`/api/analytics/v1.0/project/${projectId}`);
  }

  async fetchAnalyticsContent(analyticsId) {
    return restClient.get(`/api/analytics/v1.0/${analyticsId}/content`);
  }

  async fireAnalyticsComputations(projectId, sequenceId, type) {
    return restClient.post(`/api/analytics/v1.0/compute/project/${projectId}/sequence/${sequenceId}/type/${type}`);
  }

  async fetchAnalyticsAsExcelFileMode(analyticsId) {
    return restClient.get(`/api/analytics/v1.0/${analyticsId}/export/xlsx`, { responseType: 'blob' });
  }
}

export default AnalyticsService;
