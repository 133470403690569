import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { makeStyles } from 'tss-react/mui';
import { AuthService } from '../../services/AuthService';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { useTranslation } from 'react-i18next';
import { isValidEmail, isBlank } from '../../utils/utils';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles()((theme) => {
  return {
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}
});

export default function LoginPanel(props) {
  const { setRedirectToHome, setShowRememberPasword, openSnackbar } = props;
  const { t } = useTranslation();

  const { classes } = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [displayErrors, setDisplayErrors] = useState({ emailLength: false, emailValidation: false, password: false });

  const handleSubmit = (e) => {
    e.preventDefault();

    let errorsInFields = { ...displayErrors };
    errorsInFields.emailLength = isBlank(email);
    errorsInFields.emailValidation = !isValidEmail(email);
    errorsInFields.password = isBlank(password);
    setDisplayErrors(errorsInFields);

    let nbErrors = Object.entries(errorsInFields).filter(value => value[1] === true).length;
    if (nbErrors > 0) {
      return;
    }

    AuthService.signIn(email, password)
      .then(user => {
        if (user) {
          setRedirectToHome(true);
        }
      })
      .catch(err => {
        openSnackbar('error', t('react.login.error.invalid.username.or.password'));
      });
  }

  const handleShowRememberPassword = (event) => {
    event.preventDefault();
    setShowRememberPasword(true);
  }

  return (
    <Grid container>
      <Grid item xs>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Typography component="h1" variant="h5" style={{ textAlign: 'center' }}>
            {t("react.login.connect")}
          </Typography>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="email"
            required
            label={t('react.login.email')}
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            error={displayErrors.emailValidation || displayErrors.emailLength}
            helperText={displayErrors.emailLength && t("react.validation.email.empty") || displayErrors.emailValidation && t("react.validation.email.invalid")}
            onChange={e => setEmail(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            required
            name="password"
            label={t('react.login.password')}
            id="password"
            type="password"
            autoComplete="current-password"
            onChange={e => setPassword(e.target.value)}
            error={displayErrors.password}
            helperText={displayErrors.password && t('react.validation.password.empty')}
          />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            type="submit"
          >
            {t("react.login.button.connection")}
          </Button>
          <Link href="#" variant="body2" onClick={handleShowRememberPassword}>
            {t("react.login.button.forgot.password")}
          </Link>
        </form>
      </Grid>
    </Grid>
  );
}
