import React, { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import Paper from '@mui/material/Paper';
import { Grid, Typography, IconButton, Box } from '@mui/material';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { Trans } from 'react-i18next';

const useStyles = makeStyles()(theme => ({
    paper: {
        padding: '10px',
        marginBottom: '15px'
    },
    marginRight5: {
        marginRight: 5
    },
    marginTop5: {
        marginTop: 5
    },
    navigationButtons: {
        display: 'flex',
        justifyContent: 'end',
    }
}));

export default function TipsHelper({ t }) {
    const { classes } = useStyles();

    const tips = [
        { title: t('react.surveys.helper.tips1.title'), content: t('react.surveys.helper.tips1') },
        { title: t('react.surveys.helper.tips2.title'), content: t('react.surveys.helper.tips2') },
        { title: t('react.surveys.helper.tips3.title'), content: t('react.surveys.helper.tips3') }
    ];
    const [tipsIndex, setTipsIndex] = useState(Math.floor(Math.random() * tips.length));

    const handleTipChange = (increment) => {
        setTipsIndex(prevIndex => (prevIndex + increment + tips.length) % tips.length);
    };

    return (
        <Paper className={classes.paper}>
            <Grid container alignItems="center">
                <TipsAndUpdatesIcon className={classes.marginRight5} />
                <Typography variant='h6' component='h6'>{tips[tipsIndex].title}</Typography>
            </Grid>
            <Typography className={classes.marginTop5}><Trans>{tips[tipsIndex].content}</Trans></Typography>
            <Box className={classes.navigationButtons}>
                <IconButton onClick={() => handleTipChange(-1)}><NavigateBeforeIcon /></IconButton>
                <IconButton onClick={() => handleTipChange(1)}><NavigateNextIcon /></IconButton>
            </Box>
        </Paper>
    );
}
