import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

export default function FourthStateButton(props) {

    const { stateValues, stateIcons, stateLabels, stateValue, setStateValue, style } = props;

    return (
        <ButtonGroup style={style ? style : {}}>
            <Button 
                variant={stateValue === stateValues[0] ? 'contained' : 'outlined'} 
                startIcon={stateIcons && stateIcons[0]}
                onClick={e => setStateValue(stateValues[0])} size="small" 
                color={stateValue === stateValues[0] ? 'primary' : undefined}>{stateLabels[0]}</Button>
            <Button 
                variant={stateValue === stateValues[1] ? 'contained' : 'outlined'} 
                startIcon={stateIcons && stateIcons[1]}
                onClick={e => setStateValue(stateValues[1])} size="small" 
                color={stateValue === stateValues[1] ? 'primary' : undefined}>{stateLabels[1]}</Button>
            <Button 
                variant={stateValue === stateValues[2] ? 'contained' : 'outlined'} 
                startIcon={stateIcons && stateIcons[2]}
                onClick={e => setStateValue(stateValues[2])} 
                color={stateValue === stateValues[2] ? 'primary' : undefined}>{stateLabels[2]}</Button>
            <Button 
                variant={stateValue === stateValues[3] ? 'contained' : 'outlined'} 
                startIcon={stateIcons && stateIcons[3]}
                onClick={e => setStateValue(stateValues[3])} 
                color={stateValue === stateValues[3] ? 'primary' : undefined}>{stateLabels[3]}</Button>
        </ButtonGroup>
    );
};