import React from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official';

export default function DonutChartQuota(props) {

    const {
        t,
        index,
        thisQuestion,
        nbMaxAnswers
    } = props;

    const donutChartQuota = (index) => {

        let temporaryCategories = []
        let temporaryData = [];
        let temporaryColors = ['rgb(124, 181, 236)', 'rgb(67, 67, 72)', 'rgb(144, 237, 125)', 'rgb(247, 163, 92)', 'rgb(128, 133, 233)', 'rgb(241, 92, 128)'];
        thisQuestion.answers.filter(answer => answer.quota !== 0).forEach((element, subindex) => (
            temporaryCategories.push(element.answer),
            temporaryData.push({
                y: element.quota,
                color: temporaryColors[index],
                drilldown: {
                    name: element.answer,
                    categories: [
                        t('react.reporting.survey.quota.remaining'),
                        t('react.reporting.survey.quota.completed')
                    ],
                    data: [
                        (nbMaxAnswers*element.quota/100)-element.nbAnswers,
                        element.nbAnswers
                    ],
                },
            })
        ))
        
        var colors = Highcharts.getOptions().colors,
            categories = temporaryCategories,
            data = temporaryData,
            browserData = [],
            versionsData = [],
            i,
            j,
            dataLen = data.length,
            drillDataLen,
            brightness;

        // Build the data arrays
        for (i = 0; i < dataLen; i += 1) {

            // add browser data
            browserData.push({
                name: categories[i],
                y: data[i].y,
                color: temporaryColors[i]
            });

            // add version data
            drillDataLen = data[i].drilldown.data.length;
            for (j = 0; j < drillDataLen; j += 1) {
                brightness = 0.2 - (j / drillDataLen) / 5;
                versionsData.push({
                    name: data[i].drilldown.categories[j],
                    y: data[i].drilldown.data[j],
                    color: temporaryColors[i]
                    // color: Highcharts.color(data[i].color).brighten(brightness).get()
                });
            }
        }

        return {
            chart: {
                type: 'pie',
            },
            title: {
                text: t('react.reporting.survey.quota.title')
            },
            plotOptions: {
                pie: {
                    shadow: false,
                    center: ['50%', '50%'],
                    size: 240
                },
                
            },
            tooltip: {
                valueSuffix: '%',
                formatter: function () {
                    // tooltip is on interior circle value
                    let interiorCircle = temporaryCategories.find(data => data === this.point.name) !== undefined;
                    if (interiorCircle == true) {
                        return this.point.name + ':<b> ' + this.y + '%' + '</b>'
                    } else {
                        // exemple data = [{... y: 60}, {... y: 40}, {... y: 0}].
                        // quota of index 0 = 60, index 1 = 40, index 2 = 0.
                        // for each quota, there are 2 values, so index of the find function bellow is between 0-5.
                        // if index is 0 or 1, we are in data quota 0. If index is 2 or 3 we are in data quota 1, etc.
                        // divide by 2 and math floor, then we get the good quota of this value.
                        let dataQuota = 0;
                        this.series.data.find((d, index) => {
                            if (d.id === this.point.id) {
                                // get quota of the value
                                dataQuota = data[Math.floor(index / 2)].y;
                            }
                        })
                        return this.point.name + ':<b> ' + ((this.y / dataQuota * 100)/nbMaxAnswers * 100).toFixed(1) + '%' + '</b>'
                    }
                }
            },
            series: [{
                name: 'Quotas',
                data: browserData,
                size: '50%',
                dataLabels: {
                    formatter: function () {
                        return this.y > 1 ? this.point.name : null;
                    },
                    color: '#ffffff',
                    distance: -30
                },
            }, {
                name: 'Données',
                data: versionsData,
                size: '80%',
                innerSize: '60%',
                dataLabels: {
                    formatter: function () {
                        return this.y > 0 ? '<b>' + this.point.name + ':</b> ' + this.y + '' : null;
                    }
                },
                id: 'versions'
            }],
            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 400
                    },
                    chartOptions: {
                        series: [{
                        }, {
                            id: 'versions',
                            dataLabels: {
                                enabled: false
                            }
                        }]
                    }
                }]
            },
            credits: {
                enabled: false
            },
        }
    }

    return (
        <HighchartsReact highcharts={Highcharts} options={donutChartQuota(index)} />
    );
};