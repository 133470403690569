import React, { useState, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitleWithCloseIcon from '../shared/DialogTitleWithCloseIcon/DialogTitleWithCloseIcon';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import CodificationService from '../../services/CodificationService'

const codificationService = new CodificationService();

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    maxHeight: '400px',
    backgroundColor: theme.palette.background.paper,
  },
}));

const MergeEntriesDialog = (props) => {
  // expected parameters
  const {
    t,
    entriesId,
    libraryId,
    openState,
    callbackAfterConfirm,
    callbackAfterCancel,
    openSnackbar,
    closeSnackbar
  } = props;

  const { classes } = useStyles();

  const [entries, setEntries] = useState([]);
  const [selectedDestinationId, setSelectedDestinationId] = useState(0);

  useEffect(() => {
    if (!openState) return;

    let isCancelled = false;

    setEntries([]);

    var promises = [];
    for (var i = 0; i < entriesId.length; i++) {
      promises.push(codificationService.fetchBiblioEntry(libraryId, entriesId[i]));
    }

    Promise.all(promises)
      .then(function (results) {
        if (!isCancelled) {
          var e = [];

          results.forEach((item, i) => {
            e.push(item.data);
          });

          setEntries(e);
        }
      })
      .catch(err => {
        openSnackbar('error', t("react.codification.entries.error.while.loading"));
      });
    return () => {
      isCancelled = true;
    };
  }, [openState, entriesId, libraryId, openSnackbar, t]);

  const handleClickConfirm = (event) => {
    event.preventDefault();

    if (selectedDestinationId === 0) {
      openSnackbar('error', t('react.codification.merge.entries.destination.error.empty'));
      return;
    }

    callbackAfterConfirm(entriesId, selectedDestinationId);
  };

  const handleClickCancel = (event) => {
    event.preventDefault();
    closeSnackbar();
    callbackAfterCancel();
  };

  const handleClickSelect = (event, destinationId) => {
    event.preventDefault();
    setSelectedDestinationId(destinationId);
  };

  if (!openState) {
    return null;
  }

  return (
    <Dialog open={openState} onClose={handleClickCancel}>
      <DialogTitleWithCloseIcon
        title={t("react.codification.merge.entries.title")}
        callbackOnclose={handleClickCancel}
      />
      <DialogContent>
        <DialogContentText>
          {t("react.codification.merge.entries.content")}
        </DialogContentText>
        <List className={classes.root} >
          {
            entries.map(e => (
              <ListItem selected={selectedDestinationId === e.id} key={`ListItem${e.id}`} dense button onClick={event => handleClickSelect(event, e.id)}>
                <ListItemText id={`ListItemText${e.id}`} primary={`${e.biblioWord.word} (${e.nature} / ${e.logic} / ${e.valence})`} />
              </ListItem>
            ))
          }
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClickCancel} color="primary">
          {t("react.button.cancel")}
        </Button>
        <Button onClick={handleClickConfirm} color="primary">
          {t("react.button.apply")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default MergeEntriesDialog;
