import { createTheme } from '@mui/material/styles';

//@see https://mui.com/material-ui/customization/default-theme/

const theme = createTheme({
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          maxWidth: '1280px !important',
        },
        maxWidthXl: {
          maxWidth: '1920px !important',
        },
        maxWidthXs: {
          maxWidth: '444px !important',
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiCheckbox: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiSlider: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiSwitch: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiRadio: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiTabs: {
      defaultProps: {
        indicatorColor: 'secondary',
      },
      styleOverrides: {
        root: {
          button: {
            minWidth: '160px !important',
          }
        },
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            "backgroundColor": "rgba(0, 226, 220, 0.08)"
          }
        },
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        colorSecondary: {
          color: "#00e2dc !important" // this fix a pb in sequence where another style change "secondary" to red !
        }
      }
    },
  },
  palette: {
    background: {
      default: "#fafafa",
    },
    primary: {
      light: '#003945',
      main: '#003945',
      dark: '#003945',
      contrastText: '#fff',
    },
    secondary: {
      light: '#00e2dc',
      main: '#00e2dc',
      dark: '#00e2dc',
      contrastText: '#000',
    }
  },
});

export default theme;
