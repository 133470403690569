import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import SecurityIcon from '@mui/icons-material/Security';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from 'tss-react/mui';
import IconButton from '@mui/material/IconButton';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import WarningIcon from '@mui/icons-material/Warning';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ProjectService from '../../services/ProjectService'
import Toolbar from '@mui/material/Toolbar';
import {toolbarStyles} from '../../common.js';
import { capitalizeFirstLetter } from '../../utils/utils.js';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import dateFormat from 'dateformat';

const projectService = new ProjectService();

const useStyles = makeStyles()(theme => ({
  helpButton: {
    color: '#66bb6a',
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  button: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  typographyIcon : {
    verticalAlign: 'sub',
  },
  SomeSpaceUnder: {
    marginBottom: theme.spacing(2),
  },
}));

export default function PanelAdmin(props) {

  const { t, project, openSnackbar } = props;

  const { classes } = useStyles();
  const { classes: toolbarClasses } = toolbarStyles();

  const [projectErrors, setProjectErrors] = useState({'total': 0});
  const [projectErrorsFrom, setProjectErrorsFrom] = useState(0);
  const [projectErrorsSize] = useState(50);
  const [loadErrors, setLoadErrors] = useState(true);

  useEffect(() => {
    if(!loadErrors) return;

    projectService.getProjectErrors(project.id, projectErrorsFrom, projectErrorsSize)
    .then(result => {
      setProjectErrors(result.data);
      setLoadErrors(false);
    })
    .catch(err => {
      setLoadErrors(false);
      openSnackbar('error', t('react.generic.error.while.loading'));
    });
  }, [loadErrors, project, projectErrorsFrom, projectErrorsSize]);

  const handleClickPreviousErrors = (event) => {
    event.preventDefault();
    setProjectErrorsFrom(projectErrorsFrom - projectErrorsSize);
    setLoadErrors(true);
  }

  const handleClickNextErrors = (event) => {
    event.preventDefault();
    setProjectErrorsFrom(projectErrorsFrom + projectErrorsSize);
    setLoadErrors(true);
  }

  const handleClickForceCodification = (event) => {
    event.preventDefault();
    projectService.fireForcedCodification(project.id)
      .then(response => {
        openSnackbar('success', 'La demande de codification a bien été envoyée, uniquement sur les séquences en mode « codification », « grouping » ou « completed »');
      })
      .catch(err => {
        openSnackbar('error', 'Une erreur est survenue lors de la demande de codification');
      });
  }

  return (
    <Grid item xs={12} container spacing={3}>
      <Grid item xs={12}>
        <Toolbar className={toolbarClasses.root}>
          <Typography className={toolbarClasses.title} variant="h6" id="tableTitle">
            {['draft', 'inspectAnswers', 'validation', 'inspectCodification', 'scoring', 'completed'].includes(project.state) &&
              <span><SecurityIcon className={classes.typographyIcon} color="secondary"/> Aucune action requise</span>
            }
            {['codification', 'grouping', 'error'].includes(project.state) &&
              <span><SecurityIcon className={classes.typographyIcon} color="error"/> Une action est requise</span>
            }
            &nbsp;-&nbsp;Etat du projet : « {capitalizeFirstLetter(project.state)} »
          </Typography>

          <Tooltip title="Forcer une codification complète">
          <span>
            <IconButton
              disabled={['codification', 'grouping', 'completed'].includes(project.state) === false}
              onClick={handleClickForceCodification}
              size="large">
              <DynamicFeedIcon color={['codification', 'grouping', 'completed'].includes(project.state) ? "primary" : "disabled"} />
            </IconButton>
          </span>
          </Tooltip>
          <Tooltip title="Modifier les regroupements">
            <span>
            <IconButton
              disabled={project.state !== 'grouping' && project.state !== 'completed'}
              onClick={() => props.navigate('/grouping/' + project.id)}
              size="large">
              <BubbleChartIcon color={(project.state === 'grouping' || project.state === 'completed') ? "primary" : "disabled"}/>
            </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Visualiser le dashboard">
            <span>
            <IconButton
              disabled={project.state !== 'grouping' && project.state !== 'completed'}
              onClick={() => props.navigate('/projects/' + project.id + '/dashboard')}
              size="large">
              <InsertChartIcon color={(project.state === 'grouping' || project.state === 'completed') ? "primary" : "disabled"}/>
            </IconButton>
            </span>
          </Tooltip>
        </Toolbar>
      </Grid>
      {project.state === 'error' &&
        <Grid item xs={12}>
          <Typography>
            Le projet contient des erreurs qui bloquent la suite du traitement. Après vérification vous pouvez
            soit passer à l'étape suivante soit revenir à l'étape précédente pour corriger ces erreurs.
          </Typography>
        </Grid>
      }
      {projectErrors.total > 0 &&
        <Grid item xs={12}>
          <Typography variant="h6" className={classes.SomeSpaceUnder}>
            <WarningIcon className={classes.typographyIcon} color="error"/> Nombre d'erreurs: {projectErrors.total}
          </Typography>
          <Typography variant="subtitle2">
            Statistiques:
          </Typography>
          {projectErrors.stats.map((oneStat, idx) => (
              <Typography  variant="body2" key={idx}>
                {t('react.'+oneStat.name, {'offsetId': 'xxx'})}: {oneStat.count} ({oneStat.percent === 0 ? 'moins de 1': oneStat.percent}%)
              </Typography>
          ))}
          <Typography variant="subtitle2" style={{marginTop: "10px"}}>
            Détails de {projectErrorsFrom + ' à ' + (projectErrorsFrom+projectErrors.hits.length)} sur {projectErrors.total}
          </Typography>
          {projectErrors.hits.map(projectError => {
            let baseParams = {
                'projectId': projectError.projectId,
                'fileId': projectError.fileId,
                'offsetId': projectError.offsetId
              };
            let params = projectError.parameters ? {...baseParams, ...JSON.parse(projectError.parameters)} : baseParams;

            return (
              <Typography variant="body2" key={projectError.id}>
                {dateFormat(projectError.creationDate, "UTC:yyyy-mm-dd'T'HH:MM")}: {t('react.'+projectError.errorKey, params)}
              </Typography>
          )})}
          <Tooltip title={t('react.button.previous')}>
            <span>
            <IconButton
              disabled={projectErrorsFrom === 0}
              onClick={e => handleClickPreviousErrors(e)}
              size="large">
              <KeyboardArrowLeftIcon />
            </IconButton>
            </span>
          </Tooltip>
          <Tooltip title={t('react.button.next')}>
            <span>
            <IconButton
              disabled={(projectErrorsFrom + projectErrorsSize) >= projectErrors.total}
              onClick={e => handleClickNextErrors(e)}
              size="large">
              <KeyboardArrowRightIcon />
            </IconButton>
            </span>
          </Tooltip>
        </Grid>
      }
    </Grid>
  );
}
