import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import { tss, withStyles } from 'tss-react/mui';
import { capitalizeFirstLetter, indexOf,findById, appendSuffixIfGreaterThan } from '../../utils/utils';
import MenuIcon from '@mui/icons-material/Menu';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { ImgIcon } from '../../common';
import { emotionalDriverFakeDataHelp, DashboardConstants } from '../../Constants.js';
import GetAppIcon from '@mui/icons-material/GetApp';
import ListItemText from '@mui/material/ListItemText';
import MuiStyledListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import WidgetsIcon from '@mui/icons-material/Widgets';
import {Box, Typography} from '@mui/material';

const useStyles = tss
    .withParams()
    .create(({ theme, fontSize }) => ({
        paper: {
            width: '380px',
            marginLeft: 16,
            position: 'relative',
            height: '100%',
        },
        cardname: {
            textAlign: "center",
            backgroundColor: "#00e2dc",
            border: "1px solid rgb(230,230,230)",
            color: 'rgb(35,35,35)',
            fontSize: '1rem',
            margin: '10px 40px',
            padding: 5
        },
        bar: {
            borderTop: "1px solid grey",
            borderRight: "1px solid grey",
            borderBottom: "1px solid grey",
            height: 20,
            paddingTop: 10,
            marginTop: 5,
        },
        valueNumber: {
            marginLeft: 5,
            paddingTop: 5,
            color: 'rgba(0, 0, 0, 0.54)',
        },
        verticalBar: {
            width: 1,
            height: '100%',
            backgroundColor: 'grey'
        },
        cardMenu: {
            position: 'absolute',
            top: 10,
            right: 10,
        },
        textHelperMessage: {
            textAlign: 'center',
            paddingTop: '5px',
            paddingBottom: '1px',
            marginBottom: '15px',
            paddingLeft: '15px',
            paddingRight: '15px',
            color: 'rgb(70,70,70)',
            backgroundColor: 'rgb(252, 196, 192)'
        },
        displayInline: {
        },
        heightBar: {
            display: "flex", height: "30px"
        },
        labelBar: {
            width: "150px",
            textAlign: "right",
            marginRight: 10,
            paddingTop: 5,
            color: 'rgba(0, 0, 0, 0.54)',
            lineHeight: '15px',
            fontSize: `${fontSize}px`,
        },
        labelBarBlur: {
            width: "150px",
            textAlign: "right",
            marginRight: 10,
            paddingTop: 5,
            color: 'rgba(0, 0, 0, 0.54)',
            lineHeight: '15px',
            filter: 'blur(1.5px)',
            cursor: 'auto',
        },
        overlay: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            cursor: 'auto',
            backgroundColor: 'transparent',
        },
        divCardId: {
            paddingBottom: 20
        },
        blurTextEmotional: {
            filter: 'blur(1px)',
            opacity: 0.3
        },
        iconTextHelper: {
            transform: 'translateX(2px) scale(2)',
            height: 10
        },
        h3Justified: {
            textAlign: 'justify'
        },
        h4NotBoldAndJustified: {
            fontWeight: 'normal',
            textAlign: 'justify'
        },
        helpZone: {
            width:'205px',
            display: "flex", 
            justifyContent: "space-between", 
            height: "30px",
            padding: '0 20px'
        },
        helpbar: {
            width: '25px',
            border: "1px solid grey",
            height: 20,
            paddingTop: 10,
            marginTop: 5,
            borderRadius: 5
        },
        labelHelpBar: {
            fontStyle: 'italic',
            marginRight: 10,
            paddingTop: 5,
            color: 'rgba(0, 0, 0, 0.54)',
            lineHeight: '15px',
            fontSize: `${fontSize}px`,
        },
        yellow: {
            backgroundColor: DashboardConstants.positiveColor
        },
        red: {
            backgroundColor: DashboardConstants.negativeColor
        }
}));

const StyledListItemIcon = withStyles(MuiStyledListItemIcon, {
    root: {
        minWidth: '30px',
    },
});

export default function EmotionalDriver(props) {

    const { t, stimuli, sequences, configuration, dashboardService, generateImage, 
        stimulusCardToImage, collectImageCallback, openSnackbar } = props;

    const { classes } = useStyles({fontSize: configuration.dashboardFontSize});

    const [dataEmotional, setDataEmotional] = useState([]);
    const [openContextMenu, setOpenContextMenu] = React.useState(false);
    const [anchorElContextMenu, setAnchorElContextMenu] = useState(null);
    const [titleStimuli, setTitleStimuli] = useState([]);
    const [cardId, setCardId] = useState('card_emotional_driver');
    const [imageTitle, setImageTitle] = useState('Emotional Drivers');
    const [ratio, setRatio] = useState(0);
    const [errorLevel, setErrorLevel] = useState(0);

    useEffect(() => {
        // retains only true stimuli (or clone having an id > 0)
        const filteredStimuli = stimuli.filter(s => s.id > 0);

        // NOTE: XF20240423 - we can compare only the same stimulus duplicated with differents filters
        let nbDistinctStimulusIds = [...new Set(filteredStimuli.map(s => s.virtual ? s.virtualOf : s.id))];

        let errorLevel = 0;
        if(filteredStimuli.length < 2) {
            // not enough cards
            errorLevel = 1;
        } else if(filteredStimuli.length > 2) {
            // too much cards
            errorLevel = 2;
        } else if(nbDistinctStimulusIds.length !== 1) {
            // not the same
            errorLevel = 3;
        } else if(configuration.hidden.length > 0 && !configuration.showHiddenCards) {
            // there are hidden cards
            errorLevel = 4;
        }
        setErrorLevel(errorLevel);

        if (errorLevel > 0) {
            setDataEmotional([]);
            setRatio(0.75);
            return;
        }

        // sort real stimulus by score ASC
        let orderedStimulus = filteredStimuli.sort((a, b) => {return a.weightedScore - b.weightedScore});
        let stimulusA = orderedStimulus[0];
        let stimulusB = orderedStimulus[1];

        // generate titles
        let tempArrTitles = [
            dashboardService.getStimulusName(stimulusA, configuration, stimuli, sequences, t),
            dashboardService.getStimulusName(stimulusB, configuration, stimuli, sequences, t)
        ];

        // detect all groups over both stimulus
        let allGroups = [];
        let allGroupsOfA = [];
        let allGroupsOfB = [];
        Object.values(stimulusA.groups).forEach((grps) => {
            grps.filter(g => !g.missing).forEach((grp) => {
                allGroupsOfA.push(grp);
                allGroups.push(grp);
            });
        });
        Object.values(stimulusB.groups).forEach((grps) => {
            grps.filter(g => !g.missing).forEach((grp) => {
                allGroupsOfB.push(grp);
                if(indexOf(allGroups, "id", grp.id) === -1) {
                    allGroups.push(grp);
                }
            });
        });

        // compute all emotional drivers
        let tempArr = allGroups
            .map(group => {
                // get score of A & B for this group
                let grpA = findById(allGroupsOfA, group.id);
                let grpScoreA = grpA === undefined ? 0 : grpA.score;

                let grpB = findById(allGroupsOfB, group.id);
                let grpScoreB = grpB === undefined ? 0 : grpB.score;

                // compute
                let negativeDriver = Math.min(grpScoreB, 0) - Math.min(grpScoreA, 0);
                let positiveDriver = Math.max(grpScoreB, 0) - Math.max(grpScoreA, 0);

                return { "group": { "id": group.id, "title": group.title }, "negativeDriver": negativeDriver, "positiveDriver": positiveDriver };
            })
            // keep only if above the limit
            .filter(d => (d.negativeDriver + d.positiveDriver) >= configuration.comparisonTriggeringValue)
            // sort by sum DESC
            .sort((a, b) => (b.negativeDriver + b.positiveDriver) - (a.negativeDriver + a.positiveDriver));

        // ratio is based on the first one (longest)
        setRatio(tempArr.length > 0 ? 160 / (tempArr[0].positiveDriver + tempArr[0].negativeDriver) : 0);

        setTitleStimuli(tempArrTitles);
        setImageTitle(`Emotional drivers ${tempArrTitles[0]} ${tempArrTitles[1]}`)
        setDataEmotional(tempArr)
    }, [stimuli, configuration])    

    useEffect(() => {
        if (!generateImage) return;
        stimulusCardToImage(
            cardId,
            undefined,
            undefined,
            false,
            false,
            imageTitle,
        ).then((result) => {
            collectImageCallback(result);
        })

    }, [generateImage, cardId,]);

    const handleCloseContextMenu = () => {
        setOpenContextMenu(false);
        setAnchorElContextMenu(null);
    };

    const handleClickOpenContextMenu = (event) => {
        setAnchorElContextMenu(event.currentTarget);
        setOpenContextMenu(true);
    };

    const handleClickItem = (actionParam) => {
        stimulusCardToImage(
            cardId,
            undefined,
            undefined,
            false,
            false,
            imageTitle,
            actionParam
        )
        setOpenContextMenu(false);
        if (actionParam === 'clipboard') {
            new Promise(resolve => setTimeout(resolve, 2000)).then(() => {
                openSnackbar('success', t('react.dashboard.card.button.copied'));
            });
        }
    }

    const getTooltip = (data) => {
        let parts = [];
        if(data.negativeDriver !== 0) {
            parts.push(`Negative driver = ${Math.round(data.negativeDriver)}`);
        }
        if(data.positiveDriver !== 0) {
            parts.push(`Positive driver = ${Math.round(data.positiveDriver)}`);
        }

        return `${capitalizeFirstLetter(data.group.title)} (${parts.join(' / ')})`;
    };

    if(dataEmotional.length === 0) {
        var errorTitle = undefined;
        var errorContent = undefined;
        if(errorLevel === 1) {
            errorTitle = t('react.dashboard.card.emotionaldriver.helper');
            errorContent = t('react.dashboard.card.emotionaldriver.helper.not.enough.stimuli');
        } else if (errorLevel === 2) {
            errorTitle = t('react.dashboard.card.emotionaldriver.helper');
            errorContent = t('react.dashboard.card.emotionaldriver.helper.too.much.stimuli')
        } else if (errorLevel === 3) {
            errorTitle = t('react.dashboard.card.emotionaldriver.helper');
            errorContent = t('react.dashboard.card.emotionaldriver.helper.different.stimuli')
        } else if (errorLevel === 4) {
            errorTitle = t('react.dashboard.card.emotionaldriver.helper.bad.configuration');
            errorContent = t('react.dashboard.card.emotionaldriver.helper.hidden.stimuli');
        } else {
            errorTitle = t('react.dashboard.card.drivers.emotional.name');
            errorContent = t('react.dashboard.card.emotionaldriver.helper.no.difference');
        }

        return (
        <Paper className={classes.paper}>
            <div className={classes.divCardId} id={cardId}>
                <div className={classes.textHelperMessage} >                   
                    <h3 className={classes.h3Justified}>{errorTitle}</h3>
                    <h4 className={classes.h4NotBoldAndJustified}>
                        {errorContent}
                        {errorLevel > 0 && <WidgetsIcon className={classes.iconTextHelper} />}
                    </h4>
                </div>
                <div className={classes.blurTextEmotional}>
                    {emotionalDriverFakeDataHelp().map((value, index) => (
                        <div key={index} className={classes.heightBar}>
                            <div className={classes.labelBarBlur}>
                                {appendSuffixIfGreaterThan(capitalizeFirstLetter(value.group.title), '...', 30)}
                            </div>
                            <div className={classes.overlay}></div>
                            <div className={classes.verticalBar}></div>                           
                                <div style={{ display: 'flex' }}>
                                    {value.negativeDriver > 0 && <div
                                        className={classes.bar}
                                        style={{
                                            width: value.negativeDriver * ratio > 3 ? value.negativeDriver * ratio : 3,
                                            backgroundColor: DashboardConstants.negativeColor,
                                            borderTopRightRadius: value.positiveDriver === 0 ? 5 : 0,
                                            borderBottomRightRadius: value.positiveDriver === 0 ? 5 : 0
                                        }}
                                    ></div>}
                                    {value.positiveDriver > 0 && <div
                                        className={classes.bar}
                                        style={{
                                            width: value.positiveDriver * ratio > 3 ? value.positiveDriver * ratio : 3,
                                            backgroundColor: DashboardConstants.positiveColor,
                                            borderTopRightRadius: 5,
                                            borderBottomRightRadius: 5,
                                        }}
                                    ></div>}
                                </div>
                        </div>
                    ))}
                </div>
            </div>
        </Paper>
    )}
    
    return (
        <Paper className={classes.paper}>
            <Box className={classes.divCardId} id={cardId}>
                <Typography variant="h3" className={classes.cardname}><b>{t('react.dashboard.card.drivers.emotional.name')}</b> <br /> {appendSuffixIfGreaterThan(titleStimuli[0], '...', 35)} <br /> {appendSuffixIfGreaterThan(titleStimuli[1], '...', 35)}</Typography>
                <Box className={classes.cardMenu}>
                    <Tooltip title={t('react.dashboard.card.show.menu')} data-html2canvas-ignore='true' /* indicate that this menu will not be exported as image */>
                        <IconButton size='small' onClick={e => handleClickOpenContextMenu(e)}>
                            <Badge>
                                <MenuIcon />
                            </Badge>
                        </IconButton>
                    </Tooltip>
                    <Menu
                        id={`context-menu-card-emotional-drivers`}
                        elevation={1}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        anchorEl={anchorElContextMenu}
                        keepMounted
                        open={openContextMenu}
                        onClose={handleCloseContextMenu}
                        data-html2canvas-ignore='true'
                    >
                        <MenuItem onClick={e => handleClickItem('clipboard')}>
                            <StyledListItemIcon>
                                <ImgIcon fontSize="small" />
                            </StyledListItemIcon>
                            <ListItemText primary={t('react.dashboard.card.button.copy')} />
                        </MenuItem>
                        <MenuItem onClick={e => handleClickItem('download')}>
                            <StyledListItemIcon>
                                <GetAppIcon fontSize="small" />
                            </StyledListItemIcon>
                            <ListItemText primary={t('react.dashboard.card.button.download')} />
                        </MenuItem>
                    </Menu>
                </Box>
                <Box>
                    {dataEmotional.map((value, index) => (
                        <Box key={index} className={classes.heightBar}>
                            <Box className={classes.labelBar}>
                                {appendSuffixIfGreaterThan(capitalizeFirstLetter(value.group.title), '...', 30)}
                            </Box>
                            <Box className={classes.verticalBar}></Box>
                            <Tooltip title={getTooltip(value)}>
                                <Box style={{ display: 'flex' }}>
                                    {value.negativeDriver > 0 && <Box
                                        className={classes.bar}
                                        style={{
                                            width: value.negativeDriver * ratio > 3 ? value.negativeDriver * ratio : 3,
                                            backgroundColor: DashboardConstants.negativeColor,
                                            borderTopRightRadius: value.positiveDriver === 0 ? 5 : 0,
                                            borderBottomRightRadius: value.positiveDriver === 0 ? 5 : 0
                                        }}
                                    ></Box>}
                                    {value.positiveDriver > 0 && <Box
                                        className={classes.bar}
                                        style={{
                                            width: value.positiveDriver * ratio > 3 ? value.positiveDriver * ratio : 3,
                                            backgroundColor: DashboardConstants.positiveColor,
                                            borderTopRightRadius: 5,
                                            borderBottomRightRadius: 5,
                                        }}
                                    ></Box>}
                                </Box>
                            </Tooltip>
                        </Box>
                    ))}
                </Box>
                <Box className={classes.helpZone} style={{marginTop: '30px'}}>
                    <Box className={classes.labelHelpBar}>{t('react.dashboard.card.drivers.emotional.help.positive')}</Box><Box className={`${classes.helpbar} ${classes.yellow}`} />
                </Box>
                <Box className={classes.helpZone}>
                    <Box className={classes.labelHelpBar}>{t('react.dashboard.card.drivers.emotional.help.negative')}</Box><Box className={`${classes.helpbar} ${classes.red}`} />
                </Box>
            </Box>
        </Paper>
    )
}