import React from 'react';
import StimulusCard from './StimulusCard';
import AiAnalysisCard from './AiAnalysisCard';
import EmotionalDriver from './EmotionalDriver';
import Grid from '@mui/material/Grid';

export default function PanelDashboard(props) {
  const { stimuli, configuration, showAiAnalysis } = props;

  return <Grid container spacing={1} >
    {showAiAnalysis !== 0 &&
      <AiAnalysisCard
        {...props}
      />
    }
    {
      stimuli.filter(stimulus => (configuration.showHiddenCards || (configuration.hidden || [])
        .indexOf(stimulus.id) === -1) && stimulus.id >= (configuration.showTotalCard ? -100 : 0))
        .map((stimulus, idx) => {
          return <StimulusCard key={stimulus.id}
            {...props}
            stimulus={stimulus}
          />;
        })
    }
    {configuration.displayEmotionalDriver &&
      <EmotionalDriver
        {...props}
      />
    }
  </Grid>
}
