import React from "react";
import Box from "@mui/material/Box";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import wordcloud from "highcharts/modules/wordcloud";
import { capitalizeFirstLetter, selectFirstWord } from "../../utils/utils";
import { DashboardConstants } from "../../Constants";

wordcloud(Highcharts);

export default function TagCloud(props) {

    const { t, data /* [ { name, weight, colorIndex } ] */ } = props;

    let formattedData = data
        .map((item) => {
            let captitalizedName = capitalizeFirstLetter(item.name);

             return {
                name: selectFirstWord(captitalizedName),
                weight: Math.abs(item.weight),
                fullweight: item.weight,
                fullname: captitalizedName,
                color: item.weight > 0 ? DashboardConstants.positiveColor : DashboardConstants.negativeColor,
             };
        })

   const chartData = () => {
      return {
          series: [
              {
                  type: "wordcloud",
                  data: formattedData,
                  name: t('react.dashboard.card.tagcloud.score'),
                  minFontSize: 20,
                  maxFontSize: 60,
                  rotation: {
                    from: 0,
                    to:0,
                    orientations: 1
                },
              },
          ],
          title: {
              margin: 0,
              align: "",
              text: "",
          },
          plotOptions: {
              series: {
                  animation: false,
              },
          },
          tooltip: {
              formatter: function () {
                  return (
                      "<b> " +
                      this.point.fullname +
                      "</b> " +
                      `</br><span style='color:${this.point.color};'>\u25CF</span>` + 
                      this.series.name +
                      ":<b> " +
                      this.point.fullweight +
                      "</b>"
                  );
              },
          },
          credits: {
              enabled: false,
          },
      };
    };

    return (
        <Box style={{transform: 'translateY(-10px)', marginBottom: '-10px'}}>
            <HighchartsReact highcharts={Highcharts} options={chartData()} />
        </Box>
    );
}
