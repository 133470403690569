import React, { forwardRef, useEffect, useLayoutEffect, useRef } from "react";
import Quill from "quill";
import { formatsReactQuill, modulesReactQuill } from "../../utils/surveysUtils";
import { Tooltip } from "@mui/material";
import IconButton from '@mui/material/IconButton';

/**
 * 
 * If you want the bottom right button, you need to pass parameters :
 * 
 * {
 *  tooltip: "your tooltip",
 *  icon: <ExempleIcon/>,
 *  callback: exempleOfCallbackFunction
 * }
 * 
 *  **/

const addNewMessage = {
    position: 'absolute', 
    bottom: 2, 
    right: 2, 
    zIndex: 1, 
    color: "#015463"
}

const QuillEditor = forwardRef(
  ({ onChange, placeholder = null, style = null, value, isError = false, bottomRightButton = null, t }, ref) => {
    const containerRef = useRef(null);
    const onChangeRef = useRef(onChange);
    const valueRef = useRef(value);

    useLayoutEffect(() => {
      onChangeRef.current = onChange;
    }, [onChange]);

    useEffect(() => {
      const container = containerRef.current;
      const editorContainer = container.appendChild(
        container.ownerDocument.createElement('div')
      );

      const quill = new Quill(editorContainer, {
        theme: "snow",
        modules: modulesReactQuill,
        formats: formatsReactQuill,
        placeholder: placeholder,
      });

      if (ref) {
        ref.current = quill;
      }
      else {
        ref.current = undefined;
      }

      if (value) {
        quill.clipboard.dangerouslyPasteHTML(value);
      }
      else {
        quill.clipboard.dangerouslyPasteHTML("");
      }

      // Lang Tooltip change
      // NOTE: That add new CSS lines that override default CSS for tooltip
      const tooltipStyleSheet = document.styleSheets[0];
      tooltipStyleSheet.insertRule(`
      .ql-tooltip::before {
        content: "${t("react.surveys.quill.editor.visit.url")}" !important;
      }`, tooltipStyleSheet.cssRules.length);
      tooltipStyleSheet.insertRule(`
      .ql-tooltip .ql-action::after {
        content: "${t("react.surveys.quill.editor.visit.url.edit")}" !important;
      }
      `, tooltipStyleSheet.cssRules.length);
      tooltipStyleSheet.insertRule(`
      .ql-tooltip .ql-remove::before {
        content: "${t("react.surveys.quill.editor.visit.url.remove")}" !important;
      }
      `, tooltipStyleSheet.cssRules.length);

      quill.on(Quill.events.TEXT_CHANGE, () => {
        const newValue = quill.root.innerHTML;
        if (newValue !== valueRef.current) {
          valueRef.current = newValue;
          onChangeRef.current?.(newValue);
        }
      });

      return () => {
        if (ref) {
          ref.current = null;
        }
        container.innerHTML = "";
      };
    }, [ref, placeholder]);

    useEffect(() => {
      if (ref.current) {
        const quill = ref.current;
        if (quill.root.innerHTML !== value) {
          quill.clipboard.dangerouslyPasteHTML(value);
          valueRef.current = value;
        }
      }
    }, [value, ref]);

    return (
    <div style={{position: "relative"}} key="quill-editor">
      <div ref={containerRef} style={style} className={isError ? "ql-error" : "ql-not-error"}></div>
      {bottomRightButton !== null && 
        <Tooltip title={bottomRightButton.tooltip}>
            <IconButton onClick={bottomRightButton.callback} style={addNewMessage}>{bottomRightButton.icon}</IconButton>
        </Tooltip>
      }
    </div>
    );
  }
);

export default QuillEditor;
