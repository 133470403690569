import React from 'react';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import TableSortLabel from '@mui/material/TableSortLabel';
import { isBlank } from '../../utils/utils.js';

export default function DictionaryListHead(props) {

  const { t, classes, headCells, onSelectAllClick, numSelected, rowCount, translateTo } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
        {headCells.map(headCell => {
          if(headCell.id === 'translation' && isBlank(translateTo)) return ;

          return (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            style={{minWidth: headCell.minWidth}}
          >
            {t(headCell.label)}
          </TableCell>
        )})}
      </TableRow>
    </TableHead>
  );
};
