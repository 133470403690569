import React, { useState, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import ApiService from '../../services/ApiService'
import Input from '@mui/material/Input';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitleWithCloseIcon from '../shared/DialogTitleWithCloseIcon/DialogTitleWithCloseIcon';
import CloseIcon from '@mui/icons-material/Close';

const restClient = new ApiService().getClient();

const useStyles = makeStyles()(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  textField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}));

export default function ImportWordTranslations(props) {

  const { classes } = useStyles();

  const {
    t,
    showSpinner,
    openSnackbar,
    openState,
    setImportDialogOpen
  } = props;

  const [importTranslationFile, setImportTranslationFile] = useState({ name: "" });
  const [importTranslationButtonDisabled, setImportTranslationButtonDisabled] = useState(true);

  const handleSelectUploadFileImportTranslation = (event) => {
    event.preventDefault();
    if (event.target.files.length === 1) {
      let fnlc = event.target.files[0].name.toLowerCase();
      if (fnlc.endsWith('.xlsx')) {
        setImportTranslationFile(event.target.files[0]);
        setImportTranslationButtonDisabled(false);
      } else {
        openSnackbar('error', "Ce type de fichier n'est pas supporté (Fichiers xlsx seulement).");
      }
    }
  };

  const handleUploadFileImportTranslation = (event) => {
    setImportDialogOpen(false);

    showSpinner(true);
    event.preventDefault();
    var formData = new FormData();
    formData.append("uploadedFile", importTranslationFile);
    restClient.post('/api/codification/v1.0/translations/import', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      responseType: 'blob'
    })
    .then(response => {
      openSnackbar('success', t("react.codification.toolbar.button.import.started"));

      setImportTranslationFile({name: ''});
      setImportTranslationButtonDisabled(true);
    })
    .catch(err => {
      openSnackbar('error', t('react.generic.error.while.saving'));
    }).finally(() => {
      showSpinner(false);
    });
  };

  return (
    <Dialog fullWidth={true} maxWidth={'sm'} open={openState} onClose={() => setImportDialogOpen(false)}>
      <DialogTitleWithCloseIcon
        title={t('react.dictionary.import.translations')}
        callbackOnclose={() => setImportDialogOpen(false)}
      />
      <DialogContent>
      <Typography>{t('react.dictionary.import.translations.desc')}</Typography>
      <form>
        <Input
          disabled
          id="importTranslationFilename"
          value={importTranslationFile.name}
          className={classes.textField}
          placeholder="Sélectionner un fichier"
          fullWidth
          endAdornment={
            <InputAdornment position="end">
              <label htmlFor="contained-button-file_2">
                <Button variant="contained" color="primary" component="span">
                  {t('react.generic.select')}
                </Button>
              </label>
            </InputAdornment>
          }
        />
        <input
          onChange={handleSelectUploadFileImportTranslation}
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          style={{ display: 'none' }}
          id="contained-button-file_2"
          type="file"
        />
      </form>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          size="large"
          startIcon={<CloseIcon />}
          onClick={() => setImportDialogOpen(false)} color="primary">
          {t('react.button.cancel')}
        </Button>
        <Button
          variant="outlined"
          size="large"
          startIcon={<CloudUploadIcon />}
          disabled={importTranslationButtonDisabled}
          onClick={handleUploadFileImportTranslation} color="primary">
          Importer
        </Button>
      </DialogActions>
    </Dialog>
  );
};
