import React, { useState, useEffect, useContext } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import SaveIcon from '@mui/icons-material/Save';
import { makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { SurveyContext } from './context';
import { TextField } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { isBlank } from '../../utils/utils';
import DialogTitleWithCloseIcon from '../shared/DialogTitleWithCloseIcon/DialogTitleWithCloseIcon';
import AddCircle from '@mui/icons-material/AddCircle';

const useStyles = makeStyles()(theme => ({
    dialogComponent: {
        minHeight: '300px',
    },
    textFieldEditAnswer: {
        marginBottom: "20px",
        marginLeft: "17px",
        marginTop: '15px',
        width: "360px"
    },
    editIcon: {
        marginRight: '10px',
        verticalAlign: 'middle'
    },
}));

export default function ParamsVariablesDialog(props) {

    const { classes } = useStyles();

    const { t, setOpenDialogVariables, openDialogVariables, answerIndex, isNewVariable, setIsNewVariable } = props;

    const { setNeedToSave, getFormConfiguration } = useContext(SurveyContext);
    const [valueVariable, setValueVariable] = useState('');
    const [valueCode, setValueCode] = useState('');
    const [valueDescription, setValueDescription] = useState('');

    useEffect(() => {
        if (!openDialogVariables) return;

        // get current component we are configuring
        let thisComponent = getFormConfiguration();
        if (!isNewVariable){
            setValueVariable(thisComponent.variables[answerIndex].value);
            setValueCode(thisComponent.variables[answerIndex].code);
            setValueDescription(thisComponent.variables[answerIndex].description);
        } else {
            setValueVariable('');
            setValueCode('');
            setValueDescription('');
        }
    }, [openDialogVariables, isNewVariable]);

    const saveParamsVariable = () => {
        let thisComponent = getFormConfiguration();
        setOpenDialogVariables(false);
        if (isNewVariable){
            var codeNumber = 1;
            getFormConfiguration().variables.forEach(v => {
                let trimmedCode = parseInt(v.code.substring(4));
                if (trimmedCode >= codeNumber) {
                    codeNumber = trimmedCode + 1;
                }
            });
            getFormConfiguration().variables.push({code: 'VAR_' + codeNumber, value: valueVariable, description: valueDescription});
            setIsNewVariable(false)
        } else {
            thisComponent.variables[answerIndex].description = valueDescription;
            thisComponent.variables[answerIndex].value = valueVariable;
            setIsNewVariable(false)
        }
        setNeedToSave(true);
    };

    const handleClose = () => {
        setOpenDialogVariables(false); 
        setIsNewVariable(false)
    }

    if(!openDialogVariables) return null;

    return (
        <Dialog
            open={openDialogVariables}
            maxWidth={'xs'}
            fullWidth={true}
        >
            <DialogTitleWithCloseIcon
                startIcon={!isNewVariable ? <EditIcon color='primary' className={classes.editIcon} /> : <AddCircle color='primary' className={classes.editIcon}/> }
                title={!isNewVariable ? t('react.project.collectforms.params.edit.variable') + `[${valueCode}]` : t('react.project.collectforms.params.add.variable')}
                callbackOnclose={handleClose}
            />
            <DialogContent component="div" className={classes.dialogComponent} >
                <DialogContentText component="div">
                    <TextField
                        value={valueVariable}
                        onChange={(e) => setValueVariable(e.target.value)}
                        className={classes.textFieldEditAnswer}
                        label={t('react.project.collectforms.params.edit.variable.content')}
                        variant="outlined"
                        error={!isNewVariable && isBlank(valueVariable)}
                        fullWidth
                        helperText={valueVariable ? valueVariable.length + '/128' : '0/128'}
                        inputProps={{ maxLength: 128 }}
                    />
                    <TextField
                        value={valueDescription}
                        multiline={true}
                        onChange={(e) => setValueDescription(e.target.value)}
                        maxRows={5}
                        minRows={5}
                        className={classes.textFieldEditAnswer}
                        label={t('react.project.collectforms.params.edit.variable.desc')}
                        variant="outlined"
                        fullWidth
                        helperText={valueDescription ? valueDescription.length + '/255' : '0/255'}
                        inputProps={{ maxLength: 255 }}
                    />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    size="large"
                    startIcon={<CloseIcon />}
                    onClick={handleClose}
                >
                    {t('react.button.cancel')}
                </Button>
                {
                    !isNewVariable ? (
                        <Button
                            variant="outlined"
                            size="large"
                            startIcon={<SaveIcon />}
                            color="primary"
                            disabled={isBlank(valueVariable)}
                            onClick={() => saveParamsVariable()}
                        >
                            {t('react.button.save')}
                        </Button>
                    ) : (
                        <Button
                            variant="outlined"
                            size="large"
                            startIcon={<AddCircle />}
                            color="primary"
                            disabled={isBlank(valueVariable)}
                            onClick={() => saveParamsVariable()}
                        >
                            {t('react.button.add')}
                        </Button>
                    )
                }
            </DialogActions>
        </Dialog>
    )
}
