import React, { useState, useEffect } from 'react';
import { tss } from 'tss-react/mui';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Icon from '@mui/material/Icon';
import { formatPValue } from '../../utils/utils.js';
import { DashboardConstants} from '../../Constants.js';
import CloseIcon from '@mui/icons-material/Close';
import Paper from '@mui/material/Paper';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const useStyles = tss
  .withParams()
  .create(({ theme, fontSize }) => ({
    container: {
      maxWidth: '80px',
      minWidth: '80px',
      height: '302px',
      position: 'relative',
      margin: '0 auto',
    },
    box: {
      margin:'10px',
      width: '80px',
      height:'max-content',
    },
    imageIcon: {
      transform: 'rotate(-90deg) translateX(-230px)',
      transformOrigin: '40px center',
    },
    stimulus: {
      position: 'absolute',
      zIndex: '1',
      width: '80px',
      textAlign: 'center',
      fontSize: `${fontSize}px`,
    },
    tooltip: {
      '&:hover':{
        cursor: 'default',
      }
    },
    scoremarker: {
      position: 'absolute',
      zIndex: '1',
      transform: 'scale(1.5)',
    },
    hideButton: {
      zIndex: '2',
      width: '100%',
      marginLeft: '70%',
      color: 'grey',
      '&:hover': {
        cursor: 'pointer',
      }
    },
    arrowBlock: {
      paddingLeft: '25px',
      height: '15px',
    },
    arrow: {
      display: 'block',
      margin: '10px auto 0',
    },
    icon: {
      position: 'absolute',
      margin: '0',
      width: '100%',
      right: '0',
    },
    chartTitle: {
      margin: '0',
    },
    paper: {
      boxShadow: 'none',
      color: 'rgba(0, 0, 0, 0.54)',
      fontSize: `${fontSize}px`,
    },
    stimulusName: {
      textAlign: 'center',
      height: '40px',
      width:'80px',
      overflow: 'hidden',
    },
    stimulusBase: {
      marginTop:'45px',
      marginBottom:'0px',
      textAlign: 'center',
    }
}));

const min = DashboardConstants.lowScore * 100;
const max = DashboardConstants.highScore * 100;

export default function StimulusCardVertical(props) {

  const {
    t, stimulus, configuration, stimuli, compareWiths, handleHideCard, handleShowCard, 
    dashboardService, sequences 
  } = props;

  const { classes } = useStyles({fontSize: configuration.dashboardFontSize});

  const [title, setTitle] = useState(stimulus.name);
  const [isHidden, setHidden] = useState(false);
  const [hideThermometerArrow, setHideThermometerArrow] = useState(true);
  const [significativity, setSignificativity] = useState(0);
  const [compareWith, setCompareWith] = useState();

  let valueLimitedToMarker = stimulus.weightedScore < min ? min : stimulus.weightedScore > max ? max : stimulus.weightedScore;
  let valueToPixel = valueLimitedToMarker / Math.abs((valueLimitedToMarker < 0) ? min : max);
  var pxToAdd = 20;
  var markerPosition = 0;

  if (valueToPixel <= 0) {
    markerPosition = 13 + ((valueToPixel + 1) * 115) -  32;
  } else {
    markerPosition = 12 + 115 + (valueToPixel * 155) - 32;
  }

  if(stimulus.weightedScore >= max - 1500) {
    pxToAdd = -5;
  }

  const Arrow = () => {
    if(stimulus.weightedScore >= 0){
      return <img alt="" src="/img/ArrowY.png" className={classes.arrow}/>
    } else {
      return <img alt="" src="/img/ArrowR.png" className={classes.arrow}/>
    }
  }

  var score = Math.round(stimulus.weightedScore / 100);

  const ThermometerBar = () => {
    return <Icon style={{ height: '100%', width: '100%' }}>
        <img alt="" className={classes.imageIcon} src="/img/thermometerbar.svg" />
    </Icon>
  }

  const ScoreMarker = () => {
    return <Icon>
        <img alt="" src="/img/scoremarker_vertical_blue.svg" />
    </Icon>
  }

  useEffect(() => {
    if (configuration && stimulus) {

      let compareWithStimulus = dashboardService.getCompareWith(stimulus, stimuli, configuration);
      if(compareWithStimulus) {
        setCompareWith(compareWithStimulus);
      }

      setHidden((configuration.hidden || []).indexOf(stimulus.id) >= 0);
      setTitle(dashboardService.getStimulusName(stimulus, configuration, stimuli, sequences, t));

      var newSignificativityValue = 0;
      var newHideThermometerArrow = true;
      if(compareWithStimulus && stimulus.significativity && stimulus.significativity[compareWithStimulus.id] !== undefined && stimulus.significativity[compareWithStimulus.id].value !== undefined) {
        let sy = stimulus.significativity[compareWithStimulus.id].value;
        newSignificativityValue = Math.round(sy * 1000) / 1000;
        newHideThermometerArrow = (sy >= configuration.comparisonSignificativityValue / 100);
      }
      setSignificativity(newSignificativityValue);
      setHideThermometerArrow(newHideThermometerArrow);
    }
  }, [configuration, stimuli, stimulus, dashboardService, compareWith, compareWiths, configuration.exportCompareWith]);

  const arrow = (compareWith && stimulus.weightedScore !== compareWith.weightedScore && !hideThermometerArrow)
      ? ((stimulus.weightedScore > compareWith.weightedScore) ? <img alt="" src="/img/ArrowY.png" width="30px" />
      : <img alt="" src="/img/ArrowR.png" width="30px" />) : <div>&nbsp;</div>

  return(<Box key={stimulus.id} className={classes.box}>
      <Paper className={ classes.paper }>
        <div className={classes.hideButton}>
          {isHidden ? <Tooltip title={t('react.dashboard.card.button.show')}>
                <VisibilityOffIcon color="error" size='small' onClick={e => handleShowCard(stimulus)} />
              </Tooltip>
            : <Tooltip title={t('react.dashboard.card.button.hide')}>
                  <CloseIcon data-html2canvas-ignore='true' size='small' onClick={e => handleHideCard(stimulus)} />
              </Tooltip>
          }
        </div>
        <div className={classes.stimulusName}>
          <Tooltip
            title={title}
            placement="top" className={classes.tooltip}
            disableHoverListener={title.length <= 8}
            arrow
          >
            <h4 className={classes.chartTitle}>{title}</h4>
          </Tooltip>
        </div>
        {Object.keys(configuration.compareWith).length > 0 &&
        <div className={classes.arrowBlock}>
          {!hideThermometerArrow &&
            <Tooltip title={t('react.dashboard.card.comparewith.significativity', {pvalue: formatPValue(significativity, configuration.pValueType)})}>{ arrow }</Tooltip>
          }
        </div>
        }
        <div className={classes.container}>
          <h3 className={classes.stimulus} style={{bottom:`${markerPosition + pxToAdd}px`}}>{score}</h3>
          <ThermometerBar/>
          <div className={classes.scoremarker} style={{bottom: `${markerPosition}px`, marginBottom: "16px"}}>
            <ScoreMarker/>
          </div>
        </div>
        <h4 className={classes.stimulusBase}>{t('react.dashboard.card.base.count', {nbMatchedResponses: stimulus.nbMatchedResponses})}</h4>
      </Paper>
  </Box>);
}
