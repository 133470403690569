import React, { useState, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitleWithCloseIcon from '../shared/DialogTitleWithCloseIcon/DialogTitleWithCloseIcon';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { orderBy } from '../../utils/utils.js';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Box from '@mui/material/Box';

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    maxHeight: '400px',
    backgroundColor: theme.palette.background.paper,
  },
  flexBetween: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  switch: {
    marginLeft: 24,
  },
  dialogContent: {
    minHeight: '420px',
    maxHeight: '420px',
    overflow: 'none'
  },
}));

export default function CopyEntriesDialog(props) {

  // expected parameters
  const {
    t,
    paths,
    libraryId,
    openState,
    callbackAfterConfirm,
    callbackAfterCancel,
    openSnackbar,
    closeSnackbar
  } = props;

  const { classes } = useStyles();

  const [displayedPaths, setDisplayedPaths] = useState([]);
  const [selectedLibraryId, setSelectedLibraryId] = useState(0);
  const [filterSameParentLibraries, setFilterSameParentLibraries] = useState(false);

  useEffect(() => {
    setFilterSameParentLibraries(false);
    setDisplayedPaths(orderBy(Object.entries(paths), 1));
  }, [openState, paths]);

  const handleClickConfirm = (event) => {
    event.preventDefault();

    if (selectedLibraryId === 0) {
      openSnackbar('error', t('react.codification.move.library.error.empty'));
      return;
    }

    callbackAfterConfirm(selectedLibraryId);
  };

  const handleClickCancel = (event) => {
    event.preventDefault();
    closeSnackbar();
    callbackAfterCancel();
  };

  const handleClickSelect = (event, libraryId) => {
    event.preventDefault();
    setSelectedLibraryId(libraryId);
  };

  const filterLibraries = () => {
    let newEntries = Object.entries(paths);

    if (!filterSameParentLibraries) {
      // we are going to filter
      let parentBranch = undefined;

      if (libraryId > 0) {
        parentBranch = paths[libraryId];
        parentBranch = parentBranch.substring(0, parentBranch.lastIndexOf('/') + 1 /* to have the "/" within */);
        newEntries = newEntries.filter(entry => entry[1].startsWith(parentBranch));
      }
    }

    setDisplayedPaths(orderBy(newEntries, 1));
    setFilterSameParentLibraries(!filterSameParentLibraries);
  };

  if (!openState) {
    return null;
  }

  return (
    <Dialog open={openState} onClose={handleClickCancel} maxWidth={"sm"} fullWidth={true}>
      <Box>
        <DialogTitleWithCloseIcon
          title={t("react.codification.copy.entries.title")}
          callbackOnclose={handleClickCancel}
        />
        <FormControlLabel
          control={<Switch checked={filterSameParentLibraries} color="secondary" onChange={filterLibraries} />}
          label={t("react.codification.move.library.show.sibling.paths")}
          labelPlacement="start"
          className={classes.switch}
        />
      </Box>
      <DialogContent className={classes.dialogContent}>
        <DialogContentText>
          {t("react.codification.copy.entries.content")}
        </DialogContentText>
        <List className={classes.root} >
          {displayedPaths.map(item => (
            <ListItem selected={selectedLibraryId === parseInt(item[0])} key={`ListItem${item[0]}`} dense button onClick={event => handleClickSelect(event, parseInt(item[0]))}>
              <ListItemText id={`ListItemText${item[0]}`} primary={item[1]} />
            </ListItem>)
          )}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClickCancel} color="primary">
          {t("react.button.cancel")}
        </Button>
        <Button onClick={handleClickConfirm} color="primary">
          {t("react.button.apply")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
