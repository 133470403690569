import { useState, useContext, useReducer, useRef } from 'react';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { SurveyContext } from './context';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { isBlankHtml } from '../../utils/surveysUtils.js';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import QuillEditor from './QuillEditor.js';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import ParamsImage from './ParamsImage';
import Button from '@mui/material/Button';

const useStyles = makeStyles()(theme => ({
  previewRoot: {
    marginBottom: '25px',
    margin: '0',
    padding: '5px',
    width: `250px`,
    height: `150px`,
    display: 'inline-block',
    borderStyle: 'dotted',
    borderRadius: '7px',
  },
  previewImg: {
    textAlign: 'center',
    verticalAlign: 'middle',
    maxWidth: '100%',
    maxHeight: '140px',
    position: 'relative',
    top: '-50px'
  },
  previewBtns: {
    textAlign: 'center',
    verticalAlign: 'middle',
    maxWidth: '100%',
    height: '50px',
    position: 'relative', top: '25%', zIndex: '100'
  },
  previewFullscreen: {
    margin: '0px 0 15px 0',
    textWrap: 'nowrap'
  },
  alignCenter: {
    textAlign: 'center',
    verticalAlign: 'middle',
    maxWidth: '100%',
    maxHeight: '200px'
  },
  paddingPaper: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 1,
    paddingBottom: 30
  },
  gridImage: {
    marginTop: 20,
  },
  powerSettingsNewIcon: {
    float: 'right',
  },
}));

export default function Introduction(props) {

  const { classes } = useStyles();

  const { t, uuid } = props

  const { formIdParameter, generateSetImageButton, generateDeleteImageButton, isBlockCurrentlyDisplayed,
    setNeedToSave, getComponentConfiguration, mediaFiles } = useContext(SurveyContext);

  const [uploading, setUploading] = useState(false);
  const [uploadButtonVisibility, setUploadButtonVisibility] = useState(false);
  const [activeTempo, setActiveTempo] = useState(getComponentConfiguration(uuid).timeout === 0 ? false : true);
  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
  const [openDialogImage, setOpenDialogImage] = useState(false);

  const quillRef = useRef();

  const changeField = (fieldName, value) => {
    switch (fieldName) {
      case "content":
        getComponentConfiguration(uuid).content = value;
        break;
      case "enabledBlock":
        getComponentConfiguration(uuid).active = !getComponentConfiguration(uuid).active;
        break;
      case "openImageFullscreen":
        getComponentConfiguration(uuid).openImageFullscreen = !getComponentConfiguration(uuid).openImageFullscreen;
        break;
      case "enabledTemporisation":
        if(activeTempo) {
          // Tempo is going to be desactivated
          getComponentConfiguration(uuid).timeout = 0;
          getComponentConfiguration(uuid).transitionAuto = false;
        } else {
          // else Tempo is going to be activated => default timeout of 3
          getComponentConfiguration(uuid).timeout = 3;
        }
        setActiveTempo(!activeTempo);
        break;
      case "temporisationDelay":
        if (value >= 0) {
          getComponentConfiguration(uuid).timeout = value;
        }
        break;
      case "transitionAuto":
        getComponentConfiguration(uuid).transitionAuto = !getComponentConfiguration(uuid).transitionAuto;
        break;
    }
    setNeedToSave(true);
    forceUpdate();
  }

  if(!isBlockCurrentlyDisplayed(uuid)) return null;

  return (
    <Paper className={classes.paddingPaper}>
      <ParamsImage
        {...props}
        uuid={uuid}
        openDialogImage={openDialogImage}
        setOpenDialogImage={setOpenDialogImage}
        enableFullScreenMode={true}
      />
      <Typography variant="h6" component="h6">
        {t("react.project.collectforms.block.introduction.title")}
        <Tooltip title={t(getComponentConfiguration(uuid).active ? "react.project.collectforms.disable" : "react.project.collectforms.enable")} className={classes.powerSettingsNewIcon}>
          <IconButton 
            color={getComponentConfiguration(uuid).active ? 'success': 'error'} 
            onClick={e => changeField('enabledBlock', e.target.value)}>
            <PowerSettingsNewIcon />
          </IconButton>
        </Tooltip>
      </Typography>
      <Grid item xs={3} xm={4} className={classes.gridImage}>
        <div id={`preview_img_${uuid}`} className={classes.previewRoot}
          onMouseOver={e => setUploadButtonVisibility(true)}
          onMouseOut={e => setUploadButtonVisibility(false)}
        >
          {<div id={`preview_button_${uuid}`} className={classes.previewBtns}>
            <div style={{ display: (formIdParameter > 0 && (uploading || getComponentConfiguration(uuid).imageId === 0 || uploadButtonVisibility)) ? 'block' : 'none' }}>
              {!uploading && generateSetImageButton(uuid, setUploading)}
              {!uploading && generateDeleteImageButton(uuid)}
              {uploading && <CircularProgress />}
            </div>
          </div>}
          {getComponentConfiguration(uuid).imageId > 0 && <div className={classes.alignCenter}>
            <img
              id={`img_${uuid}`}
              className={classes.previewImg}
              src={mediaFiles[getComponentConfiguration(uuid).imageId]}
              style={{ opacity: uploadButtonVisibility ? '0.3' : '1' }} />
          </div>}
        </div>
      </Grid>
      {getComponentConfiguration(uuid).imageId > 0 &&
        <Button style={{marginTop: 20, marginBottom: 20}} onClick={() => setOpenDialogImage(true)} variant="contained" endIcon={<ImageSearchIcon />}>
            {t("react.surveys.button.params.image")}
        </Button>
      }
      <QuillEditor
        ref = {quillRef}
        value={getComponentConfiguration(uuid).content}
        onChange={(value) => changeField('content', value)}
        isError={isBlankHtml(getComponentConfiguration(uuid).content)}
        style={{height: 400, paddingBottom: 42, marginTop: getComponentConfiguration(uuid).imageId > 0 ? 0 : 20}}
        placeholder= {t("react.project.collectforms.content")}
        {...props}
      />
      <FormControlLabel
        style={{ marginLeft: 0, marginTop: 10 }}
        control={<Switch checked={activeTempo} color="secondary" onChange={e => changeField('enabledTemporisation', e.target.value)} />}
        label={t("react.project.collectforms.temporisation")}
        labelPlacement="start"
      />
      {getComponentConfiguration(uuid).timeout !== 0 &&
        <Grid item xs={12}>
          <FormControlLabel
            style={{ marginLeft: 0 }}
            control={
              <TextField
                style={{ marginLeft: 10, width: 50 }}
                value={getComponentConfiguration(uuid).timeout}
                type='number'
                onChange={e => changeField('temporisationDelay', e.target.value)}
              />
            }
            label={t('react.project.collectforms.timeout')}
            labelPlacement="start"
          /><br />
          <FormControlLabel
            style={{ marginLeft: 0 }}
            control={<Switch checked={getComponentConfiguration(uuid).transitionAuto} color="secondary" onChange={e => changeField('transitionAuto', e.target.value)} />}
            label={t("react.project.collectforms.transition.mode")}
            labelPlacement="start"
          />
        </Grid>
      }
    </Paper>
  )
}
