import React, { useState, useContext, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import { SurveyContext } from './context';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitleWithCloseIcon from '../shared/DialogTitleWithCloseIcon/DialogTitleWithCloseIcon';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import { Typography, Box } from '@mui/material';
import { findById, cloneDeep } from '../../utils/utils';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const useStyles = makeStyles()(theme => ({
    dialog: {
        zIndex: 2000
    },
    listItemIcon: {
        minWidth: '35px',
    },
    questionProfileIndex: {
        marginLeft: "5px",
        color: "#BBB",
        fontStyle: "italic"
    },
    relative: {
        position: 'relative'
    },
    groupsArea: {
        marginTop: 10,
        width: 395,
        minHeight: '50px'
    },
    buttonAddGroup: {
        position: 'absolute',
        right: 0,
        top: 0
    },
    divActualGroup: {
        display: 'flex',
        marginBottom: -10,
        marginTop: 10
    },
    textColorBlack: {
        color: 'black'
    },
    textColorOrange: {
        color: 'orange',
        fontStyle: 'italic'
    },
    warningBox: {
        height: '24px'
    },
    spaceBetween: {
        display: 'flex',
        justifyContent: 'space-between',
        position: 'relative'
    },
    widthGroupBloc: {
        width: 100
    },
    listItem: {
        color: 'black',
        height: 43
    },
    chipGroup: {
        marginRight: 10,
        marginBottom: 10,
        boxShadow: '2px 2px 5px grey'
    }
}));

const groupTemplate = {
    'blockIndices': []
}

export default function PopupGroupsOfBlocks(props) {

    const { classes } = useStyles();

    const {
        t,
        surveysService,
        openPopupGroupsOfBlocks,
        callBackConfirmManageGroups,
        callBackCancelManageGroups,
        selectedIndexGroup,
        setSelectedIndexGroup
    } = props;

    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [groups, setGroups] = useState([]);

    const { formIdParameter, getAllBlocks, computeTitle, getFormConfiguration, setNeedToSave } = useContext(SurveyContext);

    useEffect(() => {
        if (!openPopupGroupsOfBlocks) return;

        // rebuild elements with block indices as entry
        let newGroups = getFormConfiguration().groups
            .map(group => {
                group.blockIndices = getAllBlocks()
                    .map((block, index) => [index, block.configuration.groupId])
                    .filter(pair => pair[1] /* group id */ === group.id)
                    .map(pair => pair[0] /* index */ );
                return group;
            });

        newGroups = newGroups.sort((a, b) => a.position - b.position);

        setGroups(newGroups);
        setSelectedIndex(newGroups.length > 0 ? 0 : -1);
    }, [openPopupGroupsOfBlocks])

    const handleChange = (index) => {
        if(selectedIndex < 0) return;

        let tempArr = [...groups];
        if (!tempArr[selectedIndex].blockIndices.includes(index)) {
            tempArr[selectedIndex].blockIndices.push(index);
        } else {
            tempArr[selectedIndex].blockIndices = tempArr[selectedIndex].blockIndices.filter(element => element !== index)
        }

        setGroups(tempArr);
    };

    const validGroup = (groupIndex) => {
        // get the concerned group
        let group = groups[groupIndex];
        // check if exists and if there is some blockIndices
        if (group && group.blockIndices.length > 1) {
            // get values ordered
            let values = group.blockIndices.sort((a, b) => a - b);
            for (let i = 0; i < values.length - 1 /* do not test the last one */; i++) {
                // difference betweend indices must be equal to 1, else there is a gap
                if(values[i + 1] - values[i] !== 1) return false;
            }
        }
        return true;
    }

    const addGroup = () => {
        surveysService.createFormGroup(formIdParameter)
            .then(result => {
                result.data.name = `${t('react.project.collectforms.group')} ${result.data.position}`;
                let tempArr = [...groups];
                tempArr.push(cloneDeep(groupTemplate, result.data));
                setGroups(tempArr);
                setSelectedIndex(tempArr.length - 1);
                setNeedToSave(true);
            })
            .catch(err => {
                openSnackbar('error', t("react.generic.error.while.loading"));
            });
    }

    const deleteGroup = (groupIndex) => {
        if(groups.length === 0) return;
        if (groups[groupIndex].id === selectedIndexGroup) {
            setSelectedIndexGroup(-1);
        }
        let tempArr = [...groups];
        tempArr.splice(groupIndex, 1);
        setSelectedIndex(tempArr.length > 0 ? (groupIndex - 1): -1);
        setGroups(tempArr);
        setNeedToSave(true);
    }

    const isChecked = (indexParam) => {
        return groups[selectedIndex] && groups[selectedIndex].blockIndices.includes(indexParam);
    }

    const checkAllIsValid = () => {
        for(let groupIdx in groups) {
            if (!validGroup(groupIdx)) return false;
        }
        return true;
    }

    const getBlockGroup = (blockIndex) => {
        return groups.find(g => g.blockIndices.includes(blockIndex));
    }

    if(!openPopupGroupsOfBlocks) return null;

    return (
        <Dialog
            open={openPopupGroupsOfBlocks}
            maxWidth={'sm'}
            fullWidth={true}
            className={classes.dialog}
            onClose={callBackCancelManageGroups}
        >
            <DialogTitleWithCloseIcon
                title={t("react.project.collectforms.groups.tooltip")}
                callbackOnclose={callBackCancelManageGroups}
            />
            <DialogContent component="div" className={classes.dialogComponent} >
                <DialogContentText component="div">
                    <div className={classes.relative}>
                        <div className={classes.groupsArea}>
                            {groups.map((group, index) => (
                                <Chip
                                    label={group.name}
                                    onClick={() => setSelectedIndex(index)}
                                    onDelete={() => deleteGroup(index)}
                                    deleteIcon={<CloseIcon />}
                                    color={selectedIndex === index ? "primary" : "default"}
                                    className={classes.chipGroup}
                                    key={index}
                                />
                            ))}
                        </div>

                        <Typography variant="h6" component="h6" >
                            <Button className={classes.buttonAddGroup}
                                disabled={groups.length >= 10}
                                variant="text" color="primary"
                                startIcon={<AddCircleOutlineIcon />}
                                onClick={() => addGroup()}>{t('react.project.collectforms.popupgroups.addgroup')}</Button>
                        </Typography>
                        <List>
                            {getAllBlocks().map((element, index) => {
                                const blockGroup = getBlockGroup(index);
                                const belongsToAnotherGroup = blockGroup && groups[selectedIndex] && blockGroup.id !== groups[selectedIndex].id;
                                
                                return (
                                <ListItem
                                    className={classes.listItem}
                                    dense
                                    key={index}
                                    button
                                    onClick={() => handleChange(index)}
                                    disabled={selectedIndex === -1 || element.type === 'introduction' || element.type === 'thankyou' || belongsToAnotherGroup}
                                >
                                    <ListItemIcon>
                                        <Checkbox
                                            checked={isChecked(index)}
                                            color="primary"
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary={computeTitle(element)} />
                                    {blockGroup &&
                                        <ListItemSecondaryAction className={classes.widthGroupBloc}>
                                            <Typography className={classes.warningGroups}>{blockGroup.name}</Typography>
                                        </ListItemSecondaryAction>
                                    }
                                </ListItem>
                            )})}
                        </List>
                        <Box className={classes.warningBox}>
                            {!validGroup(selectedIndex) && <Typography className={classes.textColorOrange}>{t("react.project.collectforms.popupgroups.warning.message")}</Typography>}
                        </Box>
                    </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    size="large"
                    startIcon={<CloseIcon />}
                    onClick={() => callBackCancelManageGroups()}
                >
                    {t('react.button.cancel')}
                </Button>
                <Button
                    variant="outlined"
                    size="large"
                    startIcon={<SaveIcon />}
                    color="primary"
                    disabled={!checkAllIsValid()}
                    onClick={() => callBackConfirmManageGroups(groups)}
                >
                    {t('react.button.apply')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
