import { isBlank } from '../utils/utils';
import ApiService from './ApiService';
require('dotenv').config()

const apiService = new ApiService();
const restClient = apiService.getClient();

const getPluginsStr = () =>{
    const x=navigator.plugins.length;
    let txt;
    for(var i=0;i<x;i++)
    {
      txt+=navigator.plugins[i].name + "|";
    }
    return txt;
}

const identity = {
    plugins: getPluginsStr(),
    screenWidth: window.screen.width,
    screenHeight: window.screen.height,
    browserName: navigator.appCodeName,
    locale: navigator.language
};

class CollectorsService {

	// -- COLLECTING METHODS - no auth required @see SecurityConfig ---------------------------------------------

  async getClientFormByCollectUuid(collectUuid) {
    return restClient.get(`/api/collectors/v1.0/of/${collectUuid}`);
  }

  async getClientForm(formId, participantId, queryParameters) {
    var i6y = {...identity};

    if(participantId !== undefined) {
      i6y.uuid = participantId;
    }

    if(queryParameters !== undefined) {
      i6y.queryParameters = queryParameters;
    }

    // get an anonymous client because there is no interceptors
    let unsignedClient = apiService.getAnonymousClient();
    return unsignedClient.post(`/api/collectors/v1.0/${formId}`, i6y);
  }

  async getPublicFormFileUrl(formId, imageId) {
      return restClient.get(`/api/collectors/v1.0/${formId}/img/${imageId}`, {responseType: 'arraybuffer'}).then(response => {
          const arrayBufferView = new Uint8Array(response.data);
          const blob = new Blob([arrayBufferView], {type: response.headers['content-type']});
          return {id: imageId, url: URL.createObjectURL(blob)};
      });
  }

  async collectExperienceAnswer(uuid, blockId, rankInSequence, words) {
      return restClient.post(`/api/collectors/v1.0/${uuid}/${blockId}/experience`, {rankInSequence: rankInSequence, words: words});
  }

  async collectQuestionsAnswer(uuid, blockId, data, enteredValue) {
      return restClient.post(`/api/collectors/v1.0/${uuid}/${blockId}/question`, {answers: data, enteredValue: enteredValue});
  }

  async collectOpenQuestionAnswer(uuid, blockId, data) {
    return restClient.post(`/api/collectors/v1.0/${uuid}/${blockId}/open-question`, {answer: data});
  }

  async collectHotSpotAnswer(uuid, blockId, data) {
    return restClient.post(`/api/collectors/v1.0/${uuid}/${blockId}/hotspot`, data);
  }
  
  async collectBatteryOfItemsAnswer(uuid, blockId, data) {
    return restClient.post(`/api/collectors/v1.0/${uuid}/${blockId}/battery`, data);
  }

  async updateCompletionState(uuid, formId, state) {
    return restClient.put(`/api/collectors/v1.0/${uuid}/${formId}/complete/${state}`);
  }

  async getParticipantAssignment(uuid, formId) {
    return restClient.get(`/api/collectors/v1.0/${uuid}/${formId}/assignment`);
  }

  async getParticipantValueAssignmentAtQuestion(uuid, formId, blockId) {
    return restClient.get(`/api/collectors/v1.0/${uuid}/${formId}/assignment/question/${blockId}`);
  }

  async checkBlockConditionsAgainstParticipant (uuid, formId, blockUuid) {
    return restClient.get(`/api/collectors/v1.0/${uuid}/${formId}/verify/${blockUuid}`)
  }

  getParameterizedExternalUrl(queryParameters, externalUrl) {
    if(isBlank(externalUrl) || !queryParameters || queryParameters.length === 0) return externalUrl;

    let parameterizedExternalUrl = externalUrl;

    const regex = /\[(.*?)\]/g;
    const matches = parameterizedExternalUrl.match(regex);

    if (matches) {
        matches.map(match => {
          const key = match.substring(1, match.length - 1);
          if(!isBlank(queryParameters[key])) {
            parameterizedExternalUrl = parameterizedExternalUrl.replace(match, queryParameters[key]);
          }
      });
    }

    return parameterizedExternalUrl;
  }
}

export default CollectorsService;
